import React, { FC, useMemo } from "react"
import { Dialog } from 'primereact/dialog'
import { Button } from "primereact/button"

interface ConfirmationDialogProps {
  visible: boolean
  onHide: () => void
  title: string | JSX.Element;
  message: string
  onConfirm: () => void
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const { visible, onHide, title, message, onConfirm } = props

  const footer = useMemo(() => (
    <div>
        <Button label="I'm aware" icon="pi pi-check" onClick={onConfirm} />
    </div>
  ), [onConfirm])

  return (
    <>  
      <Dialog visible={visible} onHide={onHide} header={title} footer={footer} style={{width: '50vw'}} closable={false}>
        <p>{message}</p>
      </Dialog>
    </>
  )
}

export default ConfirmationDialog