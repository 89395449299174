
import React, {useEffect, useState} from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { displayTwoDigits, parseFloatRemoveComma } from "../utils/convert";
import { bidItemDetailsDictionary, bidItemDictionaryDefault } from "../utils/dictionaries";
import { BidItemDetailsState, BidItemInterface, BidItemsDetailsProps, TaskCodeStructureFromDB } from "../interfaces/Interfaces";
import { InputText } from "primereact/inputtext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "primereact/button";
import { RootState } from "../redux/store";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from "react-redux";
import { setCurrentBidItemDisplay, setCurrentBidItemNumber, setCurrentChangeNumber, setExpandCollapse } from "../redux/appSlice";
import { useHistory } from 'react-router-dom';

export const BidItemsDetailsHistory = (props: any) => {
    const bidItemStructure: BidItemInterface = bidItemDetailsDictionary;
    const [globalFilter, setGlobalFilter] = useState<string | null>(null);
    const [isMinimized, setIsMinimized] = useState<boolean>(false);
    const history = useHistory();
    const handleButtonClick = () => {
        history.location.pathname = '/jobHistory';
    };
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get('date');
    const originalDate = dateParam !== null ? dateParam : "";
    const dateObj = new Date(originalDate);
    const year = dateObj.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthToShowBanner = monthNames[dateObj.getMonth()];

    const dispatch = useDispatch();

    const calculateGroupedBidItemFinalCost = (taskCodes: TaskCodeStructureFromDB[]) => {
        let total = 0;
        if (taskCodes && taskCodes.length) {
            for (let taskCode of taskCodes) {
                if (taskCode.FinalCost) {
                    total += parseFloatRemoveComma(taskCode.FinalCost);
                }
            }
        }
        return total;
    }

    const toggleSize = () => {
        setIsMinimized(!isMinimized);
    
        dispatch(setExpandCollapse(!isMinimized));
    };

    
    const BidItemColumnHeader = props.groupedBidItem ? "Billing Line" : bidItemStructure.BidItem;
    const DescriptionHeader = props.groupedBidItem ? "Change Number Description" : bidItemStructure.BidItemDescription;

    const FinalRevenueHeader = (
        <div className="table-header" style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
            <Button type="button" className="p-button-success" label='Go back to Main Page History' style={{ marginRight: '.5em' }} onClick={() => {
                dispatch(setCurrentBidItemNumber(0));
                dispatch(setCurrentBidItemDisplay(''))
                dispatch(setCurrentChangeNumber({
                    ChgNumber: '',
                    ChgNumberDesc: ''
                  }))
                history.location.pathname = '/jobHistory';
                }} />
            <div className="p-datatable-globalfilter-container">
            <h1>{`Bid Item History ${monthToShowBanner} ${year}`}</h1>
            </div>
        </div>
    );
/*
    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="" colSpan={props.groupedBidItem ? 6 : 7} footerStyle={{ textAlign: 'center' }} className={props.loading ? 'hidden' : ''} />
                <Column footer={`$${calculate_total("FinalRevenue")}`} className={props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "20", paddingLeft: "2" }} />
                <Column footer={`$${calculate_total("RemCost")}`} className={props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
                <Column footer={`$${calculate_total("FinalCost")}`} className={props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
                <Column footer={`$${calculate_total("PreviousFinalCost")}`} className={props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
                <Column footer={`$${calculate_total("Changes")}`} className={props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
            </Row>
        </ColumnGroup>
    );
*/
    const bidItemDynamicColumns = Object.entries(bidItemStructure).map(([key, value]) => {
        switch (key) {
            case "BidItem":
                return <Column 
                    body={(rowData: any) => (rowData[key] === "950") ? "950/960" : rowData[key]}
                    key={key} field={key} header={BidItemColumnHeader} headerStyle={{ textAlign: 'left', width: '6em' }}
                    style={{ textAlign: 'left', width: '6em', fontWeight: "900" }} />;
            case "BidItemDisplay":
                return null;
            case "BidItemDescription":
                return <Column key={key} field={key} header={DescriptionHeader} headerStyle={{ textAlign: 'left', width: '12em' }}
                    style={{ textAlign: 'left', width: '12em', fontWeight: "900" }} />;
            case "ChgOrderDesc":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '5em' }}
                        style={{ textAlign: 'left', width: '5em', fontWeight: "900" }} />;
            case "UM":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em' }}
                    body={(rowData: any) => rowData[key]}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "QTYBilled":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FFF9AB' }}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "JTDQty":
                    return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FAD8B1' }}
                        body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                        style={{ textAlign: 'center', width: '6em' }} />;
            case "QtyAdjustment":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#D2FACF' }}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "UnitPrice":
            case "Amount":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FFF9AB' }}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "FinalQuantity":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FAD8B1' }}
                    body={(rowData: any) => parseFloatRemoveComma(rowData[key]) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em', fontWeight: "900", backgroundColor: '#FFFCED' }} />;
            case "FinalRevenue":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                    body={(rowData: any) => parseFloatRemoveComma(rowData[key]) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em', backgroundColor: '#FFFCED' }} />;
            case "HasEditedFields":
                return null;
            case "FinalCost":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                    body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? "-" : parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "RemCost":
                      return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                          body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                          style={{ textAlign: 'center', width: '6em' }} />;
            case "GainLoss":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                    body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? '-' : parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "PreviousFinalCost":
            case "Changes":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FFA3E0' }}
                    body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? '-' : parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "BidComments":
                return <Column key={key} field={key} header={value}
                    headerStyle={{ textAlign: 'left', width: '10em', backgroundColor: '#FFA3E0' }}
                    style={{ textAlign: 'left', width: '10em', backgroundColor: '#FFFCED' }} />;
            default:
                return <Column key={key} field={key} header={value}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
        }
    });

    return (
        <div className={`card card-w-title ${isMinimized ? 'minimized' : ''}`}>
            <div className="header-container">
                <h1>{`Bid Item History ${monthToShowBanner} ${year}`}</h1>
                <Button onClick={toggleSize} className="toggle-button" label={isMinimized ? 'Expand' : 'Collapse'} />
            </div>
            {
                !isMinimized ?
                    <DataTable
                        value={props.bidItems}
                        header={FinalRevenueHeader}
                        globalFilter={globalFilter}
                        autoLayout={true}
                        editMode="cell"
                        loading={props.loading}
                        loadingIcon={'pi-md-refresh'}
                        //footerColumnGroup={footerGroup}
                    >
                        {bidItemDynamicColumns}
                    </DataTable> :
                    <div></div>
            }
        </div>
    )
}
