import { apiCall } from "./axiosRequest";
import { AxiosResponse } from "axios";

export class JobDataService {

  async getJobData(jobNumber: string) {
    const [error, response] = await apiCall("getjobdata", { jobNumber: jobNumber });

    return [error, response] as [Error | null, AxiosResponse<any> | undefined];
  }

  async resetJob(jobNumber: string) {
    const [error, response] = await apiCall("resetjob", { jobNumber: jobNumber });

    return [error, response] as [Error | null, AxiosResponse<any> | undefined];
  }

  async getJobDataHistory(jobNumber: string) {
    const [error, response] = await apiCall("getjobdatahistory", { jobNumber: jobNumber });

    return [error, response] as [Error | null, AxiosResponse<any> | undefined];
  }
}