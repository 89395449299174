import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { taskCodeDictionary } from "../utils/dictionaries";
import { exportTaskCodesToExcel } from "../utils/excelSheetsExport";
import '../assets/sass/TaskCodesDetails.scss';
import { displayTwoDigits, parseFloatRemoveComma, toLocaleStringUS, toPercentageTwoDecimals } from "../utils/convert";
import { hardcodedBidItemsRCRP } from "../utils/hardcodedBidItems";
import { TaskCodeInterface } from "../interfaces/Interfaces";
import { Checkbox } from "primereact/checkbox";

export const TaskCodesDetailsHistory = (props: any) => {
  const [globalSearch, setGlobalSearch] = useState('');
  const [ filters, setFilters] = useState({
    RemCost: false,
    Changes: false,
    JTDCost: false,
    MTDCost: false,
  })
  const [ taskcodesData, setTaskcodesData] = useState<any[]>([props.taskCode])
  const [ taskcodesFiltered, setTaskcodesFiltered] = useState<any[]>([])
  const [ isFiltered, setIsFiltered] = useState(false)

  const toastTR = useRef(null);
  const tasksCodesTable = useRef(null);
 
  const urlParams = new URLSearchParams(window.location.search);
  const dateParam = urlParams.get('date');
  const originalDate = dateParam !== null ? dateParam : "";
  const dateObj = new Date(originalDate);
  const year = dateObj.getFullYear();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthToShowBanner = monthNames[dateObj.getMonth()];

  let taskCodeItemStructure = taskCodeDictionary

  const prevPropsRef = useRef(props);
  const prevStateRef = useRef({
    filters,
    globalSearch,
  });

  useEffect(() => {
    const prevProps = prevPropsRef.current;
    const prevState = prevStateRef.current;

    if (!Object.is(prevState.filters, filters) || prevState.globalSearch !== globalSearch) {
      let isFilteredLocal = false;
      let filteredData = props.taskCodes;

      for (const [key, value] of Object.entries(filters)) {
        if (value && filteredData.length) {
          isFilteredLocal = true;
          filteredData = filterZeroValuesColumns(filteredData, key);
        }
      }

      if (isFilteredLocal) {
        setTaskcodesFiltered(filteredData);
        setIsFiltered(true);
      } else {
        setIsFiltered(false);
      }

    }

    if (prevProps.jobNumber !== props.jobNumber) {
      setGlobalSearch('');
      setTaskcodesFiltered([]);
      setIsFiltered(false);
      setFilters({
        RemCost: false,
        Changes: false,
        JTDCost: false,
        MTDCost: false,
      });
    }


    prevPropsRef.current = props;
    prevStateRef.current = { filters, globalSearch };

  }, [props, filters, globalSearch]);

      
  const filterZeroValuesColumns = (taskcodes: TaskCodeInterface[], column: string) => {
    if(column === "JTDCost"){
      column = "JTDTotalCost"
    }else if(column === "RemCost"){
      column = "RemainingTotalCost"
    }
    const filteredItems = taskcodes.filter((bi: any) => Number(parseFloatRemoveComma(bi[column]).toFixed(2)) !== 0)

    return filteredItems;
  }

  const calculateTotal = (property: any) => {
    let total = 0;
    props.taskCodesData.forEach((taskCode: { [x: string]: string | number; }) => {
      if (taskCode[property]) {
        total += parseFloatRemoveComma(taskCode[property]);
      }
    });
    return toLocaleStringUS(total);
  }

  const downloadExcel = () => {
    const taskCodes = props.jobTaskCodes;
    const filePath = `jobnumber${props.currentJobNumber}-TaskCodes-excel-from-Forecast.xlsx`;
    exportTaskCodesToExcel(taskCodes, filePath);
  }

  const handleGlobalSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setGlobalSearch(event.target.value);
  }

  const arraysEqual = (a: any[], b: any[]) => {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }

  const handleRemCostFilter = () => {
    const newFilters = {
      ...filters,
      RemCost: !filters.RemCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }

  const handleDeltaFilter = () => {
    const newFilters = {
      ...filters,
      Changes: !filters.Changes,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }

  const handleMtdCostFilter = () => {
    const newFilters = {
      ...filters,
      MTDCost: !filters.MTDCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }

  const handleJtdCostFilter = () => {
    const newFilters = {
      ...filters,
      JTDCost: !filters.JTDCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }

  const remCostHeader = (value: any) => (
    <div className='col-header-with-filter'>
      <span>{value}</span>
      <span>
        <Checkbox onChange={handleRemCostFilter} checked={filters.RemCost} />
      </span>
    </div>
  )

  const deltaHeader = (value: any) => (
    <div className='col-header-with-filter'>
      <span>{value}</span>
      <span>
        <Checkbox onChange={handleDeltaFilter} checked={filters.Changes} />
      </span>
    </div>
  )

  const mtdCostHeader = (value: any) => (
    <div className='col-header-with-filter'>
      <span>{value}</span>
      <span>
        <Checkbox onChange={handleMtdCostFilter} checked={filters.MTDCost} />
      </span>
    </div>
  )

  const jtdCostHeader = (value: any) => (
    <div className='col-header-with-filter'>
      <span>{value}</span>
      <span>
        <Checkbox onChange={handleJtdCostFilter} checked={filters.JTDCost} />
      </span>
    </div>
  )

  let taskCodesColumns = [Object.entries(taskCodeItemStructure).map(([key, value]) => {
    switch (key) {
      case "Taskcode":
        return <Column 
          key={key} 
          field={key} 
          header={value} 
          style={{ textAlign: 'left', width: '4.5em', minWidth: '4.75em' }}
          className="task-code-id-cell"
          />;
      case "TaskcodeDescription":
        return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '6em', minWidth: '6em' }}
          style={{ textAlign: 'left', width: '3em' }} />;
      case "UnitOfMeasure":
        return <Column key={key} field={key} header={value} style={{ textAlign: 'center', width: '6em', minWidth: '6em' }} />;
      case "BudgetUnits":
      case "BudgetUnitCost":
      case "BudgetTotalCost":
        return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFF9AB' }}
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "JTDUnits":
      case "JTDUnitCost":
        return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#D2FACF' }}
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "MTDCost":
        return <Column key={key} field={key} header={mtdCostHeader(value)}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#D2FACF' }}
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "JTDTotalCost":
        return <Column key={key} field={key} header={jtdCostHeader(value)}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#D2FACF' }}
          body={(rowData: any) => {
            const value = rowData[key];
            if (isNaN(parseFloat(value))) {
                return value;
            } else {
                const valueFinalCost = parseFloatRemoveComma(rowData["FinalCost"]); 
                const valueJTDTotalCost = parseFloatRemoveComma(rowData["JTDTotalCost"]);
                const valueJTDFinalQTY = parseFloatRemoveComma(rowData["FinalQuantity"]);
                const valueJTDFinalUnitCost = parseFloatRemoveComma(rowData["FinalUnitCost"]);
                const valueCCOs = rowData["CCOs"]
                let highlightTheNumber = false
                if (valueJTDTotalCost > valueFinalCost) {
                  if (valueCCOs) {
                      let finalNumber = valueJTDFinalQTY * valueJTDFinalUnitCost;
                      if (valueJTDTotalCost > finalNumber) {
                        highlightTheNumber = true
                      }
                  } else {
                    highlightTheNumber = true
                  }
                }
                return !highlightTheNumber ? (
                  displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span>{displayTwoDigits(rowData[key])}</span>
                ) 
                : (displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span style={{backgroundColor: '#FEA28E', padding: '0.5em'}}>{displayTwoDigits(rowData[key])}</span>);
            }
        }}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "RemainingUnits":
      case "RemainingUnitCost":
        return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FAD8B1' }}
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "RemainingTotalCost":
        return <Column key={key} field={key} header={remCostHeader(value)}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FAD8B1' }}
          body={(rowData: any) => {
            const value = rowData[key];
            const jtdTotalCost = parseFloatRemoveComma(rowData["JTDTotalCost"]);
            const valueCCOs = rowData["CCOs"];
            const remainingTotalCost = parseFloatRemoveComma(rowData["RemainingTotalCost"]);
            const finalQuantity = parseFloatRemoveComma(rowData["FinalQuantity"]);
            const finalUnitCost = parseFloatRemoveComma(rowData["FinalUnitCost"]);
            const finalCost = parseFloatRemoveComma(rowData["FinalCost"]);
            const calculatedCost = jtdTotalCost + remainingTotalCost;
            const finalCostCalculation1 = calculatedCost - (finalQuantity * finalUnitCost);
            const finalCostCalculation2 = calculatedCost - finalCost;

            if (isNaN(parseFloat(value))) {
              return displayTwoDigits(value);
            }

            const displayedValue = displayTwoDigits(value);

            if (displayedValue === "0.00") {
              return "-";
            }

            if (displayTwoDigits(finalCost) === "0.00") {
              if (finalCostCalculation1 > -0.5 && finalCostCalculation1 < 0.5) {
                return displayedValue;
              } else {
                return (
                  <span
                    title="The remaining cost is outdated, please fix by inputting the same Final Cost again to recalculate"
                    style={{ backgroundColor: '#FFF9AB', padding: '0.5em' }}
                  >
                    {displayedValue}
                  </span>
                );
              }
            } else {
              if (finalCostCalculation2 > -0.5 && finalCostCalculation2 < 0.5) {
                return displayedValue;
              } else {
                return (
                  <span
                    title="The remaining cost is outdated, please fix by inputting the same Final Cost again to recalculate"
                    style={{ backgroundColor: '#FFF9AB', padding: '0.5em' }}
                  >
                    {displayedValue}
                  </span>
                );
              }
            }
            

            if (valueCCOs === null) {
              return (
                <span
                  title="The remaining cost is outdated, please fix by inputting the same Final Cost again to recalculate"
                  style={{ backgroundColor: '#FFF9AB', padding: '0.5em' }}
                >
                  {displayedValue}
                </span>
              );
            }

return (
  <span style={{ backgroundColor: '#FFFCED', padding: '0.5em' }}>
    {displayedValue}
  </span>
);
          }}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "FinalQuantity":
      case "FinalUnitCost":
        return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#BADFF5' }}
          body={(rowData: any) => {
            const CCOsValue = rowData['CCOs'];
            return isNaN(parseFloat(rowData[key])) 
              ? rowData[key] 
              : CCOsValue !== null && CCOsValue[0].isTotalAllocated === true ? "-"
              : displayTwoDigits(rowData[key]) === "0.00" || displayTwoDigits(rowData[key]) === "∞" 
              ? "-" 
              : displayTwoDigits(rowData[key]);
          }}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "FinalCost":
        return <Column key={key} field={key} header={value}
        headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#BADFF5' }}
        body={(rowData: any) => {
          const value = rowData[key];
          if (isNaN(parseFloat(value))) {
              return value;
          } else {
              const valueFinalCost = parseFloatRemoveComma(rowData["FinalCost"]); 
              const valueJTDTotalCost = parseFloatRemoveComma(rowData["JTDTotalCost"]);
              const valueJTDFinalQTY = parseFloatRemoveComma(rowData["FinalQuantity"]);
              const valueJTDFinalUnitCost = parseFloatRemoveComma(rowData["FinalUnitCost"]);
              const valueCCOs = rowData["CCOs"]
              let highlightTheNumber = false
              if (valueJTDTotalCost > valueFinalCost) {
                if (valueCCOs) {
                    let finalNumber = valueJTDFinalQTY * valueJTDFinalUnitCost;
                    if (valueJTDTotalCost > finalNumber) {
                      highlightTheNumber = true
                    }
                } else {
                  highlightTheNumber = true
                }
              }
              return !highlightTheNumber ? (
                displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span>{displayTwoDigits(rowData[key])}</span>
              ) 
              : (displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span style={{backgroundColor: '#FEA28E', padding: '0.5em'}}>{displayTwoDigits(rowData[key])}</span>);
          }
      }}
        style={{ textAlign: 'center', width: '3em' }} />;  
      case "QtyAdjustment":
        return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#BADFF5' }}
          
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;  
      case "RecoveryPercent":
        return (props.specialBidItem && hardcodedBidItemsRCRP.includes(props.currentBidItemNumber)) 
          ? <Column key={key} field={key} header={value}
              headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: 'orange' }}
              body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : toPercentageTwoDecimals(rowData[key])}
              style={{ textAlign: 'center', width: '3em'}} />
          : null
      case "CostGL":
      case "LastMonthFinalCost":
        return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFA3E0' }}
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "Changes":
        return <Column key={key} field={key} header={deltaHeader(value)}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFA3E0' }}
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;
      case "Comments":
        return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'left', width: '6em', minWidth: '6em', backgroundColor: '#FFA3E0' }}
          style={{ textAlign: 'left', width: '3em'}}
          bodyStyle={{ maxWidth: '100%', overflow: 'hidden'}}
          />;
      case "CCOs":
        return null;
      default:
        // If it's a number, add comma separator
        return <Column key={key} field={key} header={value}
          body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
          style={{ textAlign: 'center', width: '3em' }} />;
    }
  })];

  const header = (
    <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
      {`Task Codes History ${monthToShowBanner} ${year}`}
      <div>
        {/* Additional header content */}
      </div>
    </div>
  );

  return (
    <div>
      <Growl ref={toastTR} />
      <div className="card card-w-title" ref={tasksCodesTable}>
        <div className="taskcodes-table-title">
          <div>
            <h1>Task Codes History</h1>
          </div>
        </div>
        <DataTable
          value={(isFiltered || globalSearch)
            ? taskcodesFiltered
            : props.taskCodes
          }
          header={header}
          autoLayout={true}
          editMode="cell"
          globalFilter={globalSearch}
          loading={props.loading}
          loadingIcon={'pi-md-refresh'}
          scrollable
          scrollHeight={!props.setExpandCollapse ? "600px" : "600px"}
          className="taskcodes-table"
          style={{ width: '100%', margin: '0 auto', overflowX: 'scroll' }}
        >
          {taskCodesColumns}
        </DataTable>
      </div>
    </div>
  );
}
