import  { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Location } from "history";

export const getParams = <Params extends { [K in keyof Params]?: string } = {}>(location: Location<any>) => {
	const params = new URLSearchParams(location.search);
	const newState: Params = {} as any;
	params.forEach((value, key) => {
		// @ts-expect-error
		newState[key] = value;
	});
	return newState;
}

const useQueryParams = <Params extends { [K in keyof Params]?: string } = {}>() => {
    const location = useLocation();
	const history = useHistory();

    const [state, setState] = useState<Params>({} as any);

    useEffect(() => {
        setState(getParams(location));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

	useEffect(() => {
		return history.listen(() => {
			setState(getParams(history.location));
		});
	}, [history])

    return state;
};

export default useQueryParams;