import React from "react";
import "./MessageComponent.css"

export const MessageComponent = (data: any) => {
  
  return (
      <div className="message-container">
        <div className="message">
          {data.props[0]?.length > 0 ? <div><span>These taskcodes are not being displayed: {data.props[0]}</span><br /><br /></div> : <span></span>}
          {data.props[1]?.length > 0 ? <span>There are taskcodes that have Final Cost lower than JTD Cost {data.props[1]}<br /><br /></span> : <span></span>}
        </div>
      </div>
  );
}