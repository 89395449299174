import { BidItemToBeSaved } from "../interfaces/Interfaces";

export const sanitizeBidItemToBeSaved = (bidItem: BidItemToBeSaved): BidItemToBeSaved => {
  let sanitizedBidItem: BidItemToBeSaved = {...bidItem};

  let regex = /(')/g;

  const replacer = (match: string): string => {
    switch (match) {
      case `'`:
        return `''`;
      default:
        return '';
    }
  }

  for (let key in sanitizedBidItem) {
    if (key === 'BidItemDescription' && sanitizedBidItem[key].match(regex)) {
      let stringReplaced = sanitizedBidItem[key].replace(regex, replacer);
      sanitizedBidItem[key] = stringReplaced;
    }
  }

  return sanitizedBidItem;
}