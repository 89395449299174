import { AnyAction, createSlice, PayloadAction  } from "@reduxjs/toolkit";
import { getTokenFromCookie, getIdTokenFromCookie, getRefreshTokenFromCookie } from "../auth/getTokenFromCookie";
import {
  AppState,
  AuthState,
  JobsMenuBidItemInterface,
  JobsMenuItem,
  AssignableTaskCode,
  ExecutiveSummary,
  CCOInTaskCode,
  TaskCodeStructureFromDB,
  JobsListRefresh,
} from "../interfaces/Interfaces";
import { setBidItemInitialValues } from "../utils/bidItemFieldsCalculations";
import { parseFloatRemoveComma } from "../utils/convert";

import { hardcodedBidItems } from "../utils/hardcodedBidItems";
import { linkTaskCodesToBidItems } from  "../utils/maintainLinkedTaskCodesToBidItems";
import { taskCodesBidItemFor888 } from "../utils/utils";

export const getInitialState = (token?: string | null | undefined, id_token?: string | null | undefined, refresh_token?: string | null | undefined): AppState => ({
  auth: {
    token: token === undefined ? getTokenFromCookie() : token,
    id_token: id_token === undefined ? getIdTokenFromCookie() : id_token,
    refresh_token: refresh_token === undefined ? getRefreshTokenFromCookie() : refresh_token
  },
  forecast: {
    constructionOperations: {}
  },
  currentJobNumber: "0",
  currentJobDescription: "",
  currentBidItemNumber: "000",
  currentChangeNumber: "000",
  currentChangeNumberDesc: "",
  selectedBidItems: [],
  selectedTaskCodes: [],
  selectedPCs: [],
  setCurrentBidItemDisplay: "",
  jobsMenuItems: [],
  jobsMenuItemsHistory: [{BidItems: [], TaskCodes: [], PCs: [], AssignableTaskCodes: []}],
  changeJobInProgress: false,
  resetJobInProgress: false,
  unAssignedTaskCodesToBidItemsIds: [],
  setLoadingExecutiveSummary: false,
  lastUpdated: '',
  isJobDataLoading: false,
  setUsername: '',
  taskcodesWithoutBidItem: [],
  taskcodesWithFlagsFC: [],
  pendingCostAssigment: [],
  setExpandCollapse: false,
  lastBidItemNumber: "000",
  lastChgNumber: "000",
  hasDisplayedFinalCostMessage: false

});

export function getPCsFromBidItems (bidItemsFromServer: any[]): any[] {
  return bidItemsFromServer.filter(bidItems =>
    bidItems.ChgNumber !== "000"
  ) || [];
}

export function removePCsFromBidItems (bidItemsFromServer: any[], pcList: any[]): any[] {
  return bidItemsFromServer.filter((bidItemFromServer) => !pcList.some(
    pcListItem => bidItemFromServer === pcListItem
  ));
}

export function updateBudgetTotalAndFinalCost (hardcodedBidItemsNotPresentInDatabase: any[], taskCodesFromServer: any[]): void {
  hardcodedBidItemsNotPresentInDatabase.forEach((bidItem) => {
    if (bidItem.BidItem === "888") {
      // The value of BudgetTotalCost from all the TaskCodes which are assigned to the 888 Bid Item
      let calculatedAmount = taskCodesFromServer.filter(taskCode => taskCodesBidItemFor888.includes(taskCode.BidItem))?.map(
        (taskCode) => taskCode.BudgetTotalCost)?.reduce(
        (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);

      // The value of FinalCost from all the TaskCodes which are assigned to the 888 Bid Item
      let calculatedFinalCost = taskCodesFromServer.filter(taskCode => taskCodesBidItemFor888.includes(taskCode.BidItem))?.map(
        (taskCode) => taskCode.FinalCost)?.reduce(
        (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);

      bidItem.Amount = calculatedAmount;
      bidItem.FinalCost = calculatedFinalCost;
    } else {

      // The value of BudgetTotalCost from all the TaskCodes of the assigned Bid Item
      let calculatedAmount = taskCodesFromServer.filter(taskCode => taskCode.BidItem === bidItem.BidItem)?.map(
        (taskCode) => taskCode.BudgetTotalCost)?.reduce(
        (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);

      // The value of FinalCost from all the TaskCodes of the assigned Bid Item
      let calculatedFinalCost = taskCodesFromServer.filter(taskCode => taskCode.BidItem === bidItem.BidItem)?.map(
        (taskCode) => taskCode.FinalCost)?.reduce(
        (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);

      // The value of JTDCost from all the TaskCodes of the assigned Bid Item
      let calculatedJTDCost = taskCodesFromServer.filter(taskCode => taskCode.BidItem === bidItem.BidItem)?.map(
          (taskCode) => taskCode.JTDCost)?.reduce(
          (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);
      
      // The value of MTDCost from all the TaskCodes of the assigned Bid Item
      let calculatedMTDCost = taskCodesFromServer.filter(taskCode => taskCode.BidItem === bidItem.BidItem)?.map(
        (taskCode) => taskCode.MTDCost)?.reduce(
        (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);

      bidItem.Amount = calculatedAmount;
      bidItem.FinalCost = calculatedFinalCost;
      bidItem.MTDCost = calculatedMTDCost;
      bidItem.JTDCost = calculatedJTDCost;
    }
  });
}

export function updateMTDCostlAndJTDCost (bidItemsFromServer: any[], taskCodesFromServer: any[]): void {
  
  bidItemsFromServer.forEach((bidItem) => {

      // The value of JTDCost from all the TaskCodes of the assigned Bid Item
      let calculatedJTDCost = taskCodesFromServer.filter(taskCode => taskCode.BidItem === bidItem.BidItem)?.map(
          (taskCode) => taskCode.JTDTotalCost)?.reduce(
          (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);
      
      // The value of MTDCost from all the TaskCodes of the assigned Bid Item
      let calculatedMTDCost = taskCodesFromServer.filter(taskCode => taskCode.BidItem === bidItem.BidItem)?.map(
        (taskCode) => taskCode.MTDCost)?.reduce(
        (previousValue, currentValue) => parseFloatRemoveComma(previousValue) + parseFloatRemoveComma(currentValue), 0);

      bidItem.MTDCost = calculatedMTDCost;
      bidItem.JTDCost = calculatedJTDCost;
  });
}

export function composePCs (pcList: any[]): any[] {
  let groupedPCsByChgNumber: any[] = [];
  let composedPCsList: any[] = [];
  let emptyBidItem = {
    Amount: 0,
    FinalCost: 0,
    BidItem: "-",
    BidItemDescription: "-",
    ChgNumber: "",
    ChgOrderDesc: "",
    MTDCost: 0,
    JTDCost: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    GLDate: "-",
    JobDescription: "",
    JobNumber: "",
    LastUpdated: "-",
    QTYBilled: 0,
    QtyAdjustment: 0,
    UM: "-",
    UnitPrice: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  };

  pcList.forEach(bidItem => {
    if (bidItem.ChgNumber !== "000") {
      // We group all the PC Items by the Chgnumber
      groupedPCsByChgNumber[bidItem.ChgNumber] = [...groupedPCsByChgNumber[bidItem.ChgNumber] || [], bidItem];
    }
  });

  // For each unique ChgNumber
  for (let [, value] of Object.entries(groupedPCsByChgNumber)) {

    // we create an empty "composedPC"
    let composedPC = JSON.parse(JSON.stringify(emptyBidItem));

    // For each Pc Item for that unique ChgNumber, we sum up the values inside the "composedPC"
    value.forEach((pc: any) => {
      composedPC.Amount = (parseFloatRemoveComma(composedPC.Amount) || 0) + (parseFloatRemoveComma(pc.Amount) || 0);
      composedPC.FinalCost = (parseFloatRemoveComma(composedPC.FinalCost) || 0) + (parseFloatRemoveComma(pc.FinalCost) || 0);
      composedPC.ChgNumber = pc.ChgNumber;
      composedPC.ChgOrderDesc = pc.ChgOrderDesc;
      composedPC.FinalQuantity = (parseFloatRemoveComma(composedPC.FinalQuantity) || 0) + (parseFloatRemoveComma(pc.FinalQuantity) || 0);
      composedPC.FinalRevenue = (parseFloatRemoveComma(composedPC.FinalRevenue) || 0) + (parseFloatRemoveComma(pc.FinalRevenue) || 0);
      composedPC.JobDescription = pc.JobDescription;
      composedPC.JobNumber = pc.JobNumber;
      composedPC.QTYBilled = (parseFloatRemoveComma(composedPC.QTYBilled) || 0) + (parseFloatRemoveComma(pc.QTYBilled) || 0);
      composedPC.QtyAdjustment = (parseFloatRemoveComma(composedPC.QtyAdjustment) || 0) + (parseFloatRemoveComma(pc.QtyAdjustment) || 0);
      composedPC.UnitPrice = (parseFloatRemoveComma(composedPC.UnitPrice) || 0) + (parseFloatRemoveComma(pc.UnitPrice) || 0);
      composedPC.GainLoss = (parseFloatRemoveComma(composedPC.GainLoss) || 0) + (parseFloatRemoveComma(pc.GainLoss) || 0);
      composedPC.PreviousFinalCost = (parseFloatRemoveComma(composedPC.PreviousFinalCost) || 0) + (parseFloatRemoveComma(pc.PreviousFinalCost) || 0);
      composedPC.Changes = (parseFloatRemoveComma(composedPC.Changes) || 0) + (parseFloatRemoveComma(pc.Changes) || 0);
    });

    composedPCsList = [...composedPCsList, composedPC]
  }
  
  return composedPCsList.sort((PC1, PC2) => {
    return PC1.ChgNumber > PC2.ChgNumber ? 1 : -1
  });
}

type ExecutiveSummaryData = {
  jobNumber: string,
  data: ExecutiveSummary
}

type UpdateExecutiveSummaryData = {
  jobNumber: string,
  Comments: string,
  Submit?: boolean,
  DateNow?: string,
  User?: string
}

export const appSlice = createSlice({
  name: 'app',
  initialState: getInitialState(),
  reducers: {
    reset: () => {
      return getInitialState();
    },
    login: (state, action: PayloadAction<AuthPayload>) => {
      state.auth = action.payload;
    },
    logout: () => {
      return ({
        ...getInitialState(null, null, null)
      })
    },
    addToken: (state, action: PayloadAction<Partial<AuthState>>) => {
      state.auth.token = action.payload.token;
      state.auth.id_token = action.payload.id_token;
      state.auth.refresh_token = action.payload.refresh_token;
    },
    init: (state) => {
      state.currentJobNumber = "0";
      state.currentJobDescription = "";
      state.currentBidItemNumber = "000";
      state.jobsMenuItems = [];
      state.selectedBidItems = [];
      state.selectedTaskCodes = [];
      state.selectedPCs = [];
      state.changeJobInProgress = false;
      state.unAssignedTaskCodesToBidItemsIds = [];
      state.resetJobInProgress = false;
    },
    // TODO: research the Any Action
    setCurrentBidItemNumber: (state, action: PayloadAction<any>) => {
      state.currentBidItemNumber = action.payload;
    },
    setCurrentJobNumberAndDescription: (state, action: PayloadAction<any>) => {
      state.currentJobNumber = action.payload.JobNumber;
      state.currentJobDescription = action.payload.JobDescription;
    },
    setCurrentChangeNumber: (state, action: PayloadAction<any>) => {
      state.currentChangeNumber = action.payload.ChgNumber;
      state.currentChangeNumberDesc = action.payload.ChgNumberDesc;
    },
    setCurrentBidItemDisplay: (state, action: PayloadAction<any>) => {
      state.setCurrentBidItemDisplay = action.payload;
    },
    setJobsMenuNumberAndDescriptions: (state, action: PayloadAction<JobsListRefresh>) => {
      const jobsFromServer = action.payload.jobsMenuItems
      const currentJobNumber = action.payload.currentJobNumber

      if (!state.jobsMenuItems.length && jobsFromServer?.length) {
        state.jobsMenuItems = jobsFromServer;
      } else {
        jobsFromServer?.forEach((job) => {
          let stateJobIndex = state.jobsMenuItems.findIndex(stateJob => stateJob.JobNumber === job.JobNumber);
      
          if (stateJobIndex >= 0) {
            if (state.jobsMenuItems[stateJobIndex].JobNumber === currentJobNumber) {
              state.jobsMenuItems[stateJobIndex] = {
                ...state.jobsMenuItems[stateJobIndex],
                JobDescription: job.JobDescription,
                JobNumber: job.JobNumber,
                Area: job.Area,
                PM: job.PM
              }
            } else {
              state.jobsMenuItems[stateJobIndex] = job  
            }
          } else {
            state.jobsMenuItems.push(job);
          }
        })

        state.jobsMenuItems.forEach((job) => {
          const jobFromServer = jobsFromServer?.find(j => j.JobNumber === job.JobNumber)

          if (jobFromServer) {
            job = {
              ...job,
              JobDescription: jobFromServer.JobDescription,
              JobNumber: jobFromServer.JobNumber,
              Area: jobFromServer.Area,
              PM: jobFromServer.PM
            }
          }
        });
      }
    },

    setCurrentBidItems: (state) => {
      // Get the Bid Items from the current Job Number
      let currentBidItems = state.jobsMenuItems.find(jobMenuItem => jobMenuItem.JobNumber === state.currentJobNumber)?.BidItems || [];
      // let jobMenuItemIndex = state.jobsMenuItems?.findIndex(jobMenuItem => jobMenuItem.JobNumber === state.currentJobNumber);
      // let BidItemIndex = state.jobsMenuItems[jobMenuItemIndex].BidItems.findIndex(bidItem => bidItem.BidItem === state.currentBidItemNumber);
      state.selectedBidItems = [];
      
      if (state.currentChangeNumber !== "000") {
        // The bid Item selected here will be the composed Bid item which will contain the TaskCode list

        // Filter the ones that has the ChgNumber equal with the currentChangeNumber
        state.selectedBidItems = currentBidItems.filter(bidItems => bidItems.ChgNumber === state.currentChangeNumber) || [];
      } else {
        state.selectedBidItems = currentBidItems.filter(bidItems => bidItems.BidItem === state.currentBidItemNumber) || [];
      }
    },
    setCurrentTaskCodes: (state) => {
      state.selectedTaskCodes = [];

      if (state.currentChangeNumber !== "000") {
        // Filter the task codes that has CCOs with the ChgNumber equal with the currentChangeNumber
        let jobMenuItemIndex = state.jobsMenuItems?.findIndex(jobMenuItem => jobMenuItem.JobNumber === state.currentJobNumber);
        let changeNumberIndex = state.jobsMenuItems[jobMenuItemIndex].BidItems?.findIndex(bidItem => bidItem.ChgNumber === state.currentChangeNumber);
        let taskcodesCCOsNotNull = state.jobsMenuItems[jobMenuItemIndex].TaskCodes?.filter(taskcode => taskcode.CCOs !== null);
        
        if (taskcodesCCOsNotNull && taskcodesCCOsNotNull.length > 0) {
          let ccosIndex: number; 
          let taskCodes: TaskCodeStructureFromDB[] = [];

          let taskCodesToBeSet = [...JSON.parse(JSON.stringify(taskcodesCCOsNotNull))]
          
          for (let i = 0; i < taskCodesToBeSet.length; i++) {
            if (Array.isArray(taskCodesToBeSet[i].CCOs)) {
              ccosIndex = taskCodesToBeSet[i].CCOs.findIndex((cco: { ChgNumber: string; }) => cco.ChgNumber === state.currentChangeNumber);
          } else if (typeof taskCodesToBeSet[i].CCOs === 'object' && taskCodesToBeSet[i].CCOs !== null) {
              const ccosArray = [taskCodesToBeSet[i].CCOs];
              ccosIndex = ccosArray.findIndex((cco) => cco.ChgNumber === state.currentChangeNumber);
          } else {
              continue;
          }
      
          if (ccosIndex !== -1) {
              taskCodes.push(taskCodesToBeSet[i]);
          }
          }

          let sortedTaskCodes = taskCodes.sort((taskCode1, taskCode2)=> taskCode1.Taskcode > taskCode2.Taskcode ? 1 : -1);

          state.jobsMenuItems[jobMenuItemIndex].BidItems[changeNumberIndex].TaskCodeItems = sortedTaskCodes;
          state.selectedTaskCodes = sortedTaskCodes;
        }
      } else {
        state.selectedBidItems.forEach(bidItem => {
          if (bidItem.TaskCodeItems) {
            state.selectedTaskCodes = [...state.selectedTaskCodes, ...bidItem.TaskCodeItems].sort((taskCode1, taskCode2) => {
              return taskCode1.Taskcode > taskCode2.Taskcode ? 1 : -1
            })
          }
        });
      }
    },
    setCurrentBidItemsHistory: (state) => {
      // Get the Bid Items from the current Job Number
      let currentBidItems = state.jobsMenuItemsHistory[0]?.BidItems || [];
      // let jobMenuItemIndex = state.jobsMenuItems?.findIndex(jobMenuItem => jobMenuItem.JobNumber === state.currentJobNumber);
      // let BidItemIndex = state.jobsMenuItems[jobMenuItemIndex].BidItems.findIndex(bidItem => bidItem.BidItem === state.currentBidItemNumber);
      state.selectedBidItems = [];
      
      if (state.currentChangeNumber !== "000") {
        // The bid Item selected here will be the composed Bid item which will contain the TaskCode list

        // Filter the ones that has the ChgNumber equal with the currentChangeNumber
        state.selectedBidItems = currentBidItems.filter((bidItems: { ChgNumber: string; }) => bidItems.ChgNumber === state.currentChangeNumber) || [];
      } else {
        state.selectedBidItems = currentBidItems.filter((bidItems: { BidItem: string; }) => bidItems.BidItem === state.currentBidItemNumber) || [];
      }
    },
    setCurrentTaskCodesHistory: (state) => {
      state.selectedTaskCodes = [];

      if (state.currentChangeNumber !== "000") {
        // Filter the task codes that has CCOs with the ChgNumber equal with the currentChangeNumber
        let changeNumberIndex = state.jobsMenuItemsHistory[0].BidItems?.findIndex((bidItem: { ChgNumber: string; }) => bidItem.ChgNumber === state.currentChangeNumber);
        let taskcodesCCOsNotNull = state.jobsMenuItemsHistory[0].TaskCodes?.filter((taskcode: { CCOs: null; }) => taskcode.CCOs !== null);
        
        if (taskcodesCCOsNotNull && taskcodesCCOsNotNull.length > 0) {
          let ccosIndex: number; 
          let taskCodes: TaskCodeStructureFromDB[] = [];

          let taskCodesToBeSet = [...JSON.parse(JSON.stringify(taskcodesCCOsNotNull))]
          
          for (let i = 0; i < taskCodesToBeSet.length; i++) {
            if (Array.isArray(taskCodesToBeSet[i].CCOs)) {
              ccosIndex = taskCodesToBeSet[i].CCOs.findIndex((cco: { ChgNumber: string; }) => cco.ChgNumber === state.currentChangeNumber);
          } else if (typeof taskCodesToBeSet[i].CCOs === 'object' && taskCodesToBeSet[i].CCOs !== null) {
              const ccosArray = [taskCodesToBeSet[i].CCOs];
              ccosIndex = ccosArray.findIndex((cco) => cco.ChgNumber === state.currentChangeNumber);
          } else {
              continue;
          }
      
          if (ccosIndex !== -1) {
              taskCodes.push(taskCodesToBeSet[i]);
          }
          }

          let sortedTaskCodes = taskCodes.sort((taskCode1, taskCode2)=> taskCode1.Taskcode > taskCode2.Taskcode ? 1 : -1);

          state.jobsMenuItemsHistory[0].BidItems[changeNumberIndex].TaskCodeItems = sortedTaskCodes;
          state.selectedTaskCodes = sortedTaskCodes;
        }
      } else {
        state.jobsMenuItemsHistory[0].TaskCodes.forEach((taskCode: { BidItem: string; }) => {
          if (taskCode.BidItem === state.currentBidItemNumber) {
            state.selectedTaskCodes.push(taskCode)
          }
            state.selectedTaskCodes.sort((taskCode1, taskCode2) => {
              return taskCode1.Taskcode > taskCode2.Taskcode ? 1 : -1
            })
        });
      }
    },
    updatePCItem: (state, action: AnyAction) => {
      const pcItem = {...action.payload.pcItem} as JobsMenuBidItemInterface
      
      const jobIndex = state.jobsMenuItems.findIndex(job => job.JobNumber === pcItem.JobNumber)
      const jobPCIndex = state.jobsMenuItems[jobIndex]?.PCs?.findIndex(pc => pc.BidItem === pcItem.BidItem && pc.ChgNumber === pcItem.ChgNumber)

      if (jobPCIndex >= 0) {
        state.jobsMenuItems[jobIndex].PCs[jobPCIndex] = pcItem
      }

      const selectedPCIndex = state.selectedPCs?.findIndex(pc => pc.BidItem === pcItem.BidItem && pc.ChgNumber === pcItem.ChgNumber)

      if (selectedPCIndex >= 0) {
        state.selectedPCs[selectedPCIndex] = pcItem
      }
    },
    setCurrentPCs: (state) => {
      state.selectedPCs = [];
      // Get the PCs from the current Job Number
      let currentPCs = state.jobsMenuItems.find(jobMenuItem => jobMenuItem.JobNumber === state.currentJobNumber)?.PCs || [];
      // In case that the current ChgNumber is not 000, we let the selectedPCs items clear
      if (state.currentChangeNumber !== "000") {
        // Filter the ones that has the ChgNumber equal with the currentChangeNumber
        state.selectedPCs = currentPCs.filter(pcItem =>
          pcItem.ChgNumber === state.currentChangeNumber
        ) || [];
      }
    },
    setCurrentPCsHistory: (state) => {
      state.selectedPCs = [];
      // Get the PCs from the current Job Number
      let currentPCs = state.jobsMenuItemsHistory.find((jobMenuItem: { JobNumber: string; }) => jobMenuItem.JobNumber === state.currentJobNumber)?.PCs || [];
      // In case that the current ChgNumber is not 000, we let the selectedPCs items clear
      if (state.currentChangeNumber !== "000") {
        // Filter the ones that has the ChgNumber equal with the currentChangeNumber
        state.selectedPCs = currentPCs.filter((pcItem: { ChgNumber: string; }) =>
          pcItem.ChgNumber === state.currentChangeNumber
        ) || [];
      }
    },
    setJobsMenuBidItemsAndTaskCodes: (state, action: PayloadAction<any>) => {
      let bidItemsFromServer = action.payload.data.BidItems;
      let taskCodesFromServer = action.payload.data.TaskCodes;

      // Get the list of Hardcoded Bid Items. See if there are any already from the DB (don't show those that already exists)
      let hardcodedBidItemsNotPresentInDatabase = JSON.parse(JSON.stringify(hardcodedBidItems.filter((hardcodedBidItem) => !bidItemsFromServer.some(
        (bidItem: JobsMenuBidItemInterface) => bidItem.BidItem === hardcodedBidItem.BidItem
      ))));

      // Assign Budget and Final Cost for Hardcoded Bid Items based on the assigned TaskCodes
      updateBudgetTotalAndFinalCost(hardcodedBidItemsNotPresentInDatabase, taskCodesFromServer);

      let currentJobMenuItemIndex = state.jobsMenuItems?.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === action.payload.JobNumber);

      // Handle the PCs (items which have a ChgNumber different than "000")
      let pcList = getPCsFromBidItems(bidItemsFromServer);

      //is sorted numerically by the BidItem field
      let bidItemsWithoutPCs = removePCsFromBidItems(bidItemsFromServer, pcList)

      let sortedBidItems = [...bidItemsWithoutPCs, ...hardcodedBidItemsNotPresentInDatabase].sort((BT_a, BT_b) => {        
        let a: number = parseInt(BT_a.BidItem, 10); 
        let b: number = parseInt(BT_b.BidItem, 10); 
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0;
      });

      let composedPCs = composePCs(pcList);

      // @ts-ignore
      taskCodesFromServer.forEach(element => {
        if (taskCodesBidItemFor888.includes(element.BidItem)) {
          element.BidItem = '888'
        }
      });

      if (currentJobMenuItemIndex >= 0) {
        state.jobsMenuItems[currentJobMenuItemIndex].BidItems = [...sortedBidItems, ...composedPCs];
        state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes = taskCodesFromServer || [];
        state.jobsMenuItems[currentJobMenuItemIndex].PCs = pcList;
        // We are keeping from the original Taskcodes only the 4 properties from below.
        // @ts-ignore
        state.jobsMenuItems[currentJobMenuItemIndex].AssignableTaskCodes = taskCodesFromServer.map(({Taskcode, BidItem, TaskcodeDescription, UnitOfMeasure, BudgetUnits}) => ({
          Taskcode, BidItem, TaskcodeDescription, UnitOfMeasure, BudgetUnits
        })) || [];
      } else {
        state.jobsMenuItems?.push({
          JobNumber: bidItemsFromServer[0]?.JobNumber.toString(),
          JobDescription: bidItemsFromServer[0]?.JobDescription,
          BidItems: [...bidItemsWithoutPCs, ...hardcodedBidItemsNotPresentInDatabase, ...composedPCs],
          TaskCodes: taskCodesFromServer || [],
          PCs: pcList,
          // @ts-ignore
          AssignableTaskCodes: taskCodesFromServer.map(({ Taskcode, BidItem, TaskcodeDescription, UnitOfMeasure, BudgetUnits }) =>
          ({Taskcode, BidItem, TaskcodeDescription, UnitOfMeasure, BudgetUnits})) || [],
          ExecutiveSummary: {
            AllocatedIndirects: null,
            BackchargesRevenue: null,
            BaseContractItemsBilling: null,
            BaseContractItemsCost: null,
            BaseContractItemsRevenue: null,
            CCOBilling: null,
            CCOs: null,
            Claims: null,
            Comments: "",
            Date: "",
            ExecutiveCCOs: null,
            Variance: null,
            JobNumber: "",
            Maintenance: null,
            Margin: null,
            Mobilization: null,
            Overhead: null,
            PercentComplete: null,
            RecognizedRevenue: null,
            LastMonthMargin: null,
            TMCCOs: null,
            TotalBilledToDate: null,
            TotalJTDCost: null,
            TotalProjectedCost: null,
            TotalProjectedRevenue: null,
            UnanticipatedConditions: null,
            UnbilledRevenue: null,
            VariancePercentage: null,
            MarginPercentage: null,
            LastMonthMarginPercentage: null,
            Submit: null,
            DateNow: null,
            User: null,
            DateChanges: null,
            UserChanges: null
          },
        })
      }
    },

    setJobsMenuBidItemsAndTaskCodesHistory: (state, action: PayloadAction<any>) => {
      let bidItemsFromServer = action.payload.data.BidItems;
      let taskCodesFromServer = action.payload.data.TaskCodes;

      // Get the list of Hardcoded Bid Items. See if there are any already from the DB (don't show those that already exists)
      let hardcodedBidItemsNotPresentInDatabase = JSON.parse(JSON.stringify(hardcodedBidItems.filter((hardcodedBidItem) => !bidItemsFromServer.some(
        (bidItem: JobsMenuBidItemInterface) => bidItem.BidItem === hardcodedBidItem.BidItem
      ))));

      // Assign Budget and Final Cost for Hardcoded Bid Items based on the assigned TaskCodes
      updateBudgetTotalAndFinalCost(hardcodedBidItemsNotPresentInDatabase, taskCodesFromServer);

      // Assign MTDCost and JTDCost for  Bid Items based on TaskCodes
      updateMTDCostlAndJTDCost(bidItemsFromServer, taskCodesFromServer);

      // Handle the PCs (items which have a ChgNumber different than "000")
      let pcList = getPCsFromBidItems(bidItemsFromServer);

      //is sorted numerically by the BidItem field
      let bidItemsWithoutPCs = removePCsFromBidItems(bidItemsFromServer, pcList)

      let sortedBidItems = [...bidItemsWithoutPCs, ...hardcodedBidItemsNotPresentInDatabase].sort((BT_a, BT_b) => {        
        let a: number = parseInt(BT_a.BidItem, 10); 
        let b: number = parseInt(BT_b.BidItem, 10); 
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0;
      });

      let composedPCs = composePCs(pcList);

      // @ts-ignore
      taskCodesFromServer.forEach(element => {
        if (taskCodesBidItemFor888.includes(element.BidItem)) {
          element.BidItem = '888'
        }
      });

        state.jobsMenuItemsHistory[0].BidItems = [...sortedBidItems, ...composedPCs];
        state.jobsMenuItemsHistory[0].TaskCodes = taskCodesFromServer || [];
        state.jobsMenuItemsHistory[0].PCs = pcList;
        // We are keeping from the original Taskcodes only the 4 properties from below.
        // @ts-ignore
        state.jobsMenuItemsHistory[0].AssignableTaskCodes = taskCodesFromServer.map(({Taskcode, BidItem, TaskcodeDescription, UnitOfMeasure, BudgetUnits}) => ({
          Taskcode, BidItem, TaskcodeDescription, UnitOfMeasure, BudgetUnits
        })) || [];
    },
    bidItemSaved: (state, action: AnyAction) => {
      let currentJobMenuItemIndex = state.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === action.payload.JobNumber);
      let bidItemsToBeUpdated: any[] = [];

      if (!Array.isArray(action.payload.BidItemToBeSaved)) {
        bidItemsToBeUpdated = [action.payload.BidItemToBeSaved];
      } else {
        bidItemsToBeUpdated = [...action.payload.BidItemToBeSaved];
      }
      
      bidItemsToBeUpdated.forEach(bidItem => {
        let pcItem = bidItem.ChgNumber !== "000";

        // TODO: The below code could do with a refactoring but decided to go for readability for the moment
        if (currentJobMenuItemIndex >= 0) {
          if (pcItem) {
            let currentPcItems = state.jobsMenuItems[currentJobMenuItemIndex].PCs || [];
  
            let pcItemToBeSavedIndex = currentPcItems.findIndex((pcItem: JobsMenuBidItemInterface) =>
              pcItem.BidItem === bidItem.BidItem &&
              pcItem.BidItemDescription === bidItem.BidItemDescription &&
              pcItem.ChgNumber === bidItem.ChgNumber
            );
  
            if (pcItemToBeSavedIndex >= 0) {
              currentPcItems[pcItemToBeSavedIndex] = bidItem;
              state.jobsMenuItems[currentJobMenuItemIndex].PCs = currentPcItems;
            } else {
              currentPcItems.push(action.payload.BidItemToBeSaved);
              state.jobsMenuItems[currentJobMenuItemIndex].PCs = currentPcItems;
            }
          } else {
            let currentBidItems = state.jobsMenuItems[currentJobMenuItemIndex].BidItems || [];
  
            let bidItemToBeSavedIndex = currentBidItems.findIndex((BidItem: JobsMenuBidItemInterface) =>
              BidItem.BidItem === bidItem.BidItem &&
              BidItem.BidItemDescription === bidItem.BidItemDescription &&
              BidItem.ChgNumber === bidItem.ChgNumber
            );
  
            if (bidItemToBeSavedIndex >= 0) {
              currentBidItems[bidItemToBeSavedIndex] = bidItem;
              state.jobsMenuItems[currentJobMenuItemIndex].BidItems = currentBidItems;
            } else {
              currentBidItems.push(bidItem);
              state.jobsMenuItems[currentJobMenuItemIndex].BidItems = currentBidItems;
            }
          }
        } else {
          state.jobsMenuItems.push({
            JobNumber: action.payload.JobNumber.toString(),
            JobDescription: action.payload.JobDescription,
            BidItems: pcItem ? [] : [bidItem],
            TaskCodes: action.payload.data.TaskCodes || [],
            PCs: pcItem ? [bidItem] : [],
            // @ts-ignore
            AssignableTaskCodes: action.payload.data.TaskCodes.map(({Taskcode, TaskcodeDescription, UnitOfMeasure, BudgetUnits}) => ({
              Taskcode, TaskcodeDescription, UnitOfMeasure, BudgetUnits
            })) || [],
            ExecutiveSummary: {
              AllocatedIndirects: null,
              BackchargesRevenue: null,
              BaseContractItemsBilling: null,
              BaseContractItemsCost: null,
              BaseContractItemsRevenue: null,
              CCOBilling: null,
              CCOs: null,
              Claims: null,
              Comments: "",
              Date: "",
              ExecutiveCCOs: null,
              Variance: null,
              JobNumber: "",
              Maintenance: null,
              Margin: null,
              Mobilization: null,
              Overhead: null,
              PercentComplete: null,
              RecognizedRevenue: null,
              LastMonthMargin: null,
              TMCCOs: null,
              TotalBilledToDate: null,
              TotalJTDCost: null,
              TotalProjectedCost: null,
              TotalProjectedRevenue: null,
              UnanticipatedConditions: null,
              UnbilledRevenue: null,
              VariancePercentage: null,
              MarginPercentage: null,
              LastMonthMarginPercentage: null,
              Submit: null,
              DateNow: null,
              User: null,
              DateChanges: null,
              UserChanges: null
            }
          })
        }
      })


    },
    // bidItemSaved: (state, action: AnyAction) => {
    //   let currentJobMenuItemIndex = state.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === action.payload.JobNumber);
    //   let pcItem = action.payload.BidItemToBeSaved.ChgNumber !== "000";

    //   // TODO: The below code could do with a refactoring but decided to go for readability for the moment
    //   if (currentJobMenuItemIndex >= 0) {
    //     if (pcItem) {
    //       let currentPcItems = state.jobsMenuItems[currentJobMenuItemIndex].PCs || [];

    //       let pcItemToBeSavedIndex = currentPcItems.findIndex((pcItem: JobsMenuBidItemInterface) =>
    //         pcItem.BidItem === action.payload.BidItemToBeSaved.BidItem &&
    //         pcItem.BidItemDescription === action.payload.BidItemToBeSaved.BidItemDescription &&
    //         pcItem.ChgNumber === action.payload.BidItemToBeSaved.ChgNumber
    //       );

    //       if (pcItemToBeSavedIndex >= 0) {
    //         currentPcItems[pcItemToBeSavedIndex] = action.payload.BidItemToBeSaved;
    //         state.jobsMenuItems[currentJobMenuItemIndex].PCs = currentPcItems;
    //       } else {
    //         currentPcItems.push(action.payload.BidItemToBeSaved);
    //         state.jobsMenuItems[currentJobMenuItemIndex].PCs = currentPcItems;
    //       }
    //     } else {
    //       let currentBidItems = state.jobsMenuItems[currentJobMenuItemIndex].BidItems || [];

    //       let bidItemToBeSavedIndex = currentBidItems.findIndex((BidItem: JobsMenuBidItemInterface) =>
    //         BidItem.BidItem === action.payload.BidItemToBeSaved.BidItem &&
    //         BidItem.BidItemDescription === action.payload.BidItemToBeSaved.BidItemDescription &&
    //         BidItem.ChgNumber === action.payload.BidItemToBeSaved.ChgNumber
    //       );

    //       if (bidItemToBeSavedIndex >= 0) {
    //         currentBidItems[bidItemToBeSavedIndex] = action.payload.BidItemToBeSaved;
    //         state.jobsMenuItems[currentJobMenuItemIndex].BidItems = currentBidItems;
    //       } else {
    //         currentBidItems.push(action.payload.BidItemToBeSaved);
    //         state.jobsMenuItems[currentJobMenuItemIndex].BidItems = currentBidItems;
    //       }
    //     }
    //   } else {
    //     state.jobsMenuItems.push({
    //       JobNumber: action.payload.JobNumber.toString(),
    //       JobDescription: action.payload.JobDescription,
    //       BidItems: pcItem ? [] : [action.payload.BidItemToBeSaved],
    //       TaskCodes: action.payload.data.TaskCodes || [],
    //       PCs: pcItem ? [action.payload.BidItemToBeSaved] : [],
    //       // @ts-ignore
    //       AssignableTaskCodes: action.payload.data.TaskCodes.map(({Taskcode, TaskcodeDescription, UnitOfMeasure, BudgetUnits}) => ({
    //         Taskcode, TaskcodeDescription, UnitOfMeasure, BudgetUnits
    //       })) || [],
    //       ExecutiveSummary: {
    //         AllocatedIndirects: null,
    //         BackchargesRevenue: null,
    //         BaseContractItemsBilling: null,
    //         BaseContractItemsCost: null,
    //         BaseContractItemsRevenue: null,
    //         CCOBilling: null,
    //         CCOs: null,
    //         Claims: null,
    //         Comments: "",
    //         Date: "",
    //         ExecutiveCCOs: null,
    //         JobContribution: null,
    //         JobNumber: "",
    //         Maintenance: null,
    //         Margin: null,
    //         Mobilization: null,
    //         Overhead: null,
    //         PercentComplete: null,
    //         RecognizedRevenue: null,
    //         SalaryOHAllocation: null,
    //         TMCCOs: null,
    //         TotalBilledToDate: null,
    //         TotalJTDCost: null,
    //         TotalProjectedCost: null,
    //         TotalProjectedRevenue: null,
    //         UnanticipatedConditions: null,
    //         UnbilledRevenue: null,
    //         JobContributionPercentage: null,
    //         MarginPercentage: null,
    //         SalaryOHAllocationPercentage: null
    //       }
    //     })
    //   }
    // },

    maintainAllLinkedTaskCodesToBidItems: (state) => {
      state.jobsMenuItems.forEach((jobMenuItem) => {
        linkTaskCodesToBidItems(jobMenuItem);
      })
    },

    updateAssignedTaskCodes: (state, action: AnyAction) => {
      if (action.payload.taskCodesToBeUpdated?.length) {
        // Find the current job
        let currentJobMenuItemIndex = state.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === state.currentJobNumber);
        if (currentJobMenuItemIndex >= 0) {
          let unAssignedTaskCodesToBidItemsIds: Array<string> = []
          
          // If there are TaskCodes
          if (state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes?.length) {
            // Start counter
            let taskCodeProcessed = 0;
  
            // For each AssignableTaskCode
            action.payload.taskCodesToBeUpdated.forEach((taskCodesToBeUpdated: AssignableTaskCode) => {
              // Find the index of the TaskCode with the same TaskCode number from our list
              let indexOfTaskCodeToBeUpdated = state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes.findIndex(taskCode => taskCode.Taskcode === taskCodesToBeUpdated.Taskcode);
  
              if (indexOfTaskCodeToBeUpdated >= 0) {
                if (!action.payload.groupedBidItem) {
                  // Update the TaskCode in our list with the idToWhichWeAssignTaskCode (BidItem number or PC + BidItem Number) if action.payload.assignTaskCodesOperation is true
                  // Otherwise, means we need to Unassign the taskCode (reset it) by assigning to BidItem the value of first 3 characters from TaskCode number
                  if (action.payload.areWeAssigningTaskCodes) {
                    unAssignedTaskCodesToBidItemsIds.push(state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[indexOfTaskCodeToBeUpdated].BidItem);
                  }
                  
                  state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[indexOfTaskCodeToBeUpdated].BidItem = action.payload.areWeAssigningTaskCodes
                    ? action.payload.idToWhichWeAssignTaskCode
                    : state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[indexOfTaskCodeToBeUpdated].Taskcode.slice(0, 3);
                    
                } else {
                    let currentFinalCost = state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[indexOfTaskCodeToBeUpdated].FinalCost
                    
                    state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[indexOfTaskCodeToBeUpdated].FinalCost = taskCodesToBeUpdated.FinalCost ?? currentFinalCost
                    state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[indexOfTaskCodeToBeUpdated].RecoveryPercent = taskCodesToBeUpdated?.RecoveryPercent || 0
                    state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[indexOfTaskCodeToBeUpdated].CCOs = taskCodesToBeUpdated.CCOs || null
                }
              }
    
              // Increment counter
              taskCodeProcessed++;
  
              // If all the AssignableTaskCodes have been processed
              if (taskCodeProcessed === action.payload.taskCodesToBeUpdated.length) {
                linkTaskCodesToBidItems(state.jobsMenuItems[currentJobMenuItemIndex]);
  
                state.selectedBidItems = [];
                state.selectedTaskCodes = [];
  
                if (state.currentChangeNumber !== "000") {
                  // Filter the ones that has the ChgNumber equal with the currentChangeNumber
                  let selectedBidItems = state.jobsMenuItems[currentJobMenuItemIndex].BidItems.filter(bidItems =>
                    bidItems.ChgNumber === state.currentChangeNumber
                  );
  
                  if (selectedBidItems.length) {
                    state.selectedBidItems = selectedBidItems;
                  }
                  
                  let selectedTaskCodes = state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes.filter(taskCode => taskCode.CCOs?.some(cco => cco?.ChgNumber === state.currentChangeNumber));
  
                  state.selectedTaskCodes = selectedTaskCodes.sort((taskCode1, taskCode2) => taskCode1.Taskcode > taskCode2.Taskcode ? 1 : -1)
  
                } else {
                  let selectedBidItems = state.jobsMenuItems[currentJobMenuItemIndex].BidItems.filter(bidItems =>
                    bidItems.BidItem === state.currentBidItemNumber
                  );
  
                  if (selectedBidItems.length) {
                    state.selectedBidItems = selectedBidItems;
                    // Add to our Selected Task Codes, all the Task Codes of the Selected Bid Item(s)
                    selectedBidItems.forEach(bidItem => {
                      if (bidItem.TaskCodeItems) {
                        state.selectedTaskCodes = [...state.selectedTaskCodes, ...bidItem.TaskCodeItems].sort((taskCode1, taskCode2) => {
                          return taskCode1.Taskcode > taskCode2.Taskcode ? 1 : -1
                        })
                      }
                    });
                  }
                }
              }
            });
            
            if (unAssignedTaskCodesToBidItemsIds.length) {
              state.unAssignedTaskCodesToBidItemsIds = unAssignedTaskCodesToBidItemsIds.filter((id, index) => unAssignedTaskCodesToBidItemsIds.indexOf(id) === index);
            }
          }
        }
      }
    },

    resetUnAssignedTaskCodesToBidItemsIds: (state) => {
      state.unAssignedTaskCodesToBidItemsIds = []
    },

    setChangeJobInProgress: (state, action: AnyAction) => {
      state.changeJobInProgress = action.payload.changeJobInProgress;
    },

    setResetJobInProgress: (state, action: PayloadAction<any>) => {
      state.resetJobInProgress = action.payload.resetJobInProgress;
    },

    cleanJobState: (state, action: PayloadAction<any>) => {
      state.selectedBidItems = [];
      state.selectedTaskCodes = [];
      state.selectedPCs = [];

      let jobNumber = action.payload.jobNumber;
      let jobIndex = state.jobsMenuItems.findIndex(item => item.JobNumber === jobNumber);

      if (jobIndex !== -1) {
        state.jobsMenuItems[jobIndex].BidItems = []
        state.jobsMenuItems[jobIndex].TaskCodes = []
        state.jobsMenuItems[jobIndex].PCs = []
        state.jobsMenuItems[jobIndex].AssignableTaskCodes = []
      }
    },

    setExecutiveSummary: (state, action: PayloadAction<ExecutiveSummaryData>) => {
      let currentJobMenuItemIndex = state.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === action.payload.jobNumber);
      if(state.jobsMenuItems[currentJobMenuItemIndex] != null) {
        state.jobsMenuItems[currentJobMenuItemIndex].ExecutiveSummary = action.payload.data;
      }
    },

    updateExecutiveSummary: (state, action: PayloadAction<UpdateExecutiveSummaryData>) => {
      let currentJobMenuItemIndex = state.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === action.payload.jobNumber);

      if(state.jobsMenuItems[currentJobMenuItemIndex] != null) {
        state.jobsMenuItems[currentJobMenuItemIndex].ExecutiveSummary.Submit = action.payload.Submit;
        state.jobsMenuItems[currentJobMenuItemIndex].ExecutiveSummary.DateNow = action.payload.DateNow;
        state.jobsMenuItems[currentJobMenuItemIndex].ExecutiveSummary.User = action.payload.User;
        state.jobsMenuItems[currentJobMenuItemIndex].ExecutiveSummary.Comments = action.payload.Comments;
      }
    },

    updateTaskCodeDetails: (state, action: PayloadAction<any>) => {
      const currentJobMenuItemIndex = state.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === action.payload.JobNumber);

      if(state.jobsMenuItems[currentJobMenuItemIndex] != null) {
        const taskCodeIndex = state.jobsMenuItems?.[currentJobMenuItemIndex].TaskCodes?.findIndex((taskCodeItem) => taskCodeItem.Taskcode === action.payload.taskCodeToBeSaved.Taskcode && taskCodeItem.BidItem === action.payload.taskCodeToBeSaved.BidItem); 

        const taskCodeItem = state.jobsMenuItems?.[currentJobMenuItemIndex].TaskCodes?.filter((taskCodeItem) => taskCodeItem.Taskcode === action.payload.taskCodeToBeSaved.Taskcode && taskCodeItem.BidItem === action.payload.taskCodeToBeSaved.BidItem)?.[0];

        if(taskCodeItem != null) {
          // update task code from jobsMenuItems
          const _taskCodeItem = {
            ...taskCodeItem,
            QtyAdjustment: action.payload.taskCodeToBeSaved.QtyAdjustment,
            RecoveryPercent: action.payload.taskCodeToBeSaved.RecoveryPercent,
            RemainingTotalCost: action.payload.taskCodeToBeSaved.RemainingTotalCost,
            RemainingUnitCost: action.payload.taskCodeToBeSaved.RemainingUnitCost,
            RemainingUnits: action.payload.taskCodeToBeSaved.RemainingUnits,
            JTDTotalCost: action.payload.taskCodeToBeSaved.JTDTotalCost,
            JTDUnitCost: action.payload.taskCodeToBeSaved.JTDUnitCost,
            JTDUnits: action.payload.taskCodeToBeSaved.JTDUnits,
            FinalQuantity: action.payload.taskCodeToBeSaved.FinalQuantity,
            FinalUnitCost: action.payload.taskCodeToBeSaved.FinalUnitCost,
            FinalCost: action.payload.taskCodeToBeSaved.FinalCost,
            BudgetTotalCost: action.payload.taskCodeToBeSaved.BudgetTotalCost,
            BudgetUnits: action.payload.taskCodeToBeSaved.BudgetUnits,
            Comments: action.payload.taskCodeToBeSaved.Comments,
            HasEditedFields: action.payload.taskCodeToBeSaved.HasEditedFields,
            CheckFinalCost: action.payload.taskCodeToBeSaved.CheckFinalCost,
            CCOs: action.payload.taskCodeToBeSaved.CCOs,
            CostGL: action.payload.taskCodeToBeSaved.CostGL,
            FinalCostOverwritten: action.payload.taskCodeToBeSaved.FinalCostOverwritten
          }
          if (state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[taskCodeIndex] != null) {
            state.jobsMenuItems[currentJobMenuItemIndex].TaskCodes[taskCodeIndex] = _taskCodeItem;
          }

          // update task code from jobMenuItems -> BidItems -> TaskCodeItems
          const bidItemIndex = taskCodesBidItemFor888.includes(action.payload.taskCodeToBeSaved.BidItem)
            ? state.jobsMenuItems?.[currentJobMenuItemIndex].BidItems?.findIndex((bidItem) => bidItem.BidItem === "888")
            : state.jobsMenuItems?.[currentJobMenuItemIndex].BidItems?.findIndex((bidItem) => bidItem.BidItem === action.payload.taskCodeToBeSaved.BidItem);

          if(state.jobsMenuItems[currentJobMenuItemIndex].BidItems?.[bidItemIndex] != null) {
            const bidItemTaskCodeIndex = state.jobsMenuItems[currentJobMenuItemIndex].BidItems[bidItemIndex].TaskCodeItems.findIndex((taskCodeItem) => taskCodeItem.Taskcode === action.payload.taskCodeToBeSaved.Taskcode);

            if(state.jobsMenuItems[currentJobMenuItemIndex].BidItems[bidItemIndex].TaskCodeItems?.[bidItemTaskCodeIndex] != null) {
              const _taskCodeItem = state.jobsMenuItems[currentJobMenuItemIndex].BidItems[bidItemIndex].TaskCodeItems.filter((item) => item.Taskcode === action.payload.taskCodeToBeSaved.Taskcode)?.[0];

              state.jobsMenuItems[currentJobMenuItemIndex].BidItems[bidItemIndex].TaskCodeItems[bidItemTaskCodeIndex] = {
                ..._taskCodeItem,
                QtyAdjustment: action.payload.taskCodeToBeSaved.QtyAdjustment,
                RecoveryPercent: action.payload.taskCodeToBeSaved.RecoveryPercent,
                RemainingTotalCost: action.payload.taskCodeToBeSaved.RemainingTotalCost,
                RemainingUnitCost: action.payload.taskCodeToBeSaved.RemainingUnitCost,
                RemainingUnits: action.payload.taskCodeToBeSaved.RemainingUnits,
                JTDTotalCost: action.payload.taskCodeToBeSaved.JTDTotalCost,
                JTDUnitCost: action.payload.taskCodeToBeSaved.JTDUnitCost,
                JTDUnits: action.payload.taskCodeToBeSaved.JTDUnits,
                FinalQuantity: action.payload.taskCodeToBeSaved.FinalQuantity,
                FinalUnitCost: action.payload.taskCodeToBeSaved.FinalUnitCost,
                FinalCost: action.payload.taskCodeToBeSaved.FinalCost,
                BudgetTotalCost: action.payload.taskCodeToBeSaved.BudgetTotalCost,
                BudgetUnits: action.payload.taskCodeToBeSaved.BudgetUnits,
                Comments: action.payload.taskCodeToBeSaved.Comments,
                CCOs: action.payload.taskCodeToBeSaved.CCOs,
                HasEditedFields: action.payload.taskCodeToBeSaved.HasEditedFields,
                CheckFinalCost: action.payload.taskCodeToBeSaved.CheckFinalCost,
                CostGL: action.payload.taskCodeToBeSaved.CostGL,
                FinalCostOverwritten: action.payload.taskCodeToBeSaved.FinalCostOverwritten
              }
            }
          }

          // update task code from selectedBidItems
          if(state.selectedBidItems != null) {
            const _taskCodeIndex = state.selectedBidItems[0].TaskCodeItems?.findIndex((taskCodeItem: any) => taskCodeItem.Taskcode === action.payload.taskCodeToBeSaved.Taskcode);

            if(_taskCodeIndex !== undefined && _taskCodeIndex !== -1) {
              const taskCodeItemFromBidItems = state.selectedBidItems[0].TaskCodeItems?.filter((taskCodeItem: any) => taskCodeItem.Taskcode === action.payload.taskCodeToBeSaved.Taskcode)?.[0];

              const _taskCodeItem = {
                ...taskCodeItemFromBidItems,
                QtyAdjustment: action.payload.taskCodeToBeSaved.QtyAdjustment,
                RecoveryPercent: action.payload.taskCodeToBeSaved.RecoveryPercent,
                RemainingTotalCost: action.payload.taskCodeToBeSaved.RemainingTotalCost,
                RemainingUnitCost: action.payload.taskCodeToBeSaved.RemainingUnitCost,
                RemainingUnits: action.payload.taskCodeToBeSaved.RemainingUnits,
                JTDTotalCost: action.payload.taskCodeToBeSaved.JTDTotalCost,
                JTDUnitCost: action.payload.taskCodeToBeSaved.JTDUnitCost,
                JTDUnits: action.payload.taskCodeToBeSaved.JTDUnits,
                FinalQuantity: action.payload.taskCodeToBeSaved.FinalQuantity,
                FinalUnitCost: action.payload.taskCodeToBeSaved.FinalUnitCost,
                FinalCost: action.payload.taskCodeToBeSaved.FinalCost,
                BudgetTotalCost: action.payload.taskCodeToBeSaved.BudgetTotalCost,
                BudgetUnits: action.payload.taskCodeToBeSaved.BudgetUnits,
                Comments: action.payload.taskCodeToBeSaved.Comments,
                CCOs: action.payload.taskCodeToBeSaved.CCOs,
                HasEditedFields: action.payload.taskCodeToBeSaved.HasEditedFields,
                FinalCostOverwritten: action.payload.taskCodeToBeSaved.FinalCostOverwritten
              }
              state.selectedBidItems[0].TaskCodeItems[_taskCodeIndex] = _taskCodeItem;
            }
          }
        }
      }
    },

    updatePCListDetails: (state) => {
      if(state.currentChangeNumber !== '000') {
        let emptyBidItem = {
          Amount: 0,
          FinalCost: 0,
          BidItem: "-",
          BidItemDescription: "-",
          ChgNumber: "",
          ChgOrderDesc: "",
          FinalQuantity: 0,
          FinalRevenue: 0,
          HasEditedFields: false,
          GLDate: "-",
          JobDescription: "",
          JobNumber: "",
          LastUpdated: "-",
          QTYBilled: 0,
          QtyAdjustment: 0,
          UM: "-",
          UnitPrice: 0,
        };

        // let _bidItemToModify = JSON.parse(JSON.stringify(emptyBidItem));
        const selectedPCList = state.selectedPCs;

        if(selectedPCList.length > 0 && selectedPCList[0].ChgNumber === state.currentChangeNumber) {
          // take the selectedPCs from redux and recalculate the values, because in redux we have the values from DB without initial calculation
          let _updatedPcItems = JSON.parse(JSON.stringify(selectedPCList));
          setBidItemInitialValues(_updatedPcItems, [], false);

          _updatedPcItems.forEach((pcItem: any) => {
            emptyBidItem.Amount = (emptyBidItem.Amount || 0) + (parseFloatRemoveComma(pcItem.Amount) || 0);
            emptyBidItem.FinalCost = (emptyBidItem.FinalCost || 0) + (parseFloatRemoveComma(pcItem.FinalCost) || 0);
            emptyBidItem.FinalQuantity = (emptyBidItem.FinalQuantity || 0) + (parseFloatRemoveComma(pcItem.FinalQuantity )|| 0);
            emptyBidItem.FinalRevenue = (emptyBidItem.FinalRevenue || 0) + (parseFloatRemoveComma(pcItem.FinalRevenue )|| 0);
            emptyBidItem.QTYBilled = (emptyBidItem.QTYBilled || 0) + (parseFloatRemoveComma(pcItem.QTYBilled) || 0);
            emptyBidItem.QtyAdjustment = (emptyBidItem.QtyAdjustment || 0) + (parseFloatRemoveComma(pcItem.QtyAdjustment) || 0);
            emptyBidItem.UnitPrice = (emptyBidItem.UnitPrice || 0) + (parseFloatRemoveComma(pcItem.UnitPrice) || 0);
          });
          const item = {
            ...state.selectedBidItems[0],
            Amount: emptyBidItem.Amount,
            FinalCost: emptyBidItem.FinalCost,
            FinalQuantity: emptyBidItem.FinalQuantity,
            FinalRevenue: emptyBidItem.FinalRevenue,
            QTYBilled: emptyBidItem.QTYBilled,
            QtyAdjustment: emptyBidItem.QtyAdjustment,
            UnitPrice: emptyBidItem.UnitPrice
          }
  
          state.selectedBidItems[0] = item;
          
          const jobNumberIndex = state.jobsMenuItems.findIndex((item) => item.JobNumber === state.currentJobNumber);
          const bidItemIndexByChgNumber = state.jobsMenuItems[jobNumberIndex].BidItems.findIndex((item) => item.ChgNumber === state.currentChangeNumber);
          
          const bidItemFromJobMenuItems = state.jobsMenuItems[jobNumberIndex].BidItems[bidItemIndexByChgNumber];

          // update bid item from jobsMenuItems -> BidItems
          state.jobsMenuItems[jobNumberIndex].BidItems[bidItemIndexByChgNumber] = {
            ...bidItemFromJobMenuItems,
            Amount: emptyBidItem.Amount,
            FinalCost: emptyBidItem.FinalCost,
            FinalQuantity: emptyBidItem.FinalQuantity,
            FinalRevenue: emptyBidItem.FinalRevenue,
            QTYBilled: emptyBidItem.QTYBilled,
            QtyAdjustment: emptyBidItem.QtyAdjustment,
            UnitPrice: emptyBidItem.UnitPrice
          }
        }
      }
    },

    setLoadingExecutiveSummary: (state, action) => {
      state.setLoadingExecutiveSummary = action.payload.setLoading
    },

    setPrevGLandDeltaCCOs: (state) => {
      const jobNumberIndex = state?.jobsMenuItems?.findIndex((item) => item?.JobNumber === state?.currentJobNumber);

      let stateBidItems = state.jobsMenuItems[jobNumberIndex].BidItems
      let pcsBidItems = state.jobsMenuItems[jobNumberIndex].PCs
      for (let bidItems in stateBidItems) {
        let changes = 0;
        let prevGL = 0;
        for (let pcs in pcsBidItems) {  
          if (stateBidItems[bidItems].ChgNumber === pcsBidItems[pcs].ChgNumber) {
            changes += pcsBidItems[pcs].Changes;
            prevGL += pcsBidItems[pcs].PreviousFinalCost; 
          }
          stateBidItems[bidItems].Changes = changes;
          stateBidItems[bidItems].PreviousFinalCost = prevGL;
        }
      }
    },

    setUsername: (state, action) => {
      state.setUsername = action.payload
    },

    setLastUpdated: (state, action: PayloadAction<string>) => {
      const newUdatedDateTime = action.payload;

      state.lastUpdated = newUdatedDateTime;
    },

    setIsJobDataLoading: (state, action: PayloadAction<boolean>) => {
      const isLoading = action.payload;

      state.isJobDataLoading = isLoading;
    },

    setTaskcodesWithoutBidItem: (state, action) => {
      const taskCodes = action.payload;

      state.taskcodesWithoutBidItem = taskCodes
    },

    setTaskcodesWithFlagsFC: (state, action) => {
      const taskCodes = action.payload;

      state.taskcodesWithFlagsFC = taskCodes
    },

    setExpandCollapse: (state, action) => {
      state.setExpandCollapse = action.payload.setExpandCollapse
    },

    setLastBidItemNumber: (state, action: PayloadAction<any>) => {
      state.lastBidItemNumber = action.payload;
    },
    setLastChgNumber: (state, action: PayloadAction<any>) => {
      state.lastChgNumber = action.payload;
    },
    setHasDisplayedFinalCostMessage: (state, action: PayloadAction<any>) => {
      state.hasDisplayedFinalCostMessage = action.payload
    }

  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => state)
  },

});

export const {
  reducer: app,
  actions: {
    addToken,
    init,
    setCurrentBidItemNumber,
    setCurrentJobNumberAndDescription,
    setCurrentChangeNumber,
    logout,
    setCurrentBidItemDisplay,
    setJobsMenuNumberAndDescriptions,
    setJobsMenuBidItemsAndTaskCodes,
    setJobsMenuBidItemsAndTaskCodesHistory,
    bidItemSaved,
    maintainAllLinkedTaskCodesToBidItems,
    setCurrentBidItems,
    setCurrentTaskCodes,
    setCurrentPCs,
    setCurrentBidItemsHistory,
    setCurrentTaskCodesHistory,
    setCurrentPCsHistory,
    updateAssignedTaskCodes,
    setChangeJobInProgress,
    setResetJobInProgress,
    cleanJobState,
    setExecutiveSummary,
    updateExecutiveSummary,
    updateTaskCodeDetails,
    updatePCListDetails,
    resetUnAssignedTaskCodesToBidItemsIds,
    setLoadingExecutiveSummary,
    setPrevGLandDeltaCCOs,
    setUsername,
    updatePCItem,
    setLastUpdated,
    setIsJobDataLoading,
    setTaskcodesWithoutBidItem,
    setTaskcodesWithFlagsFC,
    setExpandCollapse,
    setLastBidItemNumber,
    setLastChgNumber,
    setHasDisplayedFinalCostMessage,
  },
} = appSlice;

interface AuthPayload extends AuthState { }