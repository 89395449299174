export const taskCodesBidItemFor888 = ["777", "830", "860", "861", "870", "880", "888", "920", "970", "980"];

const dateNow = new Date();
dateNow.setMonth(dateNow.getMonth() - 1);

const year = dateNow.getFullYear();
const month = dateNow.getMonth() + 1;
const day = dateNow.getDate();

export const date = new Date(`${month}-${day}-${year}`);

export const reorderObject = (obj: any, order: any): any => {
    const reordered: any = {};
    for (const key of Object.keys(order)) {
      if (obj.hasOwnProperty(key)) {
        reordered[key] = obj[key];
      }
    }
    return reordered;
  };
  