import * as React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { displayTwoDigits, parseFloatRemoveComma } from "../utils/convert";
import { bidItemDetailsDictionary, bidItemDictionary, bidItemDictionaryDefault } from "../utils/dictionaries";
import { BidItemDetailsState, BidItemInterface, BidItemsDetailsProps, TaskCodeStructureFromDB } from "../interfaces/Interfaces";
import { InputText } from "primereact/inputtext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "primereact/button";
import { RootState } from "../redux/store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { bidItemSaved, setCurrentBidItems, setCurrentTaskCodes, setExpandCollapse, setLoadingExecutiveSummary, updateAssignedTaskCodes, updateTaskCodeDetails } from "../redux/appSlice";

class BidItemsDetails extends React.Component<BidItemsDetailsProps, BidItemDetailsState> {
  private bidItemStructure: BidItemInterface;

  constructor(props: BidItemsDetailsProps) {
    super(props);
    this.bidItemStructure = bidItemDetailsDictionary;
    this.state = {
      globalFilter: null,
      isMinimized: false
    }
  }

  calculate_total(property: any): string{
    let total = 0;
    for (let bidItem of this.props.bidItemData) {
        // @ts-ignore
        if (bidItem[property]) {
          // @ts-ignore
          total += parseFloatRemoveComma(bidItem[property]);
        }
    }
    return displayTwoDigits(total);
  }

  calculateGroupedBidItemFinalCost(taskCodes: TaskCodeStructureFromDB[]) {
    let total = 0;
    if (taskCodes && taskCodes.length) {
      for (let taskCode of taskCodes) {
        if (taskCode.FinalCost) {
          total += parseFloatRemoveComma(taskCode.FinalCost);
        }
      }
    }
    return total;
  }

  calculateGL() {
    //if (this.props.groupedBidItem) {
      //return parseFloatRemoveComma(this.calculate_total("FinalRevenue")) - parseFloatRemoveComma(this.calculateGroupedBidItemFinalCost(this.props.taskCodes));
    //} else {
      return parseFloatRemoveComma(this.calculate_total("FinalRevenue")) - parseFloatRemoveComma(this.calculate_total("FinalCost"));  
    //}
  }

    toggleSize = () => {
        this.setState({ isMinimized: !this.state.isMinimized});
    };

  render() {
    let BidItemColumnHeader = this.props.groupedBidItem ? "Billing Line" : this.bidItemStructure.BidItem;
    let DescriptionHeader = this.props.groupedBidItem ? "Change Number Description" : this.bidItemStructure.BidItemDescription;

    let FinalRevenueHeader = (
        <div className="table-header" style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
            {this.props.bidItemTableTitle}
            <div className="p-datatable-globalfilter-container">
                <InputText type="search" onInput={(e) => this.props.globalSearchValue(e)} placeholder="Global Search" sizes="50" />
            </div>
        </div>
    );

    let footerGroup =
        <ColumnGroup>
            <Row>
                <Column footer="" colSpan={this.props.groupedBidItem ? 6 : 10} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
                <Column footer={`$${this.calculate_total("FinalRevenue")}`} className={this.props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "20", paddingLeft: "2" }} />
                <Column footer={`$${this.calculate_total("FinalCost")}`} className={this.props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
                <Column footer={`$${this.calculate_total("RemCost")}`} className={this.props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
                <Column footer="" colSpan={0} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
                <Column footer={`$${this.calculate_total("PreviousFinalCost")}`} className={this.props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
                <Column footer={`$${this.calculate_total("Changes")}`} className={this.props.loading ? 'hidden' : ''} footerStyle={{ textAlign: 'center', width: '3em', paddingRight: "2", paddingLeft: "2" }} />
            </Row>
        </ColumnGroup>;

    let bidItemDynamicColumns = Object.entries(this.bidItemStructure).map(([key, value]) => {
        switch (key) {
            case "BidItem":
                return <Column 
                    body={(rowData: any) => (rowData[key] === "950") ? "950/960" : rowData[key]}
                    key={key} field={key} header={BidItemColumnHeader} headerStyle={{ textAlign: 'left', width: '6em' }}
                    style={{ textAlign: 'left', width: '6em', fontWeight: "900" }} />;
            case "BidItemDisplay":
                return null;
            case "BidItemDescription":
                return <Column key={key} field={key} header={DescriptionHeader} headerStyle={{ textAlign: 'left', width: '12em' }}
                    style={{ textAlign: 'left', width: '12em', fontWeight: "900" }} />;
            case "ChgOrderDesc":
                return this.props.groupedBidItem ? null : 
                    <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '5em' }}
                        style={{ textAlign: 'left', width: '5em', fontWeight: "900" }} />;
            case "UM":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em' }}
                    body={(rowData: any) => rowData[key]}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "QTYBilled":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FFF9AB' }}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "JTDQty":
                    return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FAD8B1' }}
                        body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                        style={{ textAlign: 'center', width: '6em' }} />;
            case "QtyAdjustment":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#D2FACF' }}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "UnitPrice":
            case "Amount":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FFF9AB' }}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "FinalQuantity":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FAD8B1' }}
                    editor={!this.props.specialBidItem ? (props => this.props.editor(props, key, false)) : undefined}
                    onEditorCancel={props => this.props.onEditorCancel(props, false)}
                    onEditorSubmit={props => this.props.onEditorSubmit(props, false)}
                    body={(rowData: any) => this.props.specialBidItem || parseFloatRemoveComma(rowData[key]) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em', fontWeight: "900", backgroundColor: !this.props.specialBidItem && '#FFFCED' }} />;
            case "FinalRevenue":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                    editor={!this.props.specialBidItem ? (props => this.props.editor(props, key, false)) : undefined}
                    onEditorCancel={props => this.props.onEditorCancel(props, false)}
                    onEditorSubmit={props => this.props.onEditorSubmit(props, false)}
                    body={(rowData: any) => parseFloatRemoveComma(rowData[key]) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em', backgroundColor: !this.props.specialBidItem && '#FFFCED' }} />;
            case "HasEditedFields":
                return null;
            case "FinalCost":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                    body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? "-" : parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "RemCost":
                      return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                          body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                          style={{ textAlign: 'center', width: '6em' }} />;
            case "GainLoss":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#BADFF5' }}
                    body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? '-' : parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "PreviousFinalCost":
            case "Changes":
                return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', backgroundColor: '#FFA3E0' }}
                    body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? '-' : parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
            case "BidComments":
                return <Column key={key} field={key} header={value}
                    headerStyle={{ textAlign: 'left', width: '10em', backgroundColor: '#FFA3E0' }}
                    editor={(props) => this.props.editor(props, key, false)}
                    onEditorCancel={(props) => this.props.onEditorCancel(props, false)}
                    onEditorSubmit={(props) => this.props.onEditorSubmit(props, false)}
                    style={{ textAlign: 'left', width: '10em', backgroundColor: '#FFFCED' }} />;
            default:
                return <Column key={key} field={key} header={value}
                    body={(rowData: any) => parseFloatRemoveComma(displayTwoDigits(rowData[key])) === 0 ? "-" : displayTwoDigits(rowData[key])}
                    style={{ textAlign: 'center', width: '6em' }} />;
        }
    });

    return (
      <div className={`card card-w-title ${this.state.isMinimized ? 'minimized' : ''}`}>
            <div className="header-container">
                    <h1>{this.props.bidItemTableTitle}</h1>
                    <Button onClick={this.toggleSize} className="toggle-button" label={this.state.isMinimized ? 'Expand' : 'Collapse'} />
                </div>
                {
                !this.state.isMinimized ? 
                <DataTable
                value={this.props.bidItemData}
                header={FinalRevenueHeader}
                globalFilter={this.state.globalFilter}
                autoLayout={true}
                editMode="cell"
                loading={this.props.loading}
                loadingIcon={'pi-md-refresh'}
                footerColumnGroup={footerGroup}
            >
                {bidItemDynamicColumns}
            </DataTable> : 
            <div></div>
            }
        </div>
    )
}

}



export default(BidItemsDetails);

