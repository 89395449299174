import { apiCall } from "./axiosRequest";
import { AxiosResponse } from "axios";

export class ExecutiveSummaryService {
  
  async getExecutiveSummary(jobNumber: string, month: number, year: number) {
      const [error, response] = await apiCall("getexecutivesummary", { jobNumber, month, year });
      return [error, response] as [Error | null, AxiosResponse<any> | undefined];
  }

  async saveExecutiveSummary(jobNumber: string, comments: string, submit?: boolean, dateNow?: string, username?: string) {
    function deleteStar(text: string): string {
      const newText = text.replace(/\*/g, '');
      return newText;
    }
    comments = deleteStar(comments)
    const [error, response] = await apiCall("saveexecutivesummary", { jobNumber, comments, submit, dateNow, username});
    return [error, response] as [Error | null, AxiosResponse<any> | undefined];
  }

  async updateExecutiveSummary(jobNumber: string, month: number, year: number, user?: string) {
    const date = new Date().toLocaleString('en-US', { timeZone: 'PST' });
      const [error, response] = await apiCall("updateexecutivesummary", { jobNumber, month, year, user, date});
      return [error, response] as [Error | null, AxiosResponse<any> | undefined];
  }

}