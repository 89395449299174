import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { CCOInTaskCode, TaskCodeStructureFromDB } from '../../interfaces/Interfaces'
import { displayTwoDigits, numbersAndDotOnly, parseFloatRemoveComma } from '../../utils/convert'
import { calculateTaskCodeFinalCost, calculateTaskCodeRemainingFinalCost } from '../../utils/taskCodeFieldsCalculationsFunctions'
import { hardcodedBidItemsRCRP } from '../../utils/hardcodedBidItems'
import RecoverCalculator from './RecoverCalculator'
import './TaskCodeToCCODetails.scss'

interface TaskCodeCCODetailsProps {
  currentTaskCode: TaskCodeStructureFromDB | any;
  visible?: 'visible' | 'hidden';
  onConfirm: (taskCodeId: string, CCOs: CCOInTaskCode[], recoverPercent: number) => void;
  onCancel: (taskCodeId: string, isAssigment?: boolean) => void;
}

const TaskCodeToCCODetails = (props: TaskCodeCCODetailsProps) => {
  const { visible, onConfirm, onCancel } = props
  const { Taskcode, TaskcodeDescription, BidItem, FinalCost, RecoveryPercent, CCOs } = props.currentTaskCode
  const [updatedCCOs, setUpdatedCCOs] = useState<CCOInTaskCode[]>([])
  const [recoverPercent, setRecoverPercent] = useState<number>(0)
  const [inputInitialValue, setInputInitialValue] = useState<string>('')
  const [taskCodeRemainingFinalCost, setTaskCodeRemainingFinalCost] = useState<number>(0)
  const [taskCodeFinalCost, setTaskCodeFinalCost] = useState<number>(0)
  const isBidItemRCRP = useMemo(() => hardcodedBidItemsRCRP.some(bidItem => bidItem === BidItem), [BidItem])

  useEffect(() => {
    if (CCOs) {
      const currentCCOs: CCOInTaskCode[] = CCOs.map((cco: any) => ({...cco, AssignedCost: parseFloat(cco.AssignedCost)}))
      const totalCCOsCost = calculateTaskCodeRemainingFinalCost(currentCCOs)
      setTaskCodeFinalCost(props.currentTaskCode.hasEditedFields
        ? props.currentTaskCode.FinalCost
        : calculateTaskCodeFinalCost(props.currentTaskCode, false, true, true))
      setUpdatedCCOs(currentCCOs)
      setRecoverPercent(RecoveryPercent)
      setTaskCodeRemainingFinalCost((parseFloatRemoveComma(taskCodeFinalCost) ?? 0) - totalCCOsCost)
    }    
  }, [CCOs, FinalCost, RecoveryPercent, props.currentTaskCode, taskCodeFinalCost])

  const onEditorValueChange = useCallback((props: any, input: any) => {
    let CCOs = JSON.parse(JSON.stringify(updatedCCOs))
    let myInput = numbersAndDotOnly(input.value)
    
    CCOs[props.rowIndex][props.field] = myInput

    setUpdatedCCOs(CCOs)

  }, [updatedCCOs])

  const onEditoSubmit = useCallback((props: any) =>  {
    let CCOs = JSON.parse(JSON.stringify(updatedCCOs))
    let totalCCOsCost = calculateTaskCodeRemainingFinalCost(CCOs)

    if (CCOs && props.rowData[props.field] === '') {
      CCOs[props.rowIndex].AssignedCost = 0
    }
    
    if (isNaN(totalCCOsCost)) {
      totalCCOsCost = 0
    }

    setTaskCodeRemainingFinalCost((taskCodeFinalCost ?? 0) - totalCCOsCost)
    
    const ccosToUpdate: CCOInTaskCode[] = CCOs?.map((cco: any) => ({...cco, AssignedCost: parseFloat(cco.AssignedCost)}))
    
    setUpdatedCCOs(ccosToUpdate)
    
  }, [updatedCCOs, taskCodeFinalCost])

  const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (inputInitialValue === '') {
      setInputInitialValue(event.target.value)
    }
    event.target.select()
  }, [inputInitialValue])

  const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      event.target.value = '0.00'
    }
    setInputInitialValue(event.target.value)
  }, [])

  const handleRecoverChange = useCallback((value: string) => {
    setRecoverPercent(parseFloat(value))
  }, [])

  const handleAllocateTotal = useCallback((...args: any) => {
    const props = args[1]
    const ccosToUpdate = updatedCCOs?.map((cco, i) => (
      props.rowIndex === i
        ? {...cco, AssignedCost: taskCodeFinalCost}
        : {...cco, AssignedCost: 0}
    ))

    setTaskCodeRemainingFinalCost(0)
    setUpdatedCCOs(ccosToUpdate)
  }, [taskCodeFinalCost, updatedCCOs])

  const resetState = useCallback(() => {
    setUpdatedCCOs([])
    setRecoverPercent(0)
    setInputInitialValue('')
    setTaskCodeRemainingFinalCost(0)
    setTaskCodeFinalCost(0)
  }, [])

  const handleConfirm = useCallback(() => {
    const ccosToBeSaved = updatedCCOs?.map(cco => ({
      ...cco,
      isTotalAllocated: cco.AssignedCost === taskCodeFinalCost
    }))

    onConfirm(Taskcode, ccosToBeSaved, recoverPercent)
    resetState()
  }, [Taskcode, updatedCCOs, recoverPercent, taskCodeFinalCost, onConfirm, resetState])

  const handleCancel = useCallback(() => {
    onCancel(Taskcode)
    resetState()
  }, [onCancel, Taskcode, resetState])

  const editor = useCallback((props: any, key: string) => {
    return <InputText type="text" value={props.rowData[key]} onFocus={handleFocus} onBlur={handleBlur} onChange={(e) => onEditorValueChange(props, e.target)}  />;
  }, [handleFocus, handleBlur, onEditorValueChange])

  const header = useMemo(() => ((
    <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
      Task Code to CCO Details
    </div>
  )), [])

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Type" style={{ width: '8em' }} />
        <Column header="Id" style={{ width: '4em' }} />
        <Column header="Description" />
        <Column header="Costs" style={{ width: '12em' }} />
      </Row>
      <Row>
        <Column header="Task Code" />
        <Column header={Taskcode} />
        <Column header={TaskcodeDescription} />
        <Column header={`$ ${displayTwoDigits(taskCodeFinalCost)}`} />
      </Row>
    </ColumnGroup>
  )

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Remaining Task Code Cost" colSpan={3} />
        <Column footer={`$ ${displayTwoDigits(taskCodeRemainingFinalCost)}`} />
      </Row>
    </ColumnGroup>
  )

  return (
    <div className={`taskCode-to-cco-popup taskCode-to-cco-popup-${visible}`}>
      <div className="card card-w-title taskCode-to-cco">
        <div className='card-content-flex'>
          <DataTable 
            value={updatedCCOs} 
            header={header} 
            headerColumnGroup={headerGroup} 
            footerColumnGroup={footerGroup} 
            editMode="cell" 
            className="taskCode-to-cco-table">
            <Column body={() => `CCO`} />
            <Column field="ChgNumber" />
            <Column
              field="ChgOrderDesc"
              body={(rowData: any, column: any) => (
                <div className="row-with-button">
                  <span>{rowData.ChgOrderDesc}</span>
                  {updatedCCOs.length === 1 && <Button label="Allocate Total" onClick={() => handleAllocateTotal(rowData, column)} />}
                </div>
              )}
              colSpan={2}/>
            <Column 
              field="AssignedCost" 
              body={(rowData: any) => `$ ${displayTwoDigits(rowData.AssignedCost)}`}
              editor={(props) => editor(props, 'AssignedCost')}
              onEditorSubmit={(props) => onEditoSubmit(props)}/>
          </DataTable>
          
          {recoverPercent !== undefined && isBidItemRCRP
            ? <div className='recover-actions-wrapper'>
                <RecoverCalculator 
                  cost={taskCodeRemainingFinalCost}
                  recoverPercent={recoverPercent}
                  handleRecoverChange={handleRecoverChange} />
              </div>
            : null
          }
        </div>
        <div className='card-buttons'>
          <Button label="Cancel" icon="pi pi-times" className="p-button-secondary" onClick={handleCancel}/>
          <Button label="Ok" icon="pi pi-check" onClick={handleConfirm}/>
        </div>
      </div>
    </div>
  )
}

export default TaskCodeToCCODetails