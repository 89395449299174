import { apiCall } from "./axiosRequest";
import { AxiosResponse } from "axios";
import { AssignableTaskCode, BidDetails, TaskCodesToBeAssigned, TaskCodeStructureFromDB } from "../interfaces/Interfaces";
import { sanitizeObjectStrings } from "../utils/sanitizeObjectStrings";

export class BidDetailsService {

  // TODO: Remove
  async getBidDetailsByTaskCodeAndJobNumber(bidItem: number, jobNumber: number, changeNumber: string = "000"): Promise<[Error | null, AxiosResponse<BidDetails> | undefined]> {
    // eslint-disable-next-line eqeqeq
    const [error, response] = await apiCall("getbiddetails", { uniqueId: bidItem, jobNumber: jobNumber, changeNumber: changeNumber === "000" ? "" : changeNumber });

    return [error, response]
  }

  async saveBidDetails(taskCode: TaskCodeStructureFromDB): Promise<[Error | null, AxiosResponse<any> | undefined]> {
    const [error, response] = await apiCall("savebiddetails", taskCode);
    return [error, response]
  }

  // TODO: Remove
  async getAssignableTaskCode(jobNumber: number): Promise<[Error | null, AxiosResponse<AssignableTaskCode[]> | undefined]> {
    const [error, response] = await apiCall("gettcforbi", { jobNumber: jobNumber });
    return [error, response]
  }

  async assignTaskCodesToBidItem(assignableTaskCodes: AssignableTaskCode[], jobNumber: string, IdToWhichWeAssignTaskCode: string, chgNumber: string): Promise<[Error | null, AxiosResponse<any> | undefined]> {
    let taskCodesToBeSent: TaskCodesToBeAssigned[] = [];
    for (let taskCode of assignableTaskCodes) {
      let formattedTaskCode: TaskCodesToBeAssigned = {};

      formattedTaskCode.TaskCode = taskCode.Taskcode;
      formattedTaskCode.TaskCodeDescription = taskCode.TaskcodeDescription;
      formattedTaskCode.JobNumber = jobNumber;
      formattedTaskCode.BidItem = IdToWhichWeAssignTaskCode;
      formattedTaskCode.ChgNumber = chgNumber;
      formattedTaskCode.CCOs = taskCode.CCOs?.map(cco => sanitizeObjectStrings(cco));
      formattedTaskCode.FinalCost = taskCode.FinalCost;
      formattedTaskCode.RecoveryPercent = taskCode.RecoveryPercent;
      formattedTaskCode.HasAssigned = true

      taskCodesToBeSent = [...taskCodesToBeSent, formattedTaskCode];
    }

    const [error, response] = await apiCall("assigntctobi", [...taskCodesToBeSent]);
    return [error, response]
  }

  // This could be merged with the assignTaskCodesToBidItem function but it is kept separated for readability
  async unassignTaskCodesToBidItem(assignableTaskCodes: AssignableTaskCode[], jobNumber: string, groupedBidItem: boolean): Promise<[Error | null, AxiosResponse<any> | undefined]> {
    let taskCodesToBeSent: TaskCodesToBeAssigned[] = [];
    for (let taskCode of assignableTaskCodes) {
      let formattedTaskCode: TaskCodesToBeAssigned = {};
      formattedTaskCode.TaskCode = taskCode.Taskcode;
      formattedTaskCode.TaskCodeDescription = taskCode.TaskcodeDescription;
      formattedTaskCode.JobNumber = jobNumber;
      formattedTaskCode.BidItem = groupedBidItem ? '' : taskCode.Taskcode.substr(0, 3); // We get the default number by reading the first 3 number of the TaskCode property
      formattedTaskCode.ChgNumber = "";
      formattedTaskCode.CCOs = taskCode.CCOs?.map(cco => sanitizeObjectStrings(cco));
      formattedTaskCode.FinalCost = taskCode.FinalCost;
      formattedTaskCode.RecoveryPercent = taskCode.RecoveryPercent;
      formattedTaskCode.HasAssigned = false

      taskCodesToBeSent = [...taskCodesToBeSent, formattedTaskCode];
    }

    const [error, response] = await apiCall("assigntctobi", [...taskCodesToBeSent]);
    return [error, response]
  }

  async resetTaskCode(jobNumber: string, taskCode: string, bidItem: string): Promise<[Error | null, AxiosResponse<any> | undefined]> {
    const [error, response] = await apiCall("resettaskcode", {jobNumber, taskCode, bidItem});

    return [error, response];
  }
}
