import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { ExecutiveSummary as ExecutiveSummaryState, IExecutiveSummary, JobsMenuItem } from "../../interfaces/Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import './ExecutiveSummaryComponent.css';
import { displayTwoDigits } from "../../utils/convert";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Button } from "primereact/components/button/Button";
import { ExecutiveSummaryService } from "../../service/ExecutiveSummaryService";
import { setExecutiveSummary } from "../../redux/appSlice";
import to from "await-to-js";
import { FullScreenLoadingIndicator } from "../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { CloseButton } from "../CloseButton/CloseButton";
import { getMonthYearForExecutiveSummary } from "../../utils/getMonthYearForExecutiveSummary";
import AWS from "aws-sdk";

export const ExecutiveSummaryComponent: FC<IExecutiveSummary> = (props) => {
	const [state, setState] = useState<ExecutiveSummaryState>({
		Comments: "",
		TMCCOs: 0,
		AllocatedIndirects: 0,
		BackchargesRevenue: 0,
		BaseContractItemsBilling: 0,
		BaseContractItemsCost: 0,
		BaseContractItemsRevenue: 0,
		CCOBilling: 0,
		CCOs: 0,
		Claims: 0,
		Date: "",
		ExecutiveCCOs: 0,
		Variance: 0,
		JobNumber: "",
		Maintenance: 0,
		Margin: 0,
		Mobilization: 0,
		Overhead: 0,
		PercentComplete: 0,
		RecognizedRevenue: 0,
		LastMonthMargin: 0,
		TotalBilledToDate: 0,
		TotalJTDCost: 0,
		TotalProjectedCost: 0,
		TotalProjectedRevenue: 0,
		UnanticipatedConditions: 0,
		UnbilledRevenue: 0,
		VariancePercentage: 0,
		MarginPercentage: 0,
		LastMonthMarginPercentage: 0,
		Submit: false,
		DateNow: '',
		User: '',
		DateChanges: '',
		UserChanges: ''
	});

	const [loading, setLoading] = useState<Boolean>(false);
	const [disabled, setDisabled] = useState<Boolean>(true);
	const [userName, setUsername] = useState<string>("");
	const [dateES, setDateES] = useState<string>("");
	const [submitHistoryDate, setSubmitHistoryDate] = useState<string>("");
	const [submitHistoryUsername, setSubmitHistoryUsername] = useState<string>("");


	const dispatch = useDispatch();
	const accessToken = useSelector((state: RootState) => state.app.auth.token);
	const jobNumber = useSelector((state: RootState) => state.app.currentJobNumber);
	const jobDescription = useSelector((state: RootState) => state.app.currentJobDescription);
	const jobsMenuItemsIndex = useSelector((state: RootState) => state.app.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === jobNumber))
	const executiveSummaryRedux = useSelector((state: RootState) => state.app.jobsMenuItems[jobsMenuItemsIndex]?.ExecutiveSummary);
	const lastUpdated = useSelector((state: RootState) => state.app.lastUpdated);
	const usernameLogin = useSelector((state: RootState) => state.app.setUsername);

	const executiveSummaryService = useMemo(() => new ExecutiveSummaryService(), []);

	const date = getMonthYearForExecutiveSummary();

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
		  document.body.removeAttribute('style')
		}
	}, []);

	const getSummaryData = useCallback(() => {
		const fetchData = async () => {
			setLoading(true);
	
			const urlParams = new URLSearchParams(window.location.search);
    		const dateParam = urlParams.get('date');
			const originalDate = dateParam !== null ? dateParam : "";

			const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			const dateObj = new Date(originalDate);
			const year = dateObj.getFullYear();
			const month = String(dateObj.getMonth() + 1).padStart(2, '0');
			const monthToShowBanner = monthNames[dateObj.getMonth()];
			const day = String(dateObj.getDate()).padStart(2, '0');

			const formattedDate = `${year}-${month}-${day}`;

			setDateES(`${monthToShowBanner} ${year}`);

			function findExactMatch(data: any, referenceDate: string) {
				return data.find((item: { Date: string }) => {
				  return item.Date.split('T')[0].trim() === referenceDate.trim();
				});
			  }
			const [errorHistory, responseHistory] = await executiveSummaryService.getExecutiveSummary(jobNumber, parseInt(month), 2000);
			const dataSubmitHistory = responseHistory?.data[0];
			const exactMatch = findExactMatch(responseHistory?.data, formattedDate);
			
			const dateNowParse = (data: any) => {
				let regex = /(T|Z|")/g;
			
				const replacer = (match:any) => {
				switch (match) {
					  case `Z`:
						return ` `;
					case `T`:
						return ` `;
					case `"`:
						return ` `;
					  default:
						return ` `;
					}
				};
				if(data !== ""){
					if (data.match(regex)) {
						let stringReplaced = data.replace(regex, replacer);
						let deleteMiliseconds = stringReplaced.split('.')[0];
						let dateParse = new Date(deleteMiliseconds).toLocaleString('en-US', { timeZone: 'PST' })
						return `${dateParse}`;
					} else {
						  return `${data}`;
					}
				}else{
					return data;
				}
				}

			//Setting username for Submit flag
			let url: string = window.location.href;
			let region: string;

			if (url.includes('qa')) {
  			region = "us-west-1";
			} else {
  			region = "us-west-2"	
			}

			setUsername(usernameLogin);

			if (accessToken) {
				AWS.config.update({ region: region });
				let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
				let params = {
					AccessToken: accessToken
				};
				cognitoidentityserviceprovider.getUser(params, function(err, data) {
					if (err) {
						console.error(err, err.stack);
					} else {
						setUsername(data.Username) 
					}
				});
			}
			
			
			if (dataSubmitHistory != null && errorHistory == null) {
				setSubmitHistoryDate(dateNowParse(dataSubmitHistory.DateNow));
				setSubmitHistoryUsername(dataSubmitHistory.User)
			}
			const data: ExecutiveSummaryState = exactMatch;
			if (data != null && errorHistory == null) {		
				setState({
					Comments: data?.Comments == null || data.Comments === "" ? " " : data.Comments,
					TMCCOs: data?.TMCCOs,
					AllocatedIndirects: data?.AllocatedIndirects,
					BackchargesRevenue: data?.BackchargesRevenue,
					BaseContractItemsBilling: data?.BaseContractItemsBilling,
					BaseContractItemsCost: data?.BaseContractItemsCost,
					BaseContractItemsRevenue: data?.BaseContractItemsRevenue,
					CCOBilling: data?.CCOBilling,
					CCOs: data?.CCOs,
					Claims: data?.Claims,
					Date: data?.Date,
					ExecutiveCCOs: data?.ExecutiveCCOs,
					Variance: data?.Variance,
					JobNumber: data?.JobNumber,
					Maintenance: data?.Maintenance,
					Margin: data?.Margin,
					Mobilization: data?.Mobilization,
					Overhead: data?.Overhead,
					PercentComplete: data?.PercentComplete,
					RecognizedRevenue: data?.RecognizedRevenue,
					LastMonthMargin: data?.LastMonthMargin,
					TotalBilledToDate: data?.TotalBilledToDate,
					TotalJTDCost: data?.TotalJTDCost,
					TotalProjectedCost: data?.TotalProjectedCost,
					TotalProjectedRevenue: data?.TotalProjectedRevenue,
					UnanticipatedConditions: data?.UnanticipatedConditions,
					UnbilledRevenue: data?.UnbilledRevenue,
					VariancePercentage: data.VariancePercentage,
					MarginPercentage: data.MarginPercentage,
					LastMonthMarginPercentage: data.LastMonthMarginPercentage,
					Submit: data.Submit,
					DateNow: data.DateNow,
					User: data.User,
					DateChanges: data.DateChanges,
					UserChanges: data.UserChanges
				})
				//dispatch(setExecutiveSummary({jobNumber, data}))
				setLoading(false);	
				setDisabled(false);
			}
			else {
				// if the data is undefined (there is no entry on Executive Summary table), then you don't have a row you can update
				setLoading(false);
				setDisabled(false);
			}
		}

		fetchData()
	}, [accessToken, date.month, date.year, executiveSummaryService, jobNumber])

	useEffect(() => {
		// Make the API call to check if have any update
		getSummaryData();
		
	}, [getSummaryData, lastUpdated]);

	useEffect(() => {
		if (executiveSummaryRedux) {
			setState(executiveSummaryRedux);
		}
	}, [executiveSummaryRedux]);

	const onCommentChange = (eventTarget: any) => {
		setDisabled(false);

		setState({
			...state,
			Comments: eventTarget.value
		});
	}

	if(state.Date !== null){
		return (
			<>
				{ loading && <FullScreenLoadingIndicator /> }
				<div id="executive_summary">
					<div className="top_bar">
						<div className="job_details">{jobNumber + "-" + jobDescription}</div>
						<div className="forecast_period">Forecast {dateES}</div>
						<CloseButton onClick={props.onClose} />
					</div>
					<div className="executive_summary_content">
						<div className="middle_info">
							<div className="block">
								<div className="block_label">Revenue</div>
								<div className="content">
									<div className="top">
										<div className="row">
											<div className="label">Base Contract Items</div>
											<input value={"$" + displayTwoDigits(state?.BaseContractItemsRevenue as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Executed CCOs</div>
											<input value={"$" + displayTwoDigits(state?.ExecutiveCCOs as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Backcharges/Claims</div>
											<input value={"$" + displayTwoDigits(state?.BackchargesRevenue as number)} readOnly />
										</div>
									</div>
									<div className="mid">
										<div className="row">
											<div className="label">T&M CCOs (Projected)</div>
											<input value={"$" + displayTwoDigits(state?.TMCCOs as number)} readOnly />
										</div>
									</div>
									<div className="total">
										<div className="row">
											<div className="label">Total Projected Revenue</div>
											<input value={"$" + displayTwoDigits(state?.TotalProjectedRevenue as number)} readOnly />
										</div>
									</div>
								</div>
							</div>
							<div className="block">
								<div className="block_label">Cost at Completion</div>
								<div className="content">
									<div className="top">
										<div className="row">
											<div className="label">Base Contract Items</div>
											<input value={"$" + displayTwoDigits(state?.BaseContractItemsCost as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Mobilization</div>
											<input value={"$" + displayTwoDigits(state?.Mobilization as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Overhead</div>
											<input value={"$" + displayTwoDigits(state?.Overhead as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Allocated Indirects</div>
											<input value={"$" + displayTwoDigits(state?.AllocatedIndirects as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Maintenance</div>
											<input value={"$" + displayTwoDigits(state?.Maintenance as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Unanticipated Conditions</div>
											<input value={"$" + displayTwoDigits(state?.UnanticipatedConditions as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Claims</div>
											<input value={"$" + displayTwoDigits(state?.Claims as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">CCOs</div>
											<input value={"$" + displayTwoDigits(state?.CCOs as number)} readOnly />
										</div>
									</div>
									<div className="mid">
										<div className="row">
											<div className="label">Total JTD Cost</div>
											<input value={"$" + displayTwoDigits(state?.TotalJTDCost as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">% Complete</div>
											<input value={displayTwoDigits(state?.PercentComplete as number)} readOnly />
										</div>
									</div>
									<div className="total">
										<div className="row">
											<div className="label">Total Projected Cost</div>
											<input value={"$" + displayTwoDigits(state?.TotalProjectedCost as number)} readOnly />
										</div>
									</div>
								</div>
							</div>
							<div className="block">
								<div className="block_label">Billing</div>
								<div className="content">
									<div className="top">
										<div className="row">
											<div className="label">Base Contract Items</div>
											<input value={"$" + displayTwoDigits(state?.BaseContractItemsBilling as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">CCO</div>
											<input value={"$" + displayTwoDigits(state?.CCOBilling as number)} readOnly />
										</div>
									</div>
									<div className="mid">
										<div className="row red">
											{state?.UnbilledRevenue as number < 0 ? <div className="label">Unbilled Revenue</div> : <div className="label">Deferred Revenue</div>}
											<input value={"$" + displayTwoDigits(state?.UnbilledRevenue as number)} readOnly />
										</div>
										<div className="row">
											<div className="label">Total Billed to Date</div>
											<input value={"$" + displayTwoDigits(state?.TotalBilledToDate as number)} readOnly />
										</div>
									</div>
									<div className="total">
										<div className="row">
											<div className="label">Recognized Revenue</div>
											<input value={"$" + displayTwoDigits(state?.RecognizedRevenue as number)} readOnly />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bottom_info">
							<div className="block">
								<div className="block_label">Summary</div>
								<div className="table_header">
									<div className="header_item"></div>
									<div className="header_item">$$</div>
									<div className="header_item">%</div>
								</div>
								<div className="table_content">
									<div className="row">
										<div className="label">Margin</div>
										<input value={"$" + displayTwoDigits(state?.Margin as number)} readOnly />
										<input value={displayTwoDigits(state?.MarginPercentage as number) + " %"} readOnly />
									</div>
									<div className="row">
										<div className="label">Sallary OH Allocation</div>
										<input value={"$" + displayTwoDigits(state?.LastMonthMargin as number)} readOnly />
										<input value={displayTwoDigits(state?.LastMonthMarginPercentage as number) + " %"} readOnly />
									</div>
									<div className="row">
										<div className="label">Job Contribution</div>
										<input value={"$" + displayTwoDigits(state?.Variance as number)} readOnly />
										<input value={displayTwoDigits(state?.VariancePercentage as number) + " %"} readOnly />
									</div>
								</div>
							</div>
							<div className="block">
								<div className="block_label">Comments</div>
								<InputTextarea rows={7} cols={65} value={state.Comments as string} onChange={(e) => onCommentChange(e.target)} className="input_area"/>
							</div>
						</div>
						
						<div className="confirmation_buttons">
							<div>
								<Button icon="pi pi-times" iconPos="left" label="Cancel" 
									onClick={(e) => props.onClose?.()} className="p-button-rounded p-button-danger" />	
							</div>
							
							<div>
								<div>
								<div>
									{(state.Submit && state.DateNow) && <span className="submitted-flag">Submitted on {(state.DateNow)} by user {state.User}</span>}
								</div>
								<div>
									{<span className="changes-flag">Last Change {(state.DateChanges)} by user {state.UserChanges}</span>}
								</div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)	
	} else {
		return null
	}
	
}