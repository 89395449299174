import React, { FC, useMemo } from "react"
import { Dialog } from 'primereact/dialog'
import { Button } from "primereact/button"
import { exportDataWarningToExcel } from "../../utils/excelSheetsExport"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store";

const ConfirmationDialogES = (props: any) => {
  const { visible, onHide, title, message, onConfirm, onCancel, buttonCancel, buttonConfirm } = props

  const taskcodesWithoutBidItem = useSelector((state: RootState) => state.app.taskcodesWithoutBidItem);
	const taskcodesWithFlagFC = useSelector((state: RootState) => state.app.taskcodesWithFlagsFC);
  const jobNumber = useSelector((state: RootState) => state.app.currentJobNumber)

  const functionDownloadExcel = () => {
    let taskCodeNumbersWithoutBidItems: string[]= [];
    let taskCodeNumbersWithFlagFc: string[]= [];

    taskcodesWithoutBidItem.forEach(function(element){
      taskCodeNumbersWithoutBidItems.push(element.Taskcode)
    })
    
    taskcodesWithFlagFC.forEach(function(element){
      taskCodeNumbersWithFlagFc.push(element.Taskcode)
    })

    exportDataWarningToExcel(taskCodeNumbersWithoutBidItems, taskCodeNumbersWithFlagFc, jobNumber)
  }

  const footer = useMemo(() => (
    <div>
        <Button label={buttonConfirm} icon="pi pi-check" onClick={onConfirm} />        
        {onCancel ? <Button label={buttonCancel} icon="pi pi-times" onClick={onCancel} /> : null}
        {message === "Are you sure you want to Submit?" ? <Button icon="pi pi-download" onClick={functionDownloadExcel} /> : <div/>}        
    </div>
  ), [onConfirm, onCancel])

  return (
    <>  
      <Dialog visible={visible} onHide={onHide} header={title} footer={footer} style={{width: '50vw'}}>
        <p>{message}</p>
      </Dialog>
    </>
  )
}

export default ConfirmationDialogES