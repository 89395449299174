import { configureStore, ThunkAction, Action, Middleware } from '@reduxjs/toolkit';
import { app } from "./appSlice";
import { logger } from './middleware/loggerMiddleware';
import thunk from 'redux-thunk'
import { tokenMiddleware } from './middleware/loginMiddleware';
import { logoutMiddleware } from './middleware/logoutMiddleware';

export const store = configureStore({
  reducer: {
    app
  },
  middleware: [thunk, tokenMiddleware, logger, logoutMiddleware] as Middleware[],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
