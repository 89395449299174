import { apiGetCallJobHistoryData } from "./axiosRequest";
import { AxiosResponse } from "axios";

export class JobHistory {

    async getJobHistoryApi(jobNumber: string, date: string) {
        const [error, response] = await apiGetCallJobHistoryData({ jobNumber, date });
        return [error, response] as [Error | null, AxiosResponse<any> | undefined];
    }
  
}
