import { BidItemInterface, JobsMenuBidItemInterface, TaskCodeInterface, TaskCodeStructureFromDB } from "../interfaces/Interfaces";

export function parseFloatRemoveComma (myNumber: string | number): number {
  if (typeof myNumber === "number") {
    myNumber = myNumber.toString();
  }

  return parseFloat(myNumber?.replace(/,/g, ''));
}

export function numbersAndDotOnly (myNumber: string): string {
  // Allow one minus("-") at the beginning, after that, allow only number and dot(".")
  return myNumber?.replace(/(?!^[-])[^\d.]/g, '');
}

export function toLocaleStringUS (myNumber: number): string {
  return myNumber.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 15})
}

export function displayTwoDigits (myNumber: string | number): string {
  myNumber = parseFloatRemoveComma(myNumber);

  if (Number(myNumber.toFixed(2)) === -0) {
    myNumber = 0
  }

  return myNumber.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export function displayTreeDigits (myNumber: string | number): string {
  myNumber = parseFloatRemoveComma(myNumber);

  return myNumber.toLocaleString("en-US", {minimumFractionDigits: 3, maximumFractionDigits: 3})
}

export function toLocaleStringUSorZero (myNumber: number): string {
  // If myNumber is 0, -0, NaN or Infinity, return "0.00"

  return (!myNumber || !isFinite(myNumber)) ? "0.00" : toLocaleStringUS(myNumber);
}

export function toPercentage (myNumber: number): string {
  return `${myNumber.toString()} %`
}

export function toPercentageTwoDecimals(myNumber: number): string {
  return `${displayTwoDigits(toPercentage(myNumber))} %`
}

export function convertTaskCodesToAssignableTaskCodes (taskCodes: TaskCodeStructureFromDB[]) {
  let convertedTaskCodes = JSON.parse(JSON.stringify(taskCodes));
  for (let taskCode of convertedTaskCodes) {
    // Delete any properties which is not one of the below mentioned
    for (let property in taskCode) {
      if (taskCode.hasOwnProperty(property)) {
        switch (property) {
          case "Taskcode":
          case "BidItem":
          case "TaskcodeDescription":
          case "UnitOfMeasure":
          case "BudgetUnits":
            break;
          default:
            delete taskCode[property];
            break;
        }
      }
    }
  }

  return convertedTaskCodes;
}

export function convertJobMenuBidItemToBidItem(jobMenuBidItem: JobsMenuBidItemInterface): BidItemInterface {

  let convertedBidItem: BidItemInterface = {
    BidItem: jobMenuBidItem?.BidItem,
    BidItemDisplay: jobMenuBidItem?.BidItemDisplay,
    BidItemDescription: jobMenuBidItem?.BidItemDescription,
    ChgNumber: jobMenuBidItem?.ChgNumber,
    ChgOrderDesc: jobMenuBidItem?.ChgOrderDesc,
    UM: jobMenuBidItem?.UM,
    QTYBilled: jobMenuBidItem?.QTYBilled.toString(),
    UnitPrice: jobMenuBidItem?.UnitPrice.toString(),
    Amount: jobMenuBidItem?.Amount.toString(),
    QtyAdjustment: jobMenuBidItem?.QtyAdjustment.toString(),
    MTDCost: jobMenuBidItem?.MTDCost === null ? "0" : jobMenuBidItem?.MTDCost.toString(),
    JTDCost: jobMenuBidItem.JTDCost === null ? "0" : jobMenuBidItem.JTDCost.toString(),
    JTDQty: jobMenuBidItem?.JTDQty.toString(),
    FinalQuantity: jobMenuBidItem?.FinalQuantity.toString(),
    FinalRevenue: jobMenuBidItem?.FinalRevenue.toString(),
    HasEditedFields: jobMenuBidItem?.HasEditedFields,
    FinalCost: jobMenuBidItem?.FinalCost === null ? "0" : jobMenuBidItem?.FinalCost.toString(),
    GainLoss: jobMenuBidItem?.GainLoss.toString(),
    PreviousFinalCost: jobMenuBidItem?.PreviousFinalCost.toString(),
    Changes: jobMenuBidItem?.Changes === null ? "0" : jobMenuBidItem?.Changes.toString(),
    BidComments: jobMenuBidItem?.BidComments,
    RemCost: jobMenuBidItem?.RemCost.toString()
  }
  return convertedBidItem;
}

export function convertTaskCodeFromDBToTaskCodeInterface(taskCode: TaskCodeStructureFromDB): TaskCodeInterface {
  let convertedTaskCode = {
    ...taskCode,
    UnitOfMeasure: taskCode.UnitOfMeasure,
    BudgetUnits: taskCode.BudgetUnits.toString(),
    BudgetUnitCost: taskCode.BudgetUnitCost.toString(),
    BudgetTotalCost: taskCode.BudgetTotalCost.toString(),
    Changes: taskCode.Changes.toString(),
    MTDCost: taskCode.MTDCost.toString(),
    JTDUnits: taskCode.JTDUnits.toString(),
    JTDUnitCost: taskCode.JTDUnitCost.toString(),
    JTDTotalCost: taskCode.JTDTotalCost.toString(),
    RemainingUnits: taskCode.RemainingUnits.toString(),
    RemainingUnitCost: taskCode.RemainingUnitCost.toString(),
    RemainingTotalCost: taskCode.RemainingTotalCost.toString(),
    FinalQuantity: taskCode.FinalQuantity.toString(),
    FinalUnitCost: taskCode.FinalUnitCost.toString(),
    FinalCost: taskCode.FinalCost.toString(),
    QtyAdjustment: taskCode.QtyAdjustment.toString(),
    RecoveryPercent: taskCode.RecoveryPercent.toString(),
    LastMonthFinalCost: taskCode.LastMonthFinalCost?.toString() || '',
    Comments: taskCode.Comments || '',
    CCOs: taskCode.CCOs || [],
    HasEditedFields: '',
    CostGL: taskCode.CostGL.toString(),
    CheckFinalCost: '',
  }

  return convertedTaskCode;
}