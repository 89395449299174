import { addIdTokenToCookie, addRefreshTokenToCookie, addTokenToCookie } from "../../auth/addTokenToCookie";

/*
export const loginMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: any) => {
  if (action.type === "app/login" && action.payload.token! != null && action.payload.token! !== "") {
    addTokenToCookie(action.payload.token!);
  }
  return next(action);
}
*/

export const tokenMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: any) => {
    if (
    action.type === "app/addToken"
    && action.payload.token! != null
    && action.payload.token! !== ""
    && action.payload.id_token! != null
    && action.payload.id_token! !== ""
    && action.payload.refresh_token! != null
    && action.payload.refresh_token! !== ""
  ) {

    addTokenToCookie(action.payload.token!);
    addIdTokenToCookie(action.payload.id_token!);
    addRefreshTokenToCookie(action.payload.refresh_token!);
  }
  return next(action);
}
