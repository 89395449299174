import { apiCall } from "./axiosRequest";
import { AxiosError, AxiosResponse } from "axios";
import { jobMenuResponse } from "../interfaces/Interfaces";

export class JobListService {
  async getJobList(): Promise<any> {
    const [error, response] = await apiCall("getalljobnumbers", {});
    if ((error as AxiosError) != null) {
      return error as AxiosError;
    } else {
      return (response as AxiosResponse<jobMenuResponse[]>);
    }
  }
}
