import React, { FC, RefObject, useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { IAppTopBarProps } from './interfaces/Interfaces';
import { ExecutiveSummary } from './components/ExecutiveSummary/ExecutiveSummary';
import { ModalContext } from './components/Modal/ModalContext/ModalContext';
import { useResetJob } from './hooks/useResetJob';
// import { useIsEditedJob } from './hooks/useIsEditedJob';
import ConfirmationDialog from './components/ConfirmationDialog/ConfirmationDialog';
import { cleanJobState, setResetJobInProgress } from './redux/appSlice';
import './assets/sass/AppTopbar.scss';
import 'primeicons/primeicons.css';
import { useHistory } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { RootState } from './redux/store';
import { MessageComponent } from './components/MessageComponent/MessageComponent';
import { ExecutiveSummaryComponent } from './components/ExecutiveSummaryHistoryComponent/ExecutiveSummaryComponent';

const AppTopBar: FC<IAppTopBarProps> = (props) => {
  const [rotateMenuButton, setRotateMenuButton] = useState<boolean>(true);
  const [dialogDisplay, setDialogDisplay] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState(false);
  const [taskCodesToShow, setTaskCodesToShow] = useState<string>();
  const [taskCodesWithFlagToShow, setTaskCodesWithFlagToShow] = useState<string>();
  const home: MenuItem = useMemo(() => ({ icon: 'pi pi-home', url: "/" }), []);
  const dispatch = useDispatch()
  // const isEditedJob = useIsEditedJob(props.currentJobNumber);
  const resetJob = useResetJob();
  const modal = useContext(ModalContext);
  const toastTR: RefObject<Growl> = useRef(null);
  const history = useHistory();
  const changeJobInProgress = useSelector((state: RootState) => state.app.changeJobInProgress)
  const isExecutiveSummaryButtonDisabled = useMemo(() => {
    return (changeJobInProgress || props.setLoadingExecutiveSummary || props.currentJobNumber === "0" || props.history?.location.pathname === "/forecast") ? true : false
  }, [props.setLoadingExecutiveSummary, props.currentJobNumber, props.history?.location.pathname, changeJobInProgress]);

  let taskcodesWithoutBidItem: any[] = []
  taskcodesWithoutBidItem = useSelector((state: RootState) => state.app.taskcodesWithoutBidItem)
  let taskcodesWithFlagFC: any[] = [] 
  taskcodesWithFlagFC = useSelector((state: RootState) => state.app.taskcodesWithFlagsFC)

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    if (
      topbarRightRef.current &&
      !topbarRightRef.current.contains(event.target as Node)
      
    ) {
      setShowMessage(false);
    }
  };
  const handleDocumentClick = (event: MouseEvent) => {
    event.stopPropagation();
    handleClickOutside(event);
  };

  
  document.addEventListener('click', handleDocumentClick);
  
  return () => {
    document.removeEventListener('click', handleDocumentClick);
  };
  
}, []);

const topbarRightRef = useRef<HTMLDivElement>(null);

	let executiveSummaryRef: React.MutableRefObject<any> = useRef(null);

  const onToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onMenuButtonClick(event);
    setRotateMenuButton(rotateMenuButton => !rotateMenuButton);

    if (props.layoutMode === "static") {
      props.setLayoutMode("slim")
    } else {
      props.setLayoutMode("static")
    }

    event.preventDefault();
  }

  const onOpenExecutiveSummary = (e:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    modal?.openModal?.({
      element: <ExecutiveSummary
        executiveSummaryRef={executiveSummaryRef}
        currentJobNumber={props.currentJobNumber}
        onClose={() => {}}
      />
    });
  }

  const onOpenExecutiveSummaryHistory = (e:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log("executiveSummaryRef ---", executiveSummaryRef)
    console.log("e ---", e)
    modal?.openModal?.({
      element: <ExecutiveSummaryComponent
        executiveSummaryRef={executiveSummaryRef}
        currentJobNumber={props.currentJobNumber}
        onClose={() => {}}
      />
    });
  }

  const urlParams = new URLSearchParams(window.location.search);
  const dateParam = urlParams.get('date');
  const originalDate = dateParam !== null ? dateParam : "";
  const urlPathname = window.location.pathname
  const dateObj = new Date(originalDate);
  const year = dateObj.getFullYear();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthToShowBanner = monthNames[dateObj.getMonth()];
  
  const onConfirmReset = useCallback(async () => {
    setDialogDisplay(false);
    dispatch(setResetJobInProgress({resetJobInProgress: true}));
    
    history.push(`/job?jobNumber=${props.currentJobNumber}&jobDescription=${props.currentJobDescription}&bidItemsPage=${1}`);
    
    const [error, response] = await resetJob(props.currentJobNumber);
    
    if (error !== null) {
      dispatch(setResetJobInProgress({resetJobInProgress: false}));
      toastTR.current && toastTR.current.show({ severity: 'error', summary: 'Job reset Error', detail: (error as AxiosError).response?.data.message?.toString(), life: 5000 });
    } else {
      dispatch(cleanJobState({jobNumber: props.currentJobNumber}));
      toastTR.current && toastTR.current.show({ severity: 'success', summary: 'Job Successfully reset', detail: response?.data.message?.toString(), life: 5000 });
    }
    
  }, [dispatch, resetJob, history, props.currentJobNumber, props.currentJobDescription]);


  const functionTaskcodesMessages = () => {
    let taskCodeNumbersWithoutBidItems: string[]= [];
    let taskCodeNumbersWithFlagFc: string[]= [];

    taskcodesWithoutBidItem.forEach(function(element){
      taskCodeNumbersWithoutBidItems.push(element.Taskcode)
    })
    
    taskcodesWithFlagFC.forEach(function(element){
      taskCodeNumbersWithFlagFc.push(element.Taskcode)
    })

    const taskcodesWithoutBI =  taskCodeNumbersWithoutBidItems.join(", ")
    const taskcodesWithFlag = taskCodeNumbersWithFlagFc.join(", ")
    setTaskCodesToShow(taskcodesWithoutBI)
    setTaskCodesWithFlagToShow(taskcodesWithFlag)
   }

    return (
    <div className="topbar clearfix">
      <div className="topbar-left">
        <div className="logo" />
      </div>
      <div className="topbar-right" ref={topbarRightRef} onClick={(e) => e.stopPropagation()}>
        <div className={rotateMenuButton ? "button-menu-container button-menu-container-transition" : "button-menu-container"}>
          <button className="p-link" id="menu-button" onClick={(e) => onToggle(e)}>
            <i className={rotateMenuButton ? "" : "button-menu-button-transition"} />
          </button>
        </div>
        <div className='mobile'>
        {/* <a href={settings.logOutUri} target="_blank" rel="noopener noreferrer"><Button type="button" label="Logout" icon="pi-md-send" onClick={onLogout}></Button></a> */}
        
        <div className='topbar-buttons'>
          { urlPathname === '/jobHistory' || urlPathname === '/bidDetailsHistory' 
          ?
          <Button
          className="executive-summary"
          onClick={(e) => onOpenExecutiveSummaryHistory(e)}
          disabled={isExecutiveSummaryButtonDisabled}
          label={`Executive Summary ${monthToShowBanner} ${year}`}
          style={{ cursor: isExecutiveSummaryButtonDisabled ? 'not-allowed' : 'pointer',  width: '228px' }}
        />
          :
          <Button
            className="executive-summary"
            onClick={(e) => onOpenExecutiveSummary(e)}
            disabled={isExecutiveSummaryButtonDisabled}
            label={props.setLoadingExecutiveSummary ? 'Loading...' : 'Executive Summary'}
            style={{ cursor: isExecutiveSummaryButtonDisabled ? 'not-allowed' : 'pointer'  }}
          />
          }
          

          {/* Uncomment Button to enable ResetJob functionality */}
          {/* <Button
            className="reset-job"
            onClick={() => setDialogDisplay(true)}
            disabled={!isEditedJob}
            label="Reset job"
            icon="pi pi-refresh"
          /> */}
        </div>
        { urlPathname === '/jobHistory' || urlPathname === '/bidDetailsHistory' 
        ? null 
        : 
        <div className="topbar-breadCrumbs">
          <BreadCrumb model={props.breadCrumbItems} home={home} />
        </div>
        }
        
      </div>
      { urlPathname === '/jobHistory' || urlPathname === '/bidDetailsHistory' ? 
      null
      : 
      <div className="topbar-right topbar-bell">
      <button
        onClick={() => setShowMessage(!showMessage)}
        ref={buttonRef}
        className={taskcodesWithoutBidItem.length || taskcodesWithFlagFC.length ? "notification" : ""}
        type="button"
        style={{ border: "none", background: "none", padding: "0", margin: "0" }}
      >
      
      <Button 
      onClick={functionTaskcodesMessages}
      type="button"
      icon='pi pi-bell'/>
      </button>
      
      {showMessage && (
      <div>
      {taskcodesWithoutBidItem.length || taskcodesWithFlagFC.length ? (
      <MessageComponent props={[taskCodesToShow, taskCodesWithFlagToShow]} />
      ) : (
      ""
      )}
  </div>
)}

      </div>
      }
      
      </div>
      {dialogDisplay
        ? <ConfirmationDialog 
            visible={dialogDisplay} 
            onHide={() => setDialogDisplay(false)}
            title={`Reset job #${props.currentJobNumber}`}
            message={'Are you sure you want to reset this job to its default values?'}
            onConfirm={onConfirmReset}
            onCancel={() => setDialogDisplay(false)}
          />
        : null
      }
    </div>
  )
  }

export default AppTopBar;