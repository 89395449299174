import to from 'await-to-js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { addIdTokenToCookie } from '../auth/addTokenToCookie';
import { getIdTokenFromCookie, getRefreshTokenFromCookie } from '../auth/getTokenFromCookie';
import { removeIdTokenFromCookie, removeRefreshTokenFromCookie } from '../auth/removeTokenFromCookie';
import { settings } from "../config/settings";
import * as https from 'https'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const apiCall = async (action: string, object: any): Promise<[Error | null, AxiosResponse<any> | undefined]> => {
  //const idToken = "eyJraWQiOiJjY0Z3NGVDKzVyQ1hyK1BibEwwXC90UzlGZmpUVUdRTlRrWWdDV09MY1pUaz0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoibVpUeG8tbWxmYVdYQ1NXU2piYURUZyIsInN1YiI6ImJlMDhlYzljLWM5MTItNGQ5OC04ZWYxLTI3ODUxZWQwMWE1MyIsImNvZ25pdG86Z3JvdXBzIjpbInVzLXdlc3QtMl90U3J6OFRmZG9fTG9naW4iXSwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl90U3J6OFRmZG8iLCJjb2duaXRvOnVzZXJuYW1lIjoibG9naW5fYW5kcmVpLm5lY3VsYWlAdGVhbWFydC5ybyIsImF1ZCI6InVzZjRzcmE1cDU0ZWtxc2FraWkzZGs2OGIiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJhbmRyZWkubmVjdWxhaUB0ZWFtYXJ0LnJvIiwicHJvdmlkZXJOYW1lIjoiTG9naW4iLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cHM6XC9cL3N0cy53aW5kb3dzLm5ldFwvY2I2ZTczYWEtYjQzNS00OTk3LTlhZWQtODExYTUzMTZlY2Q0XC8iLCJwcmltYXJ5IjoidHJ1ZSIsImRhdGVDcmVhdGVkIjoiMTYwMzI3NTY4ODQwNCJ9XSwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MDM3MTA3OTEsImV4cCI6MTYwMzcxNDM5MSwiaWF0IjoxNjAzNzEwNzkxLCJlbWFpbCI6ImFuZHJlaS5uZWN1bGFpQHRlYW1hcnQucm8ifQ.J1FxTW9D-1y1ZqEsUarHxeffJjeiX5nHCaX-MEqBs1QkXNhh0uAUTNeegsVaAQkV5CoPth8zfWnUDQuHX3MqtwPz0pklOx6BwDT6wsgxNHxRzCRQEQi7rFhdz-twgQ9CE5H6ZAqXrlt6SNsVvPG_B5T58Ak_6Zg_b3yrJWxhQT-a4Rmarvw7rezw5zO98iMrAPOTRAtr3P2Kx5aNtJjAy8V1a_9rM45mGcedyjYrGcJE168jxfJTtikb2TAqbS2mYmR7IK8jXSZ_LliVjIQqBag3qOuD73ADyivCg1cY3svW3dIhFBCjfstUKdFTWxnVRCZQlxYXvx-7g71lQpwBaA"
  const idToken = getIdTokenFromCookie();
  //const idToken = settings.oldToken;
  const refreshToken = getRefreshTokenFromCookie();
  //const refreshToken = settings.refreshToken;
  const cognitoRefreshToken = refreshToken ? `Bearer ${refreshToken}` : null;
  const cognitoHeader = idToken ? `Bearer ${idToken}` : null;
  const config: AxiosRequestConfig = {
    headers: { cognito: cognitoHeader, refreshToken: cognitoRefreshToken }
  };

  let [error, res] = await to(axios.post(
    settings.forecastManager,
    {
      action: action,
      data: object
    },
    config
  ))

  if (error?.message === "Request failed with status code 402") {
    removeIdTokenFromCookie();
    removeRefreshTokenFromCookie();
    window.location.reload();
  }
  const newID_Token = res?.headers?.id_token
  if (newID_Token !== "" && newID_Token != null && newID_Token !== undefined) {
    if (newID_Token !== idToken) {
      addIdTokenToCookie(newID_Token);
    }
  }

  return [error, res]
}

export const apiPostCallFilterApi = async (
  body: any
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {
  try {
    const tokenTest = getIdTokenFromCookie();  
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': tokenTest ? `Bearer ${tokenTest}` : '',
        'Content-Type': 'application/json'
      }
    };

    const apiUrl = settings.apiFilter;

    const response = await axios.post(apiUrl, body, config);

    return [null, response];
  } catch (error) {
    console.log(error);
    return [null, undefined];
  }
};

export const apiGetCallFilterApi = async (
  params: any
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {
  try {
    const tokenTest = getIdTokenFromCookie();  
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': tokenTest ? `Bearer ${tokenTest}` : '',
        'Content-Type': 'application/json'
      },
      params: params
    };

    const apiUrl = settings.apiFilter;

    const response = await axios.get(apiUrl, config);

    return [null, response];
  } catch (error) {
    console.log(error);
    return [null, undefined];
  }
};

export const apiGetCallJobHistoryData = async (
  params: { jobNumber: string, date: string }
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {
  if (!params.jobNumber) {
    return [new Error("jobNumber is required"), undefined];
  }
  if (!params.date) {
    return [new Error("date is required"), undefined];
  }

  try {
    const tokenTest = getIdTokenFromCookie();  
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': tokenTest ? `Bearer ${tokenTest}` : '',
        'Content-Type': 'application/json'
      },
      params: params
    };

    const apiUrl = settings.apiGetJobHistory;

    const response = await axios.get(apiUrl, config);

    return [null, response];
  } catch (error) {
    console.log(error);
    return [null, undefined];
  }
};

export const apiPostCallPmFilterApi = async (
  body: any
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {
  try {
    const tokenTest = getIdTokenFromCookie();  
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': tokenTest ? `Bearer ${tokenTest}` : '',
        'Content-Type': 'application/json'
      }
    };

    const apiUrl = settings.apiPmFilter;

    const response = await axios.post(apiUrl, body, config);

    return [null, response];
  } catch (error) {
    console.log(error);
    return [null, undefined];
  }
};

export const apiGetCallPmFilterApi = async (
  params: any
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {
  try {
    const tokenTest = getIdTokenFromCookie();  
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': tokenTest ? `Bearer ${tokenTest}` : '',
        'Content-Type': 'application/json'
      },
      params: params
    };

    const apiUrl = settings.apiPmFilter;

    const response = await axios.get(apiUrl, config);

    return [null, response];
  } catch (error) {
    console.log(error);
    return [null, undefined];
  }
};