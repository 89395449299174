import { apiGetCallFilterApi, apiPostCallFilterApi } from "./axiosRequest";
import { AxiosResponse } from "axios";

export class FilterService {
    
    async postFilterApi(username: string, data: any) {
        const [error, response] = await apiPostCallFilterApi({ username, data:data });
        return [error, response] as [Error | null, AxiosResponse<any> | undefined];
    }

    async getFilterApi(username: string) {
        const [error, response] = await apiGetCallFilterApi({ username });
        return [error, response] as [Error | null, AxiosResponse<any> | undefined];
    }
  
}
