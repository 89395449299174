import { CCOInTaskCode, JobsMenuBidItemInterface } from "../interfaces/Interfaces";

export function linkTaskCodesToBidItems(jobMenuItem: any) {
  if (jobMenuItem.TaskCodes) {
    // We clear all the Task Codes from all the BidItems of the current Job
    jobMenuItem.BidItems.forEach((bidItem: JobsMenuBidItemInterface) => {
      bidItem.TaskCodeItems = [];
    });
    
    for (let taskCode of jobMenuItem.TaskCodes) {
      if (
        taskCode.BidItem === "777" ||
        taskCode.BidItem === "830" ||
        taskCode.BidItem === "850" ||
        taskCode.BidItem === "860" ||
        taskCode.BidItem === "861" ||
        taskCode.BidItem === "870" ||
        taskCode.BidItem === "888" ||
        taskCode.BidItem === "920" ||
        taskCode.BidItem === "970" ||
        taskCode.BidItem === "980"
      ) {
        let indexOfParentBidItem = jobMenuItem.BidItems.findIndex((bidItem: JobsMenuBidItemInterface) => bidItem.BidItem === "888");
        
        if (indexOfParentBidItem >= 0) {
          // Add the Task Code to the array of TaskCodeItems INSIDE the bid item which it belongs to
          jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems = [...jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems, taskCode];
        }
      } else if (
        taskCode.BidItem === "950" ||
        taskCode.BidItem === "960"
      ) {
        let indexOfParentBidItem = jobMenuItem.BidItems.findIndex((bidItem: JobsMenuBidItemInterface) => bidItem.BidItem === "950");
        
        if (indexOfParentBidItem >= 0) {
          // Add the Task Code to the array of TaskCodeItems INSIDE the bid item which it belongs to
          jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems = [...jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems, taskCode];
        }
      } else if (
        taskCode.BidItem === "944"
      ) {
        let indexOfParentBidItem = jobMenuItem.BidItems.findIndex((bidItem: JobsMenuBidItemInterface) => bidItem.BidItem === "944");
        
        if (indexOfParentBidItem >= 0) {
          // Add the Task Code to the array of TaskCodeItems INSIDE the bid item which it belongs to
          jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems = [...jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems, taskCode];
        }
      }
      else {
        // Find if there is a Bid Item with the same BidItem as the Task Code (this means the TaskCode is assigned to it)
        let indexOfParentBidItem = jobMenuItem.BidItems.findIndex((bidItem: JobsMenuBidItemInterface) => Number(bidItem.BidItem) === Number(taskCode.BidItem));
        
        if (indexOfParentBidItem >= 0) {
          // Add the Task Code to the array of TaskCodeItems INSIDE the bid item which it belongs to
          jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems = [...jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems, taskCode];
        }
      }

      if (taskCode.CCOs && taskCode.CCOs.length) {
        let indexOfParentBidItem: number = jobMenuItem.BidItems.findIndex((bidItem: JobsMenuBidItemInterface) => taskCode.CCOs.some((cco: CCOInTaskCode) => bidItem.ChgNumber === cco?.ChgNumber));

        if (indexOfParentBidItem >= 0) {
          jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems = [...jobMenuItem.BidItems[indexOfParentBidItem].TaskCodeItems, taskCode];
        }
      }
    }
  }
}