import React, {useEffect, useRef, useState} from 'react';
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from 'react-redux';
import AWS from "aws-sdk";
import { AxiosError } from 'axios';
import { RootState } from "../redux/store";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Growl } from 'primereact/growl';
import { Checkbox } from 'primereact/checkbox';
import {
  setCurrentBidItemNumber,
  setCurrentJobNumberAndDescription,
  setCurrentChangeNumber,
  setCurrentBidItemDisplay,
  setJobsMenuBidItemsAndTaskCodesHistory,
  setUsername,
  setLastBidItemNumber,
  setLastChgNumber
} from "../redux/appSlice";
import { BidItemInterface, BidItemsProps, BidItemsState, BidItemToBeSaved, jobMenuResponse } from "../interfaces/Interfaces";
import { bidItemDictionary, bidItemDictionaryDefault } from "../utils/dictionaries";
import { BidItemsService } from "../service/BidItemsService";
import { JobDataService } from "../service/JobDataService";
import { displayTwoDigits, numbersAndDotOnly, parseFloatRemoveComma, toLocaleStringUS } from "../utils/convert";
import * as BidItemCalculations from "../utils/bidItemFieldsCalculations";
import { getMonthYearForExecutiveSummary } from '../utils/getMonthYearForExecutiveSummary';
import { getParams } from "../utils/useQueryParams";
import { hardcodedBidItems, hardcodedBidItemsRCRP } from "../utils/hardcodedBidItems";
import { exportBidItemsAndTaskCodes } from "../utils/excelSheetsExport";
import 'primeicons/primeicons.css';
import '../assets/sass/BidItems.scss';
import to from 'await-to-js';
import EditColumns from './EditColumns/EditColumns';
import { FilterService } from '../service/filterService';
import { reorderObject } from '../utils/utils';
import { JobHistory } from '../service/JobDataHistory';
import { useHistory, useLocation } from 'react-router-dom';


export const BidItemsHistory = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();

  const [ inputInitialValue, setInputInitialValue] = useState("")
  const [executionCount, setExecutionCount] = useState(0);
  const [ bidItemData, setBidItemData] = useState<any[]>([])
  const [ taskCodeData, setTaskCodeData] = useState<any[]>([])
  const [ loading, setLoading] = useState(false)
  const [ changeDetected, setChangeDetected] = useState(false)
  const [ globalFilter, setGlobalFilter] = useState(null)
  const [ bidItemFiltered, setBidItemFiltered] = useState<any[]>([])
  const [ globalSearch, setGlobalSearch] = useState("")
  const [ pagination, setPagination] = useState({
    first1: 0,
    rows: 20,
    currentPage: 1,
  })
  const [ isFiltered, setIsFiltered] = useState(false)
  const [ filters, setFilters] = useState({
    RemCost: false,
    Changes: false,
    JTDCost: false,
    MTDCost: false,
  })

  const prevPropsRef = useRef(props);
  const prevStateRef = useRef({
    filters,
    globalSearch,
  });

  const [ scrollHeight, setScrollHeight] = useState('40vh')
  
  const [ username, setUsername ]= useState("")
  const [ isEditColumnsVisible, setIsEditColumnsVisible ]= useState(false)

  const urlParams = new URLSearchParams(window.location.search);
  const dateParam = urlParams.get('date');
  const originalDate = dateParam !== null ? dateParam : "";
  const dateObj = new Date(originalDate);
  const year = dateObj.getFullYear();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthToShowBanner = monthNames[dateObj.getMonth()];

  const accessToken = useSelector((state: RootState) => state.app.auth.token);
  let usernameLogin = useSelector((state: RootState) => state.app.setUsername);

  const currentJobBidItems = useSelector((state: RootState) => state.app.jobsMenuItemsHistory[0]?.BidItems);
  const currentJobTaskCodes = useSelector((state: RootState) => state.app.jobsMenuItemsHistory[0]?.TaskCodes);

  let bidItemsService: BidItemsService;
  let jobDataService: JobDataService;
  const toastTR: React.RefObject<Growl> = React.createRef();
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (inputInitialValue === '') {
      setInputInitialValue( event.target.value );
    }
    event.target.select()
  };

  let bidItemStructure: any;
  let bidItemStructureToCompare: any;
  const IsThisPCItem = (rowData: any) => {
    if (rowData.ChgNumber !== "000") {
      return true
    }
  };
  const IsThisHardcodedBidItem = (rowData: any) => {
    const _hardcodedBidItems = hardcodedBidItems.map(bid => bid.BidItem);
    return _hardcodedBidItems.includes(rowData.BidItem);
  };
  const IsThisHardcodedBidItemAllowFinalRevenueEdit = (rowData: any) => {
    // Return the BidItems numbers which DON@T have a numeric value on Final Revenue field (it means that the user is NOT able to edit them). Current: 888, 950
    return hardcodedBidItemsRCRP.includes(rowData.BidItem);
  };

  const rowClass = (rowData: any) => {
    return {
      'background-color-pink': IsThisPCItem(rowData)
    }
  };

    bidItemsService = new BidItemsService();
    jobDataService = new JobDataService();

    bidItemStructure = bidItemDictionaryDefault;
    //bidItemStructureToCompare = bidItemDictionary;


  
    useEffect(() => {
      const prevProps = prevPropsRef.current;
      const prevState = prevStateRef.current;
  
      if (!Object.is(prevState.filters, filters) || prevState.globalSearch !== globalSearch) {
        let isFilteredLocal = false;
        let filteredData = bidItemData;
  
        for (const [key, value] of Object.entries(filters)) {
          if (value && filteredData.length) {
            isFilteredLocal = true;
            filteredData = filterZeroValuesColumns(filteredData, key);
          }
        }
  
        if (isFilteredLocal) {
          setBidItemFiltered(filteredData);
          setIsFiltered(true);
        } else {
          setIsFiltered(false);
        }
  
        globalSearch && setGlobalSearchBidItems(globalSearch, filteredData);
  
      }
  
      if (prevProps.jobNumber !== props.jobNumber) {
        setGlobalSearch('');
        setBidItemFiltered([]);
        setIsFiltered(false);
        setFilters({
          RemCost: false,
          Changes: false,
          JTDCost: false,
          MTDCost: false,
        });
      }
  
      if (prevProps.lastUpdated !== props.lastUpdated) {
        setInitialValues();
      }
  
      if (prevProps.isJobDataLoading !== props.isJobDataLoading) {
        setLoading(props.isJobDataLoading);
      }
  
      prevPropsRef.current = props;
      prevStateRef.current = { filters, globalSearch };
      updateScrollHeight()
  
    }, [props, location, filters, globalSearch, loading, pagination]);

    
  const filterZeroValuesColumns = (bidItems: BidItemToBeSaved[], column: string) => {
    const filteredItems = bidItems.filter((bi: any) => Number(parseFloatRemoveComma(bi[column]).toFixed(2)) !== 0)

    return filteredItems;
  }

  const loadHistoryData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const jobNumber = urlParams.get('jobNumber');
    const date = urlParams.get('date');

    const jobDataHistory = new JobHistory();
    if(jobNumber !== null && date !== null){
      const [error, respuesta] = await jobDataHistory.getJobHistoryApi(jobNumber, date);
      dispatch(setJobsMenuBidItemsAndTaskCodesHistory({
        data: respuesta?.data,
        JobNumber: jobNumber
      }));
      }
    setBidItemData(currentJobBidItems)
    setTaskCodeData(currentJobTaskCodes)
  }

  const setInitialValues = async () => {
    if(bidItemData.length === 0 ){
      await loadHistoryData()
    }
    let updatedBidItems = [...JSON.parse(JSON.stringify(currentJobBidItems))];
    let taskCodes = [...JSON.parse(JSON.stringify(currentJobTaskCodes))];
    BidItemCalculations.setBidItemFinalCostCCOs(updatedBidItems, taskCodes);
    BidItemCalculations.setBidItemInitialValues(updatedBidItems, taskCodes, true);
    console.log("updatedBidItems--- ", updatedBidItems)
    console.log("taskCodes--- ", taskCodes)

    BidItemCalculations.calculateBidItemDetailsFinalCostFieldHistory(updatedBidItems, taskCodes);
    BidItemCalculations.setBidItemFinalRevenueHardcodeBidItems(updatedBidItems);
    BidItemCalculations.setBidItemGainLossHardcodeBidItems(updatedBidItems);
    BidItemCalculations.setBidItemPrevGLandDelta(updatedBidItems);

    
    setBidItemData(updatedBidItems)

    //setBidItemData([updatedBidItems.map(el => el.ChgNumber === "000" ? { ...el, BidItemDisplay: el.BidItem } : { ...el, BidItemDisplay: "-" })])
    
    //await getUsername()
    // Filters preferences
    /*
    const waitForUsername = (): Promise<string> => {
      return new Promise((resolve) => {
        const checkUsername = () => {
          if (username !== "") {
            resolve(username);
          } else {
            setTimeout(checkUsername, 100); 
          }
        };
        checkUsername();
      });
    };
  
    const userName = await waitForUsername();
    
    if (userName !== "") {
      const filterService = new FilterService();
      const [errorFilterPreferences, resFilterPreferences] = await filterService.getFilterApi(userName);
  
      bidItemStructure = resFilterPreferences !== undefined 
        ? reorderObject(resFilterPreferences.data.data, bidItemDictionary) 
        : bidItemDictionaryDefault;
    }
    */

    }
    
    
      useEffect(() => {
        setInitialValues();
    },[currentJobBidItems, currentJobTaskCodes]);
  
    


    const setJobNumberAndJobDescription = () => {

        const urlParams = new URLSearchParams(window.location.search);
        const jobNumber = urlParams.get('jobNumber');
        const jobDescription = urlParams.get('jobDescription');
  
        dispatch(setCurrentJobNumberAndDescription({
              JobNumber: jobNumber,
              JobDescription: jobDescription
            }))
          }

    useEffect(() => {
      setJobNumberAndJobDescription();
    },[]);
  
    const actionTemplate = (rowData: any, column: any) => {
      return <Button type="button" icon="pi-md-search" className="p-button-success" style={{ marginRight: '.5em' }} onClick={() => {
        dispatch(setCurrentBidItemNumber(rowData["BidItem"]));
        dispatch(setLastBidItemNumber(rowData["BidItem"]))
        dispatch(setLastChgNumber(rowData["ChgOrderDesc"]))
        dispatch(setCurrentBidItemDisplay(rowData["BidItemDisplay"]));
        dispatch(setCurrentChangeNumber({
          ChgNumber: rowData["ChgNumber"],
          ChgNumberDesc: rowData["ChgOrderDesc"]
        }));

        history.location.pathname = '/bidDetailsHistory';
      }} />
    } 

    const getFirstPageWhereCCOStarts = () => {
      let firstPage;
    
      if (globalSearch.length > 0) {
          firstPage = bidItemFiltered.findIndex((bi: { ChgNumber: string; }) => bi.ChgNumber !== "000");
      } else {
          firstPage = currentJobBidItems.findIndex((bi: { ChgNumber: string; }) => bi.ChgNumber !== "000")
      }
  
      const contentToSearch = currentJobBidItems[firstPage].ChgOrderDesc;
  
      
      const elements = document.querySelectorAll('td');
  
      for (let i = 0; i < elements.length; i++) {
          if (elements[i].innerHTML.includes(contentToSearch)) {
              elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
              break; 
          }
      }
  }
  

  const downloadExcel = () => {
    
    let bidItems: BidItemToBeSaved[] = bidItemData;
    const taskCodes = currentJobTaskCodes;
    // Job Number harcoded
    const filePath = `Forecast-jobnumber-6751-history-revenue-and-costs.xlsx`;

    for(let i = 0; i < bidItems.length; i++){
        bidItems[i].RemCost = parseFloatRemoveComma(bidItems[i].RemCost)
    }

    // Check the data to export excel
    exportBidItemsAndTaskCodes({ bidItems, taskCodes }, filePath);
  }

  const handleChange: any = (event: any) => {
    setGlobalSearch((event.target as HTMLTextAreaElement).value)
  }

  const setGlobalSearchBidItems  = (search: any, filteredData?: any[]) => {
    let currentBidItems: BidItemToBeSaved[] = filteredData ?? bidItemData;
    
    let globalSearchbidItem = currentBidItems.filter((bidItem) => (  
      (bidItem?.BidItem).includes(search)
      || (bidItem?.BidItemDescription).toLowerCase().includes(search)
      || parseFloatRemoveComma(bidItem?.Amount).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalCost).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalQuantity).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalRevenue).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.GainLoss).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.UnitPrice).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.JTDQty).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.Changes).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.PreviousFinalCost).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.JTDQty).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.RemCost).toFixed(2).includes(search)
      )
    )    
  
    setBidItemFiltered([...globalSearchbidItem])
  }

  const handleRemCostFilter = () => {
    const newFilters = {
      ...filters,
      RemCost: !filters.RemCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }

  const handleDeltaFilter = () => {
    const newFilters = {
      ...filters,
      Changes: !filters.Changes,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }

  const handleMtdCostFilter = () => {
    const newFilters = {
      ...filters,
      MTDCost: !filters.MTDCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }

  const handleJtdCostFilter = () => {
    const newFilters = {
      ...filters,
      JTDCost: !filters.JTDCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    setFilters(newFilters)
  }


  const onOpenEditColumns = (e:any) => {
    setIsEditColumnsVisible(true)
  };

  const onCloseEditColumns = () => {
    setIsEditColumnsVisible(true)
  };

  const confirmationEditColumns = (columns: string[]) => {
    setIsEditColumnsVisible(false)

    const filterService = new FilterService();
    const userName = username

    const newObject: Record<string, string> = columns.reduce((acc: any, key) => {
      if (bidItemStructureToCompare.hasOwnProperty(key)) {
        acc[key] = bidItemStructureToCompare[key]
      }
      return acc;
    }, {});

    bidItemStructure = reorderObject(newObject, bidItemDictionary);
    
    filterService.postFilterApi(userName, newObject)
  };

  const updateScrollHeight = () => {
    const headerElement = document.querySelector('.p-datatable-header') as HTMLElement | null;
    const headerHeight = headerElement ? headerElement.offsetHeight : 0;
    const availableHeight = (window.innerHeight - headerHeight) - 330;
    setScrollHeight(`${availableHeight}px`);
  }

    const remCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={handleRemCostFilter} checked={filters.RemCost} />
        </span>
      </div>
    )

    const deltaHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={handleDeltaFilter} checked={filters.Changes} />
        </span>
      </div>
    )

    const mtdCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={handleMtdCostFilter} checked={filters.MTDCost} />
        </span>
      </div>
    )

    const jtdCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={handleJtdCostFilter} checked={filters.JTDCost} />
        </span>
      </div>
    )

    let bidItemDynamicColumns = [Object.entries(bidItemStructure).map(([key, value]) => {
      switch (key) {
        case "BidItemDisplay":
          return <Column headerStyle={{ textAlign: 'left', width: '3em' }}
            excludeGlobalFilter={true}
            style={{ textAlign: 'left', width: '3em', fontWeight: "900" }} />;
        case "BidItem":
          return <Column key={key} 
          body={(rowData: any) => {
            return (rowData[key] === "950") ? "950/960" : rowData[key];
        }}
         field={key} header={value} headerStyle={{ textAlign: 'left', width: '3em' }}
            style={{ textAlign: 'left', width: '3em', fontWeight: "900" }} />;
        case "BidItemDescription":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '10em' }}
            style={{ textAlign: 'left', width: '10em', fontWeight: "900" }} />;
        case "ChgOrderDesc":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '5em' }}
            style={{ textAlign: 'left', width: '5em', fontWeight: "900" }} />;
        case "UM":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : toLocaleStringUS(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "QTYBilled":
        case "Amount":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FFF9AB' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "UnitPrice":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FAD8B1' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "QtyAdjustment":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FAD8B1' }}
            body={(rowData: any) => IsThisHardcodedBidItem(rowData) || IsThisPCItem(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
            style={{ textAlign: 'center', width: '3em'}}
          />;
        case "JTDQty":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FAD8B1' }}
            body={(rowData: any) => IsThisHardcodedBidItem(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
            style={{ textAlign: 'center', width: '3em' }} />;
        case "FinalQuantity":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FFF9AB' }}
            body={(rowData: any) => IsThisHardcodedBidItem(rowData)
              ? displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em'}} />;
        case "FinalRevenue":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FFF9AB' }}
            body={(rowData: any) => IsThisHardcodedBidItemAllowFinalRevenueEdit(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" || isNaN(parseFloat(rowData[key])) ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em'}} />;
        case "FinalCost":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#92D050' }}
            body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "JTDCost":
              return <Column key={key} field={key} header={jtdCostHeader(value)} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#92D050' }}
                body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
                style={{ textAlign: 'center', width: '3em' }} />;
        case "MTDCost":
              return <Column key={key} field={key} header={mtdCostHeader(value)} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#92D050' }}
                body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
                style={{ textAlign: 'center', width: '3em' }} />;
        case "RemCost":
          return <Column key={key} field={key} header={remCostHeader(value)} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#92D050' }}
            body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "GainLoss":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FAD8B1' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "PreviousFinalCost":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#92D050' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "Changes":
          return <Column key={key} field={key} header={deltaHeader(value)} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#92D050' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "HasEditedFields":
        case "BidComments":
          return null
        default:
          return <Column key={key} field={key} header={value}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
      }
    })];

    let header = (
      <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
        <div style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap'}}>
          <div style={{ 'paddingRight' : '12px'}}>Bid Items</div>
        </div>
        <div className="p-datatable-globalfilter-container">
        {/*
          <Button
            className="executive-summary"
            onClick={(e) => onOpenEditColumns(e)}
            label='Edit Columns'
            //style={{ cursor: isExecutiveSummaryButtonDisabled ? 'not-allowed' : 'pointer'  }}
          />
        */}
          <InputText type="search" onInput={handleChange} placeholder="Global Search" sizes="50" value={globalSearch} />
        </div>
      </div>
    );

    return (
      <div>
        <Growl ref={toastTR} />
        <div style={{'display': 'flex', 'justifyContent': 'center'}}>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="biditems-table-title">
                  <div className="biditems-title-link">
                    <h1>{`Bid Items History ${monthToShowBanner} ${year}`}</h1> 
                    {
                      currentJobBidItems.findIndex((bi: { ChgNumber: string; }) => bi.ChgNumber !== "000")
                        ? <div 
                          className="go_to_cco" 
                          onClick={() => getFirstPageWhereCCOStarts()}>
                            {`Go to the first page where CCOs History ${monthToShowBanner} ${year} start`}
                          </div>
                        : null
                    }
                  </div>
                  <span className="create_excel" onClick={() => downloadExcel()} title="Download report sheet">
                      {!loading && <i className='pi pi-download'></i>}
                  </span>
                </div>
                <DataTable
                  value={(isFiltered || globalSearch)
                    ? bidItemFiltered
                    : bidItemData
                  }
                  header={header}
                  globalFilter={globalFilter}
                  rowClassName={rowClass}
                  autoLayout={true}
                  loading={loading}
                  loadingIcon={'pi-md-refresh'}
                  first={pagination.first1 as number}
                  className="bid-items-table"
                  scrollable
                  scrollHeight={scrollHeight}
                  style={{ width: '100%', margin: '0 auto', overflowX: 'scroll' }} 
                > 
                  <Column header={""} body={actionTemplate} style={{ textAlign: 'center', width: '3em' }} />
                  {bidItemDynamicColumns}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        <EditColumns
          visible={isEditColumnsVisible}
          title={"Edit columns"}
          onConfirm={confirmationEditColumns}
          onCancel={onCloseEditColumns}
          onHide={onCloseEditColumns}
          Structure={bidItemStructure}
        />
      </div>
)}

