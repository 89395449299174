import React from 'react';

// Show witch enviroment we are in 
let url: string = window.location.href;
let env: string;

if (url.includes('qa')) {
  env = "QA";
} else if (url.includes('local')) {
  env = "LOCAL"
} else if (url.includes("dev")) {
  env = "DEV"
} else {
  env = "PROD"
}
export class Welcome extends React.Component {

  render() {
    return <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="welcome">
            <h1>Welcome to {env} Forecast 2.0</h1>
            <div className="welcome_image_container">
              <div className="welcome_image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Welcome;