import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AxiosError } from "axios";
import to from 'await-to-js';
import { RootState } from "../redux/store";
import { updateAssignedTaskCodes, updateTaskCodeDetails, setCurrentTaskCodes, bidItemSaved, setCurrentBidItems, setLoadingExecutiveSummary, setTaskcodesWithFlagsFC } from "../redux/appSlice";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { BidDetailsService } from "../service/BidDetailsService";
import { taskCodeDictionary } from "../utils/dictionaries";
import {
  convertTaskCodesToAssignableTaskCodes,
  displayTwoDigits,
  parseFloatRemoveComma,
  toLocaleStringUS,
  toPercentageTwoDecimals,
} from "../utils/convert";
import { settings } from "../config/settings"
import {
  AssignableTaskCode,
  CCOInTaskCode,
  JobsMenuTaskCodeInterface,
  TaskCodeInterface,
  TaskCodesDetailsProps,
  TaskCodesDetailsState,
  TaskCodeStructureFromDB
} from "../interfaces/Interfaces";
import { ExecutiveSummaryService } from "../service/ExecutiveSummaryService";
import { getMonthYearForExecutiveSummary } from "../utils/getMonthYearForExecutiveSummary";
import { taskCodesBidItemFor888 } from "../utils/utils";
import '../assets/sass/TaskCodesDetails.scss';
import TaskCodeToCCODetails from './TaskCodeToCCODetails/TaskCodeToCCODetails';
import { calculateCCOsCost, calculateTaskCodeFinalCost } from "../utils/taskCodeFieldsCalculationsFunctions";
import { updateOriginalBidItems } from "../utils/updateOriginalBidItems";
import * as TaskCodeCalculations from "../utils/taskCodeFieldsCalculations";
import * as BidItemCalculations from "../utils/bidItemFieldsCalculations";
import { BidItemsService } from "../service/BidItemsService";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import { hardcodedBidItemsRCRP } from '../utils/hardcodedBidItems';
import { exportTaskCodesToExcel } from "../utils/excelSheetsExport";
import { Checkbox } from "primereact/checkbox";
import ConfirmationDialogFinalCost from "./ConfirmationDialogFinalCost/ConfirmationDialogFinalCost";

interface AssignableTaskCodeRowData {
  BudgetUnits: number
  Taskcode: string
  TaskcodeDescription: string
  UnitOfMeasure: string
  BidItem: string
}

class TaskCodesDetails extends React.Component<TaskCodesDetailsProps, TaskCodesDetailsState> {
  private mountedComponent: boolean | undefined;
  private bidDetailsService: BidDetailsService;
  private bidItemsService: BidItemsService;
  private executiveSummaryService: ExecutiveSummaryService;
  private taskCodeItemStructure: TaskCodeInterface;
  private originalAssignedTaskCodes: AssignableTaskCode[] = [];
  private defaultAssignedTaskCodes: AssignableTaskCode[] = [];
  private overlay_panel: React.RefObject<any> = React.createRef();
  private tasks_codes_table: React.RefObject<any> = React.createRef();
  private toastTR: React.RefObject<Growl> = React.createRef();

  constructor(props: TaskCodesDetailsProps) {
    super(props);
    this.state = {
      assignableTaskCodes: [],
      assignableTaskCodesGlobalFilter: null,
      assignedTaskCodeCCOChanged: false,
      confirmationDialogMessage: '',
      confirmationDialogMessageFinalCost: '',
      confirmationDialogOnConfirm: undefined,
      confirmationDialogTitle: '',
      confirmationDialogVisible: false,
      confirmationDialogVisibleFinalCost: false,
      globalFilter: null,
      globalSearch: '',
      isOverlayOpen: false,
      lastEditedTaskCodeWithCCOs: null,
      linkedTaskCodesCCOs: [],
      overlayLoading: false,
      selectedTaskCodeCCO: {},
      selectedTaskCodeCCOCost: 0,
      selectedTaskCodesToBeAssigned: [...this.props.taskCodesData],
      taskCodeFiltered: [],
      taskCodesCCOsPopupVisibility: 'hidden',
      taskCodesCCOsPopupVisibilityOnOverlay: 'hidden',
      taskcodesWithFlagsFC: [],
      finalCostCheck: false,
      filters: {
        RemCost: false,
        Changes: false,
        JTDCost: false,
        MTDCost: false,
      },
      isFiltered: false,
      taskCodesItemsFiltered: [],
      tasksWithFinalCostOverwritten: [],
      loading: false
    };

    this.bidDetailsService = new BidDetailsService();
    this.bidItemsService = new BidItemsService();
    this.taskCodeItemStructure = taskCodeDictionary;
    this.executiveSummaryService = new ExecutiveSummaryService();

    this.taskCodeCellTemplate = this.taskCodeCellTemplate.bind(this);
    this.hasAssignedCCOs = this.hasAssignedCCOs.bind(this);
    this.handleTaskCodeCellClick = this.handleTaskCodeCellClick.bind(this);
    this.saveEditedTaskCodeCCOs = this.saveEditedTaskCodeCCOs.bind(this);
    this.cancelTaskCOdeCCOsPopup = this.cancelTaskCOdeCCOsPopup.bind(this);
    this.updateLinkedTaskCodeCCOs = this.updateLinkedTaskCodeCCOs.bind(this);
    this.handleResetTaskCodeClick = this.handleResetTaskCodeClick.bind(this);
    this.confirmationDialogOnCancel = this.confirmationDialogOnCancel.bind(this);
    this.handleRemCostFilter = this.handleRemCostFilter.bind(this);
    this.handleDeltaFilter = this.handleDeltaFilter.bind(this);
    this.handleJTDCostFilter = this.handleJTDCostFilter.bind(this);
    this.handleMTDCostFilter = this.handleMTDCostFilter.bind(this);
    this.confirmationDialogOnConfirmFinalCost = this.confirmationDialogOnConfirmFinalCost.bind(this)
    this.confirmationDialogOnCancelFinalCost = this.confirmationDialogOnCancelFinalCost.bind(this)
  }

  componentDidMount() {
    this.mountedComponent = true;
    window.scrollTo(0, 0);
  }

  componentWillUnmount(): void {
    this.mountedComponent = false;
  }

  componentDidUpdate(prevProps: TaskCodesDetailsProps, prevState: TaskCodesDetailsState) {
    this.defaultAssignedTaskCodes = [];
    
    const _filteredTaskCodesData: TaskCodeStructureFromDB[] = this.props.currentBidItemNumber === '888'
      ? this.props.taskCodesData.filter(taskCode => taskCodesBidItemFor888.includes(taskCode.BidItem))
      : this.props.taskCodesData.filter(taskCode => this.props.groupedBidItem
        ? Array.isArray(taskCode.CCOs) && taskCode.CCOs.some(cco => cco.ChgNumber === this.props.currentChangeNumber)
        : taskCode.BidItem === this.props.currentBidItemNumber);

    if (this.props.taskCodesData.length && _filteredTaskCodesData.length > 0) {
      for (let taskCode of _filteredTaskCodesData) {
        if (taskCode.Taskcode?.slice(0, 3) === taskCode.BidItem && !this.props.groupedBidItem) {
          this.defaultAssignedTaskCodes = [...this.defaultAssignedTaskCodes, ...convertTaskCodesToAssignableTaskCodes([taskCode])];
        }
      }

      this.originalAssignedTaskCodes = convertTaskCodesToAssignableTaskCodes(_filteredTaskCodesData);
      
      let filteredResponse: AssignableTaskCode[] = this.originalAssignedTaskCodes;

      if (prevProps.taskCodesData !== this.props.taskCodesData) {
        this.setState({ selectedTaskCodesToBeAssigned: JSON.parse(JSON.stringify(filteredResponse)) });
      }
    }
    
    if (prevProps.taskCodesData !== this.props.taskCodesData) {
      this.setLinkedTaskCodeCCOToState(this.props.jobTaskCodes)

      if (this.state.lastEditedTaskCodeWithCCOs && this.state.lastEditedTaskCodeWithCCOs.BidItem) {
        this.updateOriginalBidItem();
      }

      if (!this.props.taskCodesData.length) {
        this.setState({ selectedTaskCodesToBeAssigned: [] });
      }
    }
    

    if (this.state.assignedTaskCodeCCOChanged) {
      this.overlay_save();

      this.setState({assignedTaskCodeCCOChanged: false});
    }

    if (this.arraysEqual(prevProps.taskcodesWithFlagsFC, this.props.taskcodesWithFlagsFC)) {
      this.setFlagsInTaskcodes();
    }

    if (!Object.is(prevState.filters, this.state.filters) || prevState.globalSearch !== this.state.globalSearch || prevProps.taskCodesData !== this.props.taskCodesData) {
      let isFiltered = false
      let filteredData = this.props.taskCodesData

      for (const [key, value] of Object.entries(this.state.filters)) {
        if (value && filteredData.length) {
          isFiltered = true

          filteredData = this.filterZeroValuesColumns(filteredData, key)
        }
      }

      if (isFiltered) {
        this.setState({
          taskCodesItemsFiltered: filteredData,
          isFiltered: true
        })
      } else {
        this.setState({
          isFiltered: false
        })
      }
    }

}

  filterZeroValuesColumns(taskCodes: any[], column: string) {
  if(column === "JTDCost"){
    column = "JTDTotalCost"
  }else if(column === "RemCost"){
    column = "RemainingTotalCost"
  }
  const filteredItems = taskCodes.filter((bi: any) => Number(parseFloatRemoveComma(bi[column]).toFixed(2)) !== 0)

  return filteredItems;
}
  setFlagsInTaskcodes() {
    const taskcodesWithFlagsFC = this.props.taskcodesWithFlagsFC.map(
      (element: { Taskcode: any }) => element.Taskcode
    );
    
    if (!this.arraysEqual(taskcodesWithFlagsFC, this.state.taskcodesWithFlagsFC)) {
      this.setState({ taskcodesWithFlagsFC: taskcodesWithFlagsFC });
    }
  }

   arraysEqual(a: any[], b: any[]) {
    if (a.length !== b.length) {
      return false;
    }
  
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
  
    return true;
  }
  //#region "Assignable TaskCodes Overlay Panel"
  setTaskCodesToBeAssigned(taskCodes: AssignableTaskCode[]) {
    let defaultTaskCodes: any = []
    let newTaskcodes: any = []
    let compareDefault: any = []

    this.defaultAssignedTaskCodes.forEach(e =>
      defaultTaskCodes.push(e.Taskcode)
    )
    taskCodes.forEach(e =>
      newTaskcodes.push(e.Taskcode)
    )

    for (let i = 0; i < defaultTaskCodes.length; i++){
      for (let a = 0; a < newTaskcodes.length; a++){
        if (defaultTaskCodes[i] === newTaskcodes[a]) {
          compareDefault.push(newTaskcodes[a]) 
        }
      }
    }

    if (this.props.groupedBidItem && taskCodes.length > this.state.selectedTaskCodesToBeAssigned.length) { 
      this.addLinkedTaskCodeCCOToState(taskCodes[taskCodes.length - 1].Taskcode);
      this.setState({taskCodesCCOsPopupVisibilityOnOverlay: 'visible'});
    } else {
      this.removeLinkedTaskCodeCCOFromState(taskCodes);
      this.setState({taskCodesCCOsPopupVisibilityOnOverlay: 'hidden'});
    }

    if (compareDefault.length >= defaultTaskCodes.length) {
      this.setState({ selectedTaskCodesToBeAssigned: [...taskCodes] }); 
    }
    
    if(this.state.selectedTaskCodesToBeAssigned.length === taskCodes.length || taskCodes.length === 0){
      this.setState({ selectedTaskCodesToBeAssigned: [...this.defaultAssignedTaskCodes] }); 
    }
  }

  setLinkedTaskCodeCCOToState(jobTaskCodes: JobsMenuTaskCodeInterface[]) {
    if(jobTaskCodes.length > 0){
      let linkedTaskCodesToCCOs = jobTaskCodes.filter(taskCode => taskCode.CCOs && taskCode.CCOs.length > 0);

    if (linkedTaskCodesToCCOs) {
      this.setState({linkedTaskCodesCCOs: linkedTaskCodesToCCOs.map(taskCode => ({
        taskCode: taskCode.Taskcode,
        recoverPercent: taskCode.RecoveryPercent,
        CCOs: taskCode.CCOs
      }))})
    }
    }
  }

  addLinkedTaskCodeCCOToState(taskCodeId: string) {
    let currentCCO = {
      AssignedCost: 0,
      ChgNumber: this.props.currentChangeNumber,
      ChgOrderDesc: this.props.currentChangeNumberDesc
    }

    let currentTaskCodeFromDB = JSON.parse(JSON.stringify(this.props.jobTaskCodes.find(taskCode => taskCode.Taskcode === taskCodeId)));
    let currentTaskCodeIndex = this.state.linkedTaskCodesCCOs?.findIndex(item => item.taskCode === taskCodeId);

    calculateTaskCodeFinalCost(currentTaskCodeFromDB, false, true)
    
    if (currentTaskCodeIndex !== undefined && currentTaskCodeIndex >= 0 && this.state.linkedTaskCodesCCOs) {
      this.setState({
        linkedTaskCodesCCOs: this.state.linkedTaskCodesCCOs.map((item, index) => {
          if (index === currentTaskCodeIndex && item.CCOs) {
            return {
              ...item,
              recoverPercent: currentTaskCodeFromDB?.RecoveryPercent || 0,
              CCOs: [...item.CCOs, currentCCO]
            }
          }
          return item
        }),
        selectedTaskCodeCCO: {
          ...currentTaskCodeFromDB,
          CCOs: [...this.state.linkedTaskCodesCCOs[currentTaskCodeIndex].CCOs || [], currentCCO]
        },
      })
    } else {
      this.setState({
        linkedTaskCodesCCOs: [
          ...(this.state.linkedTaskCodesCCOs || []),
          {
            taskCode: taskCodeId,
            recoverPercent: currentTaskCodeFromDB?.RecoveryPercent || 0,
            CCOs: [currentCCO]
          }
        ],
        selectedTaskCodeCCO: {
          ...currentTaskCodeFromDB,
          CCOs: [currentCCO]
        },
        selectedTaskCodeCCOCost: Number(currentTaskCodeFromDB?.FinalCost)
      })
    }
  }

  updateLinkedTaskCodeCCOs(taskCodeId: string, CCOs: CCOInTaskCode[], recoverPercent: number) {
    const updatedCCOs = CCOs?.map(cco => ({...cco, AssignedCost: parseFloatRemoveComma(cco.AssignedCost)}));
    
    this.setState({
      linkedTaskCodesCCOs: this.state.linkedTaskCodesCCOs?.map((item) => {
        if (item.taskCode === taskCodeId) {
          return {...item, CCOs: updatedCCOs, recoverPercent}
        }
        return item
      }),
      taskCodesCCOsPopupVisibilityOnOverlay: 'hidden',
      assignedTaskCodeCCOChanged: true
    });
  }

  async saveEditedTaskCodeCCOs(taskCodeId: string, CCOs: CCOInTaskCode[], recoverPercent: number) {
    const updatedCCOs = CCOs?.map(cco => ({...cco, AssignedCost: parseFloatRemoveComma(cco.AssignedCost)}));

    this.setState({
      linkedTaskCodesCCOs: this.state.linkedTaskCodesCCOs?.map((item) => {
        if (item.taskCode === taskCodeId) {
          return {...item, CCOs: updatedCCOs, recoverPercent}
        }
        return item;
      }),
      taskCodesCCOsPopupVisibility: 'hidden'
    });

    let updatedTaskCodes = this.props.taskCodesData.map(taskCode => {
      if (taskCode.Taskcode === taskCodeId) {
        let linkedCCOsCost = calculateCCOsCost(updatedCCOs);
        let currentTaskCodeFinalCost = calculateTaskCodeFinalCost(taskCode, false, true, true) - linkedCCOsCost;
        return {
          ...taskCode,
          FinalCost: currentTaskCodeFinalCost,
          RecoveryPercent: recoverPercent,
          CCOs: updatedCCOs
        }
      }
      return taskCode;
    })
    
    let editedTaskCodeIndex = updatedTaskCodes.findIndex(taskCode => taskCode.Taskcode === taskCodeId)
    let taskCodeToBeSaved = updatedTaskCodes[editedTaskCodeIndex]
    
    const jobNumber = this.props.currentJobNumber;

    this.props.updateTaskCodeDetails({
      JobNumber: jobNumber,
      taskCodeToBeSaved
    });
    this.props.setCurrentTaskCodes();
    this.setState({ lastEditedTaskCodeWithCCOs: taskCodeToBeSaved });

  try {
    const [saveBidDetailsError, saveBidDetailsResponse] = await to(this.bidDetailsService.saveBidDetails(taskCodeToBeSaved));
  
    if (saveBidDetailsResponse != null && saveBidDetailsError == null) {
      const _date = getMonthYearForExecutiveSummary();
      const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber.toString(), parseInt(_date.month), parseInt(_date.year), this.props.username));
      this.props.setLoadingExecutiveSummary({ setLoading: true });

      if (updateExecutiveSummaryResponse) {
        this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
      } else {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: updateExecutiveSummaryError, life: 5000 });
      }
    } else {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError?.message, life: 5000 });
      throw new Error(saveBidDetailsError?.message);
    }
  } catch (error) {
    this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: error, life: 5000 });
    
  } finally {
    this.props.setLoadingExecutiveSummary({ setLoading: false });
    this.setState({ overlayLoading: false });
  }

  }

  async updateOriginalBidItem() {
    let jobMenuItemIndex = this.props.jobsMenuItems.findIndex(item => item.JobNumber === this.props.currentJobNumber)
    let jobBidItems = this.props.jobsMenuItems[jobMenuItemIndex].BidItems

    let updatedOriginalBidItem = updateOriginalBidItems(jobBidItems, undefined, this.state.lastEditedTaskCodeWithCCOs?.BidItem);

    if (updatedOriginalBidItem && updatedOriginalBidItem.length) {
      let originalBidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(updatedOriginalBidItem[0], this.props.currentJobNumber.toString(), this.props.currentJobDescription);
      
      const [saveBidItemError, saveBidItemResponse] = await this.bidItemsService.saveBidItemToDB([originalBidItemToBeSaved]);
      if (saveBidItemError !== null) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Saved Bid Items Error', detail: saveBidItemError, life: 5000 });
      }

      this.props.bidItemSaved({
        BidItemToBeSaved: originalBidItemToBeSaved,
        JobNumber: this.props.currentJobNumber,
        JobDescription: this.props.currentJobDescription
      });
      
      this.props.setCurrentBidItems();
    }

    this.setState({ lastEditedTaskCodeWithCCOs: null });
  }

  removeLinkedTaskCodeCCOFromState(taskCodes?: AssignableTaskCode[], taskCodeId?: string) {
    if (taskCodes && !taskCodeId) {
      let newlyUnselectedTaskCode = this.state.selectedTaskCodesToBeAssigned.findIndex(selected => !taskCodes.some(taskCode => selected.Taskcode === taskCode.Taskcode));
      taskCodeId = this.state.selectedTaskCodesToBeAssigned[newlyUnselectedTaskCode]?.Taskcode;
    }

    let linkedWithoutRemoved = this.state.linkedTaskCodesCCOs?.map((item) => {
      if (item.taskCode === taskCodeId && item.CCOs) {
        let filteredCCOs = item.CCOs.filter((cco) => cco.ChgNumber !== this.props.currentChangeNumber);
        return {
          ...item,
          CCOs: filteredCCOs
        }
      }
      return item
    })

     this.setState({
      linkedTaskCodesCCOs: linkedWithoutRemoved?.filter(linkedTaskCode => linkedTaskCode.CCOs && linkedTaskCode.CCOs.length > 0),
      selectedTaskCodeCCO: {}
    })
  }

  editTaskCodeCCOs(taskCodeId: string) {
    this.setFlagsInTaskcodes()
    if (this.state.linkedTaskCodesCCOs) {
      let currentTaskCodeFromDB = JSON.parse(JSON.stringify(this.props.jobTaskCodes.find(taskCode => taskCode.Taskcode === taskCodeId)));
      let currentTaskCodeIndex = this.props.taskCodesData?.findIndex(item => item.Taskcode === taskCodeId);
      let currentTaskCodeCCOs = this.props.taskCodesData[currentTaskCodeIndex].CCOs;

      let currentTaskCodeRecoverPercent = this.props.taskCodesData[currentTaskCodeIndex]?.RecoveryPercent;
      
      if (currentTaskCodeFromDB && currentTaskCodeIndex >= 0 && currentTaskCodeCCOs) {
        this.setState({
          selectedTaskCodeCCO: {
            ...currentTaskCodeFromDB,
            RecoveryPercent: currentTaskCodeRecoverPercent,
            CCOs: currentTaskCodeCCOs
          },

          taskCodesCCOsPopupVisibility: this.state.isOverlayOpen ? 'hidden' : 'visible',
          taskCodesCCOsPopupVisibilityOnOverlay: this.state.isOverlayOpen ? 'visible' : 'hidden'
        })
      }
    }
  }

  cancelTaskCOdeCCOsPopup(taskCodeId: string) {    
    if (this.props.groupedBidItem) {
      let currentTaskCode = this.props.taskCodesData.filter(taskCode => taskCode.Taskcode === taskCodeId);
      
      let isAssigment = !currentTaskCode[0]?.CCOs?.some(cco => cco.ChgNumber === this.props.currentChangeNumber)
      
      if (isAssigment) {
        let selectedTaskCodesToBeAssigned = isAssigment && this.state.selectedTaskCodesToBeAssigned.slice(0, -1);
        this.setState({selectedTaskCodesToBeAssigned});
        this.removeLinkedTaskCodeCCOFromState(undefined, taskCodeId);
      }
    }

    this.setState({
      taskCodesCCOsPopupVisibility: 'hidden',
      taskCodesCCOsPopupVisibilityOnOverlay: 'hidden',
      selectedTaskCodeCCO: {},
      selectedTaskCodeCCOCost: 0
    });
  }
  
  setAssignableTaskCodes(){
    let assignableTaskCodes = JSON.parse(JSON.stringify(this.props.assignableTaskCodes));

    if(this.props.currentChangeNumber === "000" || this.props.specialBidItem){
      for(let i = 0; i < assignableTaskCodes.length; i++){
        for(let a = 0; a < this.state.selectedTaskCodesToBeAssigned.length; a++){
          if(assignableTaskCodes[i].Taskcode === this.state.selectedTaskCodesToBeAssigned[a].Taskcode){
            assignableTaskCodes[i].BidItem = this.props.currentBidItemNumber
          }
        } 
      }
      return assignableTaskCodes
    }else{
      return assignableTaskCodes
    }
  }

  getAssignableTaskCodes() {
    
    let assignableTaskCodes = this.setAssignableTaskCodes()

    let filteredResponse: AssignableTaskCode[] = assignableTaskCodes
    let _assignableNonOriginalTaskCodes: AssignableTaskCode[] = JSON.parse(JSON.stringify(filteredResponse));
      
    this.setState({
      overlayLoading: false,
      assignableTaskCodes: _assignableNonOriginalTaskCodes?.sort((taskCode1, taskCode2) => taskCode1?.Taskcode > taskCode2?.Taskcode ? 1 : -1),
    });
  }

  filterDefaultAssignedTaskCodes(assignableTaskCodes: AssignableTaskCode[]): AssignableTaskCode[] {
    let filteredResponse = [];
    if (this.defaultAssignedTaskCodes.length) {

      // Filter the response (all the Task Codes in the system) so that the DEFAULT Task Codes of the current Bid Item will get removed
      filteredResponse = assignableTaskCodes.filter((taskCode) => !this.defaultAssignedTaskCodes.some(
        defaultTaskCode => defaultTaskCode.Taskcode === taskCode.Taskcode && defaultTaskCode.TaskcodeDescription === taskCode.TaskcodeDescription
      ));
    } else {
      filteredResponse = assignableTaskCodes;
    }

    return filteredResponse
  }

  showOverlay(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

    this.overlay_panel.current.show(e);
    this.setState({ isOverlayOpen: true });

    this.overlay_panel.current.container.style.top = e.currentTarget.offsetTop + e.currentTarget.offsetHeight + 'px';
    this.overlay_panel.current.container.style.width = `calc(${this.tasks_codes_table.current.offsetWidth}px - 2em)`
    this.overlay_panel.current.container.style.left = `calc(${this.tasks_codes_table.current.offsetLeft}px + 1em)`
    this.overlay_panel.current.container.classList.remove('p-overlaypanel-flipped');

    if (!this.state.assignableTaskCodes.length) {
      this.setState({ overlayLoading: true });
      this.getAssignableTaskCodes();
    }
  }

  overlay_cancel(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ selectedTaskCodesToBeAssigned: JSON.parse(JSON.stringify(this.originalAssignedTaskCodes)) });
    this.setState({ assignableTaskCodes: [] })
    this.setState({ isOverlayOpen: false });
    this.overlay_panel.current.hide(e);
  }

  async overlay_save(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    let currentChangeNumber = "";
    let IdToWhichWeAssignTaskCodeForDB = this.props.groupedBidItem ? "" : this.props.currentBidItemNumber;
    
    let IdToWhichWeAssignTaskCode = this.props.groupedBidItem ? "" : this.props.currentBidItemNumber;     

    let newlyUncheckedTaskCodes = this.originalAssignedTaskCodes
      .filter((originalTaskCode) => !this.isInSelectedTaskCodes(originalTaskCode, this.state.selectedTaskCodesToBeAssigned))
      .filter((originalTaskCode) => !this.isInDefaultAssignedTaskCodes(originalTaskCode, this.defaultAssignedTaskCodes));

    let newlyCheckedTaskCodes = this.state.selectedTaskCodesToBeAssigned
      .filter((selectedTaskCode) => !this.isInOriginalAssignedTaskCodes(selectedTaskCode, this.originalAssignedTaskCodes))

    let newlyCheckedTaskCodesWithCCOs = newlyCheckedTaskCodes.map(taskCode => {
      let updatedTaskCode = {...taskCode}
      let recoverPercent = this.state.linkedTaskCodesCCOs?.find(item => item.taskCode === taskCode.Taskcode)?.recoverPercent
      let linkedCCOs = this.state.linkedTaskCodesCCOs?.find(item => item.taskCode === taskCode.Taskcode)?.CCOs
      let linkedCCOsCost = linkedCCOs && calculateCCOsCost(linkedCCOs)
      let currentTaskCode = this.props.jobTaskCodes.filter(propsTaskCode => propsTaskCode.Taskcode === taskCode.Taskcode)
      let currentTaskCodeFinalCost = calculateTaskCodeFinalCost({...currentTaskCode[0]}, false, true, true) - (linkedCCOsCost || 0);
      let bidItemId = currentTaskCode[0].BidItem

      return {
        ...updatedTaskCode,
        FinalCost: currentTaskCodeFinalCost,
        RecoveryPercent: recoverPercent,
        CCOs: linkedCCOs,
        BidItem: bidItemId
      }
    })

    let newlyUncheckedTaskCodesWithCCOs = newlyUncheckedTaskCodes.map(taskCode => {
      let updatedTaskCode = {...taskCode}
      let linkedCCOs = this.state.linkedTaskCodesCCOs?.find(item => item.taskCode === taskCode.Taskcode)?.CCOs
      let linkedCCOsCost = linkedCCOs && calculateCCOsCost(linkedCCOs)
      let currentTaskCode = this.props.jobTaskCodes.filter(propsTaskCode => propsTaskCode.Taskcode === taskCode.Taskcode)
      let currentTaskCodeFinalCost = calculateTaskCodeFinalCost({...currentTaskCode[0]}, false, true, true) - (linkedCCOsCost || 0);
      let recoverPercent = currentTaskCode[0].RecoveryPercent
      let bidItemId = currentTaskCode[0].BidItem
      
      this.overlay_panel.current.hide(e);
      return {
        ...updatedTaskCode,
        FinalCost: currentTaskCodeFinalCost,
        RecoveryPercent: recoverPercent,
        CCOs: linkedCCOs,
        BidItem: bidItemId
      }
    })
    
    if (newlyCheckedTaskCodesWithCCOs.length) {
      await this.props.updateAssignedTaskCodes({
        taskCodesToBeUpdated: newlyCheckedTaskCodesWithCCOs,
        idToWhichWeAssignTaskCode: this.props.groupedBidItem ? '' : IdToWhichWeAssignTaskCode,
        areWeAssigningTaskCodes: true,
        groupedBidItem: this.props.groupedBidItem
      });
      
      this.assignTaskCodesToBidItemsInDB(newlyCheckedTaskCodesWithCCOs, IdToWhichWeAssignTaskCodeForDB, currentChangeNumber);
    }

    if (newlyUncheckedTaskCodesWithCCOs.length) {
      await this.props.updateAssignedTaskCodes({
        taskCodesToBeUpdated: newlyUncheckedTaskCodesWithCCOs,
        idToWhichWeAssignTaskCode: '',
        areWeAssigningTaskCodes: false,
        groupedBidItem: this.props.groupedBidItem
      });

      this.unAssignTaskCodesToBidItemsInDB(newlyUncheckedTaskCodesWithCCOs);
    }

    this.props.assignedTaskCodesChanged([...newlyCheckedTaskCodesWithCCOs, ...newlyUncheckedTaskCodesWithCCOs]);

    // Reset the originalAssignedTaskCodes and assignableTaskCodes to allow taskCode selection at all times
    this.originalAssignedTaskCodes = [];
    this.setState({ assignableTaskCodes: [] })
    this.setState({ isOverlayOpen: false });
    this.overlay_panel.current.hide(e);
    this.props.setLoadingExecutiveSummary({ setLoading: true})
  }

  isInSelectedTaskCodes(originalTaskCode: AssignableTaskCode, selectedTaskCodesToBeAssigned: AssignableTaskCode[]) {
    return selectedTaskCodesToBeAssigned.some((selectedTaskCode) => (
      selectedTaskCode.Taskcode === originalTaskCode.Taskcode && selectedTaskCode.TaskcodeDescription === originalTaskCode.TaskcodeDescription
    ));
  }

  isInDefaultAssignedTaskCodes(originalTaskCode: AssignableTaskCode, defaultAssignedTaskCodes: AssignableTaskCode[]) {
    return defaultAssignedTaskCodes.some((defaultAssignedTask) => defaultAssignedTask.Taskcode === originalTaskCode.Taskcode);
  }

  isInOriginalAssignedTaskCodes(selectedTaskCode: AssignableTaskCode, originalAssignedTaskCodes: AssignableTaskCode[]) {
    return originalAssignedTaskCodes.some((originalTaskCode) => (
      originalTaskCode.Taskcode === selectedTaskCode.Taskcode && originalTaskCode.TaskcodeDescription === selectedTaskCode.TaskcodeDescription
    ));
  }

  async assignTaskCodesToBidItemsInDB(newlyCheckedTaskCodes: AssignableTaskCode[], IdToWhichWeAssignTaskCode: string, currentChangeNumber: string) {
    
    const [error, response] = await this.bidDetailsService.assignTaskCodesToBidItem(
      newlyCheckedTaskCodes,
      this.props.currentJobNumber.toString(),
      IdToWhichWeAssignTaskCode,
      currentChangeNumber
    );

    if (error != null) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: (error as AxiosError).response?.data.message, life: settings.toastLife });
    } else if (response != null) {
      // update executive summary
      this.props.setLoadingExecutiveSummary({ setLoading: true });

      try {
        const _date = getMonthYearForExecutiveSummary();
        const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber.toString(), parseInt(_date.month), parseInt(_date.year), this.props.username));

      if (updateExecutiveSummaryResponse) {
        this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
      } else {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Update Executive Summary Error', detail: updateExecutiveSummaryError, life: 5000 });
      }
      } catch (error) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Update Executive Summary Error', detail: error, life: 5000 });
      } finally {
        this.props.setLoadingExecutiveSummary({ setLoading: false });
        this.setState({ overlayLoading: false });
      }
    }
  }

  async unAssignTaskCodesToBidItemsInDB(newlyUncheckedTaskCodes: AssignableTaskCode[]) {
    const [error, response] = await this.bidDetailsService.unassignTaskCodesToBidItem(
      newlyUncheckedTaskCodes,
      this.props.currentJobNumber.toString(),
      this.props.groupedBidItem
    );

    if (error != null) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Unassigned Error', detail: (error as AxiosError).response?.data.message, life: settings.toastLife });
    } else if (response != null) {
      // TODO: Implement the synchronisation system between the Database and Store
      this.toastTR?.current?.show({ severity: 'success', summary: 'Task Codes Unassigned', detail: "Save successfully! ", life: settings.toastLife });
      
      // update executive summary
      try {
        this.props.setLoadingExecutiveSummary({ setLoading: true });
      
        const _date = getMonthYearForExecutiveSummary();
        const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber.toString(), parseInt(_date.month), parseInt(_date.year), this.props.username));
      
        if (updateExecutiveSummaryResponse) {
          this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
        } else {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Update Executive Summary Error', detail: updateExecutiveSummaryError, life: 5000 });
        }
      } catch (error) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Update Executive Summary Error', detail: error, life: 5000 });
      } finally {
        this.props.setLoadingExecutiveSummary({ setLoading: false });
        this.setState({ overlayLoading: false });
      }
    }
  }
  //#endregion

  calculate_total(property: any) {
    let total = 0;
    for (let taskCode of this.props.taskCodesData) {
      // @ts-ignore
      if (taskCode[property]) {
        // @ts-ignore
        total += parseFloatRemoveComma(taskCode[property]);
      }
    }
    return toLocaleStringUS(total);
  }

  hasAssignedCCOs(rowData: AssignableTaskCodeRowData): boolean {
    const currentTaskCode = this.state.linkedTaskCodesCCOs?.find(taskCode => taskCode.taskCode === rowData.Taskcode);    
  
    return Boolean(currentTaskCode?.CCOs?.length);
  }

  isClickableTaskCode(rowData: AssignableTaskCodeRowData): boolean {
    if (this.state.isOverlayOpen && this.state.selectedTaskCodesToBeAssigned.some(taskCode =>taskCode.Taskcode == rowData.Taskcode)) {
      return true
    } else if (!this.state.isOverlayOpen && this.props.taskCodesData.some(taskCode =>taskCode.Taskcode == rowData.Taskcode)) {
      return true
    } else {
      return false
    }
  }

  handleTaskCodeCellClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, rowData: AssignableTaskCodeRowData) {
    e.preventDefault()
    let isClickableTaskCode = this.isClickableTaskCode(rowData)
    let hasAssignedCCOs = this.hasAssignedCCOs(rowData)

    if (hasAssignedCCOs && isClickableTaskCode) {
      this.editTaskCodeCCOs(rowData.Taskcode)
    } else {
      return
    }
  }

  handleResetTaskCodeClick(rowData: any) {
    const updateCCOs = (taskCode: TaskCodeStructureFromDB): TaskCodeStructureFromDB => {
      const updatedTaskCode = JSON.parse(JSON.stringify(rowData)) as TaskCodeStructureFromDB;

      if (updatedTaskCode.CCOs?.length) {
        const ccos = updatedTaskCode.CCOs.filter(cco => !cco.isTotalAllocated);

        updatedTaskCode.CCOs = ccos.length ? ccos : null;
      }
      return updatedTaskCode;
    }

    const confirmReset = async (rowData: any) => {
      this.setState({confirmationDialogVisible: false});
      const taskCodeToReset = JSON.parse(JSON.stringify(rowData)) as JobsMenuTaskCodeInterface;
      
      const originalBidItemId = taskCodesBidItemFor888.includes(taskCodeToReset.Taskcode.slice(0, 3)) ? '888' : taskCodeToReset.Taskcode.slice(0, 3);
      const assignedBidItemId = taskCodesBidItemFor888.includes(taskCodeToReset.BidItem) ? '888' : taskCodeToReset.BidItem;
      
      const preparedTaskCode = TaskCodeCalculations.prepareTaskCodeForDB(taskCodeToReset);
      const taskCodeToBeSaved = updateCCOs(preparedTaskCode);
      TaskCodeCalculations.setTaskCodeInitialValues([taskCodeToBeSaved]);

      taskCodeToBeSaved.Comments = null
      taskCodeToBeSaved.HasEditedFields = 0;
      taskCodeToBeSaved.CheckFinalCost = false
      
      this.props.onResetTaskCode(taskCodeToBeSaved, originalBidItemId, assignedBidItemId);
    }
    
    this.setState({
      confirmationDialogVisible: true,
      confirmationDialogTitle: `Reset Task Code ${rowData.Taskcode}?`,
      confirmationDialogMessage: 'It will be restored to its default values of the current month.',
      confirmationDialogOnConfirm: () => confirmReset(rowData)
    });
  }

  confirmationDialogOnCancel() {
    this.setState({
      confirmationDialogVisible: false,
      confirmationDialogTitle: ``,
      confirmationDialogMessage: '',
      confirmationDialogOnConfirm: undefined
    });
  }

  confirmationDialogOnConfirmFinalCost() {
    this.setState({
      confirmationDialogVisibleFinalCost: false,
      confirmationDialogMessageFinalCost: ''
    });
    this.handleFinalCostOverwrittenChange(this.state.tasksWithFinalCostOverwritten)
  }

  confirmationDialogOnCancelFinalCost() {
    this.setState({
      confirmationDialogVisibleFinalCost: false,
      confirmationDialogMessageFinalCost: ''
    });
    this.setState({ tasksWithFinalCostOverwritten: [] });
  }


  taskCodeCellTemplate(rowData: AssignableTaskCodeRowData): JSX.Element {
    return (
      <div 
        className={`taskcode-id-wrapper ${this.hasAssignedCCOs(rowData) && 'have-ccos'} ${this.isClickableTaskCode(rowData) && 'clickable-cell'}`}
        onClick={(e) => this.handleTaskCodeCellClick(e, rowData)}>
        <span>{rowData.Taskcode}</span>
      </div>
    )
  }

  handleChange: any = (event: any) => {
    this.props.globalSearchValue((event.target as HTMLTextAreaElement).value);
  }

  downloadExcel = () => {
    
    const taskCodes: JobsMenuTaskCodeInterface[] = this.props.jobTaskCodes;
    const filePath = `jobnumber${this.props.currentJobNumber}-TaskCodes-excel-from-Forecast.xlsx`;

    exportTaskCodesToExcel(taskCodes, filePath);
    
    
  }

  handleCheckboxChange = async (rowData: any) => {
    rowData.CheckFinalCost = !rowData.CheckFinalCost
    const originalBidItemId = taskCodesBidItemFor888.includes(rowData.Taskcode.slice(0, 3)) ? '888' : rowData.Taskcode.slice(0, 3);
    const assignedBidItemId = taskCodesBidItemFor888.includes(rowData.BidItem) ? '888' : rowData.BidItem;
    if(rowData.CheckFinalCost){
        this.setState({confirmationDialogVisible: false});
        const taskCodeToUpdate = JSON.parse(JSON.stringify(rowData)) as JobsMenuTaskCodeInterface;
  
        const preparedTaskCode = TaskCodeCalculations.prepareTaskCodeForDB(taskCodeToUpdate);
        TaskCodeCalculations.setCheckFinalCostValues([preparedTaskCode]);

        const [saveBidDetailsError] = await this.bidDetailsService.saveBidDetails(preparedTaskCode)
        if (saveBidDetailsError !== null) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
          throw new Error(saveBidDetailsError.message);
        }

        this.props.onCheckFinalCost(preparedTaskCode, originalBidItemId, assignedBidItemId)
    }else{   
        this.setState({confirmationDialogVisible: false});
        const taskCodeToUpdate = JSON.parse(JSON.stringify(rowData)) as JobsMenuTaskCodeInterface;
  
        const preparedTaskCode = TaskCodeCalculations.prepareTaskCodeForDB(taskCodeToUpdate);
        TaskCodeCalculations.setCheckFinalCostValues([preparedTaskCode]);

        const [saveBidDetailsError] = await this.bidDetailsService.saveBidDetails(preparedTaskCode)
        if (saveBidDetailsError !== null) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
          throw new Error(saveBidDetailsError.message);
        }
        
        this.props.onCheckFinalCost(preparedTaskCode, originalBidItemId, assignedBidItemId)
    }
  };

  handleFinalCostOverwrittenChange = async (arrayTaskcodes: any) => {
    const taskcodesData =  arrayTaskcodes
    
    const processTaskcode = async (rowData: any) => {
      const originalBidItemId = taskCodesBidItemFor888.includes(rowData.Taskcode.slice(0, 3)) ? '888' : rowData.Taskcode.slice(0, 3);
      const assignedBidItemId = taskCodesBidItemFor888.includes(rowData.BidItem) ? '888' : rowData.BidItem;
      const taskCodeToUpdate = JSON.parse(JSON.stringify(rowData)) as JobsMenuTaskCodeInterface;
      let preparedTaskCode = TaskCodeCalculations.prepareTaskCodeForDB(taskCodeToUpdate);
      preparedTaskCode.FinalCostOverwritten = 0
      try {
        const [saveBidDetailsError] = await this.bidDetailsService.saveBidDetails(preparedTaskCode)
        if (saveBidDetailsError !== null) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
          throw new Error(saveBidDetailsError.message);
        }
        this.props.onCheckFinalCost(preparedTaskCode, originalBidItemId, assignedBidItemId)
        this.setState({ tasksWithFinalCostOverwritten: [] });
      }
      catch(error){
        console.error(error)
      }
  }

    try {
      this.setState({ loading: true });
      await Promise.all(taskcodesData.map(processTaskcode));
    } finally {
      this.setState({ loading: false });
    }
    
  };

  handleRemCostFilter() {
    const newFilters = {
      ...this.state.filters,
      RemCost: !this.state.filters.RemCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  handleDeltaFilter() {
    const newFilters = {
      ...this.state.filters,
      Changes: !this.state.filters.Changes,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  handleJTDCostFilter() {
    const newFilters = {
      ...this.state.filters,
      JTDCost: !this.state.filters.JTDCost,
    };
    
    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  handleMTDCostFilter() {
    const newFilters = {
      ...this.state.filters,
      MTDCost: !this.state.filters.MTDCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  render() {
    const remCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleRemCostFilter} checked={this.state.filters.RemCost} />
        </span>
      </div>
    )

    const deltaHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleDeltaFilter} checked={this.state.filters.Changes} />
        </span>
      </div>
    )

    const jtdCostHeader = (value: any) => {
      return (
          <div className='col-header-with-filter'>
              <span>{value}</span>
              <span>
                  <Checkbox onChange={this.handleJTDCostFilter} checked={this.state.filters.JTDCost} />
              </span>
          </div>
      );
  };
    const mtdCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleMTDCostFilter} checked={this.state.filters.MTDCost} />
        </span>
      </div>
    )
    
    let taskCodesColumns = [Object.entries(this.taskCodeItemStructure).map(([key, value]) => {
      switch (key) {
        case "Taskcode":
          return <Column 
            key={key} 
            field={key} 
            header={value} 
            style={{ textAlign: 'left', width: '4.5em', minWidth: '4.75em' }}
            body={this.taskCodeCellTemplate}
            className="task-code-id-cell"
            />;
        case "TaskcodeDescription":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '6em', minWidth: '6em' }}
            style={{ textAlign: 'left', width: '3em' }} />;
        case "UnitOfMeasure":
          return <Column key={key} field={key} header={value} style={{ textAlign: 'center', width: '6em', minWidth: '6em' }} />;
        case "BudgetUnits":
        case "BudgetUnitCost":
        case "BudgetTotalCost":
          return <Column key={key} field={key} header={value}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFF9AB' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "JTDUnits":
        case "JTDUnitCost":
            return <Column key={key} field={key} header={value}
              headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#D2FACF' }}
              body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
              style={{ textAlign: 'center', width: '3em' }} />;
        case "MTDCost":
          return <Column key={key} field={key} header={mtdCostHeader(value)}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#D2FACF' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "JTDTotalCost":
          return <Column key={key} field={key} header={jtdCostHeader(value)}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#D2FACF' }}
            body={(rowData: any) => {
              const value = rowData[key];
              if (isNaN(parseFloat(value))) {
                  return value;
              } else {
                  const valueFinalCost = parseFloatRemoveComma(rowData["FinalCost"]); 
                  const valueJTDTotalCost = parseFloatRemoveComma(rowData["JTDTotalCost"]);
                  const valueJTDFinalQTY = parseFloatRemoveComma(rowData["FinalQuantity"]);
                  const valueJTDFinalUnitCost = parseFloatRemoveComma(rowData["FinalUnitCost"]);
                  const valueCCOs = rowData["CCOs"]
                  let highlightTheNumber = false
                  if (valueJTDTotalCost - valueFinalCost >= 0.0090) {
                    if (valueCCOs) {
                        let finalNumber = valueJTDFinalQTY * valueJTDFinalUnitCost;
                        if (valueJTDTotalCost - finalNumber >= 0.0090) {
                          highlightTheNumber = true
                        }
                    } else {
                      highlightTheNumber = true
                    }
                  }
                  return !highlightTheNumber ? (
                    displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span>{displayTwoDigits(rowData[key])}</span>
                  ) 
                  : (displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span style={{backgroundColor: '#FEA28E', padding: '0.5em'}}>{displayTwoDigits(rowData[key])}</span>);
              }
          }}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "RemainingUnits":
        case "RemainingUnitCost":
          return <Column key={key} field={key} header={value}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FAD8B1' }}
            editor={!this.props.groupedBidItem ? (props) => this.props.editor(props, key, true) : undefined}
            onEditorCancel={(props) => this.props.onEditorCancel(props, true)}
            onEditorSubmit={(props) => this.props.onEditorSubmit(props, true)}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', backgroundColor: !this.props.groupedBidItem ? '#FFFCED' : undefined }} />;
        case "RemainingTotalCost":
          return <Column key={key} field={key} header={remCostHeader(value)}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FAD8B1' }}
            editor={!this.props.groupedBidItem ? (props) => this.props.editor(props, key, true) : undefined}
            onEditorCancel={(props) => this.props.onEditorCancel(props, true)}
            onEditorSubmit={(props) => this.props.onEditorSubmit(props, true)}
            body={(rowData: any) => {
              const value = rowData[key];
              const jtdTotalCost = parseFloatRemoveComma(rowData["JTDTotalCost"]);
              const valueCCOs = rowData["CCOs"];
              const remainingTotalCost = parseFloatRemoveComma(rowData["RemainingTotalCost"]);
              const finalQuantity = parseFloatRemoveComma(rowData["FinalQuantity"]);
              const finalUnitCost = parseFloatRemoveComma(rowData["FinalUnitCost"]);
              const finalCost = parseFloatRemoveComma(rowData["FinalCost"]);
              const calculatedCost = jtdTotalCost + remainingTotalCost;
              const finalCostCalculation1 = calculatedCost - (finalQuantity * finalUnitCost);
              const finalCostCalculation2 = calculatedCost - finalCost;

              if (isNaN(parseFloat(value))) {
                return displayTwoDigits(value);
              }

              const displayedValue = displayTwoDigits(value);

              if (displayedValue === "0.00") {
                return "-";
              }

              if (displayTwoDigits(finalCost) === "0.00") {
                if (finalCostCalculation1 > -0.1 && finalCostCalculation1 < 0.1) {
                  return displayedValue;
                } else {
                  return (
                    <span
                      title="The remaining cost is outdated, please fix by inputting the same Final Cost again to recalculate"
                      style={{ backgroundColor: '#FFF9AB', padding: '0.5em' }}
                    >
                      {displayedValue}
                    </span>
                  );
                }
              } else if (valueCCOs !== null && (finalCostCalculation1 > -0.1 && finalCostCalculation1 < 0.1)){
                return displayedValue;
              } 
              else {
                if (finalCostCalculation2 > -0.1 && finalCostCalculation2 < 0.1) {
                  return displayedValue;
                } else {
                  return (
                    <span
                      title="The remaining cost is outdated, please fix by inputting the same Final Cost again to recalculate"
                      style={{ backgroundColor: '#FFF9AB', padding: '0.5em' }}
                    >
                      {displayedValue}
                    </span>
                  );
                }
              }
              
            }}
            style={{ textAlign: 'center', width: '3em', backgroundColor: !this.props.groupedBidItem ? '#FFFCED' : undefined }} />;
        case "FinalQuantity":
        case "FinalUnitCost":
          return <Column key={key} field={key} header={value}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#BADFF5' }}
            editor={!this.props.groupedBidItem ? (props) => this.props.editor(props, key, true) : undefined}
            onEditorCancel={(props) => this.props.onEditorCancel(props, true)}
            onEditorSubmit={(props) => this.props.onEditorSubmit(props, true)}
            body={(rowData: any) => {
              const CCOsValue = rowData['CCOs'];
              return isNaN(parseFloat(rowData[key])) 
                ? rowData[key] 
                : CCOsValue !== null && CCOsValue[0].isTotalAllocated === true ? "-"
                : displayTwoDigits(rowData[key]) === "0.00" || displayTwoDigits(rowData[key]) === "∞" 
                ? "-" 
                : displayTwoDigits(rowData[key]);
            }}
            style={{ textAlign: 'center', width: '3em', backgroundColor: !this.props.groupedBidItem ? '#FFFCED' : undefined }} />;
        case "FinalCost":
          return <Column key={key} field={key} header={value}
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#BADFF5' }}
          editor={!this.props.groupedBidItem ? (props) => this.props.editor(props, key, true) : undefined }
          onEditorCancel={(props) => this.props.onEditorCancel(props, true)}
          onEditorSubmit={(props) => this.props.onEditorSubmit(props, true)}
          body={(rowData: any) => {
            const value = rowData[key];
            if (isNaN(parseFloat(value))) {
                return value;
            } else {
                const valueFinalCost = parseFloatRemoveComma(rowData["FinalCost"]); 
                const valueJTDTotalCost = parseFloatRemoveComma(rowData["JTDTotalCost"]);
                const valueJTDFinalQTY = parseFloatRemoveComma(rowData["FinalQuantity"]);
                const valueJTDFinalUnitCost = parseFloatRemoveComma(rowData["FinalUnitCost"]);
                const valueCCOs = rowData["CCOs"]
                let highlightTheNumber = false
                if (valueJTDTotalCost - valueFinalCost >= 0.0090) {
                  if (valueCCOs) {
                      let finalNumber = valueJTDFinalQTY * valueJTDFinalUnitCost;
                      if (valueJTDTotalCost - finalNumber >= 0.0090) {
                        highlightTheNumber = true
                      }
                  } else {
                    highlightTheNumber = true
                  }
                }
                return !highlightTheNumber ? (
                  displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span>{displayTwoDigits(rowData[key])}</span>
                ) 
                : (displayTwoDigits(rowData[key]) === "0.00" ? "-" : <span style={{backgroundColor: '#FEA28E', padding: '0.5em'}}>{displayTwoDigits(rowData[key])}</span>);
            }
        }}
          style={{ textAlign: 'center', width: '3em', backgroundColor: !this.props.groupedBidItem ? '#FFFCED' : undefined }} />;  
        case "QtyAdjustment":
          return <Column key={key} field={key} header={value}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#BADFF5' }}
            editor={!this.props.groupedBidItem ? (props) => this.props.editor(props, key, true) : undefined}
            onEditorCancel={(props) => this.props.onEditorCancel(props, true)}
            onEditorSubmit={(props) => this.props.onEditorSubmit(props, true)}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', backgroundColor: !this.props.groupedBidItem ? '#FFFCED' : undefined }} />;  
        case "RecoveryPercent":
          return (this.props.specialBidItem && hardcodedBidItemsRCRP.includes(this.props.currentBidItemNumber)) 
            ? <Column key={key} field={key} header={value}
                headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: 'orange' }}
                editor={(props) => this.props.editor(props, key, true)}
                onEditorCancel={(props) => this.props.onEditorCancel(props, true)}
                onEditorSubmit={(props) => this.props.onEditorSubmit(props, true)}
                body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : toPercentageTwoDecimals(rowData[key])}
                style={{ textAlign: 'center', width: '3em', backgroundColor: '#FFFCED'}} />
            : null
        case "CostGL":
        case "LastMonthFinalCost": 
            return <Column key={key} field={key} header={value} 
              headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFA3E0' }}
              body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
              style={{ textAlign: 'center', width: '3em' }} />;
        case "Changes":
          return <Column key={key} field={key} header={deltaHeader(value)} 
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFA3E0' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "Comments":
          return <Column key={key} field={key} header={value}
            headerStyle={{ textAlign: 'left', width: '6em', minWidth: '6em', backgroundColor: '#FFA3E0' }}
            editor={(props) =>  this.props.editor(props, key, false)}
            onEditorCancel={(props) => this.props.onEditorCancel(props, true)}
            onEditorSubmit={(props) => this.props.onEditorSubmit(props, true)}
            style={{ textAlign: 'left', width: '3em', backgroundColor: '#FFFCED'}}
            bodyStyle={{ maxWidth: '100%', overflow: 'hidden', backgroundColor: '#FFFCED'}}
            />;
        case "CCOs":
          return null;
        case "HasEditedFields":
          return <Column key={key} field={key} header={value}
            headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em' }}
            body={(rowdata: any) => rowdata.HasEditedFields 
              ? <Button className="p-button-warning" onClick={() => this.handleResetTaskCodeClick(rowdata)} icon="pi pi-refresh" /> 
              : '-'}
            style={{ textAlign: 'center', width: '2em' }} />;
        case "CheckFinalCost":
          return <Column key={key} field={key} header={value} 
          headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#BADFF5' }}
          body={(rowData: any) => (
            rowData.HasEditedFields && displayTwoDigits(rowData["FinalCost"]) >= displayTwoDigits(rowData["JTDTotalCost"]) ? (
              <input type="checkbox" checked={rowData.CheckFinalCost} onChange={() => this.handleCheckboxChange(rowData)}/>
            ) : '-'
          )}
          style={{ textAlign: 'center', width: '2em'}} />;
        default:
          // If it's a number, add comma separator
          return <Column key={key} field={key} header={value}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
      }
    })];

    let footerGroup =
      <ColumnGroup>
        <Row>
          <Column footer="" colSpan={5} footerStyle={{ textAlign: 'center'}} className={this.props.loading ? 'hidden' : ''} />
          <Column footer={`$${displayTwoDigits(this.calculate_total("BudgetTotalCost"))}`} className={this.props.loading ? 'hidden' : ''}
            footerStyle={{ textAlign: 'center', paddingRight: "0" }} />
          <Column footer="" colSpan={3} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
          <Column footer={`$${displayTwoDigits(this.calculate_total("JTDTotalCost"))}`} className={this.props.loading ? 'hidden' : ''}
            footerStyle={{ textAlign: 'center', paddingRight: "0" }} />
          <Column footer="" colSpan={2} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
          <Column footer={`$${displayTwoDigits(this.calculate_total("RemainingTotalCost"))}`} className={this.props.loading ? 'hidden' : ''}
            footerStyle={{ textAlign: 'center', paddingRight: "0" }} />
          <Column footer="" colSpan={2} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
          <Column footer={`$${displayTwoDigits(this.calculate_total("FinalCost"))}`} className={this.props.loading ? 'hidden' : ''}
            footerStyle={{ textAlign: 'center', paddingRight: "0" }} />
          <Column footer="" colSpan={1} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
          <Column footer="" colSpan={0} footerStyle={{ textAlign: 'center' }} className={this.props.loading || !this.props.specialBidItem ? 'hidden' : ''} />
          <Column footer={`$${displayTwoDigits(this.calculate_total("Changes"))}`} className={this.props.loading ? 'hidden' : ''}
            footerStyle={{ textAlign: 'center', paddingRight: "0" }} />
          <Column footer="" colSpan={1} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
          <Column footer={`$${displayTwoDigits(this.calculate_total("LastMonthFinalCost"))}`} className={this.props.loading ? 'hidden' : ''}
            footerStyle={{ textAlign: 'center', paddingRight: "0" }} />
          <Column footer="" colSpan={1} footerStyle={{ textAlign: 'center' }} className={this.props.loading ? 'hidden' : ''} />
        </Row>
      </ColumnGroup>;

    let header = (
      <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
        Task Codes
        <div>
        </div>
        <div className="p-datatable-globalfilter-container">
          <InputText type="search" onChange={(e) => this.props.globalSearchValue(e)} placeholder="Global Search" sizes="50" />
        </div>
      </div>
    );

    let assignableTaskCodesHeader = (
      <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
        {this.props.assignableTaskCodesTitle}
        <div className="p-datatable-globalfilter-container">
          <InputText type="search" onInput={(e) => this.setState({ assignableTaskCodesGlobalFilter: (e.target as HTMLTextAreaElement).value })} placeholder="Global Search" sizes="50" />
        </div>
      </div>
    );

    return (
      <div>
        <Growl ref={this.toastTR} />
        <div className="card card-w-title" ref={this.tasks_codes_table}>
          <div className="taskcodes-table-title">            
            <div>
              <h1>Task Codes</h1>
            </div>    
            <Button
              type="button"
              label="+"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.showOverlay(e)}
              disabled={this.state.overlayLoading}
              aria-haspopup aria-controls="overlay_panel"
              className={this.props.loading ? 'hidden' : ''}
            />
          </div>
          <DataTable 
             value={(this.state.isFiltered || this.state.globalSearch)
              ? this.state.taskCodesItemsFiltered
              : this.props.taskCodesData
            }
            header={header} 
            autoLayout={true} 
            editMode="cell" 
            globalFilter={this.state.globalFilter}
            loading={this.props.loading || this.state.loading}
            loadingIcon={'pi-md-refresh'} 
            scrollable
            scrollHeight={!this.props.setExpandCollapse ? "600px" : "600px"}
            footerColumnGroup={footerGroup}
            className="taskcodes-table"
            style={{ width: '100%', margin: '0 auto', overflowX: 'scroll' }} 
          >
            {taskCodesColumns}
          </DataTable>

          <div className="assign-taskcodes-overlaypanel">
            <OverlayPanel ref={this.overlay_panel} showCloseIcon onHide={() => this.overlay_cancel()} dismissable id="overlay_panel">
              <TaskCodeToCCODetails 
                currentTaskCode={this.state.selectedTaskCodeCCO}
                visible={this.state.taskCodesCCOsPopupVisibilityOnOverlay}
                onConfirm={this.updateLinkedTaskCodeCCOs}
                onCancel={this.cancelTaskCOdeCCOsPopup}
              />
              
              <DataTable value={this.state.assignableTaskCodes} header={assignableTaskCodesHeader} paginator rows={20}
                autoLayout={true} loading={this.state.overlayLoading} loadingIcon={'pi-md-refresh'}
                selection={this.state.selectedTaskCodesToBeAssigned} globalFilter={this.state.assignableTaskCodesGlobalFilter}
                className="p-datatable-lg"
                onSelectionChange={e => this.setTaskCodesToBeAssigned(e.value)}>
                <Column selectionMode={this.props.groupedBidItem ? 'individual' : 'multiple'} style={{ width: '3em' }} />
                <Column 
                  field="Taskcode" 
                  header="Task Code" 
                  body={this.taskCodeCellTemplate}
                  style={{ textAlign: 'left' }} 
                  className="task-code-id-cell" />
                <Column field="BidItem" header="Allocated" style={{ textAlign: 'center' }} />
                <Column field="TaskcodeDescription" header="Description" headerStyle={{ textAlign: 'left' }} style={{ textAlign: 'left' }} />
                <Column field="UnitOfMeasure" header="UOM" style={{ textAlign: 'center' }} />
                <Column field="BudgetUnits" header="Budj. QTY" headerStyle={{ textAlign: 'center', backgroundColor: '#FFF9AB' }} style={{ textAlign: 'center' }} />
              </DataTable>

              <div className="assignable-taskcodes-overlay-confirmation-buttons">
                <Button icon="pi pi-times" iconPos="left" label="Cancel" disabled={this.state.overlayLoading}
                  onClick={(e) => this.overlay_cancel(e)} className="p-button-rounded p-button-danger" />
                <Button icon="pi pi-check" iconPos="right" label="Save" disabled={this.state.overlayLoading}
                  onClick={(e) => this.overlay_save(e)} className="p-button-rounded p-button-success" />
              </div>
            </OverlayPanel>
          </div>
        </div>

        <TaskCodeToCCODetails 
          currentTaskCode={this.state.selectedTaskCodeCCO}
          visible={this.state.taskCodesCCOsPopupVisibility}
          onConfirm={this.saveEditedTaskCodeCCOs}
          onCancel={this.cancelTaskCOdeCCOsPopup}
        />

        <ConfirmationDialog
          visible={this.state.confirmationDialogVisible}
          title={this.state.confirmationDialogTitle}
          message={this.state.confirmationDialogMessage}
          onConfirm={() => this.state.confirmationDialogOnConfirm?.()}
          onCancel={this.confirmationDialogOnCancel}
          onHide={this.confirmationDialogOnCancel}
        />
      </div>
    )
  }
}

function mapStateToProps(state: RootState) {
  return {
    currentBidItemNumber: state.app.currentBidItemNumber,
    currentJobNumber: state.app.currentJobNumber,
    currentJobDescription: state.app.currentJobDescription,
    currentChangeNumber: state.app.currentChangeNumber,
    currentChangeNumberDesc: state.app.currentChangeNumberDesc,
    assignableTaskCodes: state.app.jobsMenuItems.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.AssignableTaskCodes || [],
    jobTaskCodes: state.app.jobsMenuItems.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.TaskCodes || [],
    jobBidItems: state.app.jobsMenuItems.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.BidItems || [],
    jobsMenuItems: state.app.jobsMenuItems,
    pendingCostAssigment: state.app.pendingCostAssigment,
    taskcodesWithFlagsFC: state.app.taskcodesWithFlagsFC,
    setExpandCollapse: state.app.setExpandCollapse
  };
}

function matchDispatchToProps(dispatch: any) {
  return bindActionCreators({
    updateAssignedTaskCodes: updateAssignedTaskCodes,
    updateTaskCodeDetails: updateTaskCodeDetails,
    setCurrentBidItems: setCurrentBidItems,
    setCurrentTaskCodes: setCurrentTaskCodes,
    bidItemSaved: bidItemSaved,
    setLoadingExecutiveSummary: setLoadingExecutiveSummary
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(TaskCodesDetails);