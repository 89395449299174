import { BidItemInterface, BidItemToBeSaved, JobsMenuTaskCodeInterface, TaskCodeStructureFromDB } from "../interfaces/Interfaces";
import { toLocaleStringUSorZero, parseFloatRemoveComma } from "./convert";

export function calculateBidItemQtyAdjustment(bidItem: BidItemToBeSaved, calculateByFinalQuantity?: boolean) {
  if (calculateByFinalQuantity) {
    bidItem.QtyAdjustment = parseFloatRemoveComma(bidItem.FinalQuantity) - (bidItem.JTDQty > bidItem.QTYBilled ? bidItem.JTDQty : bidItem.QTYBilled);
  } else {
    bidItem.QtyAdjustment = 0;
  }
}

export function calculateBidItemFinalQuantity(bidItem: BidItemToBeSaved, calculateByQtyAdjustment?: boolean) {
  if (calculateByQtyAdjustment || bidItem.QtyAdjustment !== 0) {
    if (parseFloatRemoveComma(bidItem.JTDQty) > parseFloatRemoveComma(bidItem.QTYBilled)) {
      bidItem.FinalQuantity = parseFloatRemoveComma(bidItem.JTDQty) + parseFloatRemoveComma(bidItem.QtyAdjustment);
    } else {
      bidItem.FinalQuantity = parseFloatRemoveComma(bidItem.QTYBilled) + parseFloatRemoveComma(bidItem.QtyAdjustment);
    }
  } else {
    if (parseFloatRemoveComma(bidItem.JTDQty) > parseFloatRemoveComma(bidItem.QTYBilled)) {
      bidItem.FinalQuantity = parseFloatRemoveComma(bidItem.JTDQty);
    } else {
      bidItem.FinalQuantity = parseFloatRemoveComma(bidItem.QTYBilled);
    }
  }
}

export function calculateOriginalBidItemFinalRevenue(bidItem: BidItemToBeSaved) { 
    bidItem.FinalRevenue = parseFloatRemoveComma(bidItem.FinalQuantity) * parseFloatRemoveComma(bidItem.UnitPrice);
}

export function calculateBidItemFinalRevenue(
  bidItem: BidItemToBeSaved, 
  taskCodes?: TaskCodeStructureFromDB[] | JobsMenuTaskCodeInterface[], 
  calculate?: boolean,
  lastEditedValue?: number) {
  let bidItemRecoveryPercent: boolean = (bidItem.BidItem === "890" ||bidItem.BidItem === "940" || bidItem.BidItem === "941" || bidItem.BidItem === "999") 

  if (bidItemRecoveryPercent) {
    let totalFinalRevenue = 0;

    if (!calculate) {
      bidItem.FinalRevenue = lastEditedValue || 0;
    } else {
      if (taskCodes) {
        for (let taskCode of taskCodes) {
          if (taskCode.FinalCost) {
            totalFinalRevenue += ((parseFloatRemoveComma(taskCode.RecoveryPercent) || 0) * (parseFloatRemoveComma(taskCode.FinalCost) / 100));
          }
        }
      }
      bidItem.FinalRevenue = parseFloatRemoveComma(totalFinalRevenue);
    }
  } else {
    if (bidItem.HasEditedFields) {
      if (bidItem.FinalRevenue) {
        bidItem.FinalRevenue = parseFloatRemoveComma(bidItem.FinalRevenue);
      } else {
        calculateOriginalBidItemFinalRevenue(bidItem);
      }
    } else {
      let totalFinalRevenue = 0;

      if (bidItem) {
        totalFinalRevenue = parseFloatRemoveComma(bidItem.FinalQuantity) * parseFloatRemoveComma(bidItem.UnitPrice);
      }

      bidItem.FinalRevenue = parseFloatRemoveComma(totalFinalRevenue);
    }
  }
}

export function calculatedBidItemFinalRevenueHardcodeBidItem(taskCodes: TaskCodeStructureFromDB[] | JobsMenuTaskCodeInterface[]) {
  let totalFinalRevenue = 0;

  for (let taskCode of taskCodes) {
    if (taskCode.FinalCost) {
      totalFinalRevenue += ((parseFloatRemoveComma(taskCode.RecoveryPercent) || 0) * (parseFloatRemoveComma(taskCode.FinalCost) / 100));
    }
  }
  
  return totalFinalRevenue;
}

export function calculateBidItemFinalCost(bidItem: BidItemToBeSaved, taskCodes?: any[]) {
  let finalCost = 0;

  if (taskCodes) {
    for (let taskCode of taskCodes) {
      finalCost += parseFloatRemoveComma(taskCode.FinalCost) || 0;
    }
  }
  
  bidItem.FinalCost = finalCost;
  
  return bidItem;
}

export function calculateBidItemFinalCostHistory(bidItem: BidItemToBeSaved, taskCodes?: any[]) {

  const taskcodesForBidItem = taskCodes?.filter(task => task.BidItem === bidItem?.BidItem);
  
  const totalFinalCostTaskcodes = taskcodesForBidItem?.reduce((acc, task) => {
    return acc + parseFloat(task.FinalCost);
  }, 0);
  
  bidItem.FinalCost =  totalFinalCostTaskcodes;

  return bidItem;
}

export function calculateBidItemRemCost(bidItem: BidItemToBeSaved, taskCodes?: any[]) {
  let finalCost = 0;

  if (taskCodes) {
    for (let taskCode of taskCodes) {
      finalCost += parseFloatRemoveComma(taskCode.RemainingTotalCost) || 0;
    }
  }

  bidItem.RemCost = finalCost;
  
  return bidItem;
}

export function calculateBidItemGainLoss(bidItem: BidItemToBeSaved) {
  bidItem.GainLoss = parseFloatRemoveComma(bidItem.FinalRevenue) - parseFloatRemoveComma(bidItem.FinalCost);
  return bidItem;
}

export function calculateInitialValuesBidItemsCCOs(bidItems: any): BidItemInterface {  
  for (let bidItem of bidItems){      
    if (bidItem.ChgNumber !== "000") {
      let finalTotalCostCCO = 0;
      
      if (bidItem.TaskCodeItems) {
        if (bidItem.TaskCodeItems?.length > 0) {
          for (let taskCode of bidItem.TaskCodeItems) {
            for (let CCOs of taskCode.CCOs) {
              if (bidItem.ChgNumber === CCOs.ChgNumber && bidItem.ChgOrderDesc === CCOs.ChgOrderDesc) {
                finalTotalCostCCO += CCOs.AssignedCost;
              }
            }
          }
        }
      bidItem.FinalCost = toLocaleStringUSorZero(parseFloatRemoveComma(finalTotalCostCCO)); 
      bidItem.GainLoss = toLocaleStringUSorZero(parseFloatRemoveComma(bidItem.FinalRevenue) - parseFloatRemoveComma(bidItem.FinalCost));
      finalTotalCostCCO = 0;
      }
    }
  }

  return bidItems;  
}

export function calculateBidItemPreviousFinalCost(bidItems: any) {
  for (let item in bidItems) {
    if (bidItems[item] !== undefined) {
    let previousFinalCost = 0
      if (bidItems[item].TaskCodeItems !== undefined) {
      //@ts-ignore
      bidItems[item].TaskCodeItems.forEach((e: { LastMonthFinalCost: string | number; }) => previousFinalCost += parseFloatRemoveComma(e.LastMonthFinalCost))
      bidItems[item].PreviousFinalCost = previousFinalCost 
      }
  }
}
  return bidItems;
}

export function calculateBidItemChanges(bidItem: any) {
  bidItem.Changes = parseFloatRemoveComma(bidItem.FinalCost) - parseFloatRemoveComma(bidItem.PreviousFinalCost);
  return bidItem;
}

export function calculateBidItemRemainingCost(taskCodes: TaskCodeStructureFromDB[]){
  let bidItemRemainingCost = 0;

  for (let i = 0; i < taskCodes?.length; i++) {
    bidItemRemainingCost += parseFloatRemoveComma(taskCodes[i].RemainingTotalCost)     
  }

  return bidItemRemainingCost
}