import { JobsMenuBidItemInterface } from "../interfaces/Interfaces";
import { date } from "./utils"

export const hardcodedBidItems: JobsMenuBidItemInterface[] = [
  {
    BidItem: "888",
    BidItemDisplay: "888",
    BidItemDescription: "Indirect Costs",
    ChgNumber: "000",
    ChgOrderDesc: "Original",
    UM: "LS",
    QTYBilled: 1,
    UnitPrice: 0,
    Amount: 0,
    QtyAdjustment: 0,
    MTDCost: 0,
    JTDCost: 0,
    JTDQty: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    FinalCost: 0,
    GLDate: date,
    JobDescription: "",
    JobNumber: "",
    LastUpdated: date,
    TaskCodeItems: [],
    BidComments: "",
    RemCost: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  },
  {
    BidItem: "890",
    BidItemDisplay: "890",
    BidItemDescription: "Unanticipated",
    ChgNumber: "000",
    ChgOrderDesc: "Original",
    UM: "LS",
    QTYBilled: 1,
    UnitPrice: 0,
    Amount: 0,
    QtyAdjustment: 0,
    MTDCost: 0,
    JTDCost: 0,
    JTDQty: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    FinalCost: 0,
    GLDate: date,
    JobDescription: "",
    JobNumber: "",
    LastUpdated: date,
    TaskCodeItems: [],
    BidComments: "",
    RemCost: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  },
  {
    BidItem: "940",
    BidItemDisplay: "940",
    BidItemDescription: "Change Orders (T&M)",
    ChgNumber: "000",
    ChgOrderDesc: "Original",
    UM: "LS",
    QTYBilled: 1,
    UnitPrice: 0,
    Amount: 0,
    QtyAdjustment: 0,
    MTDCost: 0,
    JTDCost: 0,
    JTDQty: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    FinalCost: 0,
    GLDate: date,
    JobDescription: "",
    JobNumber: "",
    LastUpdated: date,
    TaskCodeItems: [],
    BidComments: "",
    RemCost: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  },
  {
    BidItem: "941",
    BidItemDisplay: "941",
    BidItemDescription: "Change Orders (LS)",
    ChgNumber: "000",
    ChgOrderDesc: "Original",
    UM: "LS",
    QTYBilled: 1,
    UnitPrice: 0,
    Amount: 0,
    QtyAdjustment: 0,
    MTDCost: 0,
    JTDCost: 0,
    JTDQty: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    FinalCost: 0,
    GLDate: date,
    JobDescription: "",
    JobNumber: "",
    LastUpdated: date,
    TaskCodeItems: [],
    BidComments: "",
    RemCost: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  },
  {
    BidItem: "944",
    BidItemDisplay: "944",
    BidItemDescription: "Change Order - No Rev",
    ChgNumber: "000",
    ChgOrderDesc: "Original",
    UM: "LS",
    QTYBilled: 1,
    UnitPrice: 0,
    Amount: 0,
    QtyAdjustment: 0,
    MTDCost: 0,
    JTDCost: 0,
    JTDQty: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    FinalCost: 0,
    GLDate: date,
    JobDescription: "",
    JobNumber: "",
    LastUpdated: date,
    TaskCodeItems: [],
    BidComments: "",
    RemCost: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  },
  {
    BidItem: "950",
    BidItemDisplay: "950",
    BidItemDescription: "Claims",
    ChgNumber: "000",
    ChgOrderDesc: "Original",
    UM: "LS",
    QTYBilled: 1,
    UnitPrice: 0,
    Amount: 0,
    QtyAdjustment: 0,
    MTDCost: 0,
    JTDCost: 0,
    JTDQty: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    FinalCost: 0,
    GLDate: date,
    JobDescription: "",
    JobNumber: "",
    LastUpdated: date,
    TaskCodeItems: [],
    BidComments: "",
    RemCost: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  },
  {
    BidItem: "999",
    BidItemDisplay: "999",
    BidItemDescription: "Unclassified",
    ChgNumber: "000",
    ChgOrderDesc: "Original",
    UM: "LS",
    QTYBilled: 1,
    UnitPrice: 0,
    Amount: 0,
    QtyAdjustment: 0,
    MTDCost: 0,
    JTDCost: 0,
    JTDQty: 0,
    FinalQuantity: 0,
    FinalRevenue: 0,
    HasEditedFields: false,
    FinalCost: 0,
    GLDate: date,
    JobDescription: "",
    JobNumber: "",
    LastUpdated: date,
    TaskCodeItems: [],
    BidComments: "",
    RemCost: 0,
    GainLoss: 0,
    PreviousFinalCost: 0,
    Changes: 0
  }
];

export const hardcodedBidItemsRCRP = ['888', '890', '940', '941', '944', '950', '999']