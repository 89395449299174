import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import to from 'await-to-js';
import { RootState } from "../redux/store";
import { Messages } from 'primereact/messages';
import { BidDetailsService } from "../service/BidDetailsService";
import { BidItemsService } from "../service/BidItemsService";
import { hardcodedBidItems, hardcodedBidItemsRCRP } from "../utils/hardcodedBidItems";
import { numbersAndDotOnly, toLocaleStringUS, parseFloatRemoveComma, convertJobMenuBidItemToBidItem, toLocaleStringUSorZero, displayTwoDigits } from "../utils/convert";
import * as TaskCodeCalculations from "../utils/taskCodeFieldsCalculations";
import * as BidItemCalculations from "../utils/bidItemFieldsCalculations";
import {
  bidDetailsProps,
  bidDetailsState,
  TaskCodeStructureFromDB
} from "../interfaces/Interfaces";
import {
  setCurrentBidItemsHistory,
  setCurrentTaskCodesHistory,
  setCurrentPCsHistory,
} from "../redux/appSlice";
import { calculatedBidItemFinalRevenueHardcodeBidItem, calculateBidItemRemainingCost } from '../utils/bidItemCalculationsFunctions'
import { calculateDelta } from '../utils/taskCodeFieldsCalculationsFunctions'
import { BidItemsDetailsHistory } from './BidItemsDetailsHistory';
import { TaskCodesDetailsHistory } from './TaskCodesDetailsHistory';

export const BidDetailsHistory: React.FC<bidDetailsProps> = (props) => {
  const [state, setState] = useState<bidDetailsState>({
    loading: false,
    changeDetected: false,
    bidItemData: [],
    taskCodesData: [],
    groupedBidItem: false,
    specialBidItem: false,
    calculateFinalRevenue: false,
    lastEditedFinalRevenue: 0,
    inputInitialValue: '',
    taskCodeFiltered: [],
    globalSearch: '',
    hasUpdatedExecutiveSummary: false,
  });
  
  const dispatch = useDispatch();
	const currentJobNumber = useSelector((state: RootState) => state.app.currentJobNumber);
  const currentJobDescription = useSelector((state: RootState) => state.app.currentJobDescription);
	const selectedBidItems = useSelector((state: RootState) => state.app.selectedBidItems);
	const selectedTaskCodes = useSelector((state: RootState) => state.app.selectedTaskCodes);
	const selectedPCs = useSelector((state: RootState) => state.app.selectedPCs);
	const isJobDataLoading = useSelector((state: RootState) => state.app.isJobDataLoading);
	const currentBidItemNumber = useSelector((state: RootState) => state.app.currentBidItemNumber);
	const currentChangeNumber = useSelector((state: RootState) => state.app.currentChangeNumber);


  const mountedComponent = useRef<boolean>(true);
  const messages = useRef<Messages>(null);

  useEffect(() => {
    getBidDetails();
    return () => {
      mountedComponent.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedBidItems !== state.bidItemData && !state.changeDetected) {
      if (!selectedBidItems.length || 
        (selectedBidItems[0].BidItem !== currentBidItemNumber) || 
        (selectedBidItems[0].ChgNumber !== currentChangeNumber)) {
        getBidDetails();
      }
      setInitialValues();
    }

    if ((selectedPCs !== state.bidItemData || selectedTaskCodes !== state.taskCodesData) && !state.changeDetected) {      
      setInitialValues();
    }

    if (isJobDataLoading !== state.loading) {
      setState({ ...state, loading: isJobDataLoading });
    }
  }, []);

  const getBidDetails = async () => {
    await dispatch(setCurrentBidItemsHistory());
    await dispatch(setCurrentTaskCodesHistory());
    await dispatch(setCurrentPCsHistory());

    if (selectedBidItems && selectedTaskCodes) {
      if (!selectedBidItems.length) {
        const newlyCreatedHardcodedBidItem = hardcodedBidItems.find(
          (hardcodedBidItem) => hardcodedBidItem.BidItem === currentBidItemNumber
        );

        if (newlyCreatedHardcodedBidItem) {
          const newlyFormattedHardcodedBidItem: any = BidItemCalculations.prepareBidItemForDB(
            newlyCreatedHardcodedBidItem,
            currentJobNumber.toString(),
            currentJobDescription
          );
          const isRCRP = hardcodedBidItemsRCRP.some(
            (bidItem) => bidItem === newlyFormattedHardcodedBidItem.BidItem
          );

          setState({
            ...state,
            bidItemData: [newlyFormattedHardcodedBidItem],
            specialBidItem: isRCRP,
          });
        } else {
          setState({ ...state, specialBidItem: false });
        }
      }

      if (
        selectedBidItems.length === 1 &&
        hardcodedBidItemsRCRP.some(
          (hardcodedBidItem) => hardcodedBidItem === selectedBidItems[0].BidItem
        )
      ) {
        setState({
          ...state,
          specialBidItem: true,
          calculateFinalRevenue: !selectedBidItems[0].HasEditedFields,
        });
      } else {
        setState({
          ...state,
          specialBidItem: false,
          calculateFinalRevenue: false,
        });
      }
      setInitialValues();
    }
  };


  const setInitialValues = () => {
    let updatedBidItems: any[];
    let updatedTaskCodes: TaskCodeStructureFromDB[] = [];

    if (selectedBidItems[0]?.ChgNumber !== "000") {
      setState({ ...state, groupedBidItem: true });
      updatedBidItems = [...JSON.parse(JSON.stringify(selectedPCs))];
    } else {
      setState({ ...state, groupedBidItem: false });
      updatedBidItems = [...JSON.parse(JSON.stringify(selectedBidItems))];
    }

    if (updatedBidItems.length > 0) {
      updatedBidItems[0].JobDescription = currentJobDescription;
      updatedBidItems[0].JobNumber = currentJobNumber.toString();
    }

    if (state.groupedBidItem) {
      if (updatedBidItems.length > 1) {
        updatedTaskCodes = (selectedTaskCodes);
        //setTaskCodesFinalCost(updatedTaskCodes, updatedBidItems);
        setBidItemsGL(updatedBidItems);
        setBidItemsPreviousFinalCost(updatedBidItems);
        setBidDetailsDelta(updatedBidItems);
        setTaskCodesJTDCost(updatedTaskCodes, updatedBidItems);
        setTaskCodesMTDCost(updatedTaskCodes, updatedBidItems);
      } else {
        updatedTaskCodes = (selectedTaskCodes);
        //setTaskCodesFinalCost(updatedTaskCodes, updatedBidItems);
        setBidItemsGL(updatedBidItems);
        setBidItemsPreviousFinalCost(updatedBidItems);
        setBidDetailsDelta(updatedBidItems);
        setTaskCodesJTDCost(updatedTaskCodes, updatedBidItems);
        setTaskCodesMTDCost(updatedTaskCodes, updatedBidItems);
      }
    } else {
      updatedTaskCodes = [...JSON.parse(JSON.stringify(selectedTaskCodes))];
      //setTaskCodesFinalCost(updatedTaskCodes, updatedBidItems);
      setBidItemsGL(updatedBidItems);
      setBidItemsPreviousFinalCost(updatedBidItems);
      setBidDetailsDelta(updatedBidItems);
      setTaskCodesJTDCost(updatedTaskCodes, updatedBidItems);
      setTaskCodesMTDCost(updatedTaskCodes, updatedBidItems);
    }

    setTaskCodesFilter('');

    if (updatedBidItems[0]?.HasEditedFields) {
      setState({ ...state, lastEditedFinalRevenue: updatedBidItems[0]?.FinalRevenue });
    }

    if (state.specialBidItem) {
      updatedBidItems[0].FinalRevenue = calculatedBidItemFinalRevenueHardcodeBidItem(updatedTaskCodes);
    } else if (state.specialBidItem) {
      updatedBidItems[0].FinalRevenue = 0;
    }

    setTaskCodesDetailsDelta(updatedTaskCodes);

    if (updatedBidItems.length > 0) {
      if (!updatedTaskCodes.length) {
        updatedBidItems[0].RemCost = 0;
      } else {
        for (let index in updatedTaskCodes) {
          if (!updatedTaskCodes[index].HasEditedFields) {
            const changesValue = calculateDelta(updatedTaskCodes[index]);
            updatedTaskCodes[index].Changes = changesValue;
            setState({ ...state, taskCodesData: updatedTaskCodes });
          } else {
            if (state.taskCodeFiltered.length) {
              const changesValue = calculateDelta(updatedTaskCodes[index]);
              updatedTaskCodes[index].Changes = changesValue;
            }
          }
        }
        updatedBidItems[0].RemCost = calculateBidItemRemainingCost(updatedTaskCodes);
        setState({ ...state, bidItemData: updatedBidItems });
      }
    }

    if (updatedTaskCodes.length && state.groupedBidItem) {
      setState({ ...state, taskCodeFiltered: updatedTaskCodes });
    } else if (state.groupedBidItem) {
      setState({ ...state, taskCodeFiltered: [] });
    }

    setState({ ...state, bidItemData: updatedBidItems });
  };

  const setTaskCodesFilter = (event: any) => {
    let taskCodeFilter: TaskCodeStructureFromDB[] = [...state.taskCodesData];

    if (event && event.target && event.target.value) {
      taskCodeFilter = taskCodeFilter.filter((taskCode) =>
        taskCode.Taskcode.toLowerCase().includes(event.target.value.toLowerCase())
      );
    }

    setState({ ...state, taskCodeFiltered: taskCodeFilter });
  };

  const setBidItemsGL = (updatedBidItems: any[]) => {
    if (updatedBidItems.length) {
      for (let index in updatedBidItems) {
        if (!updatedBidItems[index].BidItemGL) {
          updatedBidItems[index].BidItemGL = 'A';
        }
      }
    }
  };

  const setBidItemsPreviousFinalCost = (updatedBidItems: any[]) => {
    if (updatedBidItems.length) {
      for (let index in updatedBidItems) {
        if (!updatedBidItems[index].PreviousFinalCost) {
          updatedBidItems[index].PreviousFinalCost = updatedBidItems[index].FinalCost;
        }
      }
    }
  };

  const setBidDetailsDelta = (updatedBidItems: any[]) => {
    if (updatedBidItems.length) {
      for (let index in updatedBidItems) {
        if (!updatedBidItems[index].Delta) {
          //updatedBidItems[index].Delta = BidItemCalculations.calculateDelta(updatedBidItems[index]);
        }
      }
    }
  };

  const setTaskCodesDetailsDelta = (updatedTaskCodes: TaskCodeStructureFromDB[]) => {
    if (updatedTaskCodes.length) {
      for (let index in updatedTaskCodes) {
        if (!updatedTaskCodes[index].HasEditedFields) {
          const changesValue = calculateDelta(updatedTaskCodes[index]);
          updatedTaskCodes[index].Changes = changesValue;
        }
      }
    }
  };

  
  const setTaskCodesJTDCost = (taskCodes: any, bidItem: any) => {
    if (bidItem[0] !== undefined && taskCodes !== undefined) { 
    let JTDCost = 0;
  
    if (taskCodes) {
      for (let taskCode of taskCodes) {
        JTDCost += (parseFloatRemoveComma(taskCode.JTDTotalCost) || 0);
      }
    }

    bidItem[0].JTDCost = toLocaleStringUSorZero(parseFloatRemoveComma(JTDCost));
  }
  
  }
  
  const setTaskCodesMTDCost = (taskCodes: any, bidItem: any) => {

    if (bidItem[0] !== undefined && taskCodes !== undefined) { 
    let MTDCost = 0;
  
    if (taskCodes) {
      for (let taskCode of taskCodes) {
        MTDCost += (parseFloatRemoveComma(taskCode.MTDCost) || 0);
      }
    }

    bidItem[0].MTDCost = toLocaleStringUSorZero(parseFloatRemoveComma(MTDCost));
  }
  
  }
  

  return (
    <div>
      <div>
        <h2>{props.currentJobDescription}</h2>
      </div>
      <div>
        <BidItemsDetailsHistory 
          bidItems={selectedBidItems} 
          currentBidItemNumber={currentBidItemNumber} 
          currentChangeNumber={currentChangeNumber} 
        />
      </div>
      <div>
        <TaskCodesDetailsHistory 
          taskCodes={selectedTaskCodes} 
          currentBidItemNumber={currentBidItemNumber} 
          currentChangeNumber={currentChangeNumber} 
        />
      </div>
      <Messages ref={messages} />
    </div>
  );
};
