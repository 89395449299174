import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import './EditColumns.css';

interface ConfirmationDialogProps {
  visible: boolean;
  onHide: () => void;
  title: string;
  onConfirm: (columns: string[]) => void;
  onCancel?: () => void;
  Structure?: any;
}

const EditColumns: FC<ConfirmationDialogProps> = (props) => {
  const { visible, onHide, title, onConfirm, onCancel, Structure } = props;
  const columnKeys = Object.keys(Structure);
  const [columns, setColumns] = useState<string[]>(columnKeys);
  const [initialColumns, setInitialColumns] = useState<string[]>(columnKeys);

  useEffect(() => {
    setInitialColumns(columnKeys);
    setColumns(columnKeys);
  }, [Structure]);

  const onColumnsChange = useCallback((e) => {
    const { value, checked } = e.target;
    setColumns(prevColumns =>
      checked ? [...prevColumns, value] : prevColumns.filter(column => column !== value)
    );
  }, []);

  const handleHide = useCallback(() => {
    setColumns(initialColumns);
    onHide();
  }, [initialColumns, onHide]);

  const footer = useMemo(() => (
    <div>
      <div className="columnsContainer">
        {[
          { id: 'column1', name: 'UM', value: 'UM', label: 'UOM' },
          { id: 'column2', name: 'QTYBilled', value: 'QTYBilled', label: 'Bid QTY' },
          { id: 'column3', name: 'UnitPrice', value: 'UnitPrice', label: 'Bid UP' },
          { id: 'column4', name: 'Amount', value: 'Amount', label: 'Bid Tot\'l' },
          { id: 'column5', name: 'QtyAdjustment', value: 'QtyAdjustment', label: 'QTY Adj.' },
          { id: 'column6', name: 'MTDCost', value: 'MTDCost', label: 'MTD Cost' },
          { id: 'column7', name: 'JTDCost', value: 'JTDCost', label: 'JTD Cost' },
          { id: 'column8', name: 'JTDQty', value: 'JTDQty', label: 'JTD Qty' },
          { id: 'column9', name: 'FinalQuantity', value: 'FinalQuantity', label: 'Fin\'l QTY' },
          { id: 'column10', name: 'FinalRevenue', value: 'FinalRevenue', label: 'Fin\'l Rev' },
          { id: 'column11', name: 'FinalCost', value: 'FinalCost', label: 'Fin\'l Cost' },
          { id: 'column12', name: 'RemCost', value: 'RemCost', label: 'Rem Cost' },
          { id: 'column13', name: 'GainLoss', value: 'GainLoss', label: 'G-L' },
          { id: 'column14', name: 'PreviousFinalCost', value: 'PreviousFinalCost', label: 'Prev Final Cost' },
          { id: 'column15', name: 'Changes', value: 'Changes', label: 'Delta' },
        ].map(column => (
          <div className="columnsOption">
            <Checkbox inputId={column.id} name={column.name} value={column.name} onChange={onColumnsChange} checked={columns.includes(column.name)} />
            <label className="ml-2">{column.label}</label>
          </div>
        ))}
      </div>
      <Button className="buttons" label="Confirm" icon="pi pi-check" onClick={() => onConfirm(columns)} />
      {onCancel ? <Button label="Cancel" icon="pi pi-times" onClick={() => { setColumns(initialColumns); onCancel(); }} /> : null}
    </div>
  ), [onConfirm, onCancel, onColumnsChange, columns, initialColumns]);

  return (
    <Dialog visible={visible} onHide={handleHide} header={title} footer={footer} style={{ width: '15vw' }}>
    </Dialog>
  );
};

export default EditColumns;
