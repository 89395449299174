import { JobDataService } from "../service/JobDataService"

export const useResetJob = () => {
  const jobDataService = new JobDataService()

  const resetJob = async (jobNumber: string) => {
    
    console.warn("WARNING ResetJob: unused functionality since the client din't want t reset the whole job");
    return await jobDataService.resetJob('not use this')
    
    // unused functionality since the client din't want t reset the whole job
    //return await jobDataService.resetJob(jobNumber)
  }

  return resetJob
}