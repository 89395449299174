import { CCOInTaskCode } from "../interfaces/Interfaces";
import { displayTwoDigits, parseFloatRemoveComma } from "./convert";

export function calculateTaskCodeBudgetUnitCost(taskCode: any) {
  if (parseFloatRemoveComma(taskCode.BudgetUnits) === 0) {
    taskCode.BudgetUnitCost = 0;
  } else {
    taskCode.BudgetUnitCost = parseFloatRemoveComma(taskCode.BudgetTotalCost) / parseFloatRemoveComma(taskCode.BudgetUnits);
  }
}

export function calculateTaskCodeJTDUnitCost(taskCode: any) {
  if (parseFloatRemoveComma(taskCode.JTDUnits) === 0) {
    taskCode.JTDUnitCost = 0;
  } else {
    taskCode.JTDUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.JTDUnits);
  }
}

export function calculateTaskCodeRemainingUnits(taskCode: any, isInitialValue?: boolean) {
  if (isInitialValue) {
    if (parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) > 0) {
      taskCode.RemainingUnits = 1;
    } else if ((parseFloatRemoveComma(taskCode.BudgetUnits) - parseFloatRemoveComma(taskCode.JTDUnits)) < 0) {
      taskCode.RemainingUnits = 0;
    } else {
      taskCode.RemainingUnits = parseFloatRemoveComma(taskCode.BudgetUnits) - parseFloatRemoveComma(taskCode.JTDUnits);
    }

    return;
  }

  taskCode.RemainingUnits = parseFloatRemoveComma(taskCode.FinalQuantity) - parseFloatRemoveComma(taskCode.JTDUnits);

  if (parseFloatRemoveComma(taskCode.RemainingUnits) === 0 && parseFloatRemoveComma(taskCode.RemainingTotalCost) === 0) {
     taskCode.RemainingUnits = 0
  }
}

export function calculateTaskCodeRemainingUnitCost(taskCode: any, isInitialValue?: boolean) {
  if (isInitialValue) {
    if (parseFloatRemoveComma(taskCode.RemainingUnits) === 0) {
      taskCode.RemainingUnitCost = 0;
    } else if (parseFloatRemoveComma(taskCode.JTDUnitCost) > 0) {
      taskCode.RemainingUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost);
    } else if ((parseFloatRemoveComma(taskCode.BudgetTotalCost) - parseFloatRemoveComma(taskCode.JTDTotalCost)) <= 0) {
        taskCode.RemainingUnitCost = 0;
    } else if ((parseFloatRemoveComma(taskCode.BudgetTotalCost) - parseFloatRemoveComma(taskCode.JTDTotalCost)) > 0) {
        taskCode.RemainingUnitCost = (parseFloatRemoveComma(taskCode.BudgetTotalCost) - parseFloatRemoveComma(taskCode.JTDTotalCost)) / parseFloatRemoveComma(taskCode.RemainingUnits);
    } else {
      taskCode.RemainingUnitCost = parseFloatRemoveComma(taskCode.BudgetUnitCost);
    }

    return;
  }

  if (parseFloatRemoveComma(taskCode.RemainingUnits) === 0) {
    taskCode.RemainingUnitCost = 0;
  } else {
    taskCode.RemainingUnitCost = parseFloatRemoveComma(taskCode.RemainingTotalCost) / parseFloatRemoveComma(taskCode.RemainingUnits);
  }
}

export function calculateTaskCodeRemainingTotalCost(taskCode: any, calculateByFinalCost?: boolean) {
  if (calculateByFinalCost) {
      taskCode.RemainingTotalCost = parseFloatRemoveComma(taskCode.FinalCost) - parseFloatRemoveComma(taskCode.JTDTotalCost);
    }  else {
    taskCode.RemainingTotalCost = parseFloatRemoveComma(taskCode.RemainingUnits) * parseFloatRemoveComma(taskCode.RemainingUnitCost);
  }
}

export function calculateTaskCodeFinalQuantity(taskCode: any, calculateByQtyAdjustment?: boolean, calculateByRemainingUnits?: boolean) {
  if (calculateByQtyAdjustment) {
    if (parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0){
      if(parseFloatRemoveComma(taskCode.BudgetTotalCost) !== 0 || parseFloatRemoveComma(taskCode.JTDTotalCost) !== 0){
        if(parseFloatRemoveComma(taskCode.QtyAdjustment) === 0){
          taskCode.FinalQuantity = 1
        }
      } 
    } else if (parseFloatRemoveComma(taskCode.BudgetUnits) <= parseFloatRemoveComma(taskCode.JTDUnits)) {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.JTDUnits) + parseFloatRemoveComma(taskCode.QtyAdjustment)
    } else {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.BudgetUnits) + parseFloatRemoveComma(taskCode.QtyAdjustment)
    }
  } else if (calculateByRemainingUnits) {
    if (parseFloatRemoveComma(taskCode.JTDUnits) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) !== 0 && parseFloatRemoveComma(taskCode.RemainingUnits) === 0) {
      taskCode.FinalQuantity = 1;
    } else {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.RemainingUnits) + parseFloatRemoveComma(taskCode.JTDUnits);  
    }    
  } else {
    if (parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) > 0) {
      taskCode.FinalQuantity = 1;
    } else if (parseFloatRemoveComma(taskCode.BudgetUnits) <= parseFloatRemoveComma(taskCode.JTDUnits)) {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.JTDUnits);
    } else {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.BudgetUnits);
    }
  }  
}

export function calculateTaskCodeFinalUnitCost(taskCode: any, chgOrderDesc?: string, calculateByFinalCost?: boolean, calculateByRemQty?: boolean) {
  if (calculateByFinalCost) {
    if (parseFloatRemoveComma(taskCode.FinalQuantity) === 0) {
      taskCode.FinalUnitCost = 0;
    } else {
      taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.FinalCost) / parseFloatRemoveComma(taskCode.FinalQuantity);
    }
  } else {
    if (parseFloatRemoveComma(taskCode.FinalQuantity) === 0) {
      taskCode.FinalUnitCost = 0;
    } else if(calculateByRemQty){
      if(parseFloatRemoveComma(taskCode.RemainingUnits) === 0){
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity);
      }
      else if(parseFloatRemoveComma(taskCode.BudgetUnits) === 1 && parseFloatRemoveComma(taskCode.BudgetTotalCost) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) < 0){
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity);
      }
      else if(parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0){
        if(parseFloatRemoveComma(taskCode.BudgetTotalCost) < parseFloatRemoveComma(taskCode.JTDTotalCost)){
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity)
        }else{
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity) 
        }
      }
      else if(parseFloatRemoveComma(taskCode.JTDUnitCost) > 0 || parseFloatRemoveComma(taskCode.BudgetUnits) <= parseFloatRemoveComma(taskCode.JTDUnits)){
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost)
      } 
      else if(parseFloatRemoveComma(taskCode.BudgetTotalCost) > parseFloatRemoveComma(taskCode.JTDTotalCost)){
       if(parseFloatRemoveComma(taskCode.BudgetUnits) === 0){
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity) 
       }else{
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetUnitCost)
       }
      }else{
        parseFloatRemoveComma(taskCode.JTDUnits) === 0 
        ? taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity) 
        : taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost)
        }

      } else {
      if(parseFloatRemoveComma(taskCode.FinalUnitCost) !== 0){
        // eslint-disable-next-line no-self-assign
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.FinalUnitCost)
      }else{
      if(parseFloatRemoveComma(taskCode.BudgetUnits) === 1 && parseFloatRemoveComma(taskCode.BudgetTotalCost) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) < 0){
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity)
      }
      else if (taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0) {
        if(parseFloatRemoveComma(taskCode.BudgetTotalCost) < parseFloatRemoveComma(taskCode.JTDTotalCost)){
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity)
        }else{
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity)
        }    
      } 
      else if(parseFloatRemoveComma(taskCode.JTDUnitCost) > 0 || parseFloatRemoveComma(taskCode.BudgetUnits) <= parseFloatRemoveComma(taskCode.JTDUnits)){
        taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost)
      } 
      else if(parseFloatRemoveComma(taskCode.BudgetTotalCost) > parseFloatRemoveComma(taskCode.JTDTotalCost)){
        if(parseFloatRemoveComma(taskCode.BudgetUnits) === 0){
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity)
        }else{
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetUnitCost)
        } 
      }else{
        if(parseFloatRemoveComma(taskCode.JTDUnits) === 0){
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity)
        }else{
          taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost)
        }
      }
    }
  }
}

  // TODO review this
  // if (taskCode.CCOs) {
  //   for (let CCOs of taskCode.CCOs) {
  //     if (CCOs.ChgOrderDesc === chgOrderDesc && CCOs.isTotalAllocated) {
  //       CCOs.AssignedCost = parseFloatRemoveComma(taskCode.FinalCost);
  //     }
  //   }
  // }
}

export function calculateTaskCodeFinalCost(taskCode: any, calculateByRemainingTotalCost: boolean, calculateByFinalUnitCost: boolean, isForUseInCCO?: boolean): number {
  if (calculateByFinalUnitCost) {
    taskCode.FinalCost = parseFloatRemoveComma(taskCode.FinalQuantity) * parseFloatRemoveComma(taskCode.FinalUnitCost);
  } else if (calculateByRemainingTotalCost) {
    taskCode.FinalCost = parseFloatRemoveComma(taskCode.RemainingTotalCost) + parseFloatRemoveComma(taskCode.JTDTotalCost);
  }

  if (taskCode.CCOs && !isForUseInCCO) {
    for (let CCOs of taskCode.CCOs) {
      if (CCOs.isTotalAllocated) {
        CCOs.AssignedCost = parseFloatRemoveComma(taskCode.FinalCost);
        taskCode.FinalCost = 0;
        break;
      } else {
        taskCode.FinalCost -= parseFloatRemoveComma(CCOs.AssignedCost);
      }
    }
  }
  return taskCode.FinalCost;
}

export function calculateDelta(taskCode: any): number{
  let finalCostCCOs = 0;
  if (taskCode.CCOs) {
    finalCostCCOs = parseFloatRemoveComma(taskCode.FinalQuantity) * parseFloatRemoveComma(taskCode.FinalUnitCost);
    return taskCode.Changes = parseFloatRemoveComma(taskCode.LastMonthFinalCost) - parseFloatRemoveComma(finalCostCCOs);
    }else{
    return taskCode.Changes = parseFloatRemoveComma(taskCode.LastMonthFinalCost) - parseFloatRemoveComma(taskCode.FinalCost);
  }
}

export function calculateTaskCodeFinalCostToNumber(taskCode: any): number {
  let updatedTaskCode = JSON.parse(JSON.stringify(taskCode));
  
  calculateTaskCodeFinalCost(updatedTaskCode, false, true)
  
  return parseFloatRemoveComma(updatedTaskCode.FinalCost)
}

export function calculateCCOsCost(ccos?: CCOInTaskCode[] | null) {
  if (!ccos || !ccos.length) {
    return 0;
  }

  return ccos.reduce((acc, cco) => acc + cco.AssignedCost, 0)
}

export function calculateTaskCodeQtyAdjustment(taskCode: any, isInitialValue?: boolean) {
  if (isInitialValue) {
    taskCode.QtyAdjustment = 0;

    return;
  }

  taskCode.QtyAdjustment = parseFloatRemoveComma(taskCode.FinalQuantity) - parseFloatRemoveComma(taskCode.BudgetUnits);
}

export function calculateTaskCodeRecoveryPercent(taskCode: any, calculateByRecoveryPercent?: boolean) {
  taskCode.RecoveryPercent = calculateByRecoveryPercent ? parseFloat(taskCode.RecoveryPercent) : 0;
}

export function calculateTaskCodeRemainingFinalCost(CCOs: any): number {
  let totalCCOsCost: number = 0
  
  for (let cco of CCOs) {
    totalCCOsCost += parseFloat(cco?.AssignedCost)
  }
  return totalCCOsCost
}

export function calculateTaskCodeCostGL(taskCode: any) {
  taskCode.CostGL = parseFloatRemoveComma(taskCode.BudgetTotalCost) - parseFloatRemoveComma(taskCode.FinalCost)
}

export function calculateTaskCodeRemainingUnitsInitialValues(taskCode: any) {
  if ((parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0) &&(parseFloatRemoveComma(taskCode.BudgetTotalCost) !== 0 || parseFloatRemoveComma(taskCode.JTDTotalCost) !== 0)) {
    taskCode.RemainingUnits = 1;
  } else if (parseFloatRemoveComma(taskCode.BudgetUnits) < parseFloatRemoveComma(taskCode.JTDUnits)) {
    taskCode.RemainingUnits = 0;
  } else {
    taskCode.RemainingUnits = parseFloatRemoveComma(taskCode.BudgetUnits) - parseFloatRemoveComma(taskCode.JTDUnits);
  }
}

export function calculateTaskCodeRemainingUnitCostInitialValues(taskCode: any) {
  if (parseFloatRemoveComma(taskCode.RemainingUnits) === 0) {
    taskCode.RemainingUnitCost = 0;
  } else if (parseFloatRemoveComma(taskCode.JTDUnitCost) > 0) {
    taskCode.RemainingUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost);
  } else if (
    (parseFloatRemoveComma(taskCode.BudgetUnits) === 1 && parseFloatRemoveComma(taskCode.BudgetTotalCost) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) < 0) 
    || (parseFloatRemoveComma(taskCode.BudgetTotalCost) < 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) === 0)
  ) {
      taskCode.RemainingUnitCost = 0;
  } else if (parseFloatRemoveComma(taskCode.JTDUnits) === 0) {
      if(parseFloatRemoveComma(taskCode.BudgetTotalCost) < parseFloatRemoveComma(taskCode.JTDTotalCost)){
        taskCode.RemainingUnitCost = 0;
      }else{
        taskCode.RemainingUnitCost = (parseFloatRemoveComma(taskCode.BudgetTotalCost) - parseFloatRemoveComma(taskCode.JTDTotalCost)) / parseFloatRemoveComma(taskCode.RemainingUnits);
      }
  } else {
    taskCode.RemainingUnitCost = parseFloatRemoveComma(taskCode.BudgetUnitCost);
  }
}

export function calculateTaskCodeFinalCostInitialValues(taskCode: any) {
  taskCode.FinalCost =  parseFloatRemoveComma(taskCode.FinalQuantity) === 0 || parseFloatRemoveComma(taskCode.FinalUnitCost) === 0 ? 0 : parseFloatRemoveComma(taskCode.FinalQuantity) * parseFloatRemoveComma(taskCode.FinalUnitCost);
  
  // Reset assigments of CCOs 
  if (taskCode.CCOs) {
    taskCode.CCOs = null
  }
}

export function calculateTaskCodeRemainingTotalCostInitialValues(taskCode: any) {
  if (parseFloatRemoveComma(taskCode.RemainingUnits) !== 0 || parseFloatRemoveComma(taskCode.RemainingUnitCost) !== 0) {
    taskCode.RemainingTotalCost = parseFloatRemoveComma(taskCode.RemainingUnits) * parseFloatRemoveComma(taskCode.RemainingUnitCost);
  } else {
  taskCode.RemainingTotalCost = parseFloatRemoveComma(taskCode.FinalCost) - parseFloatRemoveComma(taskCode.JTDTotalCost);
}
}

export function calculateTaskCodeFinalQuantityInitialValues(taskCode: any) {
  if ((parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0) &&(parseFloatRemoveComma(taskCode.BudgetTotalCost) !== 0 || parseFloatRemoveComma(taskCode.JTDTotalCost) !== 0)) {
    taskCode.FinalQuantity = 1;
  } else if (parseFloatRemoveComma(taskCode.BudgetUnits) <= parseFloatRemoveComma(taskCode.JTDUnits)) {
    taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.JTDUnits);
  } else {
    taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.BudgetUnits);
  }
}

export function calculateTaskCodeFinalUnitCostInitialValues(taskCode: any) {
  if (parseFloatRemoveComma(taskCode.FinalQuantity) === 0) {
    taskCode.FinalUnitCost = 0;
  } else if (parseFloatRemoveComma(taskCode.BudgetUnits) === 1 && parseFloatRemoveComma(taskCode.BudgetTotalCost) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) < 0){
    taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity);
  } else if (parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0) {
    if (parseFloatRemoveComma(taskCode.BudgetTotalCost) > parseFloatRemoveComma(taskCode.JTDTotalCost)) {
      taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetTotalCost);
    } else {
      taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost);
    }
  } else if (parseFloatRemoveComma(taskCode.JTDUnitCost) > 0 || parseFloatRemoveComma(taskCode.JTDUnits) >= parseFloatRemoveComma(taskCode.BudgetUnits)) {
    taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost);
  } else {
    if (parseFloatRemoveComma(taskCode.BudgetTotalCost) > parseFloatRemoveComma(taskCode.JTDTotalCost)) {
      taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.BudgetTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity);
    } else {
      taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDTotalCost) / parseFloatRemoveComma(taskCode.FinalQuantity);
    }
  }
}

export function calculateTaskCodeFinalQuantityFromJTD(taskCode: any) {
  taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.JTDUnits);
}

export function calculateTaskCodeFinalUnitCostFromJTD(taskCode: any) {
  taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.JTDUnitCost);
}
export function calculateTaskCodeFinalCostFromJTD(taskCode: any) {
  taskCode.FinalCost = parseFloatRemoveComma(taskCode.JTDTotalCost);
}