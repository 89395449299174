import { apiCall } from "./axiosRequest";
import { AxiosResponse } from "axios";
import { BidItemToBeSaved } from "../interfaces/Interfaces";
import { sanitizeBidItemToBeSaved } from "../utils/sanitzeBidItemToBeSaved";

export class BidItemsService {

  //TODO: remove
  async getBidItemsByJobNumber(jobNumber: number) {
    const [error, response] = await apiCall("getbiditems", { jobNumber: jobNumber })
    return [error, response] as [Error | null, AxiosResponse<any> | undefined];
  }

  async saveBidItemToDB(bidItems: BidItemToBeSaved[]) {
    let sanitized = bidItems.map(bidItem => sanitizeBidItemToBeSaved(bidItem))
    if(sanitized.length > 0 ){
      const [error, response] = await apiCall("savebiditem", sanitized);
      return [error, response] as [Error | null, AxiosResponse<any> | undefined];  
    }else{
      throw Error
    }
  }
}
