const _settings = {
  production: {
    forecastManager: "https://rlm6ydea1e.execute-api.us-west-1.amazonaws.com/prod/",
    redirectUri: "https://myjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=3nj5907j448vddajjd0o2j9n98&redirect_uri=https://www.myjobforecast.com/",
    logOutUri: "https://myjobforecast.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=3nj5907j448vddajjd0o2j9n98&redirect_uri=https://www.myjobforecast.com/logout/",
    // authenticateUri: "",
    toastLife: 5000,
    table: "PROD Job Cost Mirror",
    apiFilter: "https://fw53w3d9jd.execute-api.us-west-1.amazonaws.com/prod",
    apiPmFilter: "https://cj22hq48xh.execute-api.us-west-1.amazonaws.com/prod",
    apiGetJobHistory: "https://hl9x87wcze.execute-api.us-west-1.amazonaws.com/prod"
  },
  development: {
    forecastManager: "https://7vy0lbsyo7.execute-api.us-west-1.amazonaws.com/dev/",
    redirectUri: "https://myjobforecastdev.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=6ahmnvjh6do2t4aq3c13kqfmml&redirect_uri=https://dev.myjobforecast.com/",
    logOutUri: "https://myjobforecastdev.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=6ahmnvjh6do2t4aq3c13kqfmml&redirect_uri=https://dev.myjobforecast.com/logout/",
    toastLife: 5000,
    table: "DEV Job Cost Mirror",
    apiFilter: "https://9c81std1r8.execute-api.us-west-1.amazonaws.com/dev",
    apiPmFilter: "",
    apiGetJobHistory: "https://nvet0qphx3.execute-api.us-west-1.amazonaws.com/dev"
  },
  qa: {
    forecastManager: "https://gzf54a7mfb.execute-api.us-west-1.amazonaws.com/qa/",
    redirectUri: "https://myjobforecastqa.auth.us-west-1.amazoncognito.com/login?response_type=code&client_id=58uofdvkmb4f9vko69dp0g5app&redirect_uri=https://qa.myjobforecast.com/",
    logOutUri: "https://myjobforecastqa.auth.us-west-1.amazoncognito.com/logout?response_type=code&client_id=58uofdvkmb4f9vko69dp0g5app&redirect_uri=https://qa.myjobforecast.com/logout/",
    toastLife: 5000,
    table: "QA Job Cost Mirror",
    apiFilter: "https://y7wbvh7erj.execute-api.us-west-1.amazonaws.com/qa",
    apiPmFilter: "https://utpo9xmxv1.execute-api.us-west-1.amazonaws.com/qa",
    apiGetJobHistory: "https://v1jf74toh9.execute-api.us-west-1.amazonaws.com/qa"
  },
  localhost: {
    forecastManager: "http://localhost:3000/localhost",
    redirectUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    logOutUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    toastLife: 5000,
    table: "Job Cost Mirror",
    apiFilter: "https://9c81std1r8.execute-api.us-west-1.amazonaws.com/dev",
    apiPmFilter: "",
    apiGetJobHistory: "https://nvet0qphx3.execute-api.us-west-1.amazonaws.com/dev"
  },
  localhostDev: {
    forecastManager: "https://7vy0lbsyo7.execute-api.us-west-1.amazonaws.com/dev/",
    redirectUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    logOutUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    toastLife: 5000,
    table: "DEV Job Cost Mirror",
    apiFilter: "https://9c81std1r8.execute-api.us-west-1.amazonaws.com/dev",
    apiPmFilter: "",
    apiGetJobHistory: "https://nvet0qphx3.execute-api.us-west-1.amazonaws.com/dev"
  }
};

export const stage = process.env.REACT_APP_STAGE ?? process.env.NODE_ENV ?? "development"
export const settings = _settings[stage as "production" | "development" | "qa"];
