import * as React from 'react';
import { connect } from "react-redux";
import to from 'await-to-js';
import { RootState } from "../redux/store";
import { InputText } from "primereact/inputtext";
import { Messages } from 'primereact/messages';
import TaskCodesDetails from "./TaskCodesDetails";
import BidItemsDetails from "./BidItemsDetails";
import { BidDetailsService } from "../service/BidDetailsService";
import { BidItemsService } from "../service/BidItemsService";
import { hardcodedBidItems, hardcodedBidItemsRCRP } from "../utils/hardcodedBidItems";
import { numbersAndDotOnly, toLocaleStringUS, parseFloatRemoveComma, convertJobMenuBidItemToBidItem, toLocaleStringUSorZero, displayTwoDigits } from "../utils/convert";
import * as TaskCodeCalculations from "../utils/taskCodeFieldsCalculations";
import * as BidItemCalculations from "../utils/bidItemFieldsCalculations";
import {
  AssignableTaskCode,
  bidDetailsProps,
  bidDetailsState,
  BidItemInterface,
  BidItemToBeSaved,
  CCOInTaskCode,
  JobsMenuBidItemInterface,
  JobsMenuItem,
  TaskCodeStructureFromDB
} from "../interfaces/Interfaces";
import { Growl } from 'primereact/growl';
import { bindActionCreators } from "redux";
import {
  setCurrentBidItems,
  setCurrentTaskCodes,
  setCurrentPCs,
  bidItemSaved,
  updateTaskCodeDetails,
  updatePCListDetails,
  resetUnAssignedTaskCodesToBidItemsIds,
  setLoadingExecutiveSummary,
  updateAssignedTaskCodes,
  updatePCItem,
  setTaskcodesWithFlagsFC,
  setTaskcodesWithoutBidItem,
} from "../redux/appSlice";
import { ExecutiveSummaryService } from '../service/ExecutiveSummaryService';
import { getMonthYearForExecutiveSummary } from '../utils/getMonthYearForExecutiveSummary';
import { updateOriginalBidItems } from '../utils/updateOriginalBidItems';
import { calculatedBidItemFinalRevenueHardcodeBidItem, calculateBidItemRemainingCost } from '../utils/bidItemCalculationsFunctions'
import { calculateDelta } from '../utils/taskCodeFieldsCalculationsFunctions'
import { InputTextarea } from 'primereact/inputtextarea';

class BidDetails extends React.Component<bidDetailsProps, bidDetailsState> {
  private mountedComponent: boolean | undefined;
  private bidDetailsService: BidDetailsService;
  private bidItemsService: BidItemsService;
  private executiveSummaryService: ExecutiveSummaryService;
  private toastTR: React.RefObject<Growl> = React.createRef();
  private messages: React.RefObject<Messages> = React.createRef();
  private handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.state.inputInitialValue === '') {
      this.setState({ inputInitialValue: event.target.value });
    }
    event.target.select()
  };

  constructor(props: bidDetailsProps) {
    super(props);
    this.state = {
      loading: false,
      changeDetected: false,
      bidItemData: [],
      taskCodesData: [],
      groupedBidItem: false,
      specialBidItem: false,
      calculateFinalRevenue: false,
      lastEditedFinalRevenue: 0,
      inputInitialValue: '',
      taskCodeFiltered: [],
      globalSearch: '',
      hasUpdatedExecutiveSummary: false,
    };

    this.bidDetailsService = new BidDetailsService();
    this.bidItemsService = new BidItemsService();
    this.executiveSummaryService = new ExecutiveSummaryService();

    this.editor = this.editor.bind(this);
    this.getBidDetails = this.getBidDetails.bind(this);
    this.assignedTaskCodesChanged = this.assignedTaskCodesChanged.bind(this);
    this.onEditorCancel = this.onEditorCancel.bind(this);
    this.onEditorSubmit = this.onEditorSubmit.bind(this);
    this.resetTaskCodeUpdateUI = this.resetTaskCodeUpdateUI.bind(this);
    this.setBidItemsFilter = this.setBidItemsFilter.bind(this);
    this.setCheckFinalCost = this.setCheckFinalCost.bind(this)
  }

  componentDidMount() {
    this.mountedComponent = true;
    this.getBidDetails();
    window.scrollTo(0, 0);
  }

  componentWillUnmount(): void {
    this.mountedComponent = false;
  }

  componentDidUpdate(prevProps: bidDetailsProps, prevState: bidDetailsState) {
    if (prevProps.selectedBidItems !== this.props.selectedBidItems && !this.state.changeDetected) {
      if (!prevProps.selectedBidItems.length ||
        ((prevProps.selectedBidItems[0].BidItem !== this.props.currentBidItemNumber) || (prevProps.selectedBidItems[0].ChgNumber !== this.props.currentChangeNumber))) {
        this.getBidDetails();
      }
      
      this.setInitialValues();
    }

    if ((prevProps.selectedPCs !== this.props.selectedPCs || prevProps.selectedTaskCodes !== this.props.selectedTaskCodes) && !this.state.changeDetected) {      
      this.setInitialValues();
    }

    if (prevProps.lastUpdated !== this.props.lastUpdated) {
      this.getBidDetails();
      this.setInitialValues();
    }

    if (prevProps.isJobDataLoading !== this.props.isJobDataLoading) {
      this.setState({ loading: this.props.isJobDataLoading });
    }
  }

  getBidDetails() {
    this.props.setCurrentBidItems();
    this.props.setCurrentTaskCodes();
    this.props.setCurrentPCs();
     
    if (this.props.selectedBidItems && this.props.selectedTaskCodes && this.mountedComponent) {
      if (!this.props.selectedBidItems.length) {
        let newlyCreatedHardcodedBidItem = hardcodedBidItems.find(hardcodedBidItem => hardcodedBidItem.BidItem === this.props.currentBidItemNumber);

        if (newlyCreatedHardcodedBidItem) {
          let newlyFormattedHardcodedBidItem: any = BidItemCalculations.prepareBidItemForDB(newlyCreatedHardcodedBidItem, this.props.currentJobNumber.toString(), this.props.currentJobDescription);
          let isRCRP = hardcodedBidItemsRCRP.some(bidItem => bidItem === newlyFormattedHardcodedBidItem.BidItem);

          this.setState({
            bidItemData: [newlyFormattedHardcodedBidItem],
            specialBidItem: isRCRP
          });
        } else {
          this.setState({ specialBidItem: false });
        }
      }

      if ((this.props.selectedBidItems.length === 1) && hardcodedBidItemsRCRP.some(hardcodedBidItem => hardcodedBidItem === this.props.selectedBidItems[0].BidItem)) {
        this.setState({
          specialBidItem: true,
          calculateFinalRevenue: !this.props.selectedBidItems[0].HasEditedFields
        });
      } else {
        // reset calculateFinalRevenue if the selected bid item is not special(only some hardcoded have final revenue calculated by percentage recovery feature)
        this.setState({
          specialBidItem: false,
          calculateFinalRevenue: false
        });
      }
      this.setInitialValues();
    }
  }

  setInitialValues() {
    let updatedBidItems: any[];
    let updatedTaskCodes: TaskCodeStructureFromDB[] = [];

    if (this.props.selectedBidItems[0]?.ChgNumber !== "000") {
      this.setState({ groupedBidItem: true });
      updatedBidItems = [...JSON.parse(JSON.stringify(this.props.selectedPCs))];
    } else {
      this.setState({ groupedBidItem: false });
      updatedBidItems = [...JSON.parse(JSON.stringify(this.props.selectedBidItems))];
    }

    if (updatedBidItems.length > 0) {
      updatedBidItems[0].JobDescription = this.props.currentJobDescription;
      updatedBidItems[0].JobNumber = this.props.currentJobNumber.toString();
    }

    if (this.state.groupedBidItem) {
      if (updatedBidItems.length > 1) {
        updatedTaskCodes = this.setGroupedBidItemsTaskCodesFinalCost(this.props.selectedTaskCodes);
        this.setTaskCodesFinalCost(updatedTaskCodes, updatedBidItems);
        this.setTaskCodesJTDCost(updatedTaskCodes, updatedBidItems);
        this.setTaskCodesMTDCost(updatedTaskCodes, updatedBidItems);
        this.setBidItemsGL(updatedBidItems);
        this.setBidItemsPreviousFinalCost(updatedBidItems);
        this.setBidDetailsDelta(updatedBidItems)
      } else {
        updatedTaskCodes = this.setGroupedBidItemsTaskCodesFinalCost(this.props.selectedTaskCodes);
        this.setTaskCodesFinalCost(updatedTaskCodes, updatedBidItems);
        this.setTaskCodesJTDCost(updatedTaskCodes, updatedBidItems);
        this.setTaskCodesMTDCost(updatedTaskCodes, updatedBidItems);
        this.setBidItemsGL(updatedBidItems);
        this.setBidItemsPreviousFinalCost(updatedBidItems);
        this.setBidDetailsDelta(updatedBidItems)
      }
    } else {
      updatedTaskCodes = [...JSON.parse(JSON.stringify(this.props.selectedTaskCodes))];
      this.setTaskCodesFinalCost(updatedTaskCodes, updatedBidItems);
      this.setTaskCodesJTDCost(updatedTaskCodes, updatedBidItems);
      this.setTaskCodesMTDCost(updatedTaskCodes, updatedBidItems);
      this.setBidItemsGL(updatedBidItems);
      this.setBidItemsPreviousFinalCost(updatedBidItems);
      this.setBidDetailsDelta(updatedBidItems)
    }

    this.setTaskCodesFilter('');

    if (updatedBidItems[0]?.HasEditedFields) {
      this.setState({ lastEditedFinalRevenue: updatedBidItems[0]?.FinalRevenue });
    }

    if (this.state.specialBidItem) {
      updatedBidItems[0].FinalRevenue = calculatedBidItemFinalRevenueHardcodeBidItem(updatedTaskCodes)
    } else if (this.state.specialBidItem){
      updatedBidItems[0].FinalRevenue = 0;
    }

    this.setTaskCodesDetailsDelta(updatedTaskCodes)

    if (updatedBidItems.length > 0) {
      if (!updatedTaskCodes.length) {
        updatedBidItems[0].RemCost = 0;
      } else {
        for (let index in updatedTaskCodes) {
          if (!updatedTaskCodes[index].HasEditedFields) {
            let changesValue = calculateDelta(updatedTaskCodes[index]);
            updatedTaskCodes[index].Changes = changesValue
            this.setState({ taskCodesData: updatedTaskCodes });
          } else {
            if (this.state.taskCodeFiltered.length) {
            let changesValue = calculateDelta(updatedTaskCodes[index]);
            updatedTaskCodes[index].Changes = changesValue
            }
          }
        }
        updatedBidItems[0].RemCost = calculateBidItemRemainingCost(updatedTaskCodes)
        this.setState({ bidItemData: updatedBidItems })  
      }
    }

    if(updatedTaskCodes.length && this.state.groupedBidItem === false){
      for (let index in updatedTaskCodes) {
        if(updatedTaskCodes[index].CCOs !== null){
          updatedTaskCodes[index].HasEditedFields = true
        }
      }
    }else if(updatedTaskCodes.length && this.state.groupedBidItem === true){
      for (let index in updatedTaskCodes) {
        if(updatedTaskCodes[index].CCOs !== null){
          updatedTaskCodes[index].HasEditedFields = false
        }
      }
    }
    this.setState({
      bidItemData: updatedBidItems,
      taskCodesData: updatedTaskCodes
    });

    this.checkTaskcodesWithoutBidItem()
  }

  checkTaskcodesWithoutBidItem(){
    let bidItemNumber = []
    let taskCodesNotIncluded: any[] = [];
    let taskCodesIncluded = []
    let bidItems = [...JSON.parse(JSON.stringify(this.props.currentJobBidItems))]
    let taskCodes = [...JSON.parse(JSON.stringify(this.props.currentJobTaskCodes))]

    if(bidItems !== undefined){
      for(let i = 0; i < bidItems.length; i++){
        bidItemNumber.push(bidItems[i].BidItem)
      }
    }
    if(taskCodes !== undefined){
      for(let a = 0; a < taskCodes.length; a++){
        const hardcodedBidItems = [777, 830, 850, 860, 861, 870, 880, 888, 890, 920, 940, 941, 944, 950, 960, 999]
        if(!bidItemNumber.includes(taskCodes[a].BidItem) && !hardcodedBidItems.includes(parseInt(taskCodes[a].BidItem))){
          taskCodesNotIncluded.push(taskCodes[a])
        }else{
          taskCodesIncluded.push(taskCodes[a])
        }
      }
    }
    this.props.setTaskcodesWithoutBidItem(taskCodesNotIncluded)
  }

  setGroupedBidItemsTaskCodesFinalCost(selectedTaskCodes: TaskCodeStructureFromDB[]) {
    let currentTaskCodes = [...JSON.parse(JSON.stringify(selectedTaskCodes))].map((taskCode: TaskCodeStructureFromDB) => {
    let currentCCO: string | any[];

      if (Array.isArray(taskCode.CCOs)) {
        currentCCO = taskCode.CCOs.filter(cco => cco.ChgNumber === this.props.currentChangeNumber);
      } else if (typeof taskCode.CCOs === 'object' && taskCode.CCOs !== null) {
        // Si es un objeto, lo convertimos a un array de un solo elemento para simplificar el código
        const ccosArray = [taskCode.CCOs];
        currentCCO = ccosArray.filter((cco: CCOInTaskCode) => cco.ChgNumber === this.props.currentChangeNumber);
      } else {
        // Si no es ni un array ni un objeto, no hay CCOs para este elemento
        currentCCO = [];
      }

      if (currentCCO.length) {
        taskCode.FinalCost = currentCCO[0].AssignedCost;
      }

      return taskCode;
    });

    return currentTaskCodes;
}

  
  setTaskCodesFinalCost(taskCodes: any, bidItem: any) {
    
    if (bidItem[0] !== undefined && taskCodes !== undefined) { 
    let finalCost = 0;
  
    if (taskCodes) {
      for (let taskCode of taskCodes) {
        finalCost += (parseFloatRemoveComma(taskCode.FinalCost) || 0);
      }
    }

    bidItem[0].FinalCost = toLocaleStringUSorZero(parseFloatRemoveComma(finalCost));
  }
  
  }


  setTaskCodesJTDCost(taskCodes: any, bidItem: any) {
  
    if (bidItem[0] !== undefined && taskCodes !== undefined) { 
    let JTDCost = 0;
  
    if (taskCodes) {
      for (let taskCode of taskCodes) {
        JTDCost += (parseFloatRemoveComma(taskCode.JTDTotalCost) || 0);
      }
    }

    bidItem[0].JTDCost = toLocaleStringUSorZero(parseFloatRemoveComma(JTDCost));
  }
  
  }
  
  setTaskCodesMTDCost(taskCodes: any, bidItem: any) {

    if (bidItem[0] !== undefined && taskCodes !== undefined) { 
    let MTDCost = 0;
  
    if (taskCodes) {
      for (let taskCode of taskCodes) {
        MTDCost += (parseFloatRemoveComma(taskCode.MTDCost) || 0);
      }
    }

    bidItem[0].MTDCost = toLocaleStringUSorZero(parseFloatRemoveComma(MTDCost));
  }
  
  }
  
  
  
  setBidItemsGL(bidItem: any) {
    if (bidItem[0] !== undefined) {
      bidItem[0].GainLoss = toLocaleStringUSorZero(parseFloatRemoveComma(bidItem[0].FinalRevenue) - parseFloatRemoveComma(bidItem[0].FinalCost)); 
    }
  }

  setBidItemsPreviousFinalCost(bidItem: any) {
    let previousFinalCost = 0
    if (bidItem[0] !== undefined) {
      if(bidItem[0].TaskCodeItems !== undefined){
      //@ts-ignore
      bidItem[0].TaskCodeItems.forEach((e: { LastMonthFinalCost: string | number; }) => previousFinalCost += parseFloatRemoveComma(e.LastMonthFinalCost))
      bidItem[0].PreviousFinalCost = previousFinalCost
      }
      }
  }

  setTaskCodesDetailsDelta(taskcodes: TaskCodeStructureFromDB[]) {
    if (taskcodes.length > 0) {
      for (let taskCode of taskcodes) {
        taskCode.Changes = parseFloatRemoveComma(taskCode.FinalCost) - parseFloatRemoveComma(taskCode.LastMonthFinalCost); 
      }
    }
  }

  updateReduxStateAfterChange(taskCodes: any, tascodesWithFlagFC: any){
      for (let j = 0; j < tascodesWithFlagFC.length; j++) {
        if (taskCodes.Taskcode === tascodesWithFlagFC[j].Taskcode) {
          tascodesWithFlagFC.splice(j, 1)
        }
      }
    this.props.setTaskcodesWithFlagsFC(tascodesWithFlagFC)
  }

  updateFlagChanges(taskCode: any) {
    let taskCodes = JSON.parse(JSON.stringify(taskCode));
    let taskCodesFlags: any = [];
    if (this.props.taskcodesWithFlagsFC !== undefined) {
        taskCodesFlags = [...JSON.parse(JSON.stringify(this.props.taskcodesWithFlagsFC))];
    }

    let JTDTotalCost = parseFloatRemoveComma(taskCodes.JTDTotalCost)
    let FinalCost = parseFloatRemoveComma(taskCodes.FinalCost)
    let sumCCOs = 0;
    let taskCodeExists = false;
    if (this.props.taskcodesWithFlagsFC) {
        taskCodeExists = this.props.taskcodesWithFlagsFC.some(code => code.Taskcode === taskCodes.Taskcode);
    }

        if (JTDTotalCost > FinalCost) {
            if (taskCodes.CCOs) {
                for (let a = 0; a < taskCode.CCOs.length; a++) {
                    if (taskCodes.CCOs[a]?.AssignedCost) {
                        sumCCOs += parseFloatRemoveComma(taskCodes.CCOs[a].AssignedCost);
                    }
                }
                let finalNumber = sumCCOs + FinalCost;

                if (JTDTotalCost > finalNumber) {
                    if(!taskCodeExists){
                      taskCodesFlags.push(taskCodes);
                      this.props.setTaskcodesWithFlagsFC(taskCodesFlags);
                    }
                } else {
                  this.updateReduxStateAfterChange(taskCodes, taskCodesFlags);
                }
            } else {
              if(!taskCodeExists){
                taskCodesFlags.push(taskCodes);
                this.props.setTaskcodesWithFlagsFC(taskCodesFlags);
              }
            }
        } else {
            this.updateReduxStateAfterChange(taskCodes, taskCodesFlags);
        }
}

  
  setBidDetailsDelta(bidItems: any) {
    let delta = 0
    if (bidItems.length > 0) {
      //@ts-ignore
      bidItems.forEach(bidItem => {
        if(bidItem.TaskCodeItems !== undefined){
        //@ts-ignore
        bidItem.TaskCodeItems.forEach((e: {FinalCost: string | number; }) => delta += parseFloatRemoveComma(e.FinalCost) - parseFloatRemoveComma(e.LastMonthFinalCost))
        bidItem.Changes = delta;
        }
      });
    }
  }

  //#region "OnValueChanged and OnSubmit"
  editor(props: any, key: string, dataIsTaskCode: boolean) {
      if(key === "Comments" || key === "BidComments"){
        return <InputTextarea  type="text" value={props.rowData[key]} onChange={(e) => this.onEditorValueChange(props, e.target, dataIsTaskCode)} autoResize rows={10} cols={30}/>;
      }else{
        return <InputText type="text" value={props.rowData[key]} onFocus={this.handleFocus} onChange={(e) => this.onEditorValueChange(props, e.target, dataIsTaskCode)}/>;
      }
      
  }

  onEditorCancel(props: any, dataIsTaskCode: boolean) {
    const { field } = props;

    if (field !== "Comments" || field !== "BidComments") {
      if (dataIsTaskCode) {
        let updatedTaskCodes: TaskCodeStructureFromDB[] = TaskCodeCalculations.calculateTaskCodeFields(props, this.state.inputInitialValue);
        let updatedBidItem: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalCostField(this.state.bidItemData[0], updatedTaskCodes);


        if (this.state.specialBidItem && this.state.calculateFinalRevenue) {
          updatedBidItem = BidItemCalculations.calculateBidItemFinalRevenueField(updatedBidItem, updatedTaskCodes, true);
        }

        let notUpdatedBidItems = this.state.bidItemData.slice(1);

        this.setState({ bidItemData: [updatedBidItem, ...notUpdatedBidItems] });

        this.setState({ taskCodesData: updatedTaskCodes });
      } else {
        let updatedBidItems: BidItemToBeSaved[] = BidItemCalculations.calculateBidItemFields(props, this.state.inputInitialValue);
        this.setState({ bidItemData: updatedBidItems });
      }
    } else {
      let taskCodes = [...props.value];
      let currentTaskCode = taskCodes[props.rowIndex];
      currentTaskCode[field] = this.state.inputInitialValue;

      this.setState({ taskCodesData: taskCodes });
    }
    
    this.setState({ inputInitialValue: '' });
  }

  onEditorValueChange(props: any, input: any, dataIsTaskCode: boolean) {
    this.setState({ changeDetected: true });
    let myInput: string = "";
    const { field } = props;

    if (field !== "Comments" && field !== "BidComments") {
      myInput = numbersAndDotOnly(input.value);
      if (dataIsTaskCode) {
        let updatedTaskCodes: TaskCodeStructureFromDB[] = TaskCodeCalculations.calculateTaskCodeFields(props, myInput, this.state.groupedBidItem, this.state.bidItemData[0]);
        let updatedBidItem: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalCostField(this.state.bidItemData[0], updatedTaskCodes);

        if (this.state.specialBidItem) {
          updatedBidItem = BidItemCalculations.calculateBidItemFinalRevenueField(updatedBidItem, updatedTaskCodes, this.state.calculateFinalRevenue, this.state.lastEditedFinalRevenue);
        }
        
        let notUpdatedBidItems = this.state.bidItemData.slice(1);
        
        this.setState({ bidItemData: [updatedBidItem, ...notUpdatedBidItems] });

        this.setState({ taskCodesData: updatedTaskCodes });
      } else {
        let updatedBidItems: BidItemToBeSaved[] = BidItemCalculations.calculateBidItemFields(props, myInput);
        
        this.setState({ bidItemData: updatedBidItems });

        if (field === "FinalRevenue") {
          this.setState({ lastEditedFinalRevenue: parseFloat(myInput) });
        }
      }
    } else if(field !== "BidComments"){
      let taskCodes = [...props.value];
      let currentTaskCode = taskCodes[props.rowIndex];
      currentTaskCode[field] = input.value;

      this.setState({ taskCodesData: taskCodes });
    } else {
      let bidItems = [...props.value]
      let updatedBidItems = bidItems[props.rowIndex];
      updatedBidItems[field] = input.value;
    }
  }

  async onEditorSubmit(props: any, dataIsTaskCode: boolean) {
    this.setState({ loading: true });
    let JTDTotalCost = (parseFloatRemoveComma(props.rowData.JTDTotalCost).toFixed(2));
    let FinalCost = (parseFloatRemoveComma(props.rowData.FinalCost).toFixed(2));
    let JTDUnits = (parseFloatRemoveComma(props.rowData.JTDUnits).toFixed(2));
    let FinalQuantity = (parseFloatRemoveComma(props.rowData.FinalQuantity).toFixed(2));
    let sumCCOs = 0;
    this.props.setLoadingExecutiveSummary({ setLoading: true });

    if (JTDTotalCost > FinalCost || JTDUnits > FinalQuantity) {
      if (props.rowData.CCOs) {
          for (let a = 0; a < props.rowData.CCOs.length; a++) {
              if (props.rowData.CCOs[a]?.AssignedCost) {
                  sumCCOs += parseFloatRemoveComma(props.rowData.CCOs[a].AssignedCost);
              }
          }
          let finalNumber = sumCCOs + FinalCost;

          if (JTDTotalCost > finalNumber) {
            this.messages?.current?.show({ severity: 'warn', summary: 'The Final QTY/ Cost cannot be lower than the JTD QTY/Cost', detail: 'If this correction is necessary, please ignore this message.', sticky: true });
          }
      } else {
        this.messages?.current?.show({ severity: 'warn', summary: 'The Final QTY/ Cost cannot be lower than the JTD QTY/Cost', detail: 'If this correction is necessary, please ignore this message.', sticky: true });
      }
    }

      if (this.state.changeDetected) {
        if (dataIsTaskCode) {
          // If the field is one of the following, we update the Bid Item as well because the Final Cost of Bid Item should of had changed
          if (props.field === "RecoveryPercent"
            || props.field === "FinalQuantity"
            || props.field === "FinalUnitCost"
            || props.field === "FinalCost"
            || props.field === "RemainingUnits"
            || props.field === "RemainingUnitCost"
            || props.field === "RemainingTotalCost"
            || props.field === "QtyAdjustment") {
            let bidItemData = {
              ...this.state.bidItemData[0],
              TaskCodeItems: [...props.value]
            };

            let bidItemFinalCostCalculated: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalCostField(bidItemData, this.state.taskCodesData);
            let bidItemFinalRevenueCalculated: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalRevenueField(bidItemFinalCostCalculated, this.state.taskCodesData, this.state.calculateFinalRevenue);
            let bidItemToBeSaved: BidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(bidItemFinalRevenueCalculated);
            
            // let bidItemFinalRevenueCalculated: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalRevenueField(bidItemData, this.state.taskCodesData, this.state.calculateFinalRevenue);
            // let bidItemFinalCostCalculated: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalCostField(bidItemFinalRevenueCalculated, this.state.taskCodesData);
            // let bidItemToBeSaved: BidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(bidItemFinalCostCalculated);

            const [saveBidItemError, saveBidItemResponse] = await this.bidItemsService.saveBidItemToDB([bidItemToBeSaved]);
            if (saveBidItemError !== null) {
              this.toastTR?.current?.show({ severity: 'error', summary: 'Saved Bid Items Error', detail: saveBidItemError, life: 5000 });
            }
            
              this.props.bidItemSaved({
                BidItemToBeSaved: bidItemToBeSaved,
                JobNumber: this.props.currentJobNumber,
                JobDescription: this.props.currentJobDescription
              });
              this.props.setCurrentBidItems();
  
              if (bidItemToBeSaved?.ChgNumber !== "000" && this.props.currentChangeNumber === bidItemToBeSaved?.ChgNumber) {
                this.props.updatePCItem({pcItem: bidItemToBeSaved});
              }
          }

          this.saveTaskCode(props);

        } else {
          if (props.field === "FinalRevenue" && this.state.specialBidItem) {
            // We also save the Bid Item here
            this.setCalculateFinalRevenue(false);

            if (this.props.currentBidItemNumber === "890" || "940" || "941" || "944" || "999") {
              let bidItemData = this.state.bidItemData;
              bidItemData[0].FinalRevenue = parseFloatRemoveComma(this.state.lastEditedFinalRevenue);
              this.setState({ bidItemData: bidItemData });
              this.saveBidItem(props); 
            }
          } else {
            this.saveBidItem(props);
          }
        }
        
        this.setState({
          changeDetected: false,
          inputInitialValue: ''
        });
      }else{
        this.setState({ loading: false });
      }
    
    this.props.setLoadingExecutiveSummary({ setLoading: false });
    this.updateFlagChanges(props.rowData);
  }

  async saveBidItem(props: any) {
    const { rowIndex: index, field } = props;
    // @ts-ignore
    let myValue: string = this.state.bidItemData[index][field];
    let updatedBidItems: any[] = [...this.state.bidItemData];

    if (field !== "BidComments") {
      if (isNaN(parseFloat(myValue))) {
        myValue = "0.00";
      } else if (myValue.toString().slice(myValue.length - 1) === ".") {
        myValue += "0";
      }
      updatedBidItems[index][field] = toLocaleStringUS(parseFloatRemoveComma(myValue));
    }

    if (field === "FinalRevenue" || field === "FinalQuantity" || field === "QtyAdjustment") {
      updatedBidItems[index].HasEditedFields = true;
    }

    this.props.setLoadingExecutiveSummary({ setLoading: true})
    this.setState({ bidItemData: updatedBidItems });
    let bidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(updatedBidItems[index], this.props.currentJobNumber.toString(), this.props.currentJobDescription);

    bidItemToBeSaved.TaskCodeItems = this.props.selectedTaskCodes;

    if (this.props.currentBidItemNumber === "890" || "940" || "941" || "944" || "999"){
      this.props.bidItemSaved({
        BidItemToBeSaved: bidItemToBeSaved,
        JobNumber: this.props.currentJobNumber,
        JobDescription: this.props.currentJobDescription
      });

      this.props.setCurrentBidItems();
    }

    if(bidItemToBeSaved?.ChgNumber !== "000" && bidItemToBeSaved?.ChgNumber === this.props.currentChangeNumber) {
      this.props.setCurrentPCs();
      this.props.updatePCListDetails();
    }    
    
    this.props.bidItemSaved({
      BidItemToBeSaved: bidItemToBeSaved,
      JobNumber: this.props.currentJobNumber,
      JobDescription: this.props.currentJobDescription
    });

    this.props.setCurrentBidItems();
    
    if(bidItemToBeSaved?.ChgNumber !== "000" && bidItemToBeSaved?.ChgNumber === this.props.currentChangeNumber) {
      this.props.setCurrentPCs();
      this.props.updatePCListDetails();
    }

    this.props.setLoadingExecutiveSummary({ setLoading: true });

  try {
    const [saveBidItemsError, saveBidItemsResponse] = await to(this.bidItemsService.saveBidItemToDB([bidItemToBeSaved]));

    if (saveBidItemsResponse != null && saveBidItemsError == null) {
    const _date = getMonthYearForExecutiveSummary();
    const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber.toString(), parseInt(_date.month), parseInt(_date.year), this.props.setUsername));

    if (updateExecutiveSummaryResponse) {
      this.toastTR?.current?.show({ severity: 'success', summary: 'Update Executive Summary', detail: "Executive Summary Save successfully! ", life: 5000 });
    } else {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: updateExecutiveSummaryError, life: 5000 });
    }
  }
  } catch (error) {
    this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: error, life: 5000 });
  } finally {
    this.props.setLoadingExecutiveSummary({ setLoading: false });
    this.setState({ loading: false });
  }

  }

  async saveTaskCode(props: any) {
    const { rowIndex: index, field } = props;
    // @ts-ignore
    let myValue: string = this.state.taskCodesData[index][field];
    let updatedTaskCodes: any[] = [...this.state.taskCodesData];

    updatedTaskCodes[index].HasEditedFields = true;

    if (field !== "Comments") {
      if (isNaN(parseFloat(myValue))) {
        //Setting initial value if myValue is empty
        myValue = '0';
      } else if (myValue.toString().slice(myValue.length - 1) === ".") {
        myValue += "0";
      }
      updatedTaskCodes[index][field] = toLocaleStringUS(parseFloatRemoveComma(myValue));
      this.setState({ taskCodesData: updatedTaskCodes });
    }    
    
    let taskCodeToBeSaved = TaskCodeCalculations.prepareTaskCodeForDB(updatedTaskCodes[index]);

    const jobNumber = this.props.currentJobNumber.toString();

    this.props.updateTaskCodeDetails({
      JobNumber: jobNumber,
      taskCodeToBeSaved
    });
    this.props.setCurrentTaskCodes();

    this.props.setLoadingExecutiveSummary({ setLoading: true});

    const [saveBidDetailsError] = await to(this.bidDetailsService.saveBidDetails(taskCodeToBeSaved));

    if (saveBidDetailsError !== null) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
      throw new Error(saveBidDetailsError.message);
    }
    
    this.updateExecutiveSummary();
  }

  async updateExecutiveSummary(){
      // update executive summary
      try {
        this.props.setLoadingExecutiveSummary({ setLoading: true });
        let updatedBidItems: any[] = [...this.state.bidItemData];
      
        const _date = getMonthYearForExecutiveSummary();
      
        const [updateExecutiveSummaryError] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber, parseInt(_date.month), parseInt(_date.year), this.props.setUsername));
      
        if (updateExecutiveSummaryError !== null) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: updateExecutiveSummaryError.message, life: 5000 });
          throw new Error(updateExecutiveSummaryError.message);
        } else {
          this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
        }
      } catch (error) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: error, life: 5000 });
      } finally {
        this.props.setLoadingExecutiveSummary({ setLoading: false });
        this.setState({ loading: false });
      }
      
      //#endregion
  }

  async setCalculateFinalRevenue(calculate: boolean) {
    this.setState({ calculateFinalRevenue: calculate });

    BidItemCalculations.calculateBidItemFinalRevenueField(this.state.bidItemData[0], this.state.taskCodesData, calculate, this.state.lastEditedFinalRevenue);
  }

  async assignedTaskCodesChanged(taskCodes?: AssignableTaskCode[]) {    
    if (this.props.currentChangeNumber !== "000" && taskCodes) {
      let jobMenuItemIndex = this.props.jobsMenuItems.findIndex(item => item.JobNumber === this.props.currentJobNumber)
      let jobBidItems = this.props.jobsMenuItems[jobMenuItemIndex].BidItems
      
      let bidItemCCOData = {
        ...this.state.bidItemData[0],
        TaskCodeItems: [...this.state.taskCodesData]
      };

      let bidItemFinalRevenueCalculated: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalRevenueField(bidItemCCOData, this.state.taskCodesData);
      let bidItemFinalCostCalculated: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalCostField(bidItemFinalRevenueCalculated, this.state.taskCodesData);
      let bidItemCCOToBeSaved: BidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(bidItemFinalCostCalculated);      

      let updatedOriginalBidItems = updateOriginalBidItems(jobBidItems, taskCodes);

      let originalBidItemToBeSaved: BidItemToBeSaved[] = []
      
      if (updatedOriginalBidItems && updatedOriginalBidItems.length) {
        updatedOriginalBidItems.forEach((bidItem) => {
          let preparedBidItem: BidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(bidItem, this.props.currentJobNumber.toString(), this.props.currentJobDescription);

          originalBidItemToBeSaved = [...originalBidItemToBeSaved, preparedBidItem]          
        })
      }

      if([bidItemCCOToBeSaved, ...originalBidItemToBeSaved].length > 0){
        const [saveBidItemError, saveBidItemResponse] = await this.bidItemsService.saveBidItemToDB([bidItemCCOToBeSaved, ...originalBidItemToBeSaved]);
      if (saveBidItemError !== null) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Saved Bid Items Error', detail: "Saved Bid Items Error", life: 5000 });
      }
      }
      
      
      
      this.props.bidItemSaved({
        BidItemToBeSaved: originalBidItemToBeSaved,
        JobNumber: this.props.currentJobNumber,
        JobDescription: this.props.currentJobDescription
      });

      this.props.setCurrentBidItems();
      this.props.updatePCItem({pcItem: bidItemCCOToBeSaved});
    } else {
      // if Task Codes changed on original Bid Item
      let updatedBidItem: BidItemToBeSaved = BidItemCalculations.calculateBidItemFinalCostField(this.state.bidItemData[0], this.props.selectedTaskCodes);
      let bidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(updatedBidItem, this.props.currentJobNumber.toString(), this.props.currentJobDescription);
      
      if([bidItemToBeSaved].length > 0){
        const [saveBidItemError, saveBidItemResponse] = await this.bidItemsService.saveBidItemToDB([bidItemToBeSaved]);
      if (saveBidItemError !== null) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Saved Bid Items Error', detail: saveBidItemError, life: 5000 });
      }
      }
      
  
      this.props.bidItemSaved({
        BidItemToBeSaved: bidItemToBeSaved,
        JobNumber: this.props.currentJobNumber,
        JobDescription: this.props.currentJobDescription
      });
  
      if (this.state.bidItemData.length > 1){
        let [ ,...restOfBidItemsCCOs ]: any = this.state.bidItemData;
        this.setState({ bidItemData: [updatedBidItem, ...restOfBidItemsCCOs] });
      } else {
        this.setState({ bidItemData: [updatedBidItem] });
      }
    }
    
    if (this.props.unAssignedTaskCodesToBidItemsIds.length) {
      let bidItemsToBeSaved: BidItemToBeSaved[] = []

      this.props.unAssignedTaskCodesToBidItemsIds.forEach(id => {
        let bidItemIndex: number
        let bidItemToUpdate: JobsMenuBidItemInterface
        let convertedBidItemToUpdate: BidItemInterface
        let updatedBidItem: BidItemInterface
        let bidItemToBeSaved: BidItemToBeSaved
  
        let jobIndex = this.props.jobsMenuItems.findIndex((jobMenuItem: JobsMenuItem) => jobMenuItem.JobNumber === this.props.currentJobNumber);
  
        if (id.includes('PC')) {
          let idWithoutPC = id.slice(2);
          let changeNumber = idWithoutPC.slice(0, idWithoutPC.length / 2);
  
          bidItemIndex = this.props.jobsMenuItems[jobIndex]?.PCs.findIndex((bidItem: JobsMenuBidItemInterface) => bidItem.ChgNumber === changeNumber);
  
          if (bidItemIndex < 0) return
  
          bidItemToUpdate = this.props.jobsMenuItems[jobIndex].PCs[bidItemIndex];
  
          let changeNumberTaskCodes = this.props.jobsMenuItems[jobIndex]?.TaskCodes.filter((taskCode) => taskCode.BidItem === id)
  
          convertedBidItemToUpdate = convertJobMenuBidItemToBidItem(bidItemToUpdate);
          updatedBidItem = BidItemCalculations.calculateBidItemFinalCostField(convertedBidItemToUpdate, changeNumberTaskCodes);
        } else {
          bidItemIndex = this.props.jobsMenuItems[jobIndex].BidItems.findIndex((bidItem) => bidItem.BidItem === id);
  
          if (bidItemIndex < 0) return
  
          bidItemToUpdate = this.props.jobsMenuItems[jobIndex].BidItems[bidItemIndex];
          convertedBidItemToUpdate = convertJobMenuBidItemToBidItem(bidItemToUpdate);
          updatedBidItem = BidItemCalculations.calculateBidItemFinalCostField(convertedBidItemToUpdate, bidItemToUpdate?.TaskCodeItems);
        }
  
        bidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(updatedBidItem, this.props.currentJobNumber.toString(), this.props.currentJobDescription);
        
        if (bidItemToUpdate?.TaskCodeItems?.length) {
          bidItemToBeSaved = {
            ...bidItemToBeSaved,
            TaskCodeItems: bidItemToUpdate.TaskCodeItems
          }
        }
  
        bidItemsToBeSaved = [...bidItemsToBeSaved, bidItemToBeSaved]
       
      });
      
      const [saveBidItemError, saveBidItemResponse] = await this.bidItemsService.saveBidItemToDB(bidItemsToBeSaved);
      if (saveBidItemError !== null) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Saved Bid Items Error', detail: saveBidItemError, life: 5000 });
      }


      this.props.bidItemSaved({
        BidItemToBeSaved: bidItemsToBeSaved,
        JobNumber: this.props.currentJobNumber,
        JobDescription: this.props.currentJobDescription
      });
    }

    this.props.setCurrentBidItems();

    this.props.resetUnAssignedTaskCodesToBidItemsIds();

  }

  setBidItemsFilter(e: React.FormEvent<HTMLInputElement>) {
    let search = e.currentTarget.value;
    let selectedBidItems; 
    if (this.props.selectedPCs.length > 0) {
      selectedBidItems = [...JSON.parse(JSON.stringify(this.props.selectedPCs))];  
    } else {
      selectedBidItems = [...JSON.parse(JSON.stringify(this.props.selectedBidItems))];  
    }
    let excludedFields = ['GLDate', 'HasEditedFields', 'JobDescription', 'JobNumber', 'LastUpdated', 'TaskCodeItems']
    let isInSearch: boolean | undefined = false;

    let globalSearchBidItems = search
      ? selectedBidItems.filter((bidItem) => {
          for (let [key] of Object.entries(bidItem)) {
            if (excludedFields.includes(key)) continue

            isInSearch = typeof bidItem[key] === 'number'
              ? bidItem[key].toFixed(2).toString().includes(search)
              : bidItem[key]?.toString().toLowerCase().includes(search)

            if (isInSearch) break
          }

          return isInSearch
        })
      : [...selectedBidItems]

    this.setState({bidItemData: [...globalSearchBidItems]})
  }

  setGlobalSearchTaskCodes: any = (e: any) => {
    this.setState({ globalSearch: (e.target as HTMLTextAreaElement).value });
    this.setTaskCodesFilter((e.target as HTMLTextAreaElement).value.toLowerCase())
  }

  setTaskCodesFilter: any = (search: any) => {
    let globalSearchTaskCodes = [...JSON.parse(JSON.stringify(this.props.selectedTaskCodes))];
  
    this.setState({ taskCodeFiltered : [...JSON.parse(JSON.stringify(this.props.selectedTaskCodes))]});

    function trunc (x: any, positions = 0) {
      let s = x.toString()
      let decimalLength = s.indexOf('.') + 1
      let numStr = s.substr(0, decimalLength + positions)
      return Number(numStr)
    }
    
    globalSearchTaskCodes = this.state.taskCodeFiltered.filter((taskCode) => (
      trunc(taskCode?.BudgetTotalCost, 2).toString().includes(search)
      || trunc(taskCode?.BudgetUnitCost, 2).toString().includes(search)
      || (taskCode?.BudgetUnits).toString().includes(search)
      || trunc(taskCode?.FinalCost, 2).toString().includes(search) 
      || (taskCode?.FinalQuantity).toString().includes(search)
      || trunc(taskCode?.FinalUnitCost, 2).toString().includes(search)
      || trunc(taskCode?.JTDTotalCost, 2).toString().includes(search)
      || trunc(taskCode?.JTDUnitCost, 2).toString().includes(search)
      || trunc(taskCode?.JTDUnits, 2).toString().includes(search)
      || trunc(taskCode?.RemainingTotalCost, 2).toString().includes(search)
      || trunc(taskCode?.RemainingUnitCost, 2).toString().includes(search)
      || trunc(taskCode?.RemainingUnits, 2).toString().includes(search)
      || (taskCode?.TaskcodeDescription).toString().toLowerCase().includes(search)
      || (taskCode?.Taskcode).toString().toLowerCase().includes(search)
    ));
      
    this.setState({ taskCodesData : [...globalSearchTaskCodes]})
  }

  async resetTaskCodeUpdateUI(taskCode: TaskCodeStructureFromDB, originalBidItemId: string, assignedBidItemId: string | undefined) {
    if(originalBidItemId !== assignedBidItemId){
      this.props.updateTaskCodeDetails({
        JobNumber: this.props.currentJobNumber,
        taskCodeToBeSaved: taskCode
      });
    
      this.props.setCurrentBidItems();
      this.props.setCurrentTaskCodes();

      try {
        this.props.setLoadingExecutiveSummary({ setLoading: true });
      
        const [saveBidDetailsError] = await to(this.bidDetailsService.saveBidDetails(taskCode));
      
        if (saveBidDetailsError !== null) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
          throw new Error(saveBidDetailsError.message);
        } else {
          const _date = getMonthYearForExecutiveSummary();
          let updatedBidItems: any[] = this.state.bidItemData;
      
          const [updateExecutiveSummaryError] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber, parseInt(_date.month), parseInt(_date.year), this.props.setUsername));
      
          if (updateExecutiveSummaryError !== null) {
            this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: updateExecutiveSummaryError.message, life: 5000 });
            throw new Error(updateExecutiveSummaryError.message);
          } else {
            this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
          }
        }
      } catch (error) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: error, life: 5000 });
      } finally {
        this.props.setLoadingExecutiveSummary({ setLoading: false });
        this.setState({ loading: false });
      }
      


    }else{
      this.props.updateTaskCodeDetails({
        JobNumber: this.props.currentJobNumber,
        taskCodeToBeSaved: taskCode
      });
      
      await this.props.updateAssignedTaskCodes({
        taskCodesToBeUpdated: [taskCode],
        idToWhichWeAssignTaskCode: '',
        areWeAssigningTaskCodes: false,
        groupedBidItem: this.state.groupedBidItem
      });
      
  
      let originalBidItem = this.props.jobBidItems.find(bi => Number(bi.BidItem) === Number(originalBidItemId) && bi.ChgNumber === '000');
      let assignedBidItem: JobsMenuBidItemInterface | undefined;
      
      if (originalBidItemId !== assignedBidItemId) {
        assignedBidItem = this.props.jobBidItems.find(bi => Number(bi.BidItem) === Number(assignedBidItemId) && bi.ChgNumber === '000');
      }
  
      let preparedOriginalBidItem: BidItemToBeSaved;
      let preparedAssignedBidItem: BidItemToBeSaved;
      const bidItemsToUpdate: BidItemToBeSaved[] = []
      
      if (originalBidItem) {
        preparedOriginalBidItem = BidItemCalculations.prepareBidItemForDB(originalBidItem, this.props.currentJobNumber.toString(), this.props.currentJobDescription);
        bidItemsToUpdate.push({...preparedOriginalBidItem})
      }
  
  
      if (assignedBidItem) {
        preparedAssignedBidItem = BidItemCalculations.prepareBidItemForDB(assignedBidItem, this.props.currentJobNumber.toString(), this.props.currentJobDescription);
        bidItemsToUpdate.push({...preparedAssignedBidItem})
      }
      
      BidItemCalculations.calculateBidItemDetailsFinalCostField(bidItemsToUpdate);
      BidItemCalculations.setBidItemFinalRevenueHardcodeBidItems(bidItemsToUpdate);
      BidItemCalculations.setBidItemGainLossHardcodeBidItems(bidItemsToUpdate);
      
      if (bidItemsToUpdate.length) {
        this.props.bidItemSaved({
          BidItemToBeSaved: bidItemsToUpdate,
          JobNumber: this.props.currentJobNumber,
          JobDescription: this.props.currentJobDescription
        });
      }
      
      const taskCodeToBeSaved = bidItemsToUpdate[0]?.TaskCodeItems?.find(tc => tc.Taskcode === taskCode.Taskcode);
  
      this.props.setCurrentBidItems();
      this.props.setCurrentTaskCodes();
  
      if (taskCodeToBeSaved) {
        try {
          this.props.setLoadingExecutiveSummary({ setLoading: true });
        
          const [saveBidDetailsError] = await to(this.bidDetailsService.saveBidDetails(taskCodeToBeSaved));
        
          if (saveBidDetailsError !== null) {
            this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
            throw new Error(saveBidDetailsError.message);
          }
        
          const [saveBidItemError] = await to(this.bidItemsService.saveBidItemToDB(bidItemsToUpdate));
        
          if (saveBidItemError !== null) {
            this.toastTR?.current?.show({ severity: 'error', summary: 'Save Bid Item Error', detail: saveBidItemError.message, life: 5000 });
            throw new Error(saveBidItemError.message);
          } else {
        
            const _date = getMonthYearForExecutiveSummary;
        
            const [updateExecutiveSummaryError] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber, parseInt(_date().month), parseInt(_date().year), this.props.setUsername));
        
            if (updateExecutiveSummaryError !== null) {
              this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: updateExecutiveSummaryError.message, life: 5000 });
              this.props.setLoadingExecutiveSummary({ setLoading: false });
              throw new Error(updateExecutiveSummaryError.message);
            } else {
              this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
              this.props.setLoadingExecutiveSummary({ setLoading: false });
            }
          }
        } catch (error) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: error, life: 5000 });
        } finally {
          this.props.setLoadingExecutiveSummary({ setLoading: false });
          this.setState({ loading: false });
        }        
      };
    }
  }

  async setCheckFinalCost(taskCode: TaskCodeStructureFromDB){      
      this.props.updateTaskCodeDetails({
        JobNumber: this.props.currentJobNumber,
        taskCodeToBeSaved: taskCode
      });
      
      await this.props.updateAssignedTaskCodes({
        taskCodesToBeUpdated: [taskCode],
        idToWhichWeAssignTaskCode: '',
        areWeAssigningTaskCodes: false,
        groupedBidItem: this.state.groupedBidItem
      });
      
      let assignedBidItem: JobsMenuBidItemInterface | undefined;
      
      let preparedAssignedBidItem: BidItemToBeSaved;
      const bidItemsToUpdate: BidItemToBeSaved[] = []
  
      if (assignedBidItem) {
        preparedAssignedBidItem = BidItemCalculations.prepareBidItemForDB(assignedBidItem, this.props.currentJobNumber.toString(), this.props.currentJobDescription);
        bidItemsToUpdate.push({...preparedAssignedBidItem})
      }
      
      BidItemCalculations.calculateBidItemDetailsFinalCostField(bidItemsToUpdate);
      BidItemCalculations.setBidItemFinalRevenueHardcodeBidItems(bidItemsToUpdate);
      BidItemCalculations.setBidItemGainLossHardcodeBidItems(bidItemsToUpdate);
      if (bidItemsToUpdate.length) {
        this.props.bidItemSaved({
          BidItemToBeSaved: bidItemsToUpdate,
          JobNumber: this.props.currentJobNumber,
          JobDescription: this.props.currentJobDescription
        });
      }
      
      const taskCodeToBeSaved = bidItemsToUpdate[0]?.TaskCodeItems?.find(tc => tc.Taskcode === taskCode.Taskcode);
  
      this.props.setCurrentBidItems();
      this.props.setCurrentTaskCodes();
      if (taskCodeToBeSaved) {
        try {
          this.props.setLoadingExecutiveSummary({ setLoading: true });
          this.setState({ loading: true });
        
          const [saveBidDetailsError] = await to(this.bidDetailsService.saveBidDetails(taskCodeToBeSaved));
        
          if (saveBidDetailsError !== null) {
            this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
            throw new Error(saveBidDetailsError.message);
          }
        
          const [saveBidItemError] = await to(this.bidItemsService.saveBidItemToDB(bidItemsToUpdate));
        
          if (saveBidItemError !== null) {
            this.toastTR?.current?.show({ severity: 'error', summary: 'Save Bid Item Error', detail: saveBidItemError.message, life: 5000 });
            throw new Error(saveBidItemError.message);
          } else {
        
            const _date = getMonthYearForExecutiveSummary;
        
            const [updateExecutiveSummaryError] = await to(this.executiveSummaryService.updateExecutiveSummary(this.props.currentJobNumber, parseInt(_date().month), parseInt(_date().year), this.props.setUsername));
        
            if (updateExecutiveSummaryError !== null) {
              this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: updateExecutiveSummaryError.message, life: 5000 });
              throw new Error(updateExecutiveSummaryError.message);
            } else {
              this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
            }
          }
        } catch (error) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Executive Summary error', detail: error, life: 5000 });
        } finally {
          this.props.setLoadingExecutiveSummary({ setLoading: false });
          this.setState({ loading: false });
        }        
      };
  }

  render() {
    let assignableTaskCodesTitle: string = "Task Codes";
    let bidItemTableTitle: string = "Bid Item";
    let currentBidItem = this.state.bidItemData[0];

    if (currentBidItem) {
      assignableTaskCodesTitle =
        `${this.state.groupedBidItem ? "PC" + currentBidItem.ChgNumber : currentBidItem.BidItem + " " +
          currentBidItem.BidItemDescription
        }`;

      if (this.state.groupedBidItem) {
        bidItemTableTitle = this.state.bidItemData[0].ChgOrderDesc;
      }
    }
    return (
      <div>
        <Growl ref={this.toastTR} />
        <Messages ref={this.messages} />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-col-12">
              <BidItemsDetails                
                bidItemData={this.state.bidItemData}
                groupedBidItem={this.state.groupedBidItem}
                loading={this.state.loading}
                editor={this.editor}
                onEditorSubmit={this.onEditorSubmit}
                onEditorCancel={this.onEditorCancel}
                bidItemTableTitle={bidItemTableTitle}
                calculateFinalRevenue={this.state.calculateFinalRevenue}
                specialBidItem={this.state.specialBidItem}
                taskCodes={this.state.taskCodesData}
                globalSearchValue={this.setBidItemsFilter}
                username={this.props.setUsername}
              />

              <TaskCodesDetails
                assignableTaskCodesTitle={assignableTaskCodesTitle}
                assignedTaskCodesChanged={this.assignedTaskCodesChanged}
                editor={this.editor}
                globalSearchValue={this.setGlobalSearchTaskCodes}
                groupedBidItem={this.state.groupedBidItem}
                loading={this.state.loading}
                onEditorCancel={this.onEditorCancel}
                onEditorSubmit={this.onEditorSubmit}
                onResetTaskCode={this.resetTaskCodeUpdateUI}
                onCheckFinalCost={this.setCheckFinalCost}
                specialBidItem={this.state.specialBidItem}
                taskCodesData={this.state.taskCodesData}
                username={this.props.setUsername}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    currentBidItemNumber: state.app.currentBidItemNumber,
    currentJobNumber: state.app.currentJobNumber,
    currentJobDescription: state.app.currentJobDescription,
    currentChangeNumber: state.app.currentChangeNumber,
    selectedBidItems: state.app.selectedBidItems,
    selectedTaskCodes: state.app.selectedTaskCodes,
    selectedPCs: state.app.selectedPCs,
    unAssignedTaskCodesToBidItemsIds: state.app.unAssignedTaskCodesToBidItemsIds,
    jobBidItems: state.app.jobsMenuItems.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.BidItems || [],
    jobsMenuItems: state.app.jobsMenuItems,
    lastUpdated: state.app.lastUpdated,
    isJobDataLoading: state.app.isJobDataLoading,
    setUsername: state.app.setUsername,
    taskcodesWithFlagsFC: state.app.taskcodesWithFlagsFC,
    taskcodesWithoutBidItem : state.app.taskcodesWithoutBidItem,
    currentJobBidItems: state.app.jobsMenuItems?.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.BidItems || [],
    currentJobTaskCodes: state.app.jobsMenuItems?.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.TaskCodes || [],
    pendingCostAssigment : state.app.pendingCostAssigment,
  };
}

function matchDispatchToProps(dispatch: any) {
  return bindActionCreators({
    setCurrentBidItems: setCurrentBidItems,
    setCurrentTaskCodes: setCurrentTaskCodes,
    setCurrentPCs: setCurrentPCs,
    bidItemSaved: bidItemSaved,
    updateTaskCodeDetails: updateTaskCodeDetails,
    updatePCListDetails: updatePCListDetails,
    resetUnAssignedTaskCodesToBidItemsIds: resetUnAssignedTaskCodesToBidItemsIds,
    setLoadingExecutiveSummary: setLoadingExecutiveSummary,
    updateAssignedTaskCodes: updateAssignedTaskCodes,
    updatePCItem: updatePCItem,
    setTaskcodesWithFlagsFC : setTaskcodesWithFlagsFC,
    setTaskcodesWithoutBidItem : setTaskcodesWithoutBidItem,
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(BidDetails);
