import { apiGetCallPmFilterApi, apiPostCallPmFilterApi } from "./axiosRequest";
import { AxiosResponse } from "axios";

export class PmSelectedService {
    
    async postPmSelectedApi(username: string, data: any) {
        const [error, response] = await apiPostCallPmFilterApi({ username, data:data });
        return [error, response] as [Error | null, AxiosResponse<any> | undefined];
    }

    async getPmSelectedApi(username: string) {
        const [error, response] = await apiGetCallPmFilterApi({ username });
        return [error, response] as [Error | null, AxiosResponse<any> | undefined];
    }
  
}