export const sanitizeObjectStrings = <T>(object: any): T => {
  let elem = {...object}
  let regex = /(')|(\\)|(")/g;

  const replacer = (match: string): string => {
    switch (match) {
      case `"`:
        return `'`
      case `'`:
        return `''`;
      case '\\':
        return '';
      default:
        return '';
    }
  };

  let key: keyof typeof elem;

  for (key in elem) {
    if (typeof elem[key] === 'string') {      
      const sanitized = elem[key].replace(regex, replacer);      

      elem[key] = sanitized
    }
  }
  
  return elem
}