import to from "await-to-js";
import React, { FC, useCallback, useEffect } from "react";
import "./Menu.css";
import { NavLink, useHistory } from "react-router-dom";
import { jobMenuResponse } from "../../interfaces/Interfaces";
import { JobListService } from "../../service/JobListService";
import { useDispatch, useSelector } from "react-redux";
import { maintainAllLinkedTaskCodesToBidItems, setCurrentBidItemDisplay, setCurrentBidItemNumber, setCurrentChangeNumber, setCurrentJobNumberAndDescription, setIsJobDataLoading, setJobsMenuBidItemsAndTaskCodes, setJobsMenuNumberAndDescriptions, setLastBidItemNumber, setLastChgNumber, setLastUpdated } from "../../redux/appSlice";
import { useImmer } from "use-immer";
import { RootState } from "../../redux/store";
import dayjs from "dayjs";
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import useQueryParams from "../../utils/useQueryParams";
import { JobDataService } from "../../service/JobDataService";
import { PmSelectedService } from "../../service/pmSelectedService";
import AWS from "aws-sdk";

interface IMenu  {
	location: string;
	setItemMenu(itemMenu: MenuItem[]): void;
	breadCrumbItems: MenuItem[];
}

interface IState {
	areaSelectedOption: string,
	pmSelectedOption: string,
	searchedValue: string,
	filteredJobList: jobMenuResponse[],
	searchedJobList: jobMenuResponse[],
  	initialJobList: jobMenuResponse[],
	areaList: string[],
	pmList: string[],
	/**
	 * index, jobNumber and jobDescription reffers to the current item (the one that is selected)
	 */
	index: number | null,
	jobNumber: string | null,
	jobDescription: string | null
	currentDate: string,
	bidItemNr: string,
	username: string
}

export const Menu:FC<IMenu> = (props) => {
	const { jobNumber, jobDescription, bidItemsPage} = useQueryParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>();

	const [state, setState] = useImmer<IState>({
		areaSelectedOption: "",
		pmSelectedOption: "",
		searchedValue: "",
		filteredJobList: [],
		searchedJobList: [],
		initialJobList: [],
		areaList: [],
		pmList: [],
		index: null,
		jobDescription: "",
		jobNumber: "",
		currentDate: "",
		bidItemNr: "",
		username: ""
	});

	const currentJobNumberIndex = useSelector((state:RootState) => state.app.jobsMenuItems?.findIndex(ji => ji.JobNumber === state.app.currentJobNumber));
	const jobMenuItemDate = useSelector((state:RootState) => state.app.jobsMenuItems?.[currentJobNumberIndex]?.BidItems?.[0]?.GLDate);
	const accessToken = useSelector((state: RootState) => state.app.auth.token);
	const refreshRate = 900000;

	const dispatch = useDispatch();
	const history = useHistory();

	const getJobData = useCallback(async (jobNumber: string) => {
		if (!jobNumber) return;

		const fetchData = async () => {

			const [error, response] = await to(new JobDataService().getJobData(jobNumber));
			
			if (error !== null) {
				dispatch(setIsJobDataLoading(false));

				console.error(error)
				throw new Error(error.message);
			}

			if (response) {
				response[1]?.data?.TaskCodes?.forEach( (taskcode: { FinalCost: string,  FinalQuantity: string, FinalUnitCost: string, RemainingUnits: string, RemainingUnitCost: string, RemainingTotalCost: string, QtyAdjustment: string, BudgetUnits: string, RecoveryPercent: string}) => {
					taskcode.BudgetUnits = parseFloat(taskcode.BudgetUnits).toFixed(2)
        			taskcode.FinalQuantity = parseFloat(taskcode.FinalQuantity).toFixed(2)
        			taskcode.FinalUnitCost = parseFloat(taskcode.FinalUnitCost).toFixed(2)
        			taskcode.FinalCost = parseFloat(taskcode.FinalCost).toFixed(2)
        			taskcode.RemainingUnits = parseFloat(taskcode.RemainingUnits).toFixed(2)
        			taskcode.RemainingUnitCost = parseFloat(taskcode.RemainingUnitCost).toFixed(2)
        			taskcode.RemainingTotalCost = parseFloat(taskcode.RemainingTotalCost).toFixed(2)
					taskcode.RecoveryPercent = parseFloat(taskcode.RecoveryPercent).toFixed(2)
					taskcode.QtyAdjustment = parseFloat(taskcode.QtyAdjustment).toFixed(2)
				  })
				  response[1]?.data?.BidItems?.forEach( (biditem: { QtyAdjustment: string,  FinalRevenue: string, FinalQuantity: string}) => {
					biditem.QtyAdjustment = parseFloat(biditem?.QtyAdjustment).toFixed(2)
        			biditem.FinalRevenue = parseFloat(biditem?.FinalRevenue).toFixed(2)
        			biditem.FinalQuantity = parseFloat(biditem?.FinalQuantity).toFixed(2)
				  })
				const data = response[1]?.data
				dispatch(setJobsMenuBidItemsAndTaskCodes({
					data,
					JobNumber: jobNumber
				}));
				dispatch(maintainAllLinkedTaskCodesToBidItems());
				dispatch(setLastUpdated(new Date().toLocaleString('en-US', { timeZone: 'PST' })));
			}

			dispatch(setIsJobDataLoading(false));
		}
		await getUsername()
		await fetchData();
	}, [dispatch])

	const getUsername = () => {
		//Setting username for Submit flag
				let url: string = window.location.href;
				let region: string;
	
				if (url.includes('qa')) {
				  region = "us-west-1";
				} else {
				  region = "us-west-2"	
				}
		  if (accessToken) {
					AWS.config.update({ region: region });
					let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
					let params = {
						AccessToken: accessToken
					};
					cognitoidentityserviceprovider.getUser(params, (err, data) => {
						if (err) {
				console.error(err, err.stack);
						} else {
				setState((draft) => {
					draft.username = data.Username;
				});
				filterPmSelect(data.Username)
						}
				});
		}
	 }
	 
	const filterPmSelect = async (userName: string) => {
		const pmSelectedService = new PmSelectedService()

			const pmSelected = await pmSelectedService.getPmSelectedApi(userName)
				
			if(pmSelected[1]?.data?.data){
				if(pmSelected[1]?.data?.data === "0"){
					setState((draft) => {
						draft.pmSelectedOption = ""
					})
				}else{
					setState((draft) => {
						draft.pmSelectedOption = pmSelected[1]?.data?.data;
					})
				}
			}
		const usenameTarget = pmSelected[1]?.data?.data
		if(usenameTarget === "0"){
			onPMChangeFirstLogin("")
		}else{
			onPMChangeFirstLogin(usenameTarget)
		}
			
	}
	
	const getJobList = useCallback((isRefresh?: boolean) => {
		
		const fetchData = async () => {

			const [error, response] = await to(new JobListService().getJobList());


			if(error == null) {
				const _jobListSorted = response.data?.sort((a: any, b: any) => {
					// If JobNumber contains a non-number, put it last
					if (isNaN(a.JobNumber) || isNaN(b.JobNumber)) {
						return a.JobNumber > b.JobNumber ? 1 : -1
					} else {
						// Else, sort it asc
						return a.JobNumber - b.JobNumber
					}
				});

				let _amList: string[] = [];
				let _pmList: string[] = [];
				response.data?.forEach((item: jobMenuResponse) => {
					if((item?.Area != null && item?.Area !== "" ) && !_amList.includes(item.Area)) {
						_amList.push(item.Area);
					}

					if((item?.PM != null && item?.PM !== "") && !_pmList.includes(item.PM)) {
						_pmList.push(item.PM);
					}
				});

				_amList.sort((n, p) => n! > p! ? 1 : -1);
				_pmList.sort((n, p) => n! > p! ? 1 : -1);

				if(_jobListSorted != null) {
					setState((draft) => {
						draft.initialJobList = _jobListSorted;
						draft.filteredJobList = _jobListSorted;
						draft.searchedJobList = state.pmSelectedOption === "" ? _jobListSorted : state.searchedJobList;
						draft.areaList = state.pmSelectedOption === "" ? _amList : state.areaList;
						draft.pmList = _pmList;
					});
					if(jobNumber !== undefined){
						dispatch(setJobsMenuNumberAndDescriptions({
							jobsMenuItems: _jobListSorted,
							currentJobNumber: jobNumber
						}));
					}
				}

				if(state.searchedValue !== "") {
					// we didn't parse to an int or float number because the octal numbers doesn't let you seach with '0' in front
					// the user can get to a negative number by pressing arrow key -> if the string contains "-" you just block him
					if(state.searchedValue?.toString()?.length > 4 || state.searchedValue.includes("-")) {
						return;
					}
					else {
						const _initialJobList = state.initialJobList as jobMenuResponse[];
						setState((draft) => {
							draft.searchedValue = state.searchedValue.toString();
							draft.filteredJobList = _initialJobList;
						});
				 
						const result: jobMenuResponse[] = state.initialJobList
							?.filter((j) => j.JobNumber.toLocaleLowerCase().includes(state.searchedValue.toString())) as jobMenuResponse[];
						
						setState((draft) => {
							draft.filteredJobList = result;
						});
					}
				}

				if (isRefresh && jobNumber) {
					getJobData(jobNumber)
				}
			}
		}
		fetchData();
	}, [dispatch, setState, getJobData, jobNumber]);	

	useEffect(() => {
		getJobList();
		const refreshData = () => {
			getJobList(true);
		}

		const refreshDataInterval = setInterval(refreshData, refreshRate)

		return () => clearInterval(refreshDataInterval);

	}, [getJobList, getJobData, jobNumber]);

	useEffect(() => {
		// check if we have location in the url so that we know when to push items in to the array
		if(props.location != null && props.location !== "") {

			// check if the refresh happend from a jobNumber
			if(jobNumber !== undefined) {
				const jnIndex = state.initialJobList.findIndex((item) => item.JobNumber === jobNumber);

				// we set the job number to breadcrumb only if the user refreshed the page
				if(state.jobNumber !== jobNumber) {
					const _menuItem: MenuItem[] = [];

					_menuItem.push({
						label: `${jobNumber} - ${jobDescription}`,
						command: () => {
							dispatch(setCurrentBidItemDisplay(""));
							dispatch(setCurrentBidItemNumber("000"));
							dispatch(setCurrentChangeNumber({
								ChgNumber: "000",
								ChgNumberDesc: ""
							}));
							history.push(`/job?jobNumber=${jobNumber}&jobDescription=${jobDescription}&bidItemsPage=${bidItemsPage}`);
						}
					});
					// take job number from url and set it as the current job number, index and jobDesc
					setState((draft) => {
						draft.index = jnIndex;
						draft.jobNumber = jobNumber;
						draft.jobDescription = jobDescription;
					});
					props.setItemMenu(_menuItem);
				}
			}
		}
		else {
			// if we are on the root menu -> remove the items in breadcrumb
			const _menuItem: MenuItem[] = [];
			props.setItemMenu(_menuItem);
			// set redux to init 
			dispatch(setCurrentChangeNumber({
				ChgNumber: "000",
				ChgNumberDesc: ""
			}));
			dispatch(setCurrentBidItemNumber("000"));
			dispatch(setCurrentJobNumberAndDescription({
				JobNumber: "0",
				JobDescription: ""
			}))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.location, state.initialJobList, props.breadCrumbItems]);

	// update breadcrumb with current page
	useEffect(() => {
		if (props.location != null && props.location !== "") {
			const jnIndex = state.initialJobList.findIndex((item) => item.JobNumber === jobNumber);
			const _menuItem: MenuItem[] = [];
	
			_menuItem.push({
				label: `${jobNumber} - ${jobDescription}`,
				command: () => {
					dispatch(setCurrentBidItemDisplay(""));
					dispatch(setCurrentBidItemNumber("000"));
					dispatch(setCurrentChangeNumber({
						ChgNumber: "000",
						ChgNumberDesc: ""
					}));
					history.push(`/job?jobNumber=${jobNumber}&jobDescription=${jobDescription}&bidItemsPage=${bidItemsPage}`);
				}
			});
	
			setState((draft) => {
				draft.index = jnIndex;
				draft.jobNumber = jobNumber;
				draft.jobDescription = jobDescription;
			});
			props.setItemMenu(_menuItem);
			
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bidItemsPage])

	const filterAreaOrPm = async () => {
		setTimeout(async () => {
		const pmSelectedService = new PmSelectedService()
		if(state.username !== ""){
			const pmSelected = await pmSelectedService.getPmSelectedApi(state.username)
			
			if(pmSelected[1]?.data?.data){
				if(pmSelected[1]?.data?.data === "0"){
					setState((draft) => {
						draft.pmSelectedOption = ""
					})
			}else{
				setState((draft) => {
					draft.pmSelectedOption = pmSelected[1]?.data?.data;
				})
			}
			}
		}else{
			getUsername();
		}


		let result: jobMenuResponse[] = state.filteredJobList;
		if (state.areaSelectedOption !== "" && state.pmSelectedOption !== "" && state.username !== "") {
			result = result?.filter((item) => item.Area === state.areaSelectedOption && item.PM === state.pmSelectedOption) as jobMenuResponse[];
		}

		if (state.areaSelectedOption === "" && state.pmSelectedOption !== "" && state.username !== "") {
			result = result?.filter((item) => item.PM === state.pmSelectedOption) as jobMenuResponse[];
		}

		if (state.areaSelectedOption !== "" && state.pmSelectedOption === "" && state.username !== "") {
			result = result?.filter((item) => item.Area === state.areaSelectedOption) as jobMenuResponse[];
		}
		if (state.pmSelectedOption !== "" && state.username !== "") {
			result = result?.filter((item) => item.PM === state.pmSelectedOption) as jobMenuResponse[];
		}

		if((state.username !== "" && state.pmSelectedOption !== "") || (state.searchedJobList.length !== state.initialJobList.length)){
			setState((draft) => {
				draft.searchedJobList = result;
			})
		}
		}, 2000);
	}

	useEffect(() => {
		setState((draft) => {
			draft.currentDate =  jobMenuItemDate != null ? dayjs(new Date(jobMenuItemDate).toLocaleString('en-US', { timeZone: 'UTC' })).format("MMMM YYYY") : "";
		});
		filterAreaOrPm()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobMenuItemDate, currentJobNumberIndex]);

	useEffect(() => {
		let result: jobMenuResponse[] = state.filteredJobList;

		if (state.areaSelectedOption !== "" && state.pmSelectedOption !== "") {
			result = result?.filter((item) => item.Area === state.areaSelectedOption && item.PM === state.pmSelectedOption) as jobMenuResponse[];
		}

		if (state.areaSelectedOption === "" && state.pmSelectedOption !== "") {
			result = result?.filter((item) => item.PM === state.pmSelectedOption) as jobMenuResponse[];
		}

		if (state.areaSelectedOption !== "" && state.pmSelectedOption === "") {
			result = result?.filter((item) => item.Area === state.areaSelectedOption) as jobMenuResponse[];
		}
		if((state.username !== "" && state.pmSelectedOption !== "") || (state.searchedJobList.length !== state.initialJobList.length) || (state.searchedValue !== "")){
			setState((draft) => {
				draft.searchedJobList = result;
			});
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.pmSelectedOption, state.areaSelectedOption, state.searchedValue]);


	const onLogout = () => {
		history.push({
			pathname: '/logout'
		})
	};

	const onMenuClick = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>, job:jobMenuResponse) => {
		// get index from initial state not from the filtered one
    
		const index = state.initialJobList.findIndex((item) => item.JobNumber === job.JobNumber);
		let result: jobMenuResponse[] = state.filteredJobList;
		if(index === state.index) {
			e.preventDefault();
		}
		else {
			const _menuItem: MenuItem[] = [];
			dispatch(setLastBidItemNumber("000"));
			dispatch(setLastChgNumber("000"));
			history.push(`/job?jobNumber=${job.JobNumber}&jobDescription=${job.JobDescription}&bidItemsPage=${bidItemsPage ?? 1}`);
			
			const newFilters = {
				Changes: false,
				RemCost: false,
				MTDCost: false,
				JTDCost: false,
			  };
		  
			localStorage.setItem('filters', JSON.stringify(newFilters))

			dispatch(setIsJobDataLoading(true));

			_menuItem.push({
				label: `${job.JobNumber} - ${job.JobDescription}`,
				command: () => {
					dispatch(setCurrentBidItemDisplay(""));
					history.push(`/job?jobNumber=${job.JobNumber}&jobDescription=${job.JobDescription}&bidItemsPage=${bidItemsPage ?? 1}`);
					dispatch(setCurrentBidItemNumber("000"));
					dispatch(setCurrentChangeNumber({
						ChgNumber: "000",
						ChgNumberDesc: ""
					}));
					// remove selected bid item from breadcrumb
					if(_menuItem[1] != null) {
						_menuItem.splice(1, 1);
					}
				}
			});
			// set in redux current job number and desc
			dispatch(setCurrentJobNumberAndDescription({
				JobNumber: job.JobNumber,
				JobDescription: job.JobDescription
			}));

			setState((draft) => {
				draft.index = index;
				draft.jobDescription = job.JobDescription;
				draft.jobNumber = job.JobNumber;
			});

			setState((draft) => {
				draft.filteredJobList = result;
			});

			props.setItemMenu(_menuItem);
		}
	}

	const handleSearch = (value: string) => {
		if(value != null || value !== "") {
			// we didn't parse to an int or float number because the octal numbers doesn't let you seach with '0' in front
			// the user can get to a negative number by pressing arrow key -> if the string contains "-" you just block him
			if(value?.toString()?.length > 4 || value.includes("-")) {
				return;
			}
			else {
				const _initialJobList = state.initialJobList as jobMenuResponse[];
				setState((draft) => {
					draft.searchedValue = value.toString();
					draft.filteredJobList = _initialJobList;
				});
		 
				const result: jobMenuResponse[] = state.initialJobList
					?.filter((j) => j.JobNumber.toLocaleLowerCase().includes(value.toString())) as jobMenuResponse[];
				
				setState((draft) => {
					draft.filteredJobList = result;
				});
			}
		}
	}

	const onAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const _eValue = e.target.value;

		let pms: string[] = [];

		if (_eValue !== null && _eValue !== "") {
			pms = state.initialJobList.filter(jobItem => jobItem.Area === _eValue)
				.map(pm => pm.PM)
				.filter((v, i, a) => a.indexOf(v) === i)
				.sort((n, p) => n! > p! ? 1 : -1);
		} else {
			pms = state.initialJobList.map(pm => pm.PM)
				.filter((v, i, a) => a.indexOf(v) === i)
				.sort((n, p) => n! > p! ? 1 : -1);
		}

		setState(draft => {
		  draft.areaSelectedOption = _eValue;

			if (pms.length > 0) {
				draft.pmList = pms;
			}

			if (!pms.includes(draft.pmSelectedOption)) {
				draft.pmSelectedOption = "";
			}
		})
	}

	const onPMChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const _eValue = e.target.value;
		let areas: string[] = [];
		if(_eValue != null && _eValue !== "" ) {
			// take all the areas that have project manager the one that is selected
			areas = state.initialJobList.filter(jl => jl.PM === _eValue)
				.map(a => a.Area)
				.filter((v, i, a) => a.indexOf(v) === i)
				.sort((n, p) => n! > p! ? 1 : -1);
		} else {
			// if the user has cleared the PM dropdown -> set the initial list of areas
			areas = state.initialJobList.map(a => a.Area)
				.filter((v, i, a) => a.indexOf(v) === i)
				.sort((n, p) => n! > p! ? 1 : -1);
		}

		setState(draft => {
			draft.pmSelectedOption = _eValue;

			if(areas.length > 0) {
				draft.areaList = areas;
			}

			// if the user selects first the Area and then PM, and the areaSelectedOption is not included in the areas, we reset the areaSelectedOption
			// --- we filter areas by the ones that have PM the pmSelectedOption ---
			if(!areas.includes(draft.areaSelectedOption)) {
				draft.areaSelectedOption = "";
			}
		});
		
		const pmSelectedService = new PmSelectedService()
		if(state.username !== ""){
			if(_eValue === ""){
				pmSelectedService.postPmSelectedApi(state.username, "0")
		   }else{
			   pmSelectedService.postPmSelectedApi(state.username, _eValue)
		   }
		}
		
	}

	const onPMChangeFirstLogin = (e: any) => {
		const _eValue = e;
		let areas: string[] = [];
		if(_eValue != null && _eValue !== "" ) {
			// take all the areas that have project manager the one that is selected
			areas = state.initialJobList.filter(jl => jl.PM === _eValue)
				.map(a => a.Area)
				.filter((v, i, a) => a.indexOf(v) === i)
				.sort((n, p) => n! > p! ? 1 : -1);
		} else {
			// if the user has cleared the PM dropdown -> set the initial list of areas
			areas = state.initialJobList.map(a => a.Area)
				.filter((v, i, a) => a.indexOf(v) === i)
				.sort((n, p) => n! > p! ? 1 : -1);
		}

		setState(draft => {
			draft.pmSelectedOption = _eValue;

			if(areas.length > 0) {
				draft.areaList = areas;
			}

			// if the user selects first the Area and then PM, and the areaSelectedOption is not included in the areas, we reset the areaSelectedOption
			// --- we filter areas by the ones that have PM the pmSelectedOption ---
			if(!areas.includes(draft.areaSelectedOption)) {
				draft.areaSelectedOption = "";
			}
		});
		
		const pmSelectedService = new PmSelectedService()
		if(state.username !== ""){
			if(_eValue === ""){
				pmSelectedService.postPmSelectedApi(state.username, "0")
		   }else{
			   pmSelectedService.postPmSelectedApi(state.username, _eValue)
		   }
		}
		
	}
	 
	return (
		<div className="Menu">
			{
				state.currentDate != null && state.currentDate !== ""
				? <div className="CurrentMonth"><i className="pi pi-calendar"></i> {state.currentDate}</div>
				: null
			}
			<div className="SortFilterBy">
				<input
					className="SearchInput"
					value={state.searchedValue}
					placeholder="Search by #job"
					type="number"
					pattern="[0-9]+"
					onChange={(e) => handleSearch(e.target.value)}
					disabled={state.initialJobList?.length > 0 ? false : true}
				/>
				<select
					className="dropdown"
					value={(state.pmSelectedOption as string === " " || state.pmSelectedOption == null) ? "" : state.pmSelectedOption}
					onChange={(e) => onPMChange(e)}
				>
					<option value="" disabled={state.pmList?.length > 0 ? false : true}>PM</option>
					{
						state.pmList.map((item, index) => (
							<option value={item as string} key={index}>{item}</option>
						))
					}
				</select>
				<select
					className="dropdown"
					value={state.areaSelectedOption as string === " " || state.areaSelectedOption == null ? "" : state.areaSelectedOption}
					onChange={(e) => onAreaChange(e)}
				>
					<option value="" disabled={state.areaList?.length > 0 ? false : true}>Area</option>
					{
						state.areaList.map((item, index) => (
							<option value={item as string} key={index}>{item}</option>
						))
					}
				</select>
			</div>
			<div className="JobList">
				{
					state.searchedJobList?.length === 0 
					? <div className="LoadingMessage">
						{state.initialJobList?.length > 0 && state.searchedJobList?.length === 0  ? "No results" : <ProgressSpinner/>}
						</div>
					: state.searchedJobList?.map((job, index) => {
						return (
							<NavLink
								key={index}
								activeClassName={state.searchedJobList.findIndex(sjl => sjl.JobNumber === state.jobNumber) === index ? "active-menuitem-routerlink" : ""}
								to={`/job?jobNumber=${job.JobNumber}&jobDescription=${job.JobDescription}&bidItemsPage=1`}
								role="menuitem"
								onClick={(e) => onMenuClick(e, job)}
								exact
								onMouseEnter={(e) => {}}
								className={state.searchedJobList.findIndex(sjl => sjl.JobNumber === state.jobNumber) === index ? "menuitem active-menuitem-routerlink" : "menuitem"}
							>
								<i className="material-icons">subject</i>
								<div>{job.JobNumber} - {job.JobDescription}</div>
							</NavLink>
						)
					}
					)
				}
			</div>
			<a className="Logout" rel="noopener noreferrer">
				<Button type="button" label="Logout" icon="pi-md-send" onClick={onLogout}></Button>
			</a>
		</div>
	)
}