import * as React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import AWS from "aws-sdk";
import { AxiosError } from 'axios';
import { RootState } from "../redux/store";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Growl } from 'primereact/growl';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import {
  setCurrentBidItemNumber,
  setCurrentJobNumberAndDescription,
  setCurrentChangeNumber,
  setCurrentBidItemDisplay,
  setJobsMenuBidItemsAndTaskCodes,
  bidItemSaved,
  maintainAllLinkedTaskCodesToBidItems,
  setChangeJobInProgress,
  setExecutiveSummary,
  setCurrentBidItems,
  setResetJobInProgress,
  setLoadingExecutiveSummary,
  setPrevGLandDeltaCCOs,
  setUsername,
  setTaskcodesWithoutBidItem,
  setTaskcodesWithFlagsFC,
  setJobsMenuNumberAndDescriptions,
  setLastBidItemNumber,
  setLastChgNumber,
  setHasDisplayedFinalCostMessage,
} from "../redux/appSlice";
import { BidItemInterface, BidItemsProps, BidItemsState, BidItemToBeSaved, jobMenuResponse, JobsMenuTaskCodeInterface } from "../interfaces/Interfaces";
import { bidItemDictionary, bidItemDictionaryDefault } from "../utils/dictionaries";
import { BidItemsService } from "../service/BidItemsService";
import { JobDataService } from "../service/JobDataService";
import { ExecutiveSummaryService } from "../service/ExecutiveSummaryService";
import { displayTwoDigits, numbersAndDotOnly, parseFloatRemoveComma, toLocaleStringUS } from "../utils/convert";
import * as BidItemCalculations from "../utils/bidItemFieldsCalculations";
import { getMonthYearForExecutiveSummary } from '../utils/getMonthYearForExecutiveSummary';
import { getParams } from "../utils/useQueryParams";
import { hardcodedBidItems, hardcodedBidItemsRCRP } from "../utils/hardcodedBidItems";
import { exportBidItemsAndTaskCodes } from "../utils/excelSheetsExport";
import 'primeicons/primeicons.css';
import '../assets/sass/BidItems.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import { JobListService } from '../service/JobListService';
import to from 'await-to-js';
import EditColumns from './EditColumns/EditColumns';
import { FilterService } from '../service/filterService';
import { date, reorderObject, taskCodesBidItemFor888 } from '../utils/utils';
import { createRef } from 'react';
import ConfirmationDialogFinalCost from './ConfirmationDialogFinalCost/ConfirmationDialogFinalCost';
import * as TaskCodeCalculations from "../utils/taskCodeFieldsCalculations";
import { BidDetailsService } from '../service/BidDetailsService';

class BidItems extends React.Component<BidItemsProps, BidItemsState> {
  private mountedComponent: boolean | undefined;
  private bidItemsService: BidItemsService;
  private jobDataService: JobDataService;
  private executiveSummaryService: ExecutiveSummaryService;
  private toastTR: React.RefObject<Growl> = React.createRef();
  private bidDetailsService: BidDetailsService;
  private handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.state.inputInitialValue === '') {
      this.setState({ inputInitialValue: event.target.value });
    }
    event.target.select()
  };

  bidItemStructure: any;
  bidItemStructureToCompare: any;
  private IsThisPCItem = (rowData: any) => {
    if (rowData.ChgNumber !== "000") {
      return true
    }
  };
  private IsThisHardcodedBidItem = (rowData: any) => {
    const _hardcodedBidItems = hardcodedBidItems.map(bid => bid.BidItem);
    return _hardcodedBidItems.includes(rowData.BidItem);
  };
  private IsThisHardcodedBidItemAllowFinalRevenueEdit = (rowData: any) => {
    // Return the BidItems numbers which DON@T have a numeric value on Final Revenue field (it means that the user is NOT able to edit them). Current: 888, 950
    return hardcodedBidItemsRCRP.includes(rowData.BidItem);
  };

  private rowClass = (rowData: any) => {
    return {
      'background-color-pink': this.IsThisPCItem(rowData)
    }
  };
  private dataTableWrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: BidItemsProps) {
    super(props);
    const params = getParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>(this.props.location);
    const pageFromUrl = params.bidItemsPage;

    this.state = {
      bidItemData: [],
      loading: false,
      changeDetected: false,
      globalFilter: null,
      bidItemFiltered: [],
      globalSearch: '',
      /**
       *  on pagination, the rows prop sets how many items we will have on the page, first1 is the item index from where it starts to show on the current page
       *  if we are on the second page, the index of the first item will pe 20, because on the first page we show from item 0 to 19, and so on
       */
      pagination: {
        first1: (parseInt(pageFromUrl) != null && parseInt(pageFromUrl) > 1) ? ((parseInt(pageFromUrl) - 1) * 20) : 0,
        rows: 20,
        currentPage: parseInt(pageFromUrl) != null ? parseInt(pageFromUrl) : 1,
      },
      paginationHistory: null,
      isSavedBidItem : false,
      inputInitialValue: '',
      isFiltered: false,
      filters: {
        RemCost: false,
        Changes: false,
        JTDCost: false,
        MTDCost: false,
      },
      username: '',
      taskCodesWarning: [],
      confirmationDialogOnConfirm: undefined,
      isEditColumnsVisible: false,
      itemCount: 0,
      isJobHistoryVisible: false,
      jobDataHistoryData: [],
      bidItemStructure: bidItemDictionaryDefault,
      scrollHeight: '40vh',
      displayCalendar: 'none',
      tasksWithFinalCostOverwritten: [],
      confirmationDialogVisibleFinalCost: false, 
      confirmationDialogMessageFinalCost: ""
    };

    this.dataTableWrapperRef = createRef();
    this.bidItemsService = new BidItemsService();
    this.jobDataService = new JobDataService();
    this.executiveSummaryService = new ExecutiveSummaryService();
    this.bidDetailsService = new BidDetailsService();

    this.bidItemStructureToCompare = bidItemDictionary;
    this.actionTemplate = this.actionTemplate.bind(this);
    this.onEditorSubmit = this.onEditorSubmit.bind(this);
    this.handleRemCostFilter = this.handleRemCostFilter.bind(this);
    this.handleDeltaFilter = this.handleDeltaFilter.bind(this);
    this.handleJTDCostFilter = this.handleJTDCostFilter.bind(this);
    this.handleMTDCostFilter = this.handleMTDCostFilter.bind(this);
    this.updateScrollHeight = this.updateScrollHeight.bind(this);
    this.confirmationDialogOnConfirmFinalCost = this.confirmationDialogOnConfirmFinalCost.bind(this)
    this.confirmationDialogOnCancelFinalCost = this.confirmationDialogOnCancelFinalCost.bind(this)
  }

  componentDidMount() {
    this.mountedComponent = true;
    this.setJobNumberAndJobDescription();
    window.scrollTo(0, 0);
    const storedFilters = localStorage.getItem('filters');
    if (storedFilters) {
      this.setState({ filters: JSON.parse(storedFilters) });
    }
    this.disableSpecificMonths();
    this.updateScrollHeight();
    this.scrollToFirstChild()
  }

  componentWillUnmount(): void {
    this.mountedComponent = false;
    window.removeEventListener('resize', this.updateScrollHeight);
  }

  componentDidUpdate(prevProps: BidItemsProps, prevState: BidItemsState) {
    if (prevProps.location.search !== this.props.location.search || (this.props.resetJobInProgress && !this.props.currentJobBidItems.length)) {
      this.setJobNumberAndJobDescription();
      this.scrollToFirstChild()
      setTimeout(() => this.scrollToFirstChild(), 0);
      window.scrollTo(0, 0);
    }

    if ((prevProps.resetJobInProgress !== this.props.resetJobInProgress) || (this.props.resetJobInProgress && !this.state.loading)) {
      this.setState({loading: true});
    }

    if (!prevProps.currentJobBidItems.length && this.props.currentJobBidItems.length) {
      this.setInitialValues()
      this.updateExecutiveSummaryNewData(this.props.currentJobTaskCodes)
    }

    if(this.props.location !== prevProps.location) {
      const params = getParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>(this.props.location);

      if(parseInt(params.bidItemsPage) !== this.state.pagination.currentPage) {
        this.updatePagination(parseInt(params.bidItemsPage));
      }
    }

    if (this.state.isSavedBidItem) {
      this.setInitialValues();
      this.setState({ isSavedBidItem: false });
    }

    if (!Object.is(prevState.filters, this.state.filters) || prevState.globalSearch !== this.state.globalSearch) {
      let isFiltered = false
      let filteredData = this.state.bidItemData

      for (const [key, value] of Object.entries(this.state.filters)) {
        if (value && filteredData.length) {
          isFiltered = true

          filteredData = this.filterZeroValuesColumns(filteredData, key)
        }
      }

      if ((isFiltered || this.state.globalSearch) && !this.state.paginationHistory) {
        this.setState({
          paginationHistory: this.state.pagination
        })
      }

      if (isFiltered) {
        this.setState({
          bidItemFiltered: filteredData,
          isFiltered: true
        })
      } else {
        this.setState({
          isFiltered: false
        })
      }

      this.state.globalSearch && this.setGlobalSearchBidItems(this.state.globalSearch, filteredData)

      if (!isFiltered && !this.state.globalSearch && this.state.paginationHistory) {
        const pagination = {
          first: this.state.paginationHistory.first1,
          rows: this.state.paginationHistory.rows,
          page: this.state.paginationHistory.currentPage,
        }
        this.setState({
          paginationHistory: null
        })
      }
    }

    if (prevProps.jobNumber !== this.props.jobNumber) {
      this.setState({
        globalSearch: '',
        bidItemFiltered: [],
        isFiltered: false,
        filters: {
          RemCost: false,
        Changes: false,
        JTDCost: false,
        MTDCost: false,
        }
      });
    }

    if (prevProps.lastUpdated !== this.props.lastUpdated) {
      this.setInitialValues();
      
    }

    if (prevProps.isJobDataLoading !== this.props.isJobDataLoading) {
      this.setState({ loading: this.props.isJobDataLoading });
    }
    this.disableSpecificMonths();

    if (this.dataTableWrapperRef.current && prevState.pagination.first1 !== this.state.pagination.first1) {
      this.dataTableWrapperRef.current.scrollTop = 0;
    }
    if (prevProps.jobNumber !== this.props.jobNumber) {
      this.props.setHasDisplayedFinalCostMessage(false);
    }

    if(prevProps.lastBidItemNumber === "000" && prevProps.lastChgNumber === "Original" ){
      this.props.setHasDisplayedFinalCostMessage(true);
    }

    if (!this.props.hasDisplayedFinalCostMessage) {
      const { currentJobTaskCodes } = this.props;
      
        let taskCodesFinalCostString: string[] = [];
        const tasksWithFinalCostOverwritten = currentJobTaskCodes.filter(task => task.FinalCostOverwritten === 1);
      
      if (tasksWithFinalCostOverwritten.length > 0) {
          this.setState({ tasksWithFinalCostOverwritten: tasksWithFinalCostOverwritten });
          this.setState({ confirmationDialogVisibleFinalCost: true });
          tasksWithFinalCostOverwritten.forEach(taskcode => { taskCodesFinalCostString.push(taskcode.Taskcode) });
          
          let taskcodeString = taskCodesFinalCostString.join(', ');
          this.setState({ confirmationDialogMessageFinalCost: taskcodeString });
          this.props.setHasDisplayedFinalCostMessage(true);
        }
  }
      
  }

  updateScrollHeight() {
    const headerElement = document.querySelector('.p-datatable-header') as HTMLElement | null;
    const headerHeight = headerElement ? headerElement.offsetHeight : 0;
    const availableHeight = (window.innerHeight - headerHeight) - 330;
    this.setState({ scrollHeight: `${availableHeight}px` });
  }
  
  handleKeyDown = (e:any) => {
    if (e.key === 'Escape') {
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 1000,
        clientY: 1000,
      });

      document.dispatchEvent(clickEvent);
      document.dispatchEvent(clickEvent);
    }
  }

  filterZeroValuesColumns(bidItems: BidItemToBeSaved[], column: string) {
    const filteredItems = bidItems.filter((bi: any) => Number(parseFloatRemoveComma(bi[column]).toFixed(2)) !== 0)

    return filteredItems;
  }

  updatePagination(bidItemsPage: number) {
    this.setState({
      pagination: {
        rows: 20,
        first1: (bidItemsPage != null && bidItemsPage > 1) ? ((bidItemsPage - 1) * 20) : 0,
        currentPage: bidItemsPage
      }
    });
  }

  setJobNumberAndJobDescription() {
    if (this.mountedComponent) {
      let jobNumber: string;
      let jobDescription: string;

      const params = getParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>(this.props.location);

      if (this.props.jobNumber !== "0") {
        jobNumber = this.props.jobNumber;
      } else {
        // the user copy/pasted the URL or changed the jobNumber from URL
        jobNumber = params.jobNumber;
      }

      if (this.props.jobDescription) {
        jobDescription = this.props.jobDescription
      } else {
        jobDescription = params.jobDescription;
      }

      if (!this.props.jobNumber || !this.props.jobDescription) {
        if(jobNumber !== undefined){
          this.props.setCurrentJobNumberAndDescription({
            JobNumber: jobNumber,
            JobDescription: jobDescription
          });
        }
        if(jobNumber !== undefined){
          this.props.setCurrentJobNumberAndDescription({
            JobNumber: jobNumber,
            JobDescription: jobDescription
          });
        }
        
      }
      if (!this.props.currentJobBidItems.length || (this.props.resetJobInProgress && !this.props.currentJobBidItems.length)) {
        this.getBidItemsFromServer(jobNumber).then(() => {
          this.setInitialValues();
        });
      } else {
        this.setInitialValues();
      }
    }
  }

  checkTaskcodesWithoutBidItem(data: any){
    let bidItemNumber = []
    let taskCodesNotIncluded = []
    let taskCodesIncluded = []

    for(let i = 0; i < data.BidItems.length; i++){
      bidItemNumber.push(data.BidItems[i].BidItem)
    }
    for(let a = 0; a < data.TaskCodes.length; a++){
      const hardcodedBidItems = [777, 830, 850, 860, 861, 870, 880, 888, 890, 920, 940, 941, 944, 950, 960, 999]
      if(!bidItemNumber.includes(data.TaskCodes[a].BidItem) && !hardcodedBidItems.includes(parseInt(data.TaskCodes[a].BidItem))){
        taskCodesNotIncluded.push(data.TaskCodes[a])
      }else{
        taskCodesIncluded.push(data.TaskCodes[a])
      }
    }
    this.setFlagFinalCost(taskCodesIncluded)
    this.props.setTaskcodesWithoutBidItem(taskCodesNotIncluded)
  }

  setFlagFinalCost(dataTaskcodesincluded: any){
    
    const taskCode = [...JSON.parse(JSON.stringify(dataTaskcodesincluded))]
    const taskCodesFlags = []
    
    
    for(let i = 0; i < taskCode.length; i++){
      let JTDTotalCost = parseInt(parseFloatRemoveComma(taskCode[i].JTDTotalCost).toFixed(2));
      let FinalCost = parseInt(parseFloatRemoveComma(taskCode[i].FinalCost).toFixed(2));
      let BudgetCost = parseInt(parseFloatRemoveComma(taskCode[i].BudgetCost).toFixed(2));
      let sumCCOs = 0;
      let finalCostCCO = 0;


    if (JTDTotalCost > FinalCost && BudgetCost !== FinalCost) {
      if(taskCode[i].CCOs != null){
        if(taskCode[i].CCOs.length > 0){
          for(let a = 0; a < taskCode.length; a++){
            if(taskCode[i].CCOs[a]?.AssignedCost){
              sumCCOs += parseInt(taskCode[i].CCOs[a].AssignedCost)
            }
          }
          if(parseFloatRemoveComma(taskCode[i].FinalQuantity) > 0){
            finalCostCCO = parseFloatRemoveComma(taskCode[i].FinalQuantity) * parseFloatRemoveComma(taskCode[i].FinalUnitCost);
          }else{
            finalCostCCO = parseFloatRemoveComma(taskCode[i].RemainingTotalCost) + parseFloatRemoveComma(taskCode[i].JTDTotalCost);
          }

          if(JTDTotalCost > finalCostCCO){
            taskCodesFlags.push(taskCode[i])
          }
      }
      }else{
        taskCodesFlags.push(taskCode[i]) 
      }
      }
    }
    this.props.setTaskcodesWithFlagsFC(taskCodesFlags)
  }

  async getBidItemsFromServer(jobNumber: string) {
    if(jobNumber == null) return;
    
    const [errorJobList, responseJobList] = await to(new JobListService().getJobList());
    if(errorJobList == null) {
      const _jobListSorted = responseJobList.data?.sort((a: any, b: any) => {
        // If JobNumber contains a non-number, put it last
        if (isNaN(a.JobNumber) || isNaN(b.JobNumber)) {
          return a.JobNumber > b.JobNumber ? 1 : -1
        } else {
          // Else, sort it asc
          return a.JobNumber - b.JobNumber
        }
      });

      let _amList: string[] = [];
      let _pmList: string[] = [];
      responseJobList.data?.forEach((item: jobMenuResponse) => {
        if((item?.Area != null && item?.Area !== "" ) && !_amList.includes(item.Area)) {
          _amList.push(item.Area);
        }

        if((item?.PM != null && item?.PM !== "") && !_pmList.includes(item.PM)) {
          _pmList.push(item.PM);
        }
      });

      _amList.sort((n, p) => n! > p! ? 1 : -1);
      _pmList.sort((n, p) => n! > p! ? 1 : -1);

      if(_jobListSorted != null) {
        if(jobNumber !== undefined){
          this.props.setJobsMenuNumberAndDescriptions({
            jobsMenuItems: _jobListSorted,
            currentJobNumber: jobNumber
          });
        }
      }
    }

    this.props.setResetJobInProgress({resetJobInProgress: false});
    
    this.setState({ loading: true });
    this.props.setChangeJobInProgress({changeJobInProgress: true});

    const [error, response] = await this.jobDataService.getJobData(jobNumber);

    if (error != null) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Bid Items Error', detail: (error as AxiosError).response?.data.message?.toString(), life: 5000 });
    }
    if (response != null && this.mountedComponent) {
      response?.data?.TaskCodes?.forEach( (taskcode: { FinalCost: string,  FinalQuantity: string, FinalUnitCost: string, RemainingUnits: string, RemainingUnitCost: string, RemainingTotalCost: string, QtyAdjustment: string, BudgetUnits: string, RecoveryPercent: string}) => {
        taskcode.BudgetUnits = parseFloat(taskcode.BudgetUnits).toString()
        taskcode.FinalQuantity = parseFloat(taskcode.FinalQuantity).toString()
        taskcode.FinalUnitCost = parseFloat(taskcode.FinalUnitCost).toString()
        taskcode.FinalCost = parseFloat(taskcode.FinalCost).toString()
        taskcode.RemainingUnits = parseFloat(taskcode.RemainingUnits).toString()
        taskcode.RemainingUnitCost = parseFloat(taskcode.RemainingUnitCost).toString()
        taskcode.RemainingTotalCost = parseFloat(taskcode.RemainingTotalCost).toString()
        taskcode.RecoveryPercent = parseFloat(taskcode.RecoveryPercent).toString()
        taskcode.QtyAdjustment = parseFloat(taskcode.QtyAdjustment).toString()
      })
      response?.data?.BidItems?.forEach( (biditem: { QtyAdjustment: string,  FinalRevenue: string, FinalQuantity: string}) => {
        biditem.QtyAdjustment = parseFloat(biditem?.QtyAdjustment).toString()
        biditem.FinalRevenue = parseFloat(biditem?.FinalRevenue).toString()
        biditem.FinalQuantity = parseFloat(biditem?.FinalQuantity).toString()
      })
      this.props.setJobsMenuBidItemsAndTaskCodes({
        data: response.data,
        JobNumber: jobNumber
      });
      this.props.maintainAllLinkedTaskCodesToBidItems();

      this.checkTaskcodesWithoutBidItem(response.data)
    }

    this.setState({ loading: false });
    this.props.setChangeJobInProgress({ changeJobInProgress: false });
   
  }

  async setInitialValues() {
    
    const params = getParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>(this.props.location);
    if(params.jobNumber !== this.props.jobNumber){
      this.getBidItemsFromServer(this.props.jobNumber)
    }
    this.props.setCurrentBidItemNumber(0);
    this.props.setCurrentBidItemDisplay('');
    this.props.setCurrentChangeNumber({
      ChgNumber: '',
      ChgNumberDesc: ''
    });
    this.props.setCurrentBidItems();
    let updatedBidItems = [...JSON.parse(JSON.stringify(this.props.currentJobBidItems))];
    let taskCodes = [...JSON.parse(JSON.stringify(this.props.currentJobTaskCodes))];
    BidItemCalculations.setBidItemFinalCostCCOs(updatedBidItems, taskCodes);
    BidItemCalculations.setBidItemInitialValues(updatedBidItems, taskCodes, true);
    
    for (let i = 0; i < this.props.currentJobBidItems.length; i++) {
      if (this.props.currentJobBidItems[i].FinalRevenue === 0) {
        updatedBidItems[i].FinalRevenue = 0;
      }
    }

    BidItemCalculations.calculateBidItemDetailsFinalCostField(updatedBidItems);
    BidItemCalculations.setBidItemFinalRevenueHardcodeBidItems(updatedBidItems);
    BidItemCalculations.setBidItemGainLossHardcodeBidItems(updatedBidItems);
    BidItemCalculations.setBidItemPrevGLandDelta(updatedBidItems);

    this.setState({ 
      bidItemData: updatedBidItems.map(el => el.ChgNumber === "000" ? { ...el, BidItemDisplay: el.BidItem } : { ...el, BidItemDisplay: "-" }),
    });

    await this.getUsername()
    // Filters preferences
    const waitForUsername = (): Promise<string> => {
      return new Promise((resolve) => {
        const checkUsername = () => {
          if (this.state.username !== "") {
            resolve(this.state.username);
          } else {
            setTimeout(checkUsername, 100); 
          }
        };
        checkUsername();
      });
    };
  
    const userName = await waitForUsername();
    
    let errorFilterPreferences
    let resFilterPreferences

    if (userName !== "") {
      const filterService = new FilterService();
      [errorFilterPreferences, resFilterPreferences] = await filterService.getFilterApi(userName);

    }
    this.setState({bidItemStructure : resFilterPreferences !== undefined 
      ? reorderObject(resFilterPreferences.data.data, bidItemDictionary) 
      : bidItemDictionaryDefault})

     await this.getLastBidItemVisited()
     this.props.setLastBidItemNumber("000")
     this.props.setLastChgNumber("000")
    }
  
  //#region "OnValueChanged and OnSubmit"
  editor(props: any, key: string) {
    return <InputText type="text" value={props.rowData[key]} onFocus={this.handleFocus} onChange={(e) => this.onEditorValueChange(props, e.target)} />;
  }

  onEditorCancel(props: any) {
    let updatedBidItems: BidItemToBeSaved[] = BidItemCalculations.calculateBidItemFields(props, this.state.inputInitialValue);

    this.setState({
      bidItemData: updatedBidItems,
      inputInitialValue: ''
    });
  }

  onEditorValueChange(props: any, input: any) {
    this.setState({ changeDetected: true });
    let myInput: string = "";

    myInput = numbersAndDotOnly(input.value);

    let updatedBidItems: BidItemToBeSaved[] = BidItemCalculations.calculateBidItemFields(props, myInput);
    this.setState({ bidItemData: updatedBidItems });    
  }

  onEditorSubmit(props: any) {
    this.setState({ loading: true });
    if (this.state.changeDetected) {
      if (props.field === "FinalRevenue" || props.field === "FinalQuantity" || props.field === "QtyAdjustment") {
        this.saveBidItem(props, true);
      } else {
        this.saveBidItem(props, false);
      }

      this.setState({
        changeDetected: false,
        inputInitialValue: ''
      });
    }else{
      this.setState({ loading: false });
    }
  }

  async updateExecutiveSummaryNewData(taskCodes: any){
    try {
      const _date = getMonthYearForExecutiveSummary();
      this.props.setLoadingExecutiveSummary({ setLoading: true });
    
      const [error, response] = await this.executiveSummaryService.getExecutiveSummary(this.props.jobNumber.toString(), parseInt(_date.month), parseInt(_date.year));

      if (taskCodes && response?.data && response.data.length > 0) {
        for (let i = 0; i < taskCodes.length; i++) {
          let updateExecutive = false;
          const dateNow = new Date(response.data[0].DateChanges);

          const mostRecent = taskCodes.reduce((latest: { LastUpdated: string | number | Date; }, current: { LastUpdated: string | number | Date; }) => {
            return new Date(current.LastUpdated) > new Date(latest.LastUpdated) ? current : latest;
          });
          const datedb = new Date(mostRecent.LastUpdated);
          if (dateNow < datedb) {
            updateExecutive = true;
          }
    
          if (updateExecutive) {
            const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await this.executiveSummaryService.updateExecutiveSummary(this.props.jobNumber.toString(), parseInt(_date.month), parseInt(_date.year), "Forecast System");
    
            if (updateExecutiveSummaryResponse) {
              this.props.setLoadingExecutiveSummary({ setLoading: false });
              return this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
            } else if (updateExecutiveSummaryError) {
              this.props.setLoadingExecutiveSummary({ setLoading: false });
              return this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: updateExecutiveSummaryError, life: 5000 })
            }
          }
        }
      }
    } catch (error) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: error, life: 5000 })
    } finally {
      this.props.setLoadingExecutiveSummary({ setLoading: false });
      this.setState({ loading: false });
    }    
  }
  async getUsername() {
    //Setting username for Submit flag
			let url: string = window.location.href;
			let region: string;

			if (url.includes('qa')) {
  			region = "us-west-1";
			} else {
  			region = "us-west-2"	
			}
      
      let accessToken = this.props.accessToken;
      
      if (accessToken) {
				AWS.config.update({ region: region });
				let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
				let params = {
					AccessToken: accessToken
				};
				await cognitoidentityserviceprovider.getUser(params, (err, data) => {
					if (err) {
            console.error(err, err.stack);
            this.props.history.push(`/logout`);
					} else {
            this.setState({ username: data.Username },()=>this.props.setUsername(this.state.username))
					}
				});
    }
 }

  async saveBidItem(props: any, HasEditedFields: boolean) {
    const { rowIndex: index, field } = props;
    // @ts-ignore
    let myValue: string = this.state.bidItemData[index][field];
    let updatedBidItems: any[] = [...this.state.bidItemData];

    if (isNaN(parseFloat(myValue))) {
      myValue = "0.00";
    } else if (myValue.toString().slice(myValue.length - 1) === ".") {
      myValue += "0";
    }

    updatedBidItems[index][field] = toLocaleStringUS(parseFloatRemoveComma(myValue));
    if (HasEditedFields) {
      updatedBidItems[index].HasEditedFields = true;
    }

    this.setState({ bidItemData: updatedBidItems });

    let bidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(updatedBidItems[index], this.props.jobNumber.toString(), this.props.jobDescription);


    this.props.bidItemSaved({
      BidItemToBeSaved: bidItemToBeSaved,
      JobNumber: this.props.jobNumber,
      JobDescription: this.props.jobDescription
    });

    try {
      this.props.setLoadingExecutiveSummary({ setLoading: true });
      
      const [saveBidItemError, saveBidItemResponse] = await this.bidItemsService.saveBidItemToDB([bidItemToBeSaved]);
      if (saveBidItemError !== null) {
        this.toastTR?.current?.show({ severity: 'error', summary: 'Saved Bid Items Error', detail: "Saved Bid Items Error! ", life: 5000 });
      }
      if (saveBidItemResponse != null) {
        const _date = getMonthYearForExecutiveSummary();
        const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await this.executiveSummaryService.updateExecutiveSummary(this.props.jobNumber.toString(), parseInt(_date.month), parseInt(_date.year), this.state.username);
    
        if (updateExecutiveSummaryResponse) {
          this.toastTR?.current?.show({ severity: 'success', summary: 'Task code, Bid Item and Executive Summary updated', detail: "Task code, Bid Item and Executive Summary was successfully updated!", life: 5000 });
        } else {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: updateExecutiveSummaryError, life: 5000 });
        }
      }
    } catch (error) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: error, life: 5000 });
    } finally {
      this.props.setLoadingExecutiveSummary({ setLoading: false });
      this.setState({ loading: false });
    }
    
    
    // We reset here the Current Bid Item so that it can be re-initialized when you arrive on the Bid Details
    this.props.setCurrentBidItems();
    
    this.setState({ isSavedBidItem: true });
  }
  //#endregion

  actionTemplate(rowData: any, column: any) {
    return <Button type="button" icon="pi-md-search" className="p-button-success" style={{ marginRight: '.5em' }} onClick={() => {
      this.props.setCurrentBidItemNumber(rowData["BidItem"]);
      this.props.setLastBidItemNumber(rowData["BidItem"])
      this.props.setLastChgNumber(rowData["ChgOrderDesc"])
      this.props.setCurrentBidItemDisplay(rowData["BidItemDisplay"]);
      this.props.setCurrentChangeNumber({
        ChgNumber: rowData["ChgNumber"],
        ChgNumberDesc: rowData["ChgOrderDesc"]
      });
      this.props.location.pathname = "/bidDetails";
    }} />
  } 

  getFirstPageWhereCCOStarts = () => {
    let firstPage;
  
    if (this.state.globalSearch.length > 0) {
        firstPage = this.state.bidItemFiltered.findIndex((bi: { ChgNumber: string; }) => bi.ChgNumber !== "000");
    } else {
        firstPage = this.props.currentJobBidItems.findIndex(bi => bi.ChgNumber !== "000")
    }

    const contentToSearch = this.props.currentJobBidItems[firstPage].ChgOrderDesc;

    
    const elements = document.querySelectorAll('td');

    for (let i = 0; i < elements.length; i++) {
        if (elements[i].innerHTML.includes(contentToSearch)) {
            elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
            break; 
        }
    }
};

getLastBidItemVisited = () => {
  let index;
  let indexToSearch = this.props.lastBidItemNumber;
  let indexChgNumber = this.props.lastChgNumber;

  if (this.props.lastBidItemNumber !== "000" && this.props.lastBidItemNumber !== "-") {
    index = this.props.currentJobBidItems.findIndex(bi => bi.BidItem === indexToSearch);
    if (index !== -1) {
      const contentToSearch = this.props.currentJobBidItems[index].BidItem;
      const elements = document.querySelectorAll('td');

      for (let i = 0; i < elements.length; i++) {
        const textContent = elements[i]?.textContent?.trim();
        if (textContent === contentToSearch) {
          elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
          break;
        }
      }
    }
  } else if (this.props.lastChgNumber !== "000") {
    index = this.props.currentJobBidItems.findIndex(bi => bi.ChgOrderDesc === indexChgNumber);
    if (index !== -1) {
      const contentToSearch = this.props.currentJobBidItems[index].ChgOrderDesc;
      const elements = document.querySelectorAll('td');

      for (let i = 0; i < elements.length; i++) {
        const textContent = elements[i]?.textContent?.trim();
        if (textContent === contentToSearch) {
          elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
          break;
        }
      }
    }
  }
}


goToTheTop = () => {
  const tbody = document.querySelector('.p-datatable-tbody');
  if (tbody && tbody.firstElementChild) {
    tbody.firstElementChild.scrollIntoView({ behavior: 'smooth' });
  }
};

goToTheBottom () {
  const scrollBottomElement = document.getElementById('scrollBottom');
            const bottomElement = document.getElementById('bottom');
            if (scrollBottomElement && bottomElement) {
                scrollBottomElement.addEventListener('click', function(e) {
                    e.preventDefault();
                    bottomElement.scrollIntoView({ behavior: 'smooth' });
                });
            }
}


  downloadExcel() {
    
    let bidItems: BidItemToBeSaved[] = this.state.bidItemData;
    const taskCodes = this.props.currentJobTaskCodes;
    const filePath = `Forecast-jobnumber${this.props.jobNumber}-revenue-and-costs.xlsx`;

    for(let i = 0; i < bidItems.length; i++){
        bidItems[i].RemCost = parseFloatRemoveComma(bidItems[i].RemCost)
    }

    exportBidItemsAndTaskCodes({ bidItems, taskCodes }, filePath);
  }

  handleChange: any = (event: any) => {
    this.setState({ globalSearch: (event.target as HTMLTextAreaElement).value });
  }

  setGlobalSearchBidItems  = (search: any, filteredData?: any[]) => {
    let currentBidItems: BidItemToBeSaved[] = filteredData ?? this.state.bidItemData;
    
    let globalSearchbidItem = currentBidItems.filter((bidItem) => (  
      (bidItem?.BidItem).includes(search)
      || (bidItem?.BidItemDescription).toLowerCase().includes(search)
      || parseFloatRemoveComma(bidItem?.Amount).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalCost).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalQuantity).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalRevenue).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.GainLoss).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.UnitPrice).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.JTDQty).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.Changes).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.PreviousFinalCost).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.JTDQty).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.RemCost).toFixed(2).includes(search)
      )
    )    
    
    this.setState({ bidItemFiltered: [...globalSearchbidItem]});
  }

  handleRemCostFilter() {
    const newFilters = {
      ...this.state.filters,
      RemCost: !this.state.filters.RemCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  handleDeltaFilter() {
    const newFilters = {
      ...this.state.filters,
      Changes: !this.state.filters.Changes,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  handleJTDCostFilter() {
    const newFilters = {
      ...this.state.filters,
      JTDCost: !this.state.filters.JTDCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  handleMTDCostFilter() {
    const newFilters = {
      ...this.state.filters,
      MTDCost: !this.state.filters.MTDCost,
    };

    localStorage.setItem('filters', JSON.stringify(newFilters));

    this.setState({ filters: newFilters });
  }

  cleanJsonString = (input: any) => {
      let cleanedString = input.replace(/\\/g, '');
     
      cleanedString = cleanedString.replace(/'/g, '"');
  
      const parsedJson = JSON.stringify(cleanedString);
      return parsedJson;
  }

  onOpenJobHistory = async (e: any) => {

  const date = new Date(e).toLocaleString('en-US', { timeZone: 'Europe/Chisinau' })

  const utcDate = date.split("T")[0]
  const year = new Date(date).getFullYear()
  
  if(date === null || year === 1970){
    return null
  }else{
    const url = `/jobHistory?jobNumber=${encodeURIComponent(this.props.jobNumber)}&jobDescription=${encodeURIComponent(this.props.jobDescription)}&date=${encodeURIComponent(utcDate)}`;

    window.open(url, '_blank');
    }
    this.toggleCalendarVisibility()
  }

  onCloseJobHistory = () => {
    this.setState({isJobHistoryVisible: false});
  };

  onOpenEditColumns = (e:any) => {
    this.setState({isEditColumnsVisible: true});
  };

  onCloseEditColumns = () => {
    this.setState({isEditColumnsVisible: false});
  };

  confirmationEditColumns = (columns: string[]) => {
    this.setState({isEditColumnsVisible: false});

    const filterService = new FilterService();
    const userName = this.state.username

    const newObject: Record<string, string> = columns.reduce((acc: any, key) => {
      if (this.bidItemStructureToCompare.hasOwnProperty(key)) {
        acc[key] = this.bidItemStructureToCompare[key]
      }
      return acc;
    }, {});

    this.setState({
      bidItemStructure: reorderObject(newObject, bidItemDictionary)
    })
    
    filterService.postFilterApi(userName, newObject)
  };

  disableSpecificMonths = () => {
    
    let today = new Date();
    let maxDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);

    let minDate = new Date(2021, 10, 1);

    const monthElements = document.querySelectorAll('.p-monthpicker-month');
    const yearElement = document.querySelector('.p-datepicker-year')?.textContent
    const maxNextYear = yearElement ? yearElement > maxDate.getFullYear().toString() : false;    
    
    monthElements.forEach((monthElement, index) => {
      monthElement.classList.remove('p-disabled', 'no-click');
      if(monthElement.textContent !== null){
        const monthName = monthElement.textContent.trim().substring(0, 3);
        const monthNumber = new Date(Date.parse(monthName + ' 1, 2000')).getMonth();

        if (maxDate) {
          if (yearElement !== null || yearElement !== undefined ){
            if ((index > maxDate.getMonth() && yearElement === maxDate.getFullYear().toString()) || (maxNextYear)) {
              monthElement.classList.add('p-disabled');
              monthElement.classList.add('no-click');
            }
          }
          
      }
      }
    });
  }

  toggleCalendarVisibility = () => {
        this.setState(prevState => ({
            displayCalendar: prevState.displayCalendar === 'none' ? 'block' : 'none'
        }));
    }

  scrollToFirstChild = () => {
    const tbody = document.querySelector('.p-datatable-tbody');
    if (tbody && tbody.firstElementChild) {
      tbody.firstElementChild.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  scrollToLastChild = () => {
    const tbody = document.querySelector('.p-datatable-tbody');
    if (tbody && tbody.lastElementChild) {
      tbody.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    }
  };

  handleFinalCostOverwrittenChange = async (arrayTaskcodes: any) => {
    const taskcodesData =  arrayTaskcodes
    const processTaskcode = async (rowData: any) => {
      const taskCodeToUpdate = JSON.parse(JSON.stringify(rowData)) as JobsMenuTaskCodeInterface;
      let preparedTaskCode = TaskCodeCalculations.prepareTaskCodeForDB(taskCodeToUpdate);
      preparedTaskCode.FinalCostOverwritten = 0
      try {
        const [saveBidDetailsError] = await this.bidDetailsService.saveBidDetails(preparedTaskCode)
        if (saveBidDetailsError !== null) {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Save Task Code Error', detail: saveBidDetailsError.message, life: 5000 });
          throw new Error(saveBidDetailsError.message);
        }
        this.setState({ tasksWithFinalCostOverwritten: [] });
      }
      catch(error){
        console.error(error)
      }
  }

    try {
      this.setState({ loading: true });
      await Promise.all(taskcodesData.map(processTaskcode));
    } finally {
      this.setState({ loading: false });
    }
    
  };
  

  confirmationDialogOnConfirmFinalCost() {
    this.setState({
      confirmationDialogVisibleFinalCost: false,
      confirmationDialogMessageFinalCost: ''
    });
    this.handleFinalCostOverwrittenChange(this.state.tasksWithFinalCostOverwritten)
  }

  confirmationDialogOnCancelFinalCost() {
    this.setState({
      confirmationDialogVisibleFinalCost: false,
      confirmationDialogMessageFinalCost: ''
    });
    this.setState({ tasksWithFinalCostOverwritten: [] });
  }  

  render() {
    const remCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleRemCostFilter} checked={this.state.filters.RemCost} />
        </span>
      </div>
    )

    const deltaHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleDeltaFilter} checked={this.state.filters.Changes} />
        </span>
      </div>
    )

    const jtdCostHeader = (value: any) => {
      return (
          <div className='col-header-with-filter'>
              <span>{value}</span>
              <span>
                  <Checkbox onChange={this.handleJTDCostFilter} checked={this.state.filters.JTDCost} />
              </span>
          </div>
      );
  };
    const mtdCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleMTDCostFilter} checked={this.state.filters.MTDCost} />
        </span>
      </div>
    )

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    let minDate = new Date();

    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);

    let maxDate = new Date();

    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);

    let bidItemDynamicColumns = [Object.entries(this.state.bidItemStructure).map(([key, value]) => {
      switch (key) {
        case "BidItem":
          return <Column headerStyle={{ textAlign: 'left', width: '1em', minWidth: '1em' }}
            excludeGlobalFilter={true}
            style={{ textAlign: 'left', width: '1em', fontWeight: "900", minWidth: '1em' }} />;
        case "BidItemDisplay":
          return <Column key={key} 
          body={(rowData: any) => (rowData[key] === "950")
          ? "950 /960"
          : rowData[key]}
         field={key} header={value} headerStyle={{ textAlign: 'left', width: '3em', minWidth: '3em', overflowWrap: 'break-word' }}
            style={{ textAlign: 'left', width: '3em', fontWeight: "900", overflowWrap: 'break-word' }} />;
        case "BidItemDescription":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '10em', minWidth: '10em', overflowWrap: 'break-word' }}
            style={{ textAlign: 'left', width: '10em', fontWeight: "900", overflowWrap: 'break-word' }} />;
        case "ChgOrderDesc":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '4em', minWidth: '4em' }}
            style={{ textAlign: 'left', width: '5em', fontWeight: "900" }} />;
        case "UM":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', minWidth: '3em' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : toLocaleStringUS(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px'}} />;
        case "QTYBilled":
        case "Amount":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFF9AB' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
        case "UnitPrice":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFF9AB' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
        case "QtyAdjustment":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
            editor={
            (props) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData) || this.IsThisPCItem(props.rowData)
              ? displayTwoDigits(props.rowData[key]) === "0.00"
              ? props.rowData[key]
              : displayTwoDigits(props.rowData[key])
              : this.editor(props, key)
            }
            onEditorCancel={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorCancel(props)}
            onEditorSubmit={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorSubmit(props)}
            body={(rowData: any) => this.IsThisHardcodedBidItem(rowData) || this.IsThisPCItem(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px'}}
          />;
          case "MTDCost":
            return <Column key={key} field={key} header={mtdCostHeader(value)} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
              body={(rowData: any) => this.IsThisHardcodedBidItem(rowData)
                ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
                : isNaN(parseFloat(rowData[key]))
                  ? rowData[key]
                  : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
              style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
          
          case "JTDCost":
          return <Column key={key} field={key} header={jtdCostHeader(value)} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
            body={(rowData: any) => this.IsThisHardcodedBidItem(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
            case "JTDQty":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
            body={(rowData: any) => this.IsThisHardcodedBidItem(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
        case "FinalQuantity":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFF9AB' }}
            editor={
            (props) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData) || this.IsThisPCItem(props.rowData)
              ? displayTwoDigits(props.rowData[key]) === "0.00"
              ? props.rowData[key]
              : displayTwoDigits(props.rowData[key])
              : this.editor(props, key)
            }
            onEditorCancel={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorCancel(props)}
            onEditorSubmit={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorSubmit(props)}
            body={(rowData: any) => this.IsThisHardcodedBidItem(rowData)
              ? displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px'}} />;
        case "FinalRevenue":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FFF9AB' }}
            editor={
            (props) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData) || this.IsThisPCItem(props.rowData)
              ? displayTwoDigits(props.rowData[key]) === "0.00"
              ? props.rowData[key]
              : displayTwoDigits(props.rowData[key])
              : this.editor(props, key)
              }
            onEditorCancel={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData)
              ? undefined
              : this.onEditorCancel(props)}
            onEditorSubmit={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData)
              ? undefined
              : this.onEditorSubmit(props)}
            body={(rowData: any) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" || isNaN(parseFloat(rowData[key])) ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px'}} />;
        case "FinalCost":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
            body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
        case "RemCost":
          return <Column key={key} field={key} header={remCostHeader(value)} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
            body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px'}} />;
        case "GainLoss":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#FAD8B1' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
        case "PreviousFinalCost":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
        case "Changes":
          return <Column key={key} field={key} header={deltaHeader(value)} headerStyle={{ textAlign: 'center', width: '6em', minWidth: '6em', backgroundColor: '#92D050' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
        case "HasEditedFields":
        case "BidComments":
          return null
        default:
          return <Column key={key} field={key} header={value}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em', padding: '0px 10px' }} />;
      }
    })];

    let header = (
      <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
        <div style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap'}}>
          <div style={{ 'paddingRight' : '12px'}}>Bid Items</div>
          <div onClick={() => setTimeout(() => this.disableSpecificMonths(), 100)}>
          <Button style={{ marginTop: '3px' }}label='Scroll to the Top ▲' onClick={(e) => { e.preventDefault(); this.scrollToFirstChild(); }} />
          <Button style={{ marginBottom: '3px' }} label='Scroll to the Bottom ▼' onClick={(e) => { e.preventDefault(); this.scrollToLastChild(); }}/>
            <label className='button-prime' htmlFor="month_date">Select Month History</label>
            <Calendar 
            inputStyle={{ 
              color: 'transparent', 
              textShadow: '0 0 0 transparent', 
              textDecoration: 'none', 
              background: 'transparent', 
              userSelect: 'none', 
              caretColor: 'transparent',
              pointerEvents: 'none'
            }}
            readOnlyInput 
            className='no-text' 
            inputId="month_date" 
            value={date} 
            onChange={(e) => this.onOpenJobHistory(e.value)} 
            view="month"
            />

            </div>
        </div>
        <div className="p-datatable-globalfilter-container">
        
        <Button
            className="executive-summary"
            onClick={(e) => this.onOpenEditColumns(e)}
            label='Edit Columns'
            //style={{ cursor: isExecutiveSummaryButtonDisabled ? 'not-allowed' : 'pointer'  }}
          />
        
          <InputText type="search" onInput={this.handleChange} placeholder="Global Search" sizes="50" value={this.state.globalSearch} />
        </div>
      </div>
    );

    return (
      <div>
        <Growl ref={this.toastTR} />
        <div style={{'display': 'flex', 'justifyContent': 'center'}}>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-col-12">
              <div className="card card-w-title" style={{ marginBottom: '0px'}} ref={this.dataTableWrapperRef}>
                <div className="biditems-table-title">
                  <div className="biditems-title-link">
                    <h1>Bid Items</h1> 
                    {
                      this.props.currentJobBidItems.findIndex(bi => bi.ChgNumber !== "000")
                        ? <div 
                          className="go_to_cco" 
                          onClick={() => this.getFirstPageWhereCCOStarts()}>
                            Go to the first page where CCOs start
                          </div>
                        : null
                    }
                  </div>
                  
                  <span className="create_excel" onClick={() => this.downloadExcel()} title="Download report sheet">
                      {!this.state.loading && <i className='pi pi-download'></i>}
                  </span>
                </div>
                <DataTable
                  value={(this.state.isFiltered || this.state.globalSearch)
                    ? this.state.bidItemFiltered
                    : this.state.bidItemData
                  }
                  header={header}
                  globalFilter={this.state.globalFilter}
                  rowClassName={this.rowClass}
                  autoLayout={true}
                  loading={this.state.loading}
                  loadingIcon={'pi-md-refresh'}
                  className="bid-items-table"
                  scrollable
                  scrollHeight={this.state.scrollHeight}
                  style={{ width: '100%', margin: '0 auto', overflowX: 'scroll' }} 
                > 
                  <Column header={""} body={this.actionTemplate} style={{ textAlign: 'center', width: '3em' }} />
                  {bidItemDynamicColumns}
                </DataTable>
                
              </div>
            </div>
          </div>
        </div>
        <EditColumns
          visible={this.state.isEditColumnsVisible}
          title={"Edit columns"}
          onConfirm={this.confirmationEditColumns}
          onCancel={this.onCloseEditColumns}
          onHide={this.onCloseEditColumns}
          Structure={this.state.bidItemStructure}
        />
        <ConfirmationDialogFinalCost
          onHide={this.confirmationDialogOnCancelFinalCost}
          visible={this.state.confirmationDialogVisibleFinalCost}
          title={
            <>
              The following task codes have had their Final Cost overwritten because the JTD Cost exceeded the hardcoded forecast final cost.
              <br />
              <strong>WARNING:</strong> Please make sure you save the info before the window closes.
            </>
          }
          message={this.state.confirmationDialogMessageFinalCost}
          onConfirm={this.confirmationDialogOnCancelFinalCost}
          
        />
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    jobNumber: state.app.currentJobNumber,
    jobDescription: state.app.currentJobDescription,
    currentJobBidItems: state.app.jobsMenuItems?.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.BidItems || [],
    currentJobTaskCodes: state.app.jobsMenuItems?.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.TaskCodes || [],
    currentJobNumbers: state.app.jobsMenuItems,
    resetJobInProgress: state.app.resetJobInProgress,
    lastUpdated: state.app.lastUpdated,
    isJobDataLoading: state.app.isJobDataLoading,
    accessToken: state.app.auth.token,
    lastBidItemNumber: state.app.lastBidItemNumber,
    lastChgNumber: state.app.lastChgNumber,
    hasDisplayedFinalCostMessage: state.app.hasDisplayedFinalCostMessage
  };
}

// TODO: research if you can replace this "any"
function matchDispatchToProps(dispatch: any) {
  return bindActionCreators({
    setCurrentBidItemNumber: setCurrentBidItemNumber,
    setCurrentJobNumberAndDescription: setCurrentJobNumberAndDescription,
    setCurrentChangeNumber: setCurrentChangeNumber,
    setCurrentBidItemDisplay: setCurrentBidItemDisplay,
    setCurrentBidItems: setCurrentBidItems,
    setJobsMenuNumberAndDescriptions: setJobsMenuNumberAndDescriptions,
    setJobsMenuBidItemsAndTaskCodes: setJobsMenuBidItemsAndTaskCodes,
    bidItemSaved: bidItemSaved,
    maintainAllLinkedTaskCodesToBidItems: maintainAllLinkedTaskCodesToBidItems,
    setChangeJobInProgress: setChangeJobInProgress,
    setExecutiveSummary: setExecutiveSummary, 
    setResetJobInProgress: setResetJobInProgress,
    setLoadingExecutiveSummary: setLoadingExecutiveSummary,
    setUsername: setUsername,
    setPrevGLandDeltaCCOs: setPrevGLandDeltaCCOs,
    setTaskcodesWithoutBidItem: setTaskcodesWithoutBidItem,
    setTaskcodesWithFlagsFC: setTaskcodesWithFlagsFC,
    setLastBidItemNumber: setLastBidItemNumber,
    setLastChgNumber: setLastChgNumber,
    setHasDisplayedFinalCostMessage: setHasDisplayedFinalCostMessage
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(BidItems);