import * as xlsx from "xlsx";
import path from 'path';
import { BidItemToBeSaved, JobsMenuTaskCodeInterface, CCOInTaskCode } from "../interfaces/Interfaces";

interface ISheetItems {
    bidItems: BidItemToBeSaved[]
    taskCodes: JobsMenuTaskCodeInterface[]
}

interface ISheetData {
    data: CellsDataType[][]
    sheetName: string
}

type CellsDataType = string | { v: number, t: string, z: string }

interface IColWidth {wch: number}

const USNumberFormat = '#,###.00';
const cellUSNumberFormat = {
    t: 'n',
    z: USNumberFormat
}

const bidItemsSheetName = 'BidItems';
const taskCodesSheetName = 'TaskCodes';
const taskCodesCCOsSheetName = 'TaskCodes CCOs';

const exportExcel = (data: ISheetData[], filePath: string) => {
    const workBook = xlsx.utils.book_new();

    data.forEach(items => {
        const workSheet = xlsx.utils.aoa_to_sheet(items.data);
        workSheet["!cols"] = calcColumnsWidth(items.data);

        xlsx.utils.book_append_sheet(workBook, workSheet, items.sheetName);
    })

    xlsx.writeFile(workBook, path.resolve(filePath));
}

export const exportBidItemsAndTaskCodes = (elements: ISheetItems, filePath: string) => {
    const bidItemsData = mapBidItemsToSheet(elements.bidItems);
    const taskCodesData = mapTaskCodesToSheet(elements.taskCodes);
    const taskCodesCCOsData = mapTaskCodesCCOsToSheet(elements.taskCodes);

    const data = [
        {
            data: bidItemsData,
            sheetName: bidItemsSheetName
        },
        {
            data: taskCodesData,
            sheetName: taskCodesSheetName
        },
        {
            data: taskCodesCCOsData,
            sheetName: taskCodesCCOsSheetName
        },
    ]

    exportExcel(data, filePath);
}

export const exportBidItemsToExcel = (elements: BidItemToBeSaved[], filePath: string) => {
    const bidItems = mapBidItemsToSheet(elements);
    const data = {
        data: bidItems,
        sheetName: bidItemsSheetName
    }

    exportExcel([data], filePath);
}

export const exportTaskCodesToExcel = (elements: JobsMenuTaskCodeInterface[], filePath: string) => {
    const taskCodes = mapTaskCodesToSheet(elements);
    const data = {
        data: taskCodes,
        sheetName: taskCodesSheetName
    }

    exportExcel([data], filePath);
}

export const exportDataWarningToExcel = (taskCodeNumbersWithoutBidItems: any[], taskCodeNumbersWithFlagFc: any[], jobNumber: string) =>{
  
    const data = [['Taskcode not being displayed', 'Taskcodes with Final Cost lower than JTD Cost'],
    ...taskCodeNumbersWithoutBidItems.map((taskcode, index) => [taskcode, taskCodeNumbersWithFlagFc[index] || '']),
    ...taskCodeNumbersWithFlagFc.slice(taskCodeNumbersWithoutBidItems.length).map((taskcode) => ['', taskcode]),
    ];
    const sheet = xlsx.utils.aoa_to_sheet(data);

    const worksheet = xlsx.utils.sheet_add_aoa(sheet, data, { origin: 'A1' });
  
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'TaskcodesWithWarnings');
  
    xlsx.writeFile(workbook, `TaskcodesWithWarningsin-${jobNumber}.xlsx`);
}

const mapBidItemsToSheet = (bidItems: BidItemToBeSaved[]) => {
    const columnNames = [
        'BidItem', 
        'ChgOrderDesc', 
        'Description', 
        'UOM',
        'Bid QTY',
        'Bid UP', 
        'Bid Total',
        'QTY Adj',
        'JTD Qty',
        'Final QTY', 
        'Final Rev',
        'Final Cost',
        'Rem Cost',
        'G/L', 
        'Prev Final Cost',
        'Delta'
    ];

    const bidItemsSorted = [...bidItems].sort((a, b) => {
        if (isNaN(Number(a.BidItem))) {
            return 1;
        }

        return Number(a.BidItem) - Number(b.BidItem)
    });

    const data = bidItemsSorted.map((element) => {
        return [
            element.BidItem, 
            element.ChgOrderDesc, 
            element.BidItemDescription, 
            element.UM,
            { ...cellUSNumberFormat, v: element.QTYBilled },
            { ...cellUSNumberFormat, v: element.UnitPrice },
            { ...cellUSNumberFormat, v: element.Amount },
            { ...cellUSNumberFormat, v: element.QtyAdjustment },
            { ...cellUSNumberFormat, v: element.JTDQty },
            { ...cellUSNumberFormat, v: element.FinalQuantity },
            { ...cellUSNumberFormat, v: element.FinalRevenue },
            { ...cellUSNumberFormat, v: element.FinalCost },
            { ...cellUSNumberFormat, v: element.RemCost },
            { ...cellUSNumberFormat, v: element.GainLoss },
            { ...cellUSNumberFormat, v: element.PreviousFinalCost },
            { ...cellUSNumberFormat, v: element.Changes },
        ]
    });

    return [columnNames, ...data];
}

const mapTaskCodesToSheet = (taskCodes: JobsMenuTaskCodeInterface[]) => {
    const columnNames = [
        'Taskcode',
        'Description',
        'BidItem',
        'UOM',
        'Budget QTY',
        'Budget UC',
        'Budget Total',
        'JTD Units',
        'JTD UC',
        'JTD Cost',
        'Rem Units',
        'Rem UC',
        'Rem Cost',
        'Final QTY',
        'Final UC',
        'Final Cost',
        'Recovery Percent',
        'Qty Adj',
        'Cost G/L',
        'Delta',
        'Prev Final Cost',
        'Comments',
    ];

    const taskCodesSorted = [...taskCodes].sort((a, b) => Number(a.Taskcode) - Number(b.Taskcode));

    const data = taskCodesSorted.map((tc) => {
        let changes = tc.LastMonthFinalCost - tc.FinalCost;

        return [
            tc.Taskcode,
            tc.TaskcodeDescription,
            tc.BidItem,
            tc.UnitOfMeasure,
            { ...cellUSNumberFormat, v: tc.BudgetUnits },
            { ...cellUSNumberFormat, v: tc.BudgetUnitCost },
            { ...cellUSNumberFormat, v: tc.BudgetTotalCost },
            { ...cellUSNumberFormat, v: tc.JTDUnits },
            { ...cellUSNumberFormat, v: tc.JTDUnitCost },
            { ...cellUSNumberFormat, v: tc.JTDTotalCost },
            { ...cellUSNumberFormat, v: tc.RemainingUnits },
            { ...cellUSNumberFormat, v: tc.RemainingUnitCost },
            { ...cellUSNumberFormat, v: tc.RemainingTotalCost },
            { ...cellUSNumberFormat, v: tc.FinalQuantity },
            { ...cellUSNumberFormat, v: tc.FinalUnitCost },
            { ...cellUSNumberFormat, v: tc.FinalCost },
            { ...cellUSNumberFormat, v: tc.RecoveryPercent },
            { ...cellUSNumberFormat, v: tc.QtyAdjustment },
            { ...cellUSNumberFormat, v: tc.CostGL },
            { ...cellUSNumberFormat, v: changes },
            { ...cellUSNumberFormat, v: tc.LastMonthFinalCost },
            tc.Comments
        ]
    });

    return [columnNames, ...data];
}

const mapTaskCodesCCOsToSheet = (taskCodes: JobsMenuTaskCodeInterface[]) => {
    const columnNames = [
        'Taskcode',
        'Description',
        'Change Number',
        'Change Order Description',
        'UOM',
        'Budget QTY',
        'Budget UC',
        'Budget Total',
        'JTD Units',
        'JTD UC',
        'JTD Cost',
        'Rem Units',
        'Rem UC',
        'Rem Cost',
        'Final QTY',
        'Final UC',
        'Assigned Cost',
        'Allocate total',
        'Qty Adj',
        'Cost G/L',
        'Delta',
        'Prev Final Cost',
        'Comments',
    ];

    const taskCodesCCOs: (JobsMenuTaskCodeInterface & CCOInTaskCode)[] = []

    taskCodes.forEach(tc => {
        if (tc.CCOs?.length) {
            tc.CCOs.forEach(cco => {
                const taskCodeCCO = {
                    ...tc,
                    ChgNumber: cco.ChgNumber,
                    ChgOrderDesc: cco.ChgOrderDesc,
                    AssignedCost: cco.AssignedCost,
                    isTotalAllocated: cco.isTotalAllocated
                }

                taskCodesCCOs.push(taskCodeCCO);
            })
        }
    });

    const taskCodesCCOsSorted = [...taskCodesCCOs].sort((a, b) => Number(a.Taskcode) - Number(b.Taskcode));

    const data = taskCodesCCOsSorted.map(tc => {
        let changes = tc.LastMonthFinalCost - tc.FinalCost;

        return [
            tc.Taskcode,
            tc.TaskcodeDescription,
            tc.ChgNumber,
            tc.ChgOrderDesc,
            tc.UnitOfMeasure,
            { ...cellUSNumberFormat, v: tc.BudgetUnits },
            { ...cellUSNumberFormat, v: tc.BudgetUnitCost },
            { ...cellUSNumberFormat, v: tc.BudgetTotalCost },
            { ...cellUSNumberFormat, v: tc.JTDUnits },
            { ...cellUSNumberFormat, v: tc.JTDUnitCost },
            { ...cellUSNumberFormat, v: tc.JTDTotalCost },
            { ...cellUSNumberFormat, v: tc.RemainingUnits },
            { ...cellUSNumberFormat, v: tc.RemainingUnitCost },
            { ...cellUSNumberFormat, v: tc.RemainingTotalCost },
            { ...cellUSNumberFormat, v: tc.FinalQuantity },
            { ...cellUSNumberFormat, v: tc.FinalUnitCost },
            { ...cellUSNumberFormat, v: tc.AssignedCost },
            tc.isTotalAllocated ? 'Yes' : 'No',
            { ...cellUSNumberFormat, v: tc.QtyAdjustment },
            { ...cellUSNumberFormat, v: tc.CostGL },
            { ...cellUSNumberFormat, v: changes },
            { ...cellUSNumberFormat, v: tc.LastMonthFinalCost },
            tc.Comments
        ]
    });

    return [columnNames, ...data];
}

const calcColumnsWidth = (data: CellsDataType[][]) => {    
    const colsWidths: IColWidth[]= [];
    const cols = data[0].length;
    const colWidth = 12;
    
    for (let i = 0; i < cols; i++) {
        colsWidths.push({ wch: colWidth});
    }

    return colsWidths;
}